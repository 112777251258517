import { HttpClient, HttpHeaders } from '@angular/common/http';
import keys from 'lodash/keys';
import { from, of } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { isSet } from '@shared';
import { NpmPackage } from '../../data/npm-package';
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/api/api.service";
import * as i2 from "@angular/common/http";
var NpmRegistryService = /** @class */ (function () {
    function NpmRegistryService(apiService, http) {
        this.apiService = apiService;
        this.http = http;
    }
    NpmRegistryService.prototype.search = function (projectName, environmentName, search) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'npm_registry/search');
            var headers = new HttpHeaders();
            var data = {
                search: search
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new NpmRegistryService.SearchResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    NpmRegistryService.prototype.getDetail = function (projectName, environmentName, name) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, "npm_registry/details/" + name);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new NpmPackage().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    NpmRegistryService.prototype.getExports = function (projectName, environmentName, name, version) {
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var AsyncFunction;
            try {
                var getAsyncFunction = new Function('return Object.getPrototypeOf(async function() {}).constructor');
                AsyncFunction = getAsyncFunction();
            }
            catch (e) { }
            if (!AsyncFunction) {
                return of(undefined);
            }
            try {
                var js = "return await import('https://esm.sh/" + name + (isSet(version) ? "@" + version : '') + "')";
                var x = new AsyncFunction(js);
                return from(x().then(function (value) { return value; }));
            }
            catch (e) { }
            return of(undefined);
        }), map(function (result) {
            if (result) {
                return keys(result);
            }
            else {
                return ['default'];
            }
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    NpmRegistryService.ngInjectableDef = i0.defineInjectable({ factory: function NpmRegistryService_Factory() { return new NpmRegistryService(i0.inject(i1.ApiService), i0.inject(i2.HttpClient)); }, token: NpmRegistryService, providedIn: "root" });
    return NpmRegistryService;
}());
export { NpmRegistryService };
(function (NpmRegistryService) {
    var SearchResponse = /** @class */ (function () {
        function SearchResponse() {
        }
        SearchResponse.prototype.deserialize = function (data) {
            this.items = data['items'].map(function (item) { return new NpmPackage().deserialize(item); });
            return this;
        };
        return SearchResponse;
    }());
    NpmRegistryService.SearchResponse = SearchResponse;
})(NpmRegistryService || (NpmRegistryService = {}));
