/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../select-javascript-dependency-selector/select-javascript-dependency-selector.component.ngfactory";
import * as i2 from "../select-javascript-dependency-selector/select-javascript-dependency-selector.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../npm/sources/npm-package.source";
import * as i5 from "../../../projects/stores/current-project.store";
import * as i6 from "../../../projects/stores/current-environment.store";
import * as i7 from "../../../npm/services/npm/npm-registry.service";
import * as i8 from "../../../npm/sources/npm-package-version.source";
import * as i9 from "../../../npm/sources/npm-package-exports.source";
import * as i10 from "./select-javascript-dependency.component";
var styles_SelectJavaScriptDependencyComponent = [];
var RenderType_SelectJavaScriptDependencyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SelectJavaScriptDependencyComponent, data: {} });
export { RenderType_SelectJavaScriptDependencyComponent as RenderType_SelectJavaScriptDependencyComponent };
function View_SelectJavaScriptDependencyComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-select-javascript-dependency-selector", [], null, [[null, "selectValue"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectValue" === en)) {
        _co.npmPackageControl.patchValue($event);
        var pd_0 = (_co.setSection(_co.sections.Version) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SelectJavascriptDependencySelectorComponent_0, i1.RenderType_SelectJavascriptDependencySelectorComponent)), i0.ɵdid(1, 245760, null, 0, i2.SelectJavascriptDependencySelectorComponent, [i0.ChangeDetectorRef], { title: [0, "title"], itemIcon: [1, "itemIcon"], source: [2, "source"], searchPlaceholder: [3, "searchPlaceholder"], searchMinimumLength: [4, "searchMinimumLength"] }, { selectValue: "selectValue" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Choose package from NPM"; var currVal_1 = "components"; var currVal_2 = _co.npmPackageSource; var currVal_3 = "Package name..."; var currVal_4 = 2; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_SelectJavaScriptDependencyComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-select-javascript-dependency-selector", [], null, [[null, "selectValue"], [null, "back"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectValue" === en)) {
        _co.npmPackageVersionControl.patchValue($event);
        var pd_0 = (_co.setSection(_co.sections.Imports) !== false);
        ad = (pd_0 && ad);
    } if (("back" === en)) {
        var pd_1 = (_co.setSection(_co.sections.Package) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectJavascriptDependencySelectorComponent_0, i1.RenderType_SelectJavascriptDependencySelectorComponent)), i0.ɵdid(1, 245760, null, 0, i2.SelectJavascriptDependencySelectorComponent, [i0.ChangeDetectorRef], { title: [0, "title"], itemIcon: [1, "itemIcon"], source: [2, "source"], searchPlaceholder: [3, "searchPlaceholder"], backEnabled: [4, "backEnabled"], backTitle: [5, "backTitle"] }, { selectValue: "selectValue", back: "back" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Choose package version"; var currVal_1 = "tag"; var currVal_2 = _co.npmPackageVersionSource; var currVal_3 = "Package version..."; var currVal_4 = true; var currVal_5 = "Back to package list"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_SelectJavaScriptDependencyComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-select-javascript-dependency-selector", [], null, [[null, "selectValue"], [null, "back"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectValue" === en)) {
        _co.npmPackageExportsControl.patchValue($event);
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } if (("back" === en)) {
        var pd_1 = (_co.setSection(_co.sections.Version) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectJavascriptDependencySelectorComponent_0, i1.RenderType_SelectJavascriptDependencySelectorComponent)), i0.ɵdid(1, 245760, null, 0, i2.SelectJavascriptDependencySelectorComponent, [i0.ChangeDetectorRef], { multiple: [0, "multiple"], source: [1, "source"], searchPlaceholder: [2, "searchPlaceholder"], backEnabled: [3, "backEnabled"], backTitle: [4, "backTitle"] }, { selectValue: "selectValue", back: "back" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.npmPackageExportsSource; var currVal_2 = "Package imports..."; var currVal_3 = true; var currVal_4 = "Back to version list"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_SelectJavaScriptDependencyComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectJavaScriptDependencyComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectJavaScriptDependencyComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SelectJavaScriptDependencyComponent_3)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.section == _co.sections.Package); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.section == _co.sections.Version); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.section == _co.sections.Imports); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_SelectJavaScriptDependencyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-select-javascript-dependency", [], null, null, null, View_SelectJavaScriptDependencyComponent_0, RenderType_SelectJavaScriptDependencyComponent)), i0.ɵprd(512, null, i4.NpmPackageSource, i4.NpmPackageSource, [i5.CurrentProjectStore, i6.CurrentEnvironmentStore, i7.NpmRegistryService]), i0.ɵprd(512, null, i8.NpmPackageVersionSource, i8.NpmPackageVersionSource, [i5.CurrentProjectStore, i6.CurrentEnvironmentStore, i7.NpmRegistryService]), i0.ɵprd(512, null, i9.NpmPackageExportsSource, i9.NpmPackageExportsSource, [i5.CurrentProjectStore, i6.CurrentEnvironmentStore, i7.NpmRegistryService]), i0.ɵdid(4, 245760, null, 0, i10.SelectJavaScriptDependencyComponent, [i4.NpmPackageSource, i8.NpmPackageVersionSource, i9.NpmPackageExportsSource, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var SelectJavaScriptDependencyComponentNgFactory = i0.ɵccf("app-select-javascript-dependency", i10.SelectJavaScriptDependencyComponent, View_SelectJavaScriptDependencyComponent_Host_0, { origin: "origin", trigger: "trigger" }, { selected: "selected" }, []);
export { SelectJavaScriptDependencyComponentNgFactory as SelectJavaScriptDependencyComponentNgFactory };
