import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RuntimeEnvironment } from '@core';
import { ActionService } from '@modules/action-queries';
import { ViewContext } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { Workflow } from '@modules/workflow';
import { controlValue } from '@shared';

import { WorkflowEditController } from '../../../../services/workflow-edit-controller/workflow-edit.controller';
import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-workflow',
  templateUrl: './customize-bar-action-edit-type-workflow.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeWorkflowComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() analyticsSource: string;

  workflow: Workflow;
  parameters: ParameterField[] = [];

  constructor(
    private actionService: ActionService,
    private workflowEditController: WorkflowEditController,
    private contextTokenProvider: ViewContextTokenProvider,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(
      controlValue<Workflow>(this.form.controls.workflow),
      controlValue<ParameterField[]>(this.form.controls.action_params)
    )
      .pipe(untilDestroyed(this))
      .subscribe(([workflow, parameters]) => {
        this.workflow = workflow;
        this.parameters = parameters;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  edit(options: { customizeTrigger?: boolean } = {}) {
    let workflow: Workflow;
    const parameters: ParameterField[] = cloneDeep(this.parameters);

    if (this.workflow) {
      workflow = cloneDeep(this.workflow);
    } else {
      workflow = new Workflow();
      workflow.generateUid();
    }

    this.workflowEditController
      .open({
        runtime: RuntimeEnvironment.Browser,
        create: !this.workflow,
        workflow: workflow,
        workflowRun: workflow.testRun,
        workflowEditable: true,
        parametersEnabled: true,
        parameters: parameters,
        ...(this.context && {
          context: this.context,
          contextTokenProvider: this.contextTokenProvider
        }),
        triggerLabel: this.form.options.actionLabel,
        customizeTrigger: options.customizeTrigger,
        historyEnabled: true,
        resultEnabled: true,
        analyticsSource: ['component', this.analyticsSource].join('_')
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        result.workflow.testRun = result.workflowRun;

        this.form.controls.workflow.patchValue(result.workflow);
        this.form.controls.action_params.patchValue(result.parameters);
        this.cd.markForCheck();
      });
  }
}
