var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ITEM_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { SidebarCollapseContext } from '@modules/sidebar';
import { CustomViewTemplateType } from '@modules/views';
import { isSet } from '@shared';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarListLayoutSettingsComponent } from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.component';
import { CustomizeBarGridSettingsDataSourceComponent } from './customize-bar-grid-settings-data-source/customize-bar-grid-settings-data-source.component';
import { CustomizeBarGridSettingsForm, GridCardsPerRow } from './customize-bar-grid-settings.form';
var CustomizeBarGridSettingsComponent = /** @class */ (function (_super) {
    __extends(CustomizeBarGridSettingsComponent, _super);
    function CustomizeBarGridSettingsComponent(form, customizeBarContext, dialogService, analyticsService, cd) {
        var _this = _super.call(this, form, customizeBarContext, dialogService, analyticsService, cd) || this;
        _this.form = form;
        _this.itemContextElementPath = [ITEM_OUTPUT];
        _this.selectedItemContextElementPath = [SELECTED_ITEM_OUTPUT];
        _this.dataSourceEditComponent = CustomizeBarGridSettingsDataSourceComponent;
        _this.collapseContext = new SidebarCollapseContext();
        _this.actionsRequiredFields = ['card_click_action', 'actions', 'model_actions'];
        _this.groupsEditOverlayOpenedInitial = false;
        _this.groupsAddMissingLoading = false;
        _this.customViewTemplateTypes = CustomViewTemplateType;
        _this.cardsPerRow = GridCardsPerRow;
        return _this;
    }
    CustomizeBarGridSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.form.context = this.context;
        this.form.contextElement = this.contextElement;
        this.columnParameters$ = this.form.controls.data_source.controls.columns.getParameters$();
        this.columnOptions$ = this.form.controls.data_source.getColumnOptions$();
        this.dataConfigured$ = this.form.controls.data_source.getDataConfigured$();
        this.groupTitleTextStyleIsDefault = this.form.controls.element_styles.controls.group_title_style.isDefaultStyle$();
        combineLatest(this.form.controls.data_source.getModelDescription$(), this.form.controls.group_field.valueChanges)
            .pipe(filter(function (_a) {
            var modelDescription = _a[0], stage = _a[1];
            return isSet(modelDescription) && isSet(stage);
        }), debounceTime(10), switchMap(function () {
            _this.groupsAddMissingLoading = true;
            _this.cd.markForCheck();
            return _this.form.getMissing();
        }))
            .subscribe(function (result) {
            if (result && result.length) {
                _this.form.controls.groups.addMissingByValues(result);
            }
            _this.groupsAddMissingLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.groupsAddMissingLoading = false;
            _this.cd.markForCheck();
        });
    };
    CustomizeBarGridSettingsComponent.prototype.onGroupFieldChange = function (value) {
        this.groupsEditOverlayOpenedInitial = this.result && !isSet(this.result.settings.groupField) && isSet(value);
        this.cd.markForCheck();
    };
    return CustomizeBarGridSettingsComponent;
}(CustomizeBarListLayoutSettingsComponent));
export { CustomizeBarGridSettingsComponent };
