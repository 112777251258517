import { AfterViewInit, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import Chart from 'chart.js';
import * as Color from 'color';
import cloneDeep from 'lodash/cloneDeep';
import maxBy from 'lodash/maxBy';
import merge from 'lodash/merge';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { interval } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';

import {
  DataGroup,
  Dataset,
  datasetGroupDateLookups,
  DatasetGroupLookup,
  datasetGroupLookupUnitOfTime,
  dateFormatByUnitOfTime,
  groupDatasetByUnitOfTime,
  parseDate
} from '@modules/charts';
import { ThemeService } from '@modules/theme';
import { isColorHex, isSet, numeralFormat } from '@shared';

export interface Colors {
  gridLine: string;
  border: { [color: string]: string };
  gradient: { [color: string]: string };
  gradientOpacity: string;
}

export function safeNumber(value: any): number {
  if (!isSet(value)) {
    return 0;
  } else if (typeof value === 'number') {
    return value;
  }

  const result = parseInt(value, 10);

  if (isNaN(result)) {
    return 0;
  } else {
    return result;
  }
}

export abstract class BaseChartComponent implements OnDestroy, AfterViewInit, OnChanges {
  @Input() datasets: Dataset[] = [];
  @ViewChild('chart_element') chartElement: ElementRef;

  ctx: CanvasRenderingContext2D;
  chart: Chart;
  datasetsCurrent: Dataset[] = [];
  colors: { [color: string]: Colors } = {
    default: {
      gridLine: 'rgba(0, 0, 0, 0.05)',
      border: {
        aqua: '#6ed5d4',
        black: '#000',
        blue: '#007fd2',
        'bright-blue': '#1755e5',
        fuchsia: '#cc99ff',
        gray: '#808080',
        green: '#4bcc29',
        lime: '#baf164',
        maroon: '#800000',
        navy: '#006780',
        olive: '#678000',
        purple: '#800080',
        red: '#ff4c59',
        silver: '#C0C0C0',
        teal: '#22bbb3',
        white: '#fff',
        yellow: '#eade0c',
        orange: '#ffae00'
      },
      gradient: {
        aqua: '110,213,212',
        black: '0,0,0',
        blue: '80,125,255',
        'bright-blue': '80,125,255',
        fuchsia: '255,0,255',
        gray: '128,128,128',
        green: '68,224,90',
        lime: '0,255,2',
        maroon: '128,0,0',
        navy: '0,0,128',
        olive: '128,128,0',
        purple: '128,0,128',
        red: '428,71,90',
        silver: '192,192,192',
        teal: '0,128,128',
        white: '255,255,255',
        yellow: '255,238,106',
        orange: '255,238,106'
      },
      gradientOpacity: '0.25'
    },
    dark: {
      gridLine: 'rgba(255, 255, 255, 0.1)',
      border: {
        aqua: '#6ed5d4',
        black: '#000',
        blue: '#007fd2',
        'bright-blue': '#1755e5',
        fuchsia: '#cc99ff',
        gray: '#808080',
        green: '#4bcc29',
        lime: '#baf164',
        maroon: '#800000',
        navy: '#006780',
        olive: '#678000',
        purple: '#800080',
        red: '#ff4c59',
        silver: '#C0C0C0',
        teal: '#22bbb3',
        white: '#fff',
        yellow: '#eade0c',
        orange: '#ffae00'
      },
      gradient: {
        aqua: '29,35,41',
        black: '0,0,0',
        blue: '29,35,41',
        'bright-blue': '29,35,41',
        fuchsia: '255,0,255',
        gray: '128,128,128',
        green: '29,35,41',
        lime: '0,255,2',
        maroon: '128,0,0',
        navy: '0,0,128',
        olive: '128,128,0',
        purple: '128,0,128',
        red: '29,35,41',
        silver: '192,192,192',
        teal: '0,128,128',
        white: '255,255,255',
        yellow: '29,35,41',
        orange: '29,35,41'
      },
      gradientOpacity: '0.74'
    }
  };
  displayFormats = {
    millisecond: 'mm:ss.SSS',
    second: 'HH:mm:ss',
    minute: 'HH:mm',
    hour: 'hA',
    day: 'MMM D',
    week: 'MMM D',
    month: 'MMM',
    quarter: '[Q]Q',
    year: 'YYYY'
  };
  xAxis: Object;
  defaultDatasetFormat: string;

  constructor(protected themeService: ThemeService) {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    interval(100)
      .pipe(
        map(() => {
          return {
            width: this.chartElement.nativeElement.offsetWidth,
            height: this.chartElement.nativeElement.offsetHeight
          };
        }),
        pairwise(),
        filter(values => values[0].width != values[1].width || values[0].height != values[1].height),
        untilDestroyed(this)
      )
      .subscribe(() => this.onResize());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['datasets']) {
      this.initData();
      this.initChart();
    }
  }

  initData() {
    this.xAxis = this.getXAxis(this.datasets);
    this.datasetsCurrent = cloneDeep(this.datasets);

    if (this.xAxis['type'] == 'time') {
      const unit = this.xAxis['time']['unit'];

      this.datasetsCurrent = this.datasetsCurrent.map(dataset => {
        dataset.dataset = dataset.dataset
          .filter(group => group.group !== null && group.group !== undefined)
          .map(item => {
            if (!(item.group instanceof moment)) {
              item.group = parseDate(item.group);
            }
            return item;
          })
          .filter(group => !(group.group instanceof moment && !group.group.isValid()))
          .sort((lhs, rhs) => {
            if (lhs.group instanceof moment && rhs.group instanceof moment) {
              return lhs.group.valueOf() - rhs.group.valueOf();
            } else if (lhs.group < rhs.group) {
              return -1;
            } else if (lhs.group > rhs.group) {
              return 1;
            } else {
              return 0;
            }
          });

        if (unit) {
          dataset.dataset = groupDatasetByUnitOfTime(dataset.dataset, unit);
        }

        return dataset;
      });
    }
  }

  initChart() {
    if (this.chart) {
      this.updateChart();
    } else {
      this.createChart();
    }
  }

  createChart() {
    const labels = this.lineChartLabels();
    const datasets = this.lineChartData();
    const options = merge(
      {
        data: {
          ...(labels ? { labels: labels } : {}),
          datasets: datasets
        },
        options: this.lineChartOptions()
      },
      this.chartOptions()
    );

    this.ctx = this.chartElement.nativeElement.getContext('2d');
    this.chart = new Chart(this.ctx, options);

    this.themeService.theme$.pipe(untilDestroyed(this)).subscribe(() => this.onThemeChange());
  }

  updateChart() {
    const labels = this.lineChartLabels();
    const datasets = this.lineChartData();

    if (labels) {
      this.chart.data.labels = labels;
    }

    this.chart.data.datasets = datasets;
    this.chart.options = this.lineChartOptions();
    this.chart.update({
      duration: 0
    });
  }

  chartOptions() {
    return {};
  }

  abstract lineChartData();

  abstract lineChartOptions();

  lineChartLabels() {
    if (!this.datasetsCurrent || !this.datasetsCurrent.length) {
      return [];
    }

    return this.datasetsCurrent[0].dataset.map(item => item.group);
  }

  detectTimeUnit(datasets: Dataset[]): string {
    const durations: [string, number, number][] = [
      // ['millisecond', 1, 0],
      // ['second', 1000, 0],
      // ['minute', 1000 * 60, 0],
      // ['hour', 1000 * 60 * 60, 0],
      ['day', 1000 * 60 * 60 * 24, 0],
      ['week', 1000 * 60 * 60 * 24 * 7, 0],
      ['month', 1000 * 60 * 60 * 24 * 30, 0],
      ['quarter', 1000 * 60 * 60 * 24 * 30 * 3, 0],
      ['year', 1000 * 60 * 60 * 24 * 30 * 12, 0]
    ];

    datasets.forEach(dataset => {
      for (let i = 1; i < dataset.dataset.length; ++i) {
        let maxDuration = 0;
        const current = parseDate(dataset.dataset[i].group);
        const prev = parseDate(dataset.dataset[i - 1].group);

        if (current.isValid()) {
          dataset.dataset[i].group = current;
        }

        if (prev.isValid()) {
          dataset.dataset[i - 1].group = prev;
        }

        if (!current.isValid() || !prev.isValid()) {
          continue;
        }

        const diff = Math.abs(current.diff(prev));

        for (let j = 1; j < durations.length; ++j) {
          if (diff < durations[j][1]) {
            break;
          }

          maxDuration = j;
        }

        ++durations[maxDuration][2];
      }
    });

    return maxBy(durations, duration => duration[2])[0];
  }

  getXAxis(datasets: Dataset[]): Object {
    const lookup = datasets && datasets.length ? datasets[0].groupLookup : undefined;
    const isDateLookup = datasetGroupDateLookups.includes(lookup);

    if (lookup && (lookup == DatasetGroupLookup.Auto || isDateLookup)) {
      const firstDataset = datasets.filter(
        dataset =>
          dataset.dataset.length &&
          dataset.dataset.find(item => item.group !== undefined && item.group !== null) != undefined
      );
      const firstValue = firstDataset.length
        ? firstDataset[0].dataset.find(item => item.group !== undefined && item.group !== null).group
        : undefined;
      const firstValueIsDate = parseDate(firstValue).isValid();

      if (isDateLookup || firstValueIsDate) {
        const unit = isSet(lookup) ? datasetGroupLookupUnitOfTime(lookup) : this.detectTimeUnit(datasets);

        return {
          type: 'time',
          time: {
            unit: unit,
            isoWeekday: true,
            tooltipFormat: dateFormatByUnitOfTime[unit],
            displayFormats: this.displayFormats
          }
        };
      }
    }

    return {
      type: undefined
    };
  }

  getDatasetsTotalData(): { [k: string]: number } {
    return this.datasetsCurrent.reduce((acc, dataset) => {
      dataset.dataset.forEach(item => {
        const key = String(item.group);
        acc[key] = safeNumber(acc[key]) + safeNumber(item.value);
      });
      return acc;
    }, {});
  }

  normalizeDatasetValue(item: DataGroup, totalData: { [k: string]: number }) {
    const totalItemData = totalData[String(item.group)];
    if (totalItemData === 0 || !isSet(totalItemData)) {
      return 0;
    } else {
      return safeNumber(item.value) / totalItemData;
    }
  }

  get currentColors(): Colors {
    return this.colors[this.themeService.theme];
  }

  segmentColors(reverse = false, fill?: number): string[] {
    const mainColors = ['blue', 'red', 'green', 'fuchsia', 'teal', 'yellow', 'aqua'];
    let colors = [
      ...(reverse ? mainColors.reverse() : mainColors),
      'orange',
      'lime',
      'navy',
      'purple',
      'olive',
      'maroon'
    ]
      .map(item => this.currentColors.border[item])
      .filter(item => !!item);

    const fillFromArray = (array: any[], length: number): any[] => {
      const arrayLength = array.length;
      return range(0, length).map(i => array[i % arrayLength]);
    };

    if (fill) {
      colors = fillFromArray(colors, fill);
    }

    return colors;
  }

  gradientColor(color: string) {
    if (!color) {
      return this.currentColors.gradient['blue'];
    }

    if (isColorHex(color)) {
      return Color(color).color.join(',');
    } else {
      return this.currentColors.gradient[color] || this.currentColors.gradient['blue'];
    }
  }

  borderColor(color: string) {
    if (!color) {
      return this.currentColors.border['blue'];
    }

    if (isColorHex(color)) {
      return color;
    } else {
      return this.currentColors.border[color] || this.currentColors.border['blue'];
    }
  }

  tooltipLabel(tooltipItem, data, axes: number, showXLabel = false) {
    let label: string;
    let value: string;

    if (axes == 3) {
      label = data.datasets[tooltipItem['datasetIndex']]['label'] || '';
      value = [
        this.datasetsCurrent[tooltipItem['datasetIndex']].dataset[tooltipItem['index']].group2,
        this.datasetsCurrent[tooltipItem['datasetIndex']].dataset[tooltipItem['index']].value
      ].join(', ');
    } else if (axes == 2) {
      label = data.datasets[tooltipItem['datasetIndex']]['label'] || '';
      value = tooltipItem['yLabel'];
    } else if (axes == 1) {
      label = this.datasetsCurrent[tooltipItem['datasetIndex']].dataset[tooltipItem['index']].group;
      value = data.datasets[tooltipItem['datasetIndex']]['data'][tooltipItem['index']];
    }

    const format = this.datasetsCurrent[tooltipItem['datasetIndex']].format || this.defaultDatasetFormat;

    if (format) {
      value = numeralFormat(value, format);
    }

    if (showXLabel) {
      const xLabel = tooltipItem.xLabel;
      return `${label}: (${xLabel}, ${value})`;
    } else {
      return `${label}: ${value}`;
    }
  }

  onResize() {}

  onThemeChange() {}
}
