import { Injectable, Injector } from '@angular/core';
import isArray from 'lodash/isArray';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription, ActionType, DownloadAction, QueryAction } from '@modules/actions';
import { RawListViewSettingsColumn } from '@modules/customize';
import { FieldOutput, ParameterArray } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { ActionQuery, HttpQuery, Query, QueryService, QueryType } from '@modules/queries';
import { QueryBuilderContext, QueryBuilderService } from '@modules/queries-components';

@Injectable()
export class ActionQueryEditController {
  constructor(
    private actionStore: ActionStore,
    private actionDescriptionService: ActionDescriptionService,
    private queryBuilderService: QueryBuilderService,
    private queryService: QueryService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private injector: Injector
  ) {}

  edit(options: {
    resource: Resource;
    actionType: ActionType;
    action: ActionDescription;
    queryType: QueryType;
    queryContext: QueryBuilderContext;
    parametersControl: ParameterArray;
    baseQuery?: HttpQuery;
    analyticsSource?: string;
  }) {
    let query: ActionQuery;

    if (options.actionType == ActionType.Download && options.action.downloadAction) {
      query = options.action.downloadAction.query;
    } else if (options.action.queryAction) {
      query = options.action.queryAction.query;
    }

    return this.queryBuilderService
      .edit({
        injector: this.injector,
        queryClass: ActionQuery,
        queryTypes: [options.queryType],
        resource: options.resource,
        resourceType: options.resource.type,
        context: options.queryContext,
        query: query,
        parametersControl: options.parametersControl,
        httpOptions: {
          baseQuery: options.baseQuery
        },
        source: options.analyticsSource
      })
      .pipe(
        filter(e => !!e.saved),
        map(e => {
          const action = new ActionDescription().deserialize(options.action.serialize());
          const savedQuery = e.saved.query as ActionQuery;

          if (options.actionType == ActionType.Download) {
            if (!action.downloadAction) {
              action.downloadAction = new DownloadAction();
            }

            action.downloadAction.query = savedQuery;
          } else {
            if (!action.queryAction) {
              action.queryAction = new QueryAction();
            }

            action.queryAction.query = savedQuery;
          }

          action.actionParams = options.parametersControl.value;

          this.setAutoDetectOutputs(action, savedQuery);

          return action;
        }),
        switchMap(action => {
          return this.actionDescriptionService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            action
          );
        }),
        tap(result => this.actionStore.updateItem(result))
      );
  }

  setAutoDetectOutputs(action: ActionDescription, query: Query) {
    const response = this.queryService.getResponseProcessed(query);
    const isArrayResponse = response && isArray(response);
    const responseColumns = this.queryService.getAutoDetectColumns(query, false);

    if (!responseColumns) {
      return;
    }

    this.setOutputs(action, responseColumns, isArrayResponse);
  }

  setOutputs(action: ActionDescription, columns: RawListViewSettingsColumn[], array: boolean) {
    action.outputs = columns.map(item => {
      const output = new FieldOutput();

      output.name = item.name;
      output.verboseName = item.verboseName;
      output.field = item.field;
      output.params = item.params;
      output.updateFieldDescription();

      return output;
    });
    action.arrayOutput = array;
  }
}
