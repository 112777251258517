var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { NpmRegistryService } from '../services/npm/npm-registry.service';
var NpmPackageSource = /** @class */ (function (_super) {
    __extends(NpmPackageSource, _super);
    function NpmPackageSource(currentProjectStore, currentEnvironmentStore, npmService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.npmService = npmService;
        _this.loadedPage = false;
        return _this;
    }
    NpmPackageSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        searchQuery = (searchQuery || '').trim();
        return this.npmService
            .search(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, searchQuery)
            .pipe(map(function (result) {
            return result.items.map(function (item) {
                return {
                    value: item.name,
                    name: item.name
                };
            });
        }), tap(function () {
            _this.loadedPage = true;
        }));
    };
    NpmPackageSource.prototype.fetchByValue = function (value) {
        if (!value) {
            return of(undefined);
        }
        return this.npmService
            .getDetail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, value)
            .pipe(map(function (result) {
            return {
                value: result.name,
                name: result.name
            };
        }));
    };
    NpmPackageSource.prototype.isFetchAvailable = function () {
        return !this.loadedPage;
    };
    NpmPackageSource.prototype.resetPagination = function () {
        this.loadedPage = false;
    };
    NpmPackageSource.prototype.setStaticOptions = function (options) { };
    return NpmPackageSource;
}(SelectSource));
export { NpmPackageSource };
