<div class="sidebar__element">
  <app-sidebar-field [label]="'Overlay'" [required]="true">
    <mat-menu #new_dropdown="matMenu">
      <ng-template matMenuContent>
        <button mat-menu-item [disableRipple]="true" (click)="createPopup({ defaultName: 'Modal' })">
          <span class="choose-items-item__dropdown-icon icon-windows"></span>
          Modal
        </button>

        <button
          mat-menu-item
          [disableRipple]="true"
          (click)="createPopup({ width: 400, defaultName: 'Slideout', position: popupPositions.SideRight })"
        >
          <span class="choose-items-item__dropdown-icon icon-versions"></span>
          Slideout
        </button>

        <button
          mat-menu-item
          [disableRipple]="true"
          (click)="
            createPopup({ width: 400, defaultName: 'Dropdown', position: popupPositions.Origin, overlay: false })
          "
        >
          <span class="choose-items-item__dropdown-icon icon-fileds"></span>
          Dropdown
        </button>
      </ng-template>
    </mat-menu>

    <mat-menu #queue_dropdown="matMenu">
      <ng-template matMenuContent>
        <button
          *ngFor="let item of popupOptions"
          mat-menu-item
          [disableRipple]="true"
          (click)="form.controls.open_popup.patchValue(item.value)"
        >
          <span *ngIf="item.icon" class="choose-items-item__dropdown-icon" [ngClass]="'icon-' + item.icon"></span>
          {{ item.name }}
        </button>
        <div *ngIf="!popupOptions.length" class="mat-menu-item-message">
          Modals not found
        </div>
        <button mat-menu-item [disableRipple]="true" [matMenuTriggerFor]="new_dropdown">
          <span class="choose-items-item__dropdown-icon icon-plus"></span>
          Add new
        </button>
      </ng-template>
    </mat-menu>

    <div class="control">
      <div class="control__input">
        <div class="select select_theme_jet select_fill" [matMenuTriggerFor]="queue_dropdown" #trigger="matMenuTrigger">
          <a href="javascript:void(0)" class="select__input" [class.select__input_opened]="trigger.menuOpen">
            <span
              *ngIf="selectedPopupOption?.icon"
              class="select__input-icon"
              [ngClass]="['icon-' + selectedPopupOption.icon]"
            ></span>
            <span class="select__input-label">
              <ng-container *ngIf="selectedPopupOption">{{ selectedPopupOption?.name }}</ng-container>
              <ng-container *ngIf="!selectedPopupOption">Choose Modal</ng-container>
            </span>
            <span class="select__input-arrow"></span>
          </a>
        </div>
      </div>
      <div *ngIf="form.controls.open_popup.value" class="control__right">
        <a
          (click)="openPopup(form.controls.open_popup.value)"
          href="javascript:void(0)"
          class="button button_bigger button_icon button_orange-alternative control__button"
          [appTip]="'Open Overlay'"
        >
          <span class="icon icon-open_folder button__icon"></span>
        </a>
      </div>
    </div>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'close other overlays on open'" [layoutLeft]="true">
    <app-toggle
      [selected]="form.controls.open_popup_close_other.value"
      [orange]="true"
      (click)="form.controls.open_popup_close_other.patchValue(!form.controls.open_popup_close_other.value)"
    ></app-toggle>

    <ng-container description>
      Disable to open multiple overlays
    </ng-container>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'open if closed / close if opened'" [layoutLeft]="true">
    <app-toggle
      [selected]="form.controls.open_popup_toggle_popup.value"
      [orange]="true"
      (click)="form.controls.open_popup_toggle_popup.patchValue(!form.controls.open_popup_toggle_popup.value)"
    ></app-toggle>

    <ng-container description>
      Toggle between overlay opened and closed
    </ng-container>
  </app-sidebar-field>
</div>
