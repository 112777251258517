import { OnDestroy, TemplateRef } from '@angular/core';
import last from 'lodash/last';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of, Subject, throwError, timer } from 'rxjs';
import { catchError, debounce, debounceTime, filter, map, skip, switchMap, tap } from 'rxjs/operators';
import { ApiService, ServerRequestError } from '@modules/api';
import { forceObservable } from '@shared';
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/api/api.service";
export var CustomizeType;
(function (CustomizeType) {
    CustomizeType["Menu"] = "menu";
    CustomizeType["Layout"] = "layout";
    CustomizeType["Settings"] = "settings";
})(CustomizeType || (CustomizeType = {}));
export var CustomizeExitAction;
(function (CustomizeExitAction) {
    CustomizeExitAction[CustomizeExitAction["Cancel"] = 0] = "Cancel";
    CustomizeExitAction[CustomizeExitAction["Reset"] = 1] = "Reset";
    CustomizeExitAction[CustomizeExitAction["Save"] = 2] = "Save";
})(CustomizeExitAction || (CustomizeExitAction = {}));
var CustomizeService = /** @class */ (function () {
    // historyMaxPrevious = 50;
    function CustomizeService(apiService) {
        this.apiService = apiService;
        this._selection = new BehaviorSubject(false);
        // _animating = new BehaviorSubject<boolean>(false);
        this._enabled = new BehaviorSubject(undefined);
        this._layoutCustomization = new BehaviorSubject(undefined);
        this._lastHovered = new BehaviorSubject(undefined);
        this._handler = new BehaviorSubject(undefined);
        this._handlerInfo = new BehaviorSubject(undefined);
        this._handlerHeaderLeft = new BehaviorSubject(undefined);
        this._handlerHeaderCenter = new BehaviorSubject(undefined);
        this._contentfulRightBarHandlers = new BehaviorSubject([]);
        this.hovered = [];
        this._publishRequested = new Subject(); // TODO: Refactor
        this.createdElements = [];
        this._changed$ = new Subject();
        this.changesHistory$ = new BehaviorSubject([]);
        this.changesHistoryIndex$ = new BehaviorSubject(0);
        this._changesSaving$ = new BehaviorSubject(false);
        this._changesSaveError$ = new BehaviorSubject(undefined);
        this._createElement = new Subject();
        this.isUndoAvailable$ = this.hasChangesHistory$(-1);
        this.isRedoAvailable$ = this.hasChangesHistory$(1);
    }
    CustomizeService.prototype.ngOnDestroy = function () { };
    Object.defineProperty(CustomizeService.prototype, "enabled", {
        get: function () {
            return this._enabled.value;
        },
        set: function (value) {
            if (this.enabled == value) {
                return;
            }
            this._enabled.next(value);
            if (value != undefined) {
                this.selection = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "enabled$", {
        get: function () {
            return this._enabled.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "menuEnabled", {
        get: function () {
            return this._enabled.value == CustomizeType.Menu;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "menuEnabled$", {
        get: function () {
            return this._enabled.asObservable().pipe(map(function (item) { return item == CustomizeType.Menu; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "layoutEnabled", {
        get: function () {
            return this._enabled.value == CustomizeType.Layout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "layoutEnabled$", {
        get: function () {
            return this._enabled.asObservable().pipe(map(function (item) { return item == CustomizeType.Layout; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "anyEnabled$", {
        get: function () {
            return this._enabled.asObservable().pipe(map(function (item) { return item != undefined; }));
        },
        enumerable: true,
        configurable: true
    });
    // changes$() {
    //   return this._changes.asObservable();
    // }
    //
    // markChanged() {
    //   this._changes.next(undefined);
    // }
    CustomizeService.prototype.toggleEnabled = function (value) {
        this.enabled = this.enabled != value ? value : undefined;
    };
    Object.defineProperty(CustomizeService.prototype, "selection", {
        get: function () {
            return this._selection.value;
        },
        set: function (value) {
            if (this.selection == value) {
                return;
            }
            this._selection.next(value);
            if (value) {
                this.enabled = undefined;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "selection$", {
        get: function () {
            return this._selection.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "layoutCustomization", {
        // get animating(): boolean {
        //   return this._animating.value;
        // }
        //
        // get animating$(): Observable<boolean> {
        //   return this._animating.asObservable();
        // }
        //
        // set animating(value: boolean) {
        //   this._animating.next(value);
        // }
        get: function () {
            return this._layoutCustomization.value;
        },
        set: function (value) {
            this._layoutCustomization.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "layoutCustomization$", {
        get: function () {
            return this._layoutCustomization.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CustomizeService.prototype.toggleSelection = function () {
        this.selection = !this.selection;
    };
    CustomizeService.prototype.addHover = function (any) {
        var timeSinceHoveredForced = this.hoveredForced ? moment().diff(this.hoveredForced, 'milliseconds') : undefined;
        if (timeSinceHoveredForced !== undefined && timeSinceHoveredForced <= 60) {
            return;
        }
        this.hovered.push(any);
        this.updateLastHovered();
    };
    CustomizeService.prototype.removeHover = function (any) {
        this.hovered = this.hovered.filter(function (item) { return item !== any; });
        this.updateLastHovered();
    };
    CustomizeService.prototype.forceHover = function (any) {
        var index = this.hovered.findIndex(function (item) { return item === any; });
        if (index !== -1 && index < this.hovered.length - 1) {
            this.hovered = this.hovered.slice(0, index + 1);
            this.updateLastHovered();
        }
        this.hoveredForced = moment();
    };
    CustomizeService.prototype.updateLastHovered = function () {
        var lastHovered;
        if (this.hovered.length) {
            lastHovered = this.hovered[this.hovered.length - 1];
        }
        else {
            lastHovered = undefined;
        }
        if (this._lastHovered.value === lastHovered) {
            return;
        }
        this._lastHovered.next(lastHovered);
    };
    Object.defineProperty(CustomizeService.prototype, "lastHovered$", {
        get: function () {
            return this._lastHovered.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "handler", {
        get: function () {
            return this._handler.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "handler$", {
        get: function () {
            return this._handler.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CustomizeService.prototype.setHandler = function (handler) {
        if (this.handler === handler) {
            return;
        }
        this._handler.next(handler);
        this._handlerInfo.next(undefined);
    };
    Object.defineProperty(CustomizeService.prototype, "handlerInfo", {
        get: function () {
            return this._handlerInfo.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "handlerInfo$", {
        get: function () {
            return this._handlerInfo.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "handlerHeaderLeft", {
        get: function () {
            return this._handlerHeaderLeft.value;
        },
        set: function (value) {
            this._handlerHeaderLeft.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "handlerHeaderLeft$", {
        get: function () {
            return this._handlerHeaderLeft.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "handlerHeaderCenter", {
        get: function () {
            return this._handlerHeaderCenter.value;
        },
        set: function (value) {
            this._handlerHeaderCenter.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "handlerHeaderCenter$", {
        get: function () {
            return this._handlerHeaderCenter.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CustomizeService.prototype.setHandlerInfo = function (handler, value) {
        if (this.handler !== handler) {
            return;
        }
        this._handlerInfo.next(value);
    };
    CustomizeService.prototype.unsetHandler = function (value) {
        if (this.handler === value) {
            this._handler.next(undefined);
            this._handlerInfo.next(undefined);
            this.stopTrackChanges();
        }
    };
    CustomizeService.prototype.getContentfulRightBarHandlers = function () {
        return this._contentfulRightBarHandlers.asObservable();
    };
    CustomizeService.prototype.addContentfulRightBarHandler = function (handler) {
        var value = this._contentfulRightBarHandlers.value;
        if (value.includes(handler)) {
            return;
        }
        this._contentfulRightBarHandlers.next(value.concat([handler]));
    };
    CustomizeService.prototype.removeContentfulRightBarHandler = function (handler) {
        var value = this._contentfulRightBarHandlers.value;
        if (!value.includes(handler)) {
            return;
        }
        this._contentfulRightBarHandlers.next(value.filter(function (item) { return item !== handler; }));
    };
    CustomizeService.prototype.startTrackChanges = function () {
        var _this = this;
        this.stopTrackChanges();
        if (!this.handler || !this.handler.getChangesState) {
            return;
        }
        this.changesStartTrackSubscription = forceObservable(this.handler.getChangesState())
            .pipe(untilDestroyed(this))
            .subscribe(function (initialState) {
            _this.changesHistory$.next([initialState]);
            _this.changesHistoryIndex$.next(0);
            _this.changesAddHistorySubscription = _this.changed$
                .pipe(debounce(function (immediate) { return timer(immediate ? 0 : 200); }), switchMap(function () { return forceObservable(_this.handler.getChangesState()); }), switchMap(function (newState) {
                var currentState = _this.getCurrentChangesHistory();
                return forceObservable(_this.handler.isChangesStateEqual(currentState, newState)).pipe(map(function (stateEquals) {
                    return {
                        state: newState,
                        stateUpdated: !stateEquals
                    };
                }));
            }), filter(function (value) { return value.stateUpdated; }), untilDestroyed(_this))
                .subscribe(function (value) { return _this.addChangesHistory(value.state); });
            _this.changesSaveSubscription = _this.getCurrentChangesHistory$()
                .pipe(skip(1), debounceTime(600), switchMap(function () { return _this.saveCurrentChangesHistory().pipe(catchError(function () { return of(undefined); })); }), untilDestroyed(_this))
                .subscribe();
        });
    };
    CustomizeService.prototype.unsubscribeTrackChanges = function () {
        if (this.changesStartTrackSubscription) {
            this.changesStartTrackSubscription.unsubscribe();
            this.changesStartTrackSubscription = undefined;
        }
        if (this.changesAddHistorySubscription) {
            this.changesAddHistorySubscription.unsubscribe();
            this.changesAddHistorySubscription = undefined;
        }
        if (this.changesSaveSubscription) {
            this.changesSaveSubscription.unsubscribe();
            this.changesSaveSubscription = undefined;
        }
    };
    CustomizeService.prototype.stopTrackChanges = function () {
        this.unsubscribeTrackChanges();
        this.changesHistory$.next([]);
        this.changesHistoryIndex$.next(0);
        this._changesSaving$.next(false);
        this._changesSaveError$.next(undefined);
    };
    Object.defineProperty(CustomizeService.prototype, "changed$", {
        get: function () {
            return this._changed$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CustomizeService.prototype.markChanged = function (immediate) {
        if (immediate === void 0) { immediate = false; }
        this._changed$.next(immediate);
    };
    CustomizeService.prototype.addChangesHistory = function (state) {
        var history = this.changesHistory$.value.slice(0, this.changesHistoryIndex$.value + 1).concat([state]);
        this.changesHistory$.next(history);
        this.changesHistoryIndex$.next(history.length - 1);
    };
    CustomizeService.prototype.applyChangesHistory = function (index) {
        var state = this.changesHistory$.value[index];
        if (!state) {
            return;
        }
        this.handler.setChangesState(state);
        this.changesHistoryIndex$.next(index);
    };
    CustomizeService.prototype.hasChangesHistory$ = function (delta) {
        return combineLatest(this.changesHistory$, this.changesHistoryIndex$).pipe(map(function (_a) {
            var history = _a[0], historyIndex = _a[1];
            return history[historyIndex + delta] !== undefined;
        }));
    };
    CustomizeService.prototype.undoChangesHistory = function () {
        this.applyChangesHistory(this.changesHistoryIndex$.value - 1);
    };
    CustomizeService.prototype.redoChangesHistory = function () {
        this.applyChangesHistory(this.changesHistoryIndex$.value + 1);
    };
    CustomizeService.prototype.getCurrentChangesHistory = function () {
        return this.changesHistory$.value[this.changesHistoryIndex$.value];
    };
    CustomizeService.prototype.getCurrentChangesHistory$ = function () {
        return combineLatest(this.changesHistory$, this.changesHistoryIndex$).pipe(map(function (_a) {
            var history = _a[0], historyIndex = _a[1];
            return history[historyIndex];
        }));
    };
    CustomizeService.prototype.saveCurrentChangesHistory = function () {
        var state = this.getCurrentChangesHistory();
        return this.saveChanges(state);
    };
    CustomizeService.prototype.saveActualChanges = function () {
        var _this = this;
        if (!this.handler) {
            return of(undefined);
        }
        return forceObservable(this.handler.getChangesState()).pipe(switchMap(function (state) { return _this.saveChanges(state); }));
    };
    CustomizeService.prototype.saveChanges = function (state) {
        var _this = this;
        if (!this.apiService.getAutosave()) {
            return of(undefined);
        }
        this._changesSaveError$.next(undefined);
        this._changesSaving$.next(true);
        return this.handler.saveChangesState(state).pipe(tap(function () {
            _this._changesSaving$.next(false);
        }), catchError(function (error) {
            var errorMessage;
            if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
                errorMessage = error.nonFieldErrors[0];
            }
            else {
                errorMessage = String(error);
            }
            _this._changesSaving$.next(false);
            _this._changesSaveError$.next(errorMessage);
            return throwError(error);
        }));
    };
    Object.defineProperty(CustomizeService.prototype, "changesSaving", {
        get: function () {
            return this._changesSaving$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "changesSaving$", {
        get: function () {
            return this._changesSaving$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "changesSaveError", {
        get: function () {
            return this._changesSaveError$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeService.prototype, "changesSaveError$", {
        get: function () {
            return this._changesSaveError$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CustomizeService.prototype.getHandlerName = function () {
        return this.handlerInfo && this.handlerInfo.breadcrumbs && this.handlerInfo.breadcrumbs.length
            ? last(this.handlerInfo.breadcrumbs)
            : 'Current Page';
    };
    CustomizeService.prototype.renameHandler = function (title) {
        this.handler.renameHandler(title);
        this.markChanged();
    };
    CustomizeService.prototype.openHandlerSettings = function () {
        this.handler.openHandlerSettings();
    };
    CustomizeService.prototype.duplicateHandler = function () {
        return this.handler.duplicate();
    };
    CustomizeService.prototype.offMenu = function () {
        // if (this.menuEnabled) {
        //   this.requestExit().subscribe(allow => {
        //     if (allow) {
        //       this.toggleEnabled(CustomizeType.Layout);
        //       this.markChanged();
        //     }
        //   });
        // }
    };
    CustomizeService.prototype.requestPublish = function () {
        return this._publishRequested.next();
    };
    CustomizeService.prototype.publishRequested$ = function () {
        return this._publishRequested.asObservable();
    };
    CustomizeService.prototype.registerCreatedElement = function (element, barItem) {
        this.createdElements.push({
            element: element,
            barItem: barItem
        });
    };
    CustomizeService.prototype.applyCreatedElementComponent = function (element) {
        var createdElement = this.createdElements.find(function (item) { return item.element === element; });
        if (!createdElement) {
            return;
        }
        this.createdElements = this.createdElements.filter(function (item) { return item !== createdElement; });
        return createdElement;
    };
    CustomizeService.prototype.createElement = function (barItem) {
        this._createElement.next(barItem);
    };
    CustomizeService.prototype.createElement$ = function () {
        return this._createElement.asObservable();
    };
    CustomizeService.ngInjectableDef = i0.defineInjectable({ factory: function CustomizeService_Factory() { return new CustomizeService(i0.inject(i1.ApiService)); }, token: CustomizeService, providedIn: "root" });
    return CustomizeService;
}());
export { CustomizeService };
