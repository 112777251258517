/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../page-template-preview-start-page/page-template-preview-start-page.component.ngfactory";
import * as i2 from "../../../customize/data/view-context";
import * as i3 from "../../../projects/stores/current-environment.store";
import * as i4 from "../page-template-preview-start-page/page-template-preview-start-page.component";
import * as i5 from "../page-template-preview-other-page/page-template-preview-other-page.component.ngfactory";
import * as i6 from "../page-template-preview-other-page/page-template-preview-other-page.component";
import * as i7 from "@angular/common";
import * as i8 from "../../../customize/services/customize/customize.service";
import * as i9 from "../../../api/services/api/api.service";
import * as i10 from "./page-template-preview.component";
import * as i11 from "../../../projects/stores/current-project.store";
import * as i12 from "@angular/platform-browser";
var styles_PageTemplatePreviewComponent = [];
var RenderType_PageTemplatePreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTemplatePreviewComponent, data: {} });
export { RenderType_PageTemplatePreviewComponent as RenderType_PageTemplatePreviewComponent };
function View_PageTemplatePreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-page-template-preview-start-page", [], null, null, null, i1.View_PageTemplatePreviewStartPageComponent_0, i1.RenderType_PageTemplatePreviewStartPageComponent)), i0.ɵprd(131584, null, i2.ViewContext, i2.ViewContext, [i3.CurrentEnvironmentStore]), i0.ɵdid(2, 638976, null, 0, i4.PageTemplatePreviewStartPageComponent, [i2.ViewContext], { page: [0, "page"], fade: [1, "fade"], startPageFocus: [2, "startPageFocus"], popupFocus: [3, "popupFocus"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.startPage; var currVal_1 = ((_co.startPage.popups.length > 0) || (_co.otherPages.length > 0)); var currVal_2 = _co.startPageFocus; var currVal_3 = _co.popupFocus; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PageTemplatePreviewComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-page-template-preview-other-page", [["class", "choose-template__detail-browsers-item"]], null, null, null, i5.View_PageTemplatePreviewOtherPageComponent_0, i5.RenderType_PageTemplatePreviewOtherPageComponent)), i0.ɵprd(131584, null, i2.ViewContext, i2.ViewContext, [i3.CurrentEnvironmentStore]), i0.ɵdid(2, 114688, null, 0, i6.PageTemplatePreviewOtherPageComponent, [i2.ViewContext], { page: [0, "page"], labelsVisible: [1, "labelsVisible"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.otherPagesLabels; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PageTemplatePreviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "choose-template__detail-browsers choose-template__detail-browsers_visible"]], [[2, "choose-template__detail-browsers_visible", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatePreviewComponent_3)), i0.ɵdid(2, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.otherPages; var currVal_2 = _co.trackPage; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.otherPagesVisible; _ck(_v, 0, 0, currVal_0); }); }
export function View_PageTemplatePreviewComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatePreviewComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatePreviewComponent_2)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.startPage; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.otherPages.length; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PageTemplatePreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-page-template-preview", [], [[2, "choose-template__detail-viewport", null], [8, "style", 2]], null, null, View_PageTemplatePreviewComponent_0, RenderType_PageTemplatePreviewComponent)), i0.ɵprd(135680, null, i8.CustomizeService, i8.CustomizeService, [i9.ApiService]), i0.ɵdid(2, 638976, null, 0, i10.PageTemplatePreviewComponent, [i11.CurrentProjectStore, i3.CurrentEnvironmentStore, i12.DomSanitizer, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).viewportCls; var currVal_1 = i0.ɵnov(_v, 2).style; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var PageTemplatePreviewComponentNgFactory = i0.ɵccf("app-page-template-preview, [app-page-template-preview]", i10.PageTemplatePreviewComponent, View_PageTemplatePreviewComponent_Host_0, { startPage: "startPage", otherPages: "otherPages", modelDescription: "modelDescription", previewFocus: "previewFocus" }, {}, []);
export { PageTemplatePreviewComponentNgFactory as PageTemplatePreviewComponentNgFactory };
