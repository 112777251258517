import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { AdminMode } from '@modules/admin-mode';
import { ApiService } from '@modules/api';
import { CustomizeService } from '@modules/customize';
import { MenuSettingsStore } from '@modules/menu';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
var CustomizePreviewComponent = /** @class */ (function () {
    function CustomizePreviewComponent(currentUserStore, currentProjectStore, currentEnvironmentStore, menuSettingsStore, customizeService, apiService, projectApiService, cd) {
        this.currentUserStore = currentUserStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.menuSettingsStore = menuSettingsStore;
        this.customizeService = customizeService;
        this.apiService = apiService;
        this.projectApiService = projectApiService;
        this.cd = cd;
        this.active = false;
        this.draftChanges = [];
        this.draftChangesTotal = 0;
        this.savingChanges = false;
        this.publishLoading = false;
        this.exitPreview = new EventEmitter();
        this.deleteDraft = new EventEmitter();
        this.publishDraft = new EventEmitter();
        this.showChanges = new EventEmitter();
        this.previewAsOpened = false;
    }
    CustomizePreviewComponent.prototype.ngOnInit = function () { };
    CustomizePreviewComponent.prototype.ngOnDestroy = function () {
        this.disablePreviewAsUser();
    };
    CustomizePreviewComponent.prototype.setPreviewAsOpened = function (value) {
        this.previewAsOpened = value;
        this.cd.markForCheck();
    };
    CustomizePreviewComponent.prototype.previewAsUser = function (user) {
        var _this = this;
        if (this.currentUserStore.override && user.user && this.currentUserStore.override.uid == user.user.uid) {
            return;
        }
        combineLatest(this.getUserToken(user), this.getUserToken(user))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var token1 = _a[0], token2 = _a[1];
            _this.currentUserStore.setOverride(user.user);
            _this.currentEnvironmentStore.setOverrideUser(user);
            _this.currentEnvironmentStore.setOverrideGroup(user.group);
            _this.menuSettingsStore.getFirst(true).subscribe();
            _this.apiService.setOverrideToken(token1);
            _this.projectApiService.setOverrideToken(token2);
            if (_this.customizeService.handler && _this.customizeService.handler.reload) {
                _this.customizeService.handler.reload().subscribe();
            }
        });
    };
    CustomizePreviewComponent.prototype.getUserToken = function (user) {
        return this.projectApiService.getToken(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, {
            user: user.user,
            forceOriginal: true,
            mode: AdminMode.Builder
        });
    };
    CustomizePreviewComponent.prototype.disablePreviewAsUser = function () {
        if (!this.currentUserStore.override) {
            return;
        }
        this.apiService.clearOverrideToken();
        this.projectApiService.clearOverrideToken();
        this.currentUserStore.clearOverride();
        this.currentEnvironmentStore.clearOverrideUser();
        this.currentEnvironmentStore.clearOverrideGroup();
        this.menuSettingsStore.getFirst(true).subscribe();
        if (this.customizeService.handler && this.customizeService.handler.reload) {
            this.customizeService.handler.reload().subscribe();
        }
    };
    return CustomizePreviewComponent;
}());
export { CustomizePreviewComponent };
