import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { LocalStorage } from '@core';
import { CustomViewSettings, PopupSettings, ViewSettings } from '@modules/customize';
import { isSet } from '@shared';

export interface PopupSectionButton {
  label: string;
  icon?: string;
  handler: () => void;
}

export interface PopupSection {
  name: string;
  label: string;
  icon?: string;
  documentation?: string;
  items: PopupSettings[];
  itemsTotal: number;
  descriptionTitle?: string;
  descriptionText?: string;
  button?: PopupSectionButton;
  showEmpty?: boolean;
  defaultBannerHidden?: boolean;
}

@Component({
  selector: 'app-custom-page-popups-menu-section',
  templateUrl: './custom-page-popups-menu-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPagePopupsMenuSectionComponent implements OnInit {
  @Input() section: PopupSection;
  @Input() openedPopupUids: string[] = [];
  @Input() viewSettings: CustomViewSettings;
  @Output() openPopup = new EventEmitter<string>();
  @Output() closePopup = new EventEmitter<string>();
  @Output() customizePopup = new EventEmitter<string>();
  @Output() movePopupToPage = new EventEmitter<{ uid: string; page: ViewSettings }>();
  @Output() duplicatePopup = new EventEmitter<string>();
  @Output() deletePopup = new EventEmitter<string>();

  bannerHidden = false;

  constructor(private localStorage: LocalStorage, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    // const bannerHiddenKey = this.getBannerHiddenKey();
    // const bannerHidden = this.localStorage.get(bannerHiddenKey);
    //
    // if (isSet(bannerHidden)) {
    //   this.bannerHidden = !!bannerHidden;
    // } else if (isSet(this.section.defaultBannerHidden)) {
    //   this.bannerHidden = this.section.defaultBannerHidden;
    // } else {
    //   this.bannerHidden = false;
    // }
  }

  getBannerHiddenKey(): string {
    return ['popups_banner', this.section.name].join('_');
  }

  setBannerHidden(value: boolean) {
    this.bannerHidden = value;
    this.cd.markForCheck();

    const barHiddenKey = this.getBannerHiddenKey();
    if (value) {
      this.localStorage.set(barHiddenKey, '1');
    } else {
      this.localStorage.set(barHiddenKey, '0');
    }
  }
}
