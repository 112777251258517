var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, merge, of, throwError } from 'rxjs';
import { catchError, debounceTime, delay, delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { CustomView, CustomViewService, CustomViewSource, CustomViewsStore, CustomViewType, defaultCustomViewHtml } from '@modules/custom-views';
import { CustomElementItem, CustomizeService, MarginControl } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, isRequiredInputsSet, ParameterArray } from '@modules/fields';
import { FieldInputControl, InputFieldProvider, parametersToProviderItems } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { controlValue, generateAlphanumeric, isSet } from '@shared';
import { ActionOutputFormGroup } from '../../forms/action-output.form-group';
export function validateDistFile() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var source = parent.controls.source.value;
        if (source != CustomViewSource.CustomElement) {
            return;
        }
        var dist = parent.controls.dist.value;
        if (!control.value && !dist) {
            return { required: true };
        }
    };
}
export function validateTagName() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var source = parent.controls.source.value;
        if (source != CustomViewSource.CustomElement) {
            return;
        }
        if (!control.value) {
            return { required: true };
        }
    };
}
export function validateFiles() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var source = parent.controls.source.value;
        if (source != CustomViewSource.CustomElement) {
            return;
        }
        if (!control.value || !control.value.js || !control.value.js.length) {
            return { required: true };
        }
    };
}
export function validateInputs() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return;
        }
        var fields = parent.inputFieldProvider.getFields();
        var inputs = control.value;
        if (!isRequiredInputsSet(fields, inputs)) {
            return { required: true };
        }
    };
}
var CustomizeBarCustomEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarCustomEditForm, _super);
    function CustomizeBarCustomEditForm(currentProjectStore, currentEnvironmentStore, customViewService, customViewsStore, customizeService, formUtils, elementConfigurationService, notificationService) {
        var _this = _super.call(this, {
            source: new FormControl(CustomViewSource.View),
            custom_view_unique_name: new FormControl(null),
            html: new FormControl(defaultCustomViewHtml),
            dist: new FormControl(''),
            dist_file: new FormControl('', validateDistFile()),
            tag_name: new FormControl('', validateTagName()),
            files: new FormControl({ js: [], css: [] }, validateFiles()),
            view: new FormControl(null),
            parameters: new ParameterArray([]),
            inputs: new FormControl([], validateInputs()),
            outputs: new FormControl([]),
            actions: new ActionOutputFormGroup(elementConfigurationService),
            width_fluid: new FormControl(false),
            height_fluid: new FormControl(false),
            visible_input: new FieldInputControl({ path: ['value'] }),
            margin: new MarginControl()
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.customViewService = customViewService;
        _this.customViewsStore = customViewsStore;
        _this.customizeService = customizeService;
        _this.formUtils = formUtils;
        _this.elementConfigurationService = elementConfigurationService;
        _this.notificationService = notificationService;
        _this.ignoreSubmitControls = [_this.controls.html, _this.controls.view];
        _this.submitChanges = merge.apply(void 0, values(_this.controls)
            .filter(function (item) { return !_this.ignoreSubmitControls.includes(item); })
            .map(function (item) { return item.valueChanges; }));
        _this.submitLoading$ = new BehaviorSubject(false);
        _this.inputFieldProvider = new InputFieldProvider();
        _this.sourceOptions = [
            {
                value: CustomViewSource.View,
                name: 'Design',
                icon: 'canvas'
            },
            {
                value: CustomViewSource.HTML,
                name: 'HTML',
                icon: 'richtext'
            },
            {
                value: CustomViewSource.CustomElement,
                name: 'JS',
                icon: 'console'
            }
        ];
        return _this;
    }
    CustomizeBarCustomEditForm.prototype.ngOnDestroy = function () {
        this.inputFieldProvider.clearProvider();
    };
    CustomizeBarCustomEditForm.prototype.init = function (element, context, firstInit) {
        var _this = this;
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        this.context = context;
        var customView$ = element.customView
            ? this.customViewsStore.getDetailFirst(element.customView)
            : of(element.customViewTemporary);
        return customView$.pipe(map(function (customView) {
            var source = element.source || (customView ? customView.source : CustomViewSource.View);
            _this.controls.custom_view_unique_name.patchValue(element.customView || null);
            _this.controls.source.patchValue(source);
            if (customView) {
                _this.controls.html.patchValue(customView.html || defaultCustomViewHtml);
                _this.controls.dist.patchValue(customView.dist);
                _this.controls.tag_name.patchValue(customView.tagName);
                _this.controls.files.patchValue({
                    js: customView.filesJs,
                    css: customView.filesCss
                });
                _this.controls.view.patchValue(customView.view);
                _this.controls.actions.deserialize(customView && customView.view ? customView.view.actions : [], element.actions);
            }
            _this.controls.parameters.patchValue(element.parameters);
            _this.controls.inputs.patchValue(element.inputs);
            _this.controls.outputs.patchValue(element.outputs);
            _this.controls.width_fluid.patchValue(element.widthFluid);
            _this.controls.height_fluid.patchValue(element.heightFluid);
            _this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
            _this.controls.margin.patchValue(element.margin);
            _this.updateInputFieldProvider();
            _this.trackChanges();
            if (!firstInit) {
                _this.markAsDirty();
            }
        }));
    };
    CustomizeBarCustomEditForm.prototype.updateInputFieldProvider = function () {
        this.inputFieldProvider.setProvider(controlValue(this.controls.parameters).pipe(map(function (parameters) {
            return parameters ? parametersToProviderItems(parameters) : [];
        })));
    };
    CustomizeBarCustomEditForm.prototype.trackChanges = function () {
        var _this = this;
        this.controls.source.valueChanges
            .pipe(delay(0))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.controls.dist_file.updateValueAndValidity();
            _this.controls.tag_name.updateValueAndValidity();
            _this.controls.files.updateValueAndValidity();
        });
        this.controls.dist.valueChanges
            .pipe(delay(0))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.controls.dist_file.updateValueAndValidity();
        });
        this.inputFieldProvider
            .getFields$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.controls.inputs.updateValueAndValidity();
        });
        merge(this.controls.source.valueChanges.pipe(filter(function (value) { return [CustomViewSource.HTML, CustomViewSource.View].includes(value); })), this.controls.html.valueChanges, this.controls.view.valueChanges)
            .pipe(debounceTime(200), switchMap(function () {
            _this.submitLoading$.next(true);
            return _this.submitCustomView(_this.controls.custom_view_unique_name.value, true);
        }), tap(function (customView) {
            var uniqueName = customView ? customView.uniqueName : null;
            if (_this.controls.custom_view_unique_name.value != uniqueName) {
                _this.controls.custom_view_unique_name.patchValue(uniqueName);
            }
            _this.submitLoading$.next(false);
        }), catchError(function (error) {
            _this.submitLoading$.next(false);
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Error', error.errors[0]);
            }
            else {
                _this.notificationService.error('Error', error);
            }
            return of(undefined);
        }), untilDestroyed(this))
            .subscribe();
    };
    CustomizeBarCustomEditForm.prototype.getView = function () {
        return this.controls.view.value;
    };
    CustomizeBarCustomEditForm.prototype.submitCustomView = function (uniqueName, save) {
        var _this = this;
        if (save === void 0) { save = false; }
        var customView$;
        if (save) {
            customView$ = isSet(uniqueName) ? this.customViewsStore.getDetailFirst(uniqueName) : of(undefined);
        }
        else {
            customView$ = of(undefined);
        }
        return customView$.pipe(switchMap(function (customView) {
            var instance = customView;
            var fields = ['unique_name', 'view_type', 'html', 'view', 'params'];
            var pageUid = _this.context && _this.context.viewSettings ? _this.context.viewSettings.uid : undefined;
            var elementUid = _this.element.uid;
            if (!instance) {
                instance = new CustomView();
            }
            if (!isSet(instance.uniqueName) ||
                (isSet(instance.pageUid) && instance.pageUid != pageUid) ||
                (isSet(instance.elementUid) && instance.elementUid != pageUid)) {
                instance.uniqueName =
                    isSet(pageUid) && isSet(elementUid)
                        ? [CustomViewType.Component, pageUid, elementUid].join('.')
                        : [CustomViewType.Component, generateAlphanumeric(8, { letterFirst: true })].join('.');
            }
            instance.viewType = CustomViewType.Component;
            instance.source = _this.controls.source.value;
            instance.pageUid = pageUid;
            instance.elementUid = elementUid;
            if (instance.source == CustomViewSource.View) {
                instance.html = undefined;
                instance.dist = undefined;
                instance.tagName = undefined;
                instance.filesJs = [];
                instance.filesCss = [];
                instance.view = _this.controls.view.value;
            }
            else if (instance.source == CustomViewSource.HTML) {
                instance.html = _this.controls.html.value;
                instance.dist = undefined;
                instance.tagName = undefined;
                instance.filesJs = [];
                instance.filesCss = [];
                instance.view = undefined;
            }
            else if (instance.source == CustomViewSource.CustomElement) {
                instance.html = undefined;
                instance.dist = _this.controls.dist_file.value || _this.controls.dist.value;
                instance.tagName = _this.controls.tag_name.value;
                instance.filesJs = _this.controls.files.value['js'];
                instance.filesCss = _this.controls.files.value['css'];
                instance.view = undefined;
                if (_this.controls.dist_file.value) {
                    fields.push('dist');
                }
            }
            if (save) {
                var draft = [CustomViewSource.View, CustomViewSource.HTML].includes(instance.source);
                return customView
                    ? _this.customViewService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance, { draft: draft, fields: fields })
                    : _this.customViewService.create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, instance, { draft: draft, fields: fields });
            }
            else {
                return of(instance);
            }
        }), delayWhen(function () {
            if (save) {
                return _this.customViewsStore.getFirst(true);
            }
            else {
                return of(undefined);
            }
        }));
    };
    CustomizeBarCustomEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isCustomConfigured(instance);
    };
    CustomizeBarCustomEditForm.prototype.submit = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var instance = this.element ? cloneDeep(this.element) : new CustomElementItem();
        var existingUniqueName = this.controls.custom_view_unique_name.value;
        var save = options.submitCustomView || (!options.useTemporaryCustomView && !existingUniqueName);
        return this.submitCustomView(existingUniqueName, save).pipe(map(function (customView) {
            var uniqueName = customView ? customView.uniqueName : null;
            if (_this.controls.custom_view_unique_name.value != uniqueName) {
                _this.controls.custom_view_unique_name.patchValue(uniqueName);
            }
            instance.source = _this.controls.source.value;
            if (options.useTemporaryCustomView) {
                instance.customView = undefined;
                instance.customViewTemporary = customView;
            }
            else {
                instance.customView = customView ? customView.uniqueName : undefined;
                instance.customViewTemporary = undefined;
            }
            instance.parameters = _this.controls.parameters.value;
            instance.inputs = _this.controls.inputs.value;
            instance.outputs = _this.controls.outputs.value;
            instance.actions = _this.controls.actions.serialize();
            instance.widthFluid = _this.controls.width_fluid.value;
            instance.heightFluid = _this.controls.height_fluid.value;
            instance.visibleInput = _this.controls.visible_input.value
                ? new Input().deserialize(_this.controls.visible_input.value)
                : undefined;
            instance.margin = _this.controls.margin.value;
            return instance;
        }), tap(function (element) {
            _this.element = element;
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return CustomizeBarCustomEditForm;
}(FormGroup));
export { CustomizeBarCustomEditForm };
