import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import * as Color from 'color';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { SelectComponent, SelectSegment } from '@common/select';
import { ViewSettingsAction } from '@modules/actions';
import { ElementActionsPosition, ViewContext } from '@modules/customize';
import {
  applyParamInputs,
  deserializeDisplayField,
  DisplayField,
  FieldDescription,
  FieldType,
  getFieldDescriptionByType,
  Input,
  MultipleSelectStyle,
  OptionsType,
  ParameterField,
  registerFieldComponent
} from '@modules/fields';
import { ModelDescriptionStore, ModelService, ModelUtilsService, ReducedModelService } from '@modules/model-queries';
import { ModelSelectSource } from '@modules/models-list';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { coerceArray, isSet } from '@shared';
import { ChipsComponent } from '@ui';

import { Option } from '../../data/option';
import { optionFromGxOption } from '../../utils/select';
import { FieldComponent } from '../field/field.component';

interface SelectParams {
  fieldDescription?: FieldDescription;
  readonly: boolean;
  optionsType?: OptionsType;
  resource?: string;
  valueField?: string;
  labelField?: string;
  labelInput?: Input;
  subtitleField?: string;
  subtitleInput?: Input;
  iconField?: string;
  iconInput?: Input;
  colorField?: string;
  colorInput?: Input;
  query?: ListModelDescriptionQuery;
  detailQuery?: ModelDescriptionQuery;
  columns?: DisplayField[];
  parameters: ParameterField[];
  detailParameters?: ParameterField[];
  inputs: Input[];
  sortingField?: string;
  sortingAsc?: boolean;
  options: Option[];
}

@Component({
  selector: 'app-multiple-select-field',
  templateUrl: './multiple-select-field.component.html',
  providers: [ModelSelectSource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleSelectFieldComponent extends FieldComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  @ViewChild('list_element') listElement: ElementRef;
  @ViewChildren('list_item_element') listItemElements = new QueryList<ElementRef>();

  firstVisible = false;
  params: SelectParams = {
    readonly: false,
    parameters: [],
    inputs: [],
    options: []
  };
  source: ModelSelectSource;
  staticOptions$ = new BehaviorSubject<Option[]>([]);
  selectedOptions$ = new BehaviorSubject<Option[]>([]);
  selectedOptions: Option[] = [];
  selectedOptionsLoading = false;
  updateOptionsSubscription: Subscription;
  updateSelectedOptionsOnChangeSubscription: Subscription;
  updateSelectedOptionsSubscription: Subscription;
  topActions: ViewSettingsAction[] = [];
  bottomActions: ViewSettingsAction[] = [];
  clipListItems: number;
  styles = MultipleSelectStyle;

  modelSelectSource: ModelSelectSource;

  constructor(
    @Optional() private currentEnvironmentStore: CurrentEnvironmentStore,
    @Optional() private modelDescriptionStore: ModelDescriptionStore,
    @Optional() private resourceControllerService: ResourceControllerService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {
    super();

    if (this.modelDescriptionStore) {
      this.modelSelectSource = this.createModelSelectSource();
    }
  }

  createModelSelectSource(): ModelSelectSource {
    return Injector.create({
      providers: [
        {
          provide: ModelSelectSource,
          deps: [
            ModelService,
            ReducedModelService,
            ModelDescriptionStore,
            CurrentProjectStore,
            CurrentEnvironmentStore,
            Injector,
            ModelUtilsService
          ]
        }
      ],
      parent: this.injector
    }).get(ModelSelectSource);
  }

  ngOnInit(): void {
    this.checkChanges();

    this.selectedOptions$.pipe(untilDestroyed(this)).subscribe(value => {
      this.selectedOptions = value;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['field']) {
      this.checkChanges();
    }
  }

  ngAfterViewChecked(): void {
    if (this.firstVisible && this.listElement) {
      const clipListItems = this.getClipListItems();

      if (this.clipListItems !== clipListItems) {
        this.clipListItems = clipListItems;
        this.cd.detectChanges();
      }
    }
  }

  getClipListItems(): number {
    if (this.listElement.nativeElement.scrollWidth > this.listElement.nativeElement.clientWidth) {
      let total = 0;
      let clipListItems: number;

      this.listItemElements.forEach((item, i) => {
        if (clipListItems !== undefined) {
          return;
        }

        const margin = i > 0 ? 2 * 2 : 0;
        const itemWidth = margin + item.nativeElement.offsetWidth;
        const counterWidth = margin + 40;
        const checkWidth = i == this.listItemElements.length - 1 ? total + itemWidth : total + itemWidth + counterWidth;

        if (i == 0 || checkWidth <= this.listElement.nativeElement.clientWidth) {
          total += itemWidth;
        } else {
          clipListItems = i;
          return false;
        }
      });

      return clipListItems;
    }
  }

  onFirstVisible() {
    this.firstVisible = true;
    this.checkChanges();
  }

  checkChanges() {
    if (!this.firstVisible) {
      return;
    }

    const prevParams = this.params;
    const optionsType: OptionsType = this.field.params['options_type'] || OptionsType.Static;
    const query = this.field.params['query']
      ? new ListModelDescriptionQuery().deserialize(this.field.params['query'])
      : undefined;
    const parameters = this.field.params['parameters']
      ? this.field.params['parameters'].map(item => new ParameterField().deserialize(item))
      : [];
    const inputs = this.field.params['inputs']
      ? this.field.params['inputs'].map(item => new Input().deserialize(item))
      : [];

    const params: SelectParams = {
      fieldDescription: getFieldDescriptionByType(this.field.field),
      readonly: this.readonly,
      optionsType: optionsType,
      ...(optionsType == OptionsType.Static && {
        options: this.field.params['options'] || []
      }),
      ...(optionsType == OptionsType.Query && {
        resource: this.field.params['resource'],
        query: query,
        columns: this.field.params['columns']
          ? this.field.params['columns'].map(item => deserializeDisplayField(item))
          : undefined,
        parameters: parameters,
        inputs: inputs,
        sortingField: this.field.params['sorting_field'],
        sortingAsc: this.field.params['sorting_asc'],
        valueField: this.field.params['value_field'],
        labelField: this.field.params['label_field'],
        labelInput: this.field.params['label_field_input']
          ? new Input().deserialize(this.field.params['label_field_input'])
          : undefined,
        subtitleField: this.field.params['subtitle_field'],
        subtitleInput: this.field.params['subtitle_input']
          ? new Input().deserialize(this.field.params['subtitle_input'])
          : undefined,
        iconField: this.field.params['icon_field'],
        iconInput: this.field.params['icon_input']
          ? new Input().deserialize(this.field.params['icon_input'])
          : undefined,
        colorField: this.field.params['color_field'],
        colorInput: this.field.params['color_input']
          ? new Input().deserialize(this.field.params['color_input'])
          : undefined
      })
    };

    if (this.updateOptionsSubscription) {
      this.updateOptionsSubscription.unsubscribe();
    }

    this.updateOptionsSubscription = this.applyParamsDefaults(params)
      .pipe(
        filter(newParams => !isEqual(newParams, prevParams)),
        switchMap(newParams => {
          this.params = newParams;

          if (optionsType == OptionsType.Query && this.context instanceof ViewContext) {
            return this.context.outputValues$.pipe(
              debounceTime(10),
              map(() => applyParamInputs({}, inputs, { context: this.context, parameters: parameters }))
            );
          } else {
            return of(undefined);
          }
        }),
        distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)),
        untilDestroyed(this)
      )
      .subscribe(contextParams => {
        this.init(contextParams);
        this.updateActions();
      });
  }

  init(contextParams?: Object) {
    if (this.params.optionsType == OptionsType.Static) {
      this.source = undefined;
      this.staticOptions$.next(this.params.options);
      // TODO: Workaround for options/source not passed
      this.cd.detectChanges();
    } else if (this.params.optionsType == OptionsType.Query) {
      if (this.modelSelectSource) {
        this.modelSelectSource.init({
          resource: this.params.resource,
          query: this.params.query,
          queryParameters: this.params.parameters || [],
          detailQuery: this.params.detailQuery,
          detailQueryParameters: this.params.detailParameters || [],
          columns: this.params.columns,
          valueField: this.params.valueField,
          nameField: this.params.labelField,
          nameInput: this.params.labelInput,
          subtitleField: this.params.subtitleField,
          subtitleInput: this.params.subtitleInput,
          iconField: this.params.iconField,
          iconInput: this.params.iconInput,
          colorField: this.params.colorField,
          colorInput: this.params.colorInput,
          params: contextParams,
          sortingField: this.params.sortingField,
          sortingAsc: this.params.sortingAsc,
          multiple: true,
          context: this.context,
          contextElement: this.contextElement
        });
        this.modelSelectSource.reset();
      }

      this.source = this.modelSelectSource;
      this.staticOptions$.next([]);
      this.cd.markForCheck();
    }

    if (this.updateSelectedOptionsOnChangeSubscription) {
      this.updateSelectedOptionsOnChangeSubscription.unsubscribe();
      this.updateSelectedOptionsOnChangeSubscription = undefined;
    }

    if (this.control) {
      this.updateSelectedOptionsOnChangeSubscription = this.control.valueChanges
        .pipe(delay(0), untilDestroyed(this))
        .subscribe(() => this.updateValueOption());
    }

    this.updateValueOption();
  }

  updateValueOption() {
    if (this.updateSelectedOptionsSubscription) {
      this.updateSelectedOptionsSubscription.unsubscribe();
      this.updateSelectedOptionsSubscription = undefined;
    }

    if (!this.params.readonly) {
      return;
    }

    const currentValue = coerceArray(this.currentValue);

    if (this.params.optionsType == OptionsType.Static) {
      let option: Option[] = this.staticOptions$.value.filter(item => currentValue.find(i => i == item.value));

      if (!option && isSet(this.currentValue)) {
        option = [{ name: this.currentValue, value: this.currentValue }];
      }

      this.selectedOptions$.next(option);
      this.selectedOptionsLoading = false;
      this.cd.markForCheck();
    } else if (this.params.optionsType == OptionsType.Query && currentValue.length) {
      if (this.modelSelectSource) {
        this.selectedOptionsLoading = true;
        this.cd.markForCheck();

        this.modelSelectSource
          .fetchByValue(this.currentValue)
          .pipe(
            map(options => {
              if (!options) {
                return [];
              }

              const optionsArray = isArray(options) ? options : [options];
              return optionsArray.map(option => optionFromGxOption(option));
            }),
            untilDestroyed(this)
          )
          .subscribe(
            options => {
              this.selectedOptions$.next(options);
              this.selectedOptionsLoading = false;
              this.cd.markForCheck();
            },
            () => {
              this.selectedOptionsLoading = false;
              this.cd.markForCheck();
            }
          );
      }
    } else {
      this.selectedOptions$.next([]);
      this.selectedOptionsLoading = false;
      this.cd.markForCheck();
    }
  }

  applyParamsDefaults(params: SelectParams): Observable<SelectParams> {
    if (!params.query || params.query.queryType != QueryType.Simple || !this.modelDescriptionStore) {
      return of(params);
    }

    const modelId = [params.resource, params.query.simpleQuery.model].join('.');

    return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
      map(modelDescription => {
        if (!modelDescription) {
          return params;
        }

        if (modelDescription.getParameters && params.query.queryType == QueryType.Simple) {
          params.parameters = modelDescription.getParameters;
        }

        if (modelDescription.getDetailQuery) {
          const detailQuery = new ModelDescriptionQuery();

          detailQuery.queryType = QueryType.Simple;
          detailQuery.simpleQuery = new detailQuery.simpleQueryClass();
          detailQuery.simpleQuery.model = modelDescription.model;

          const resource = this.currentEnvironmentStore
            ? this.currentEnvironmentStore.resources.find(item => {
                return item.uniqueName == modelDescription.resource;
              })
            : undefined;
          const controller =
            this.resourceControllerService && resource ? this.resourceControllerService.get(resource.type) : undefined;
          const modelParameters = controller
            ? controller.getDetailParametersOrDefaults(resource, modelDescription)
            : [];

          params.detailQuery = detailQuery;
          params.detailParameters = modelParameters;
        }

        return params;
      })
    );
  }

  updateActions() {
    const topActions = this.elementActions.find(item => item.position == ElementActionsPosition.Top);
    const bottomActions = this.elementActions.find(item => item.position == ElementActionsPosition.Bottom);

    this.topActions = topActions ? topActions.actions : [];
    this.bottomActions = bottomActions ? bottomActions.actions : [];
    this.cd.markForCheck();
  }

  // TODO: Move to separate params option
  get selectFill() {
    return this.field.params['classes'] && this.field.params['classes'].indexOf('select_fill') != -1;
  }

  // TODO: Move to separate params option
  get selectSegment(): SelectSegment {
    if (!this.field.params['classes']) {
      return;
    }

    if (this.field.params['classes'].indexOf('select_segment-top') != -1) {
      return SelectSegment.Top;
    } else if (this.field.params['classes'].indexOf('select_segment') != -1) {
      return SelectSegment.Middle;
    } else if (this.field.params['classes'].indexOf('select_segment-bottom') != -1) {
      return SelectSegment.Bottom;
    }
  }

  backgroundCustomColor(color: string) {
    try {
      const clr = Color(color);
      return clr.alpha(0.1).string();
    } catch (e) {
      return null;
    }
  }

  asActions(value: any): ViewSettingsAction[] {
    return value as ViewSettingsAction[];
  }

  asClosableComponent(value: any): SelectComponent | ChipsComponent {
    return value as SelectComponent | ChipsComponent;
  }
}

registerFieldComponent(FieldType.MultipleSelect, MultipleSelectFieldComponent);
