import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable } from 'rxjs';

import { AdminMode } from '@modules/admin-mode';
import { ApiService, TokenOptions } from '@modules/api';
import { CustomizeService } from '@modules/customize';
import { DraftChangeItem } from '@modules/customize-utils';
import { MenuSettingsStore } from '@modules/menu';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUser } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

@Component({
  selector: 'app-customize-preview',
  templateUrl: './customize-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizePreviewComponent implements OnInit, OnDestroy {
  @Input() active = false;
  @Input() draftChanges: DraftChangeItem[] = [];
  @Input() draftChangesTotal = 0;
  @Input() savingChanges = false;
  @Input() publishLoading = false;
  @Output() exitPreview = new EventEmitter<void>();
  @Output() deleteDraft = new EventEmitter<void>();
  @Output() publishDraft = new EventEmitter<void>();
  @Output() showChanges = new EventEmitter<void>();

  previewAsOpened = false;

  constructor(
    public currentUserStore: CurrentUserStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private menuSettingsStore: MenuSettingsStore,
    private customizeService: CustomizeService,
    private apiService: ApiService,
    private projectApiService: ProjectApiService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    this.disablePreviewAsUser();
  }

  setPreviewAsOpened(value) {
    this.previewAsOpened = value;
    this.cd.markForCheck();
  }

  previewAsUser(user: ProjectUser) {
    if (this.currentUserStore.override && user.user && this.currentUserStore.override.uid == user.user.uid) {
      return;
    }

    combineLatest(this.getUserToken(user), this.getUserToken(user))
      .pipe(untilDestroyed(this))
      .subscribe(([token1, token2]) => {
        this.currentUserStore.setOverride(user.user);
        this.currentEnvironmentStore.setOverrideUser(user);
        this.currentEnvironmentStore.setOverrideGroup(user.group);
        this.menuSettingsStore.getFirst(true).subscribe();
        this.apiService.setOverrideToken(token1);
        this.projectApiService.setOverrideToken(token2);

        if (this.customizeService.handler && this.customizeService.handler.reload) {
          this.customizeService.handler.reload().subscribe();
        }
      });
  }

  getUserToken(user: ProjectUser): Observable<TokenOptions> {
    return this.projectApiService.getToken(
      this.currentProjectStore.instance.uniqueName,
      this.currentEnvironmentStore.instance.uniqueName,
      {
        user: user.user,
        forceOriginal: true,
        mode: AdminMode.Builder
      }
    );
  }

  disablePreviewAsUser() {
    if (!this.currentUserStore.override) {
      return;
    }

    this.apiService.clearOverrideToken();
    this.projectApiService.clearOverrideToken();
    this.currentUserStore.clearOverride();
    this.currentEnvironmentStore.clearOverrideUser();
    this.currentEnvironmentStore.clearOverrideGroup();
    this.menuSettingsStore.getFirst(true).subscribe();

    if (this.customizeService.handler && this.customizeService.handler.reload) {
      this.customizeService.handler.reload().subscribe();
    }
  }
}
