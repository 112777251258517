import { StorageObject } from './storage-object';

export class StorageObjectsResponse {
  objects: StorageObject[] = [];
  storageSize?: number;
  storageLimit?: number;

  deserialize(data: Object): StorageObjectsResponse {
    this.storageSize = data['storage_size'];
    this.storageLimit = data['storage_limit'];

    if (data['objects']) {
      this.objects = data['objects'].map(item => new StorageObject().deserialize(item));
    }

    return this;
  }

  serialize() {
    return {
      storage_size: this.storageSize,
      storage_limit: this.storageLimit,
      objects: this.objects.map(item => item.serialize())
    };
  }
}
