import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Option, SelectSource } from 'ng-gxselect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { controlValue, isElementInViewport, isSet, KeyboardEventKeyCode, scrollToElement2 } from '@shared';

@Component({
  selector: 'app-select-javascript-dependency-selector',
  templateUrl: './select-javascript-dependency-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectJavascriptDependencySelectorComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() itemIcon: string;
  @Input() multiple = false;
  @Input() source: SelectSource;
  @Input() searchPlaceholder = 'Search...';
  @Input() searchMinimumLength = 0;
  @Input() backEnabled = false;
  @Input() backTitle = 'Back';
  @Output() selectValue = new EventEmitter<any>();
  @Output() back = new EventEmitter<void>();

  @ViewChild('scrollable_element') scrollableElement: ElementRef;
  @ViewChildren('option_element') optionElements = new QueryList<ElementRef>();

  loading = false;
  options: Option[] = [];
  multipleControl = new FormControl([]);
  searchControl = new FormControl('');
  activeToken$ = new BehaviorSubject<Option>(undefined);
  hoverToken$ = new BehaviorSubject<Option>(undefined);
  keyboardNavigation = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue(this.searchControl)
      .pipe(debounceTime(200), untilDestroyed(this))
      .subscribe(search => {
        search = isSet(search) ? search : '';

        if (search.length >= this.searchMinimumLength) {
          this.source.search(search);
        } else {
          this.source.reset();
        }
      });

    this.source.loading$.pipe(untilDestroyed(this)).subscribe(value => {
      this.loading = value;
      this.cd.markForCheck();
    });

    this.source.options$.pipe(untilDestroyed(this)).subscribe(value => {
      this.options = value;
      this.cd.markForCheck();
      this.resetActiveToken();
    });

    this.initHotkeys();
  }

  ngOnDestroy(): void {}

  initHotkeys() {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        const activeToken = this.activeToken$.value;

        if (e.keyCode == KeyboardEventKeyCode.Tab && !e.shiftKey && activeToken) {
          e.stopPropagation();
          e.preventDefault();

          this.onClick(activeToken);
        } else if (e.keyCode == KeyboardEventKeyCode.Tab && e.shiftKey && this.backEnabled) {
          e.stopPropagation();
          e.preventDefault();

          this.back.emit();
        } else if (e.keyCode == KeyboardEventKeyCode.ArrowUp && activeToken) {
          e.stopPropagation();
          e.preventDefault();

          const sibling = this.findOptionSibling(activeToken, false);

          if (sibling) {
            this.activeToken$.next(sibling);
            this.keyboardNavigation = true;
            this.scrollToItem(sibling);
          }
        } else if (e.keyCode == KeyboardEventKeyCode.ArrowDown && activeToken) {
          e.stopPropagation();
          e.preventDefault();

          const sibling = this.findOptionSibling(activeToken, true);

          if (sibling) {
            this.activeToken$.next(sibling);
            this.keyboardNavigation = true;
            this.scrollToItem(sibling);
          }
        }
      });

    fromEvent<MouseEvent>(window.document, 'mousemove')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.keyboardNavigation = false;
      });
  }

  resetActiveToken() {
    this.activeToken$.next(this.options[0]);
  }

  findOptionSibling(option: Option, next: boolean) {
    const index = this.options.findIndex(item => item.value == option.value);

    if (index === -1) {
      return;
    }

    return next ? this.options[index + 1] : this.options[index - 1];
  }

  scrollToItem(option: Option) {
    const index = this.options.findIndex(item => item.value == option.value);
    if (index === -1) {
      return;
    }

    const element = this.optionElements.toArray()[index];
    if (!element) {
      return;
    }

    if (isElementInViewport(element.nativeElement, this.scrollableElement.nativeElement)) {
      return;
    }

    scrollToElement2(this.scrollableElement.nativeElement, element.nativeElement, 0, -40);
  }

  clearSearch() {
    this.searchControl.setValue('');
  }

  onClick(option: Option) {
    if (this.multiple) {
      let value = this.multipleControl.value;
      const exists = value.includes(option.value);

      if (option.value == '*') {
        if (exists) {
          this.multipleControl.patchValue([]);
        } else {
          this.multipleControl.patchValue(['*']);
        }
      } else {
        value = value.filter(item => item != '*');

        if (exists) {
          this.multipleControl.patchValue(value.filter(item => item != option.value));
        } else {
          this.multipleControl.patchValue([...value, option.value]);
        }
      }

      this.cd.markForCheck();
    } else {
      this.selectValue.emit(option.value);
    }
  }

  onItemHover(item: Option) {
    if (!this.keyboardNavigation) {
      this.activeToken$.next(item);
      this.hoverToken$.next(item);
    }
  }

  onItemOut() {
    this.hoverToken$.next(undefined);
  }
}
