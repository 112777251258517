<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div (cdkObserveContent)="onPopoverContentChanged()">
    <div class="popover2 popover2_header">
      <div class="popover2__inner">
        <app-select-javascript-dependency
          [origin]="origin"
          [trigger]="trigger"
          (selected)="selected.emit($event); close()"
        ></app-select-javascript-dependency>

        <div class="popover2__arrow"></div>
      </div>
    </div>
  </div>
</ng-template>
