import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import keys from 'lodash/keys';
import { from, Observable, of } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { isSet } from '@shared';

import { NpmPackage } from '../../data/npm-package';

@Injectable({
  providedIn: 'root'
})
export class NpmRegistryService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  search(projectName: string, environmentName: string, search: string): Observable<NpmRegistryService.SearchResponse> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'npm_registry/search');
        let headers = new HttpHeaders();
        const data = {
          search: search
        };

        headers = this.apiService.setHeadersToken(headers);
        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new NpmRegistryService.SearchResponse().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(projectName: string, environmentName: string, name: string): Observable<NpmPackage> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(
          projectName,
          environmentName,
          `npm_registry/details/${name}`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers });
      }),
      map(result => new NpmPackage().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getExports(projectName: string, environmentName: string, name: string, version?: string): Observable<string[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        let AsyncFunction: any;

        try {
          const getAsyncFunction = new Function('return Object.getPrototypeOf(async function() {}).constructor');
          AsyncFunction = getAsyncFunction();
        } catch (e) {}

        if (!AsyncFunction) {
          return of(undefined);
        }

        try {
          const js = `return await import('https://esm.sh/${name}${isSet(version) ? `@${version}` : ''}')`;
          const x = new AsyncFunction(js);
          return from(x().then(value => value));
        } catch (e) {}

        return of(undefined);
      }),
      map(result => {
        if (result) {
          return keys(result);
        } else {
          return ['default'];
        }
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

export namespace NpmRegistryService {
  export class SearchResponse {
    public items: NpmPackage[];

    deserialize(data: Object) {
      this.items = data['items'].map(item => new NpmPackage().deserialize(item));

      return this;
    }
  }
}
