<div
  class="customize-menu__data-menu"
  [class.customize-menu__data-menu_visible]="opened"
  (click)="markClickEvent($event)"
>
  <div class="customize-menu__data-menu-viewport" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }">
    <div class="menu3">
      <div class="menu3__items">
        <div *ngFor="let section of sections" class="menu3__section">
          <app-custom-page-popups-menu-section
            [section]="section"
            [openedPopupUids]="openedPopupUids"
            [viewSettings]="viewSettings"
            (openPopup)="openPopup.emit($event); close()"
            (closePopup)="closePopup.emit($event); close()"
            (customizePopup)="customizePopup.emit($event); close()"
            (movePopupToPage)="movePopupToPage.emit($event); close()"
            (duplicatePopup)="duplicatePopup.emit($event); close()"
            (deletePopup)="deletePopup.emit($event)"
          ></app-custom-page-popups-menu-section>
        </div>
      </div>
    </div>
  </div>
</div>
