import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';

import { JavascriptDependency } from '@modules/npm';

@Component({
  selector: 'app-select-javascript-dependency-popover',
  templateUrl: './select-javascript-dependency-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectJavascriptDependencyPopoverComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() origin: CdkOverlayOrigin;
  @Input() trigger: HTMLElement;
  @Output() selected = new EventEmitter<JavascriptDependency>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  dropdownOpened = false;
  popoverPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8,
      offsetY: 0,
      weight: 4
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'top',
      offsetX: -4,
      offsetY: -8 - 60 + 4,
      weight: 3
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'bottom',
      offsetX: -4,
      offsetY: 8 + 20,
      weight: 2
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8,
      offsetY: 0,
      weight: 1
    }
  ];
  popoverPositionsSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.initShowOnClick();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  initShowOnClick() {
    let trigger: HTMLElement;

    if (this.trigger) {
      trigger = this.trigger;
    } else if (this.origin) {
      trigger = this.origin.elementRef.nativeElement;
    }

    if (trigger) {
      fromEvent(trigger, 'click')
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.open();
        });
    }
  }

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }

  public isOpened(): boolean {
    return this.dropdownOpened;
  }

  public open() {
    this.setDropdownOpened(true);
  }

  public close() {
    this.setDropdownOpened(false);
  }

  onPopoverContentChanged() {
    this.cdkConnectedOverlay.overlayRef.updatePosition();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.popoverPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.popoverPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}
