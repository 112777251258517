import repeat from 'lodash/repeat';

import { isSet, numeralFormat, parseNumber } from '@shared';

import {
  defaultNumberFraction,
  defaultNumberFractionSeparator,
  defaultNumberThousandsSeparator,
  IValueFormat,
  NumberValueFormat
} from '../data/value-format.interface';

export function applyStaticValueFormat(value: any, valueFormat: IValueFormat): string {
  if (!valueFormat) {
    return value as any;
  }

  const result = [];

  if (
    isSet(valueFormat.numberFormat) ||
    isSet(valueFormat.numberFraction) ||
    isSet(valueFormat.numberFractionSeparator) ||
    isSet(valueFormat.numberThousandsSeparator, true)
  ) {
    const numberValue = parseNumber(value);
    const numberFraction =
      typeof valueFormat.numberFraction == 'number' ? valueFormat.numberFraction : defaultNumberFraction;
    const numberFractionSeparator = isSet(valueFormat.numberFractionSeparator)
      ? valueFormat.numberFractionSeparator
      : defaultNumberFractionSeparator;
    const numberThousandsSeparator = isSet(valueFormat.numberThousandsSeparator, true)
      ? valueFormat.numberThousandsSeparator
      : defaultNumberThousandsSeparator;
    let format = `0,0`;

    if (numberFraction > 0) {
      format += `[.]${repeat('0', numberFraction)}`;
    }

    if (valueFormat.numberFormat == NumberValueFormat.MetricPrefix) {
      format += 'a';
    } else if (valueFormat.numberFormat == NumberValueFormat.Percentage) {
      format += '%';
    } else if (valueFormat.numberFormat == NumberValueFormat.Order) {
      format += 'o';
    } else if (valueFormat.numberFormat == NumberValueFormat.FileSize) {
      format += 'b';
    } else if (valueFormat.numberFormat == NumberValueFormat.Exponential) {
      format += 'e+0';
    }

    value = numeralFormat(numberValue, format).replace(/[,.]/g, symbol => {
      if (symbol == ',') {
        return numberThousandsSeparator;
      } else if (symbol == '.') {
        return numberFractionSeparator;
      } else {
        return symbol;
      }
    });
  }

  if (valueFormat.prefix) {
    result.push(valueFormat.prefix);
  }

  result.push(value);

  if (valueFormat.postfix) {
    result.push(valueFormat.postfix);
  }

  return result.join('');
}
