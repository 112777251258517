import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, skip, switchMap } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType, ModelBasedDataSource } from '@modules/data-sources';
import { BooleanFieldStyle, Option } from '@modules/field-components';
import { serializeFieldParamName } from '@modules/field-lookups';
import {
  BaseField,
  createFormFieldFactory,
  EditableField,
  FieldType,
  getFieldDescriptionByType,
  Input as FieldInput,
  InputFilterField,
  InputValueType
} from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { traverseModelPath } from '@modules/models';
import {
  FieldInputArray,
  FieldInputControl,
  flattenInputFieldProviderItems,
  getInputRepresentation,
  InputFieldProvider,
  InputFieldProviderItem
} from '@modules/parameters';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import { ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { SidebarCollapseContext } from '@modules/sidebar';
import { coerceArray, controlValue, forceObservable, isSet, TypedChanges } from '@shared';

// TODO: Refactor import
import { DisplayFieldArray } from '@modules/customize-bar/components/display-fields-edit/display-field.array';

@Component({
  selector: 'app-field-inputs-edit-item',
  templateUrl: './field-inputs-edit-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldInputsEditItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() itemForm: FieldInputControl;
  @Input() array: FieldInputArray;
  @Input() dataSource: ModelBasedDataSource;
  @Input() parameterProvider: InputFieldProvider;
  @Input() fieldsControl: DisplayFieldArray;
  @Input() openedInitial = false;
  @Input() focusedOnOpen = false;
  @Input() formulaPlaceholder = 'Formula';
  @Input() jsPlaceholder = 'return 2 * 3;';
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() collapse = true;
  @Input() collapseContext: SidebarCollapseContext;
  @Input() remove = false;
  @Input() displayRequiredIndicator = false;
  @Input() userInput = false;
  @Input() displayValueTypes = [InputValueType.Formula];
  @Input() classes: string | string[];
  @Input() analyticsSource: string;

  createField = createFormFieldFactory();
  title: string;
  description: string;
  infoSubscription: Subscription;
  analyticsSubscription: Subscription;
  titleAdditional$: Observable<string>;
  titleAdditionalOptions: Option[] = [
    {
      value: true,
      name: 'Required to load data',
      subtitle: 'Component <u>will not display data</u> if this parameter is empty string, NULL or is not set'
    },
    {
      value: false,
      name: 'Optional to load data',
      subtitle: 'Component <u>will ignore this parameter</u> if it is empty string, NULL or is not set'
    }
  ];
  booleanFieldStyle = BooleanFieldStyle;

  dataSource$ = new BehaviorSubject<ModelBasedDataSource>(undefined);
  placeholder$: Observable<string>;
  instance$: Observable<FieldInput>;
  isSet$: Observable<boolean>;
  parameter$: Observable<EditableField>;
  providerItemWarning$: Observable<string>;
  filterFields$: Observable<InputFilterField[]>;

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<FieldInputsEditItemComponent>): void {
    if (changes.dataSource) {
      this.dataSource$.next(this.dataSource);
    }

    if (changes.itemForm) {
      this.instance$ = this.itemForm.getInstance$();
      this.isSet$ = this.itemForm.isSet$();
      this.parameter$ = this.getParameter$();
      this.placeholder$ = this.parameter$.pipe(map(parameter => this.getFieldPlaceholder(parameter)));
      this.providerItemWarning$ = this.getProviderItem$().pipe(map(item => (item ? item.warning : undefined)));
    }

    if (changes.fieldsControl) {
      this.filterFields$ = this.getFilterFields$();
    }

    if (changes.itemForm) {
      this.initItemGroup();
    }
  }

  initItemGroup() {
    if (this.infoSubscription) {
      this.infoSubscription.unsubscribe();
    }

    if (this.analyticsSubscription) {
      this.analyticsSubscription.unsubscribe();
    }

    if (!this.itemForm) {
      return;
    }

    this.infoSubscription = combineLatest(
      // Title
      this.parameter$.pipe(map(parameter => this.getTitle(parameter))),

      // Description
      combineLatest(this.parameter$, this.instance$).pipe(
        switchMap(([parameter, input]) => this.getDescription(parameter, input))
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(([title, description]) => {
        this.title = title;
        this.description = description;
        this.cd.markForCheck();
      });

    this.analyticsSubscription = this.instance$.pipe(skip(1), untilDestroyed(this)).subscribe(input => {
      if (!input) {
        return;
      }

      if (
        (input.valueType == InputValueType.StaticValue && input.staticValue) ||
        (input.valueType == InputValueType.Filter && input.filterField && input.filterLookup) ||
        (input.valueType == InputValueType.Context && input.contextValue) ||
        (input.valueType == InputValueType.Formula && input.formulaValue) ||
        (input.valueType == InputValueType.TextInputs && input.textInputsValue && input.textInputsValue.ops.length)
      ) {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.SetParameter.SuccessfullySetUp, {
          Object: this.analyticsSource,
          Type: input.valueType
        });
      }
    });

    this.titleAdditional$ = combineLatest(controlValue<boolean>(this.itemForm.controls.required), this.parameter$).pipe(
      map(([inputRequired, parameter]) => {
        if (inputRequired || (parameter && parameter.required)) {
          return 'required';
        } else {
          return 'optional';
        }
      })
    );
  }

  getParameter$(): Observable<EditableField> {
    return combineLatest(
      controlValue<string[]>(this.itemForm.controls.path),
      controlValue<string[]>(this.itemForm.controls.path).pipe(switchMap(path => this.traverseDataSourcePath$(path))),
      controlValue<string>(this.itemForm.controls.lookup),
      controlValue<boolean>(this.itemForm.controls.exclude),
      this.parameterProvider.getFields$()
    ).pipe(
      map(([path, field, lookupName, exclude, parameterFields]) => {
        const prefix = exclude ? 'exclude' : '';
        const name = [prefix, ...path, lookupName ? lookupName : '']
          .filter((str, i) => isSet(str) || i == 1)
          .join('__');

        const parameterField = path && path.length == 1 ? parameterFields.find(item => item.name == name) : undefined;
        if (parameterField) {
          if (!parameterField.field) {
            return {
              ...parameterField,
              required: false,
              field: FieldType.Select,
              params: {
                parameterWithoutField: true,
                valueEquals: (lhs, rhs) => lhs === rhs,
                options: [
                  { value: undefined, name: 'Not applied' },
                  { value: true, name: 'Always applied' }
                ],
                classes: ['select_fill']
              }
            };
          } else {
            return parameterField;
          }
        }

        if (!field) {
          return;
        }

        const fieldDescription = getFieldDescriptionByType(field.field);
        const lookup = fieldDescription
          ? fieldDescription.lookups.find(item => item.type.lookup == lookupName)
          : undefined;
        const verboseName = [prefix, field.verboseName || field.name, lookup ? lookup.type.verboseName : '']
          .filter((str, i) => isSet(str) || i == 1)
          .join(' ');
        const fieldType = lookup ? lookup.field : undefined;

        if (!fieldType || fieldType == FieldType.Boolean) {
          return {
            name: name,
            verboseName: verboseName,
            required: false,
            field: FieldType.Select,
            params: {
              parameterWithoutField: true,
              valueEquals: (lhs, rhs) => lhs === rhs,
              options: [
                { value: undefined, name: 'Not applied' },
                { value: true, name: 'Always applied' }
              ],
              classes: ['select_fill']
            },
            array: lookup ? lookup.array : false
          };
        } else {
          const fieldParams =
            lookup && lookup.fieldParams
              ? lookup.fieldParams.reduce((acc, item) => {
                  acc[item] = field.params[item];
                  return acc;
                }, {})
              : {};
          const params = {
            ...fieldParams,
            ...(lookup && lookup.extraParams)
          };

          return {
            name: name,
            verboseName: verboseName,
            required: false,
            field: fieldType,
            params: params,
            array: lookup ? lookup.array : false
          };
        }
      })
    );
  }

  getProviderItem$(): Observable<InputFieldProviderItem> {
    return combineLatest(
      controlValue<string[]>(this.itemForm.controls.path),
      controlValue<string>(this.itemForm.controls.lookup),
      controlValue<boolean>(this.itemForm.controls.exclude),
      this.parameterProvider.getItems$().pipe(map(providerItems => flattenInputFieldProviderItems(providerItems)))
    ).pipe(
      map(([path, lookup, exclude, providerItems]) => {
        if (!isSet(path) || path.length != -1) {
          return;
        }

        const name = serializeFieldParamName(path.join('__'), lookup, exclude);
        return providerItems.find(item => item.field && item.field.name == name);
      })
    );
  }

  getDataSourceParams(): {
    type: DataSourceType;
    query: ListModelDescriptionQuery;
    resource: Resource;
    modelId: string;
  } {
    const type = this.dataSource ? this.dataSource.type : undefined;
    const query = type == DataSourceType.Query ? (this.dataSource.query as ListModelDescriptionQuery) : undefined;
    const resource =
      type == DataSourceType.Query
        ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.dataSource.queryResource)
        : undefined;
    const modelId =
      resource && query && query.queryType == QueryType.Simple && query.simpleQuery
        ? [resource.uniqueName, query.simpleQuery.model].join('.')
        : undefined;

    return {
      type: type,
      query: query,
      resource: resource,
      modelId: modelId
    };
  }

  traverseDataSourcePath$(path: string[]): Observable<EditableField> {
    const { modelId } = this.getDataSourceParams();

    if (isSet(modelId)) {
      return this.modelDescriptionStore.getFirst().pipe(
        map(modelDescriptions => {
          const modelDescription = isSet(modelId) ? modelDescriptions.find(item => item.isSame(modelId)) : undefined;
          const traversePath = traverseModelPath(modelDescription, path, modelDescriptions);

          if (!traversePath || !traversePath.length) {
            return;
          }

          return traversePath[traversePath.length - 1].field;
        })
      );
    } else if (this.dataSource) {
      if (!path || path.length != 1) {
        return of(undefined);
      }

      const parameter = this.dataSource.queryParameters.find(item => item.name == path[0]);
      if (parameter) {
        return of(parameter);
      }

      const column = this.dataSource.columns.find(item => item.name == path[0]);
      if (column) {
        return of(column);
      }

      return of(undefined);
    } else {
      return of(undefined);
    }
  }

  getTitle(parameter: BaseField): string {
    const result = [];

    if (parameter) {
      result.push(parameter.verboseName || parameter.name);

      if (parameter.description) {
        result.push(parameter.description);
      }
    } else {
      const path = this.itemForm.controls.path.value ? coerceArray(this.itemForm.controls.path.value) : [];
      if (path.length) {
        if (this.itemForm.controls.exclude.value) {
          result.push('exclude');
        }

        result.push(path[path.length - 1]);

        if (isSet(this.itemForm.controls.lookup.value)) {
          result.push(this.itemForm.controls.lookup.value);
        }
      }
    }

    return result.length ? result.join(' ') : undefined;
  }

  getInputRepresentation(input: FieldInput, parameter: BaseField): Observable<string> {
    return this.filterFields$.pipe(
      switchMap(filterFields => {
        const result = getInputRepresentation(input, {
          context: this.context,
          contextElement: this.contextElement,
          contextElementPath: this.contextElementPath,
          contextElementPaths: this.contextElementPaths,
          filterFields: filterFields
        });
        return forceObservable<string>(result);
      }),
      map(result => {
        if (!isSet(result)) {
          return parameter.description;
        }

        if (parameter.params && parameter.params['parameterWithoutField']) {
          return result ? 'is applied' : undefined;
        } else {
          return result;
        }
      })
    );
  }

  getDescription(parameter: BaseField, input: FieldInput): Observable<string> {
    if (!parameter) {
      return of('Not existing');
    } else if (!parameter.field) {
      return of(null);
    }

    if (!input) {
      return of(null);
    }

    return this.getInputRepresentation(input, parameter);
  }

  getFieldPlaceholder(parameter: BaseField) {
    if (!parameter) {
      return;
    }

    const fieldDescription = getFieldDescriptionByType(parameter.field);

    if (parameter.params && parameter.params['parameterWithoutField']) {
      return 'Not applied';
    } else if (
      [FieldType.Select, FieldType.MultipleSelect, FieldType.RelatedModel, FieldType.Boolean].includes(parameter.field)
    ) {
      return 'Choose option';
    } else {
      return fieldDescription.label;
    }
  }

  getFilterFields$(): Observable<InputFilterField[]> {
    if (!this.fieldsControl) {
      return of([]);
    }

    return controlValue<BaseField[]>(this.fieldsControl).pipe(
      map(fields => {
        return fields.map(item => {
          const fieldDescription = getFieldDescriptionByType(item.field);

          return {
            name: item.name,
            label: item.verboseName || item.name,
            lookups: fieldDescription
              ? fieldDescription.lookups.map(lookup => {
                  return {
                    name: lookup.type.lookup,
                    label: lookup.type.verboseName || lookup.type.name
                  };
                })
              : []
          };
        });
      })
    );
  }

  removeItem() {
    this.array.removeControl(this.itemForm);

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.SetParameter.Deleted, {
      Object: this.analyticsSource
    });
  }
}
