import { Injectable, Injector } from '@angular/core';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import pickBy from 'lodash/pickBy';
import toPairs from 'lodash/toPairs';
import { Observable, of, throwError } from 'rxjs';
import { map, publishLast, refCount } from 'rxjs/operators';

import { ServerRequestError } from '@modules/api';
import { AggregateFunc, DataGroup, DatasetGroupLookup } from '@modules/charts';
import { RawListViewSettingsColumn } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import { Model, ModelDescription, ModelField, PAGE_PARAM, PER_PAGE_PARAM } from '@modules/models';
import { Resource, ResourceTypeItem } from '@modules/projects';
import {
  HttpMethod,
  HttpQuery,
  HttpQueryOptions,
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  ObjectQuery,
  ObjectQueryOperation,
  QueryType
} from '@modules/queries';
import { isSet } from '@shared';

import {
  FirebaseDatabaseOption,
  FirebaseDatabasesResponse,
  FirebaseFirestoreCollectionIdsResponse
} from '../../data/firebase';
import { ModelResponse } from '../../data/model-response';
import { ObjectQueryOptions } from '../../data/resource-controller';
import { aggregateGetResponse } from '../../utils/aggregate';
import { isResourceQueryCustom } from '../../utils/common';
import { applyFrontendFiltering, applyFrontendPagination, applyFrontendSorting } from '../../utils/filters';
import { groupGetResponse } from '../../utils/group';
import { RestApiResourceControllerService } from '../rest-api-resource-controller/rest-api-resource-controller.service';

export const OBJECT_QUERY_KEY_NAME = 'jet_id';
export const OBJECT_QUERY_KEY_LABEL = 'object key';

@Injectable()
export class FirebaseResourceController extends RestApiResourceControllerService {
  tokenName = 'access_token';

  constructor(protected injector: Injector) {
    super(injector);
  }

  init() {
    super.init();
  }

  supportedQueryTypes(resource: ResourceTypeItem, queryClass: any): QueryType[] {
    // return [QueryType.Http, QueryType.Object];
    return [QueryType.Object];
  }

  getFirestoreCollectionIds(
    projectId: string,
    accessToken: string
  ): Observable<FirebaseFirestoreCollectionIdsResponse> {
    const databasePath = `projects/${projectId}/databases/(default)/documents`;
    const url = `https://firestore.googleapis.com/v1/${databasePath}:listCollectionIds/`;
    const headers = { Authorization: `Bearer ${accessToken}` };
    const data = {
      pageSize: 100
    };

    return this.http
      .post(url, data, { headers: headers })
      .pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }

  getRealtimeDatabases(projectId: string, accessToken: string): Observable<FirebaseDatabasesResponse> {
    const url = `https://firebasedatabase.googleapis.com/v1beta/projects/${projectId}/locations/-/instances`;
    const headers = { Authorization: `Bearer ${accessToken}` };

    return this.http.get(url, { headers: headers }).pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }

  modelGetQueryObject(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    const query = modelDescription.getQuery;

    if (!query) {
      return throwError(
        new ServerRequestError(`No query specified for collection ${modelDescription.verboseNamePlural}`)
      );
    }

    return this.getQueryObject(
      resource,
      query,
      modelDescription.getParameters,
      params,
      body,
      modelDescription.dbFields
    ).pipe(
      map(response => {
        if (!response) {
          return;
        }

        response.results.forEach(item => {
          item.setUp(modelDescription);
        });

        return response;
      })
    );
  }

  modelGet(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    if (!modelDescription.getQuery) {
      return of(undefined);
    }

    params = params || {};

    if (modelDescription.getQuery.queryType == QueryType.Http) {
      return super.modelGet(resource, modelDescription, params, body);
    } else if (modelDescription.getQuery.queryType == QueryType.Object) {
      return this.modelGetQueryObject(resource, modelDescription, params, body);
    } else {
      return of(undefined);
    }
  }

  modelGetDetailQueryObject(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model> {
    const query = modelDescription.getDetailQuery;

    if (!query) {
      return throwError(
        new ServerRequestError(`No query specified for collection ${modelDescription.verboseNamePlural}`)
      );
    }

    return this.getDetailQueryObject(resource, query, params, modelDescription.dbFields).pipe(
      map(item => {
        if (!item) {
          return;
        }

        item.setUp(modelDescription);
        return item;
      })
    );
  }

  modelGetDetail(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model> {
    params = params || {};

    if (modelDescription.getDetailQuery) {
      if (modelDescription.getDetailQuery.queryType == QueryType.Http) {
        return super.modelGetDetail(resource, modelDescription, idField, id, params);
      } else if (modelDescription.getDetailQuery.queryType == QueryType.Object) {
        return this.modelGetDetailQueryObject(resource, modelDescription, idField, id, params);
      } else {
        return of(undefined);
      }
    } else if (modelDescription.getQuery) {
      if (modelDescription.getQuery.queryType == QueryType.Http) {
        return super.modelGetDetail(resource, modelDescription, idField, id, params);
      } else if (modelDescription.getQuery.queryType == QueryType.Object) {
        params = {
          ...params
        };

        if (isSet(modelDescription.primaryKeyField)) {
          params[idField] = id;
        }

        return this.modelGetQueryObject(resource, modelDescription, params).pipe(
          map(result => {
            if (!result || !result.results.length) {
              return;
            }

            const model = result.results[0];
            model.setUp(modelDescription);
            return model;
          })
        );
      } else {
        return of(undefined);
      }
    }
  }

  modelCreateQueryObject(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    const query = modelDescription.createQuery;

    if (!query) {
      return throwError(
        new ServerRequestError(`No query specified for collection ${modelDescription.verboseNamePlural}`)
      );
    }

    return this.createQueryObject(resource, query, modelInstance, modelDescription.dbFields, fields).pipe(
      map(item => {
        if (!item) {
          return;
        }

        item.setUp(modelDescription);
        return item;
      })
    );
  }

  modelCreate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    if (modelDescription.getQuery.queryType == QueryType.Http) {
      return super.modelCreate(resource, modelDescription, modelInstance, fields);
    } else if (modelDescription.getQuery.queryType == QueryType.Object) {
      return this.modelCreateQueryObject(resource, modelDescription, modelInstance, fields);
    } else {
      return of(undefined);
    }
  }

  modelUpdateQueryObject(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    const query = modelDescription.updateQuery;

    if (!query) {
      return throwError(
        new ServerRequestError(`No query specified for collection ${modelDescription.verboseNamePlural}`)
      );
    }

    return this.updateQueryObject(resource, query, modelInstance, modelDescription.dbFields, fields).pipe(
      map(item => {
        if (!item) {
          return;
        }

        item.setUp(modelDescription);
        return item;
      })
    );
  }

  modelUpdate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    if (modelDescription.getQuery.queryType == QueryType.Http) {
      return super.modelUpdate(resource, modelDescription, modelInstance, fields);
    } else if (modelDescription.getQuery.queryType == QueryType.Object) {
      return this.modelUpdateQueryObject(resource, modelDescription, modelInstance, fields);
    } else {
      return of(undefined);
    }
  }

  modelDeleteQueryObject(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model
  ): Observable<Object> {
    const query = modelDescription.deleteQuery;

    if (!query) {
      return throwError(
        new ServerRequestError(`No query specified for collection ${modelDescription.verboseNamePlural}`)
      );
    }

    return this.deleteQueryObject(resource, query, modelInstance);
  }

  modelDelete(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Object> {
    if (modelDescription.getQuery.queryType == QueryType.Http) {
      return super.modelDelete(resource, modelDescription, modelInstance);
    } else if (modelDescription.getQuery.queryType == QueryType.Object) {
      return this.modelDeleteQueryObject(resource, modelDescription, modelInstance);
    } else {
      return of(undefined);
    }
  }

  modelGroupQueryObject(
    resource: Resource,
    modelDescription: ModelDescription,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<any> {
    const query = modelDescription.getQuery;

    if (!query) {
      return throwError(
        new ServerRequestError(`No query specified for collection ${modelDescription.verboseNamePlural}`)
      );
    }

    return this.getGroupQueryObject(
      resource,
      query,
      xColumns,
      yFunc,
      yColumn,
      modelDescription.getParameters,
      params,
      modelDescription.dbFields
    );
  }

  modelGroup(
    resource: Resource,
    modelDescription: ModelDescription,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<any> {
    params = params || {};

    if (modelDescription.getQuery.queryType == QueryType.Http) {
      return super.modelGroup(resource, modelDescription, xColumns, yFunc, yColumn, params);
    } else if (modelDescription.getQuery.queryType == QueryType.Object) {
      return this.modelGroupQueryObject(resource, modelDescription, xColumns, yFunc, yColumn, params);
    } else {
      return of(undefined);
    }
  }

  modelAggregateQueryObject(
    resource: Resource,
    modelDescription: ModelDescription,
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<any> {
    const query = modelDescription.getQuery;

    if (!query) {
      return throwError(
        new ServerRequestError(`No query specified for collection ${modelDescription.verboseNamePlural}`)
      );
    }

    return this.getAggregateQueryObject(
      resource,
      query,
      yFunc,
      yColumn,
      modelDescription.getParameters,
      params,
      modelDescription.dbFields
    );
  }

  modelAggregate(
    resource: Resource,
    modelDescription: ModelDescription,
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<any> {
    params = params || {};

    if (modelDescription.getQuery.queryType == QueryType.Http) {
      return super.modelAggregate(resource, modelDescription, yFunc, yColumn, params);
    } else if (modelDescription.getQuery.queryType == QueryType.Object) {
      return this.modelAggregateQueryObject(resource, modelDescription, yFunc, yColumn, params);
    } else {
      return of(undefined);
    }
  }

  getQueryObject(
    resource: Resource,
    query: ListModelDescriptionQuery,
    parameters: ParameterField[] = [],
    params?: {},
    body?: {},
    columns: RawListViewSettingsColumn[] = [],
    paginate = true
  ): Observable<ModelResponse.GetResponse> {
    if (!query.objectQuery) {
      return of(undefined);
    }

    const userQuery = isResourceQueryCustom(resource, query.queryType);
    const tokens = this.queryTokensService.mergeTokens(
      this.queryTokensService.generalTokens(),
      this.queryTokensService.modelGetTokens(params, parameters, userQuery),
      this.queryTokensService.paginationTokens(query.pagination, params)
    );

    return this.objectGet(resource, query.objectQuery.query, query.objectQuery.queryOptions).pipe(
      map(result => {
        let resultBody = result as any[];

        // resultBody = this.queryService.applyTransformer(
        //   resultBody,
        //   query.objectQuery.responseTransformer,
        //   query.objectQuery.url,
        //   false,
        //   tokens
        // ) as Object[];
        // resultBody = this.queryService.getPath(resultBody, query.objectQuery.responsePath);

        if (!isArray(resultBody)) {
          resultBody = [resultBody];
        }

        // TODO: Move filtering after deserialization
        // if (query.frontendFiltering && resultBody && isArray(resultBody)) {
        if (resultBody && isArray(resultBody)) {
          resultBody = applyFrontendFiltering(resultBody, params, columns);
        }

        const data = {
          results: resultBody
        };
        const response = this.createGetResponse().deserialize(data, undefined, undefined);
        const tokensAfterResponse = {
          ...tokens,
          results: data.results
        };

        response.results.forEach(item => {
          item.deserializeAttributes(columns);
        });

        // if (query.frontendFiltering) {
        // if (true) {
        applyFrontendSorting(response, params);
        applyFrontendPagination(response, params, paginate);
        // } else if (
        //   query.pagination == QueryPagination.Page ||
        //   query.pagination == QueryPagination.Offset ||
        //   query.pagination == QueryPagination.Cursor
        // ) {
        //   if (query.paginationHasMoreFunction) {
        //     response.hasMore = this.queryService.applyTransformer(
        //       result,
        //       query.paginationHasMoreFunction,
        //       query.objectQuery.url,
        //       false,
        //       tokensAfterResponse
        //     );
        //   } else {
        //     if (query.paginationHasMorePagesPath.length) {
        //       response.hasMore = this.queryService.getPath(result, query.paginationHasMorePagesPath.join('.'));
        //     } else if (query.paginationHasMoreTotalPagesPath.length) {
        //       const totalPages = this.queryService.getPath(result, query.paginationHasMoreTotalPagesPath.join('.'));
        //       response.hasMore = tokens['paging']['page'] < totalPages;
        //     } else if (query.paginationHasMoreTotalRecordsPath.length) {
        //       const totalRecords = this.queryService.getPath(result, query.paginationHasMoreTotalRecordsPath.join('.'));
        //       const limit = query.paginationPerPage;
        //       let totalPages: number;
        //
        //       if (limit && totalRecords) {
        //         totalPages = Math.ceil(totalRecords / limit);
        //         response.hasMore = tokens['paging']['page'] < totalPages;
        //       }
        //     }
        //   }
        //
        //   if (query.paginationTotalFunction) {
        //     response.count = this.queryService.applyTransformer(
        //       result,
        //       query.paginationTotalFunction,
        //       query.objectQuery.url,
        //       false,
        //       tokensAfterResponse
        //     );
        //   } else if (query.paginationTotalPath && query.paginationTotalPath.length) {
        //     response.count = this.queryService.getPath(result, query.paginationTotalPath.join('.'));
        //   }
        // }

        // if (query.pagination == QueryPagination.Cursor) {
        //   if (query.paginationCursorPrevFunction) {
        //     response.cursorPrev = this.queryService.applyTransformer(
        //       result,
        //       query.paginationCursorPrevFunction,
        //       query.objectQuery.url,
        //       false,
        //       tokensAfterResponse
        //     );
        //   } else if (query.paginationCursorPrevPath) {
        //     response.cursorPrev = this.queryService.getPath(result, query.paginationCursorPrevPath.join('.'));
        //   }
        //
        //   if (query.paginationCursorNextFunction) {
        //     response.cursorNext = this.queryService.applyTransformer(
        //       result,
        //       query.paginationCursorNextFunction,
        //       query.objectQuery.url,
        //       false,
        //       tokensAfterResponse
        //     );
        //   } else if (query.paginationCursorNextPath) {
        //     response.cursorNext = this.queryService.getPath(result, query.paginationCursorNextPath.join('.'));
        //   }
        //
        //   response.hasMore = !!response.cursorNext;
        // }

        return response;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetailQueryObject(
    resource: Resource,
    query: ModelDescriptionQuery,
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<Model> {
    if (!query.objectQuery) {
      return of(undefined);
    }

    return this.objectQuery(resource, query.objectQuery, params).pipe(
      map(response => {
        if (!response) {
          return;
        }

        const model = this.createModel().deserialize(undefined, response);
        model.deserializeAttributes(columns);
        return model;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createQueryObject(
    resource: Resource,
    query: ModelDescriptionQuery,
    modelInstance: Model,
    columns: RawListViewSettingsColumn[] = [],
    fields?: string[]
  ): Observable<Model> {
    const data = modelInstance.serialize(fields);

    return this.objectQuery(resource, query.objectQuery, data).pipe(
      map(result => {
        const model = this.createModel().deserialize(undefined, { ...data, ...result });
        model.deserializeAttributes(columns);
        return model;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  updateQueryObject(
    resource: Resource,
    query: ModelDescriptionQuery,
    modelInstance: Model,
    columns: RawListViewSettingsColumn[] = [],
    fields?: string[]
  ): Observable<Model> {
    const data = {
      ...modelInstance.serialize(fields),
      [OBJECT_QUERY_KEY_NAME]: modelInstance.primaryKey
    };

    return this.objectQuery(resource, query.objectQuery, data).pipe(
      map(result => {
        const model = this.createModel().deserialize(undefined, data);
        model.deserializeAttributes(columns);
        return model;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  deleteQueryObject(resource: Resource, query: ModelDescriptionQuery, modelInstance: Model): Observable<Object> {
    const data = modelInstance.serialize();

    return this.objectQuery(resource, query.objectQuery, data).pipe(
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getAggregateQueryObject(
    resource: Resource,
    query: ListModelDescriptionQuery,
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<any> {
    return this.getQueryObject(resource, query, parameters, params, undefined, columns, false).pipe(
      map(response => aggregateGetResponse(response, yFunc, yColumn))
    );
  }

  getGroupQueryObject(
    resource: Resource,
    query: ListModelDescriptionQuery,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<DataGroup[]> {
    return this.getQueryObject(resource, query, parameters, params, undefined, columns, false).pipe(
      map(response => groupGetResponse(response, xColumns, yFunc, yColumn))
    );
  }

  objectToArray(result: Object): Object[] {
    return toPairs(result).map(([k, v]) => {
      if (isPlainObject(v)) {
        return {
          ...v,
          [OBJECT_QUERY_KEY_NAME]: k
        };
      } else {
        return {
          value: v,
          [OBJECT_QUERY_KEY_NAME]: k
        };
      }
    });
  }

  arrayToArray(result: Object[]): Object[] {
    return result
      .map((v, i) => {
        if (v === null) {
          return;
        }

        if (isPlainObject(v)) {
          return {
            ...v,
            [OBJECT_QUERY_KEY_NAME]: i
          };
        } else {
          return {
            value: v,
            [OBJECT_QUERY_KEY_NAME]: i
          };
        }
      })
      .filter(item => item);
  }

  objectProcess(result: any, options: ObjectQueryOptions = {}) {
    if (options.objectToArray) {
      if (isArray(result)) {
        return this.arrayToArray(result);
      } else if (isPlainObject(result)) {
        return this.objectToArray(result);
      } else {
        return result;
      }
    } else {
      return result;
    }
  }

  objectGet(
    resource: Resource,
    path: PropertyKey[] = [],
    options: ObjectQueryOptions = {}
  ): Observable<Object | Object[]> {
    const queryOptions: HttpQueryOptions = { resource: resource.uniqueName };
    const query = new HttpQuery();
    const database = resource.params['database_option'] as FirebaseDatabaseOption;

    path = path || [];

    query.method = HttpMethod.GET;
    query.url = `${database.id}/${path.join('/')}.json`;
    query.headers = [{ name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }];

    return this.httpQueryService.requestBody<any>(query, queryOptions).pipe(
      map(result => this.objectProcess(result, options)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  objectCreate(resource: Resource, path: PropertyKey[], data: Object): Observable<Object> {
    const queryOptions: HttpQueryOptions = { resource: resource.uniqueName };
    const query = new HttpQuery();
    const database = resource.params['database_option'] as FirebaseDatabaseOption;

    if (isSet(data[OBJECT_QUERY_KEY_NAME])) {
      query.method = HttpMethod.PUT;
      path = [...(path || []), data[OBJECT_QUERY_KEY_NAME]];
    } else {
      query.method = HttpMethod.POST;
      path = path || [];
    }

    query.url = `${database.id}/${path.join('/')}.json`;
    query.headers = [{ name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }];
    query.body = data = <Object>pickBy(data, (v, k) => k != OBJECT_QUERY_KEY_NAME);

    return this.httpQueryService.requestBody<{ name: string }>(query, queryOptions).pipe(
      map(result => {
        return {
          ...data,
          [OBJECT_QUERY_KEY_NAME]: result ? result.name : data[OBJECT_QUERY_KEY_NAME]
        };
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  objectUpdate(resource: Resource, path: PropertyKey[], data: Object): Observable<Object> {
    const queryOptions: HttpQueryOptions = { resource: resource.uniqueName };
    const query = new HttpQuery();
    const database = resource.params['database_option'] as FirebaseDatabaseOption;

    path = [...(path || []), data[OBJECT_QUERY_KEY_NAME]];

    query.method = HttpMethod.PATCH;
    query.url = `${database.id}/${path.join('/')}.json`;
    query.headers = [{ name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }];
    query.body = pickBy(data, (v, k) => k != OBJECT_QUERY_KEY_NAME);

    return this.httpQueryService.requestBody<{ name: string }>(query, queryOptions).pipe(
      map(() => data),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  objectDelete(resource: Resource, path: PropertyKey[], data: Object): Observable<Object> {
    const queryOptions: HttpQueryOptions = { resource: resource.uniqueName };
    const query = new HttpQuery();
    const database = resource.params['database_option'] as FirebaseDatabaseOption;

    path = [...(path || []), data[OBJECT_QUERY_KEY_NAME]];

    query.method = HttpMethod.DELETE;
    query.url = `${database.id}/${path.join('/')}.json`;
    query.headers = [{ name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }];

    return this.httpQueryService.requestBody(query, queryOptions).pipe(
      map(() => {
        return { [OBJECT_QUERY_KEY_NAME]: data[OBJECT_QUERY_KEY_NAME] };
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  setUpModelDescriptionBasedOnGetQuery(
    resource: Resource,
    modelDescription: ModelDescription,
    getQuery: ListModelDescriptionQuery,
    fields: ModelField[]
  ): ModelDescription {
    modelDescription = super.setUpModelDescriptionBasedOnGetQuery(resource, modelDescription, getQuery, fields);

    const getQueryObject = getQuery ? getQuery.objectQuery : undefined;

    if (getQueryObject) {
      const objectQuery = () => {
        const query = new ModelDescriptionQuery();
        query.queryType = QueryType.Object;
        query.objectQuery = new ObjectQuery();
        query.objectQuery.query = getQueryObject.query;
        query.objectQuery.queryOptions = getQueryObject.queryOptions;
        return query;
      };
      const objectKeyParameter = (required = true, description?: string) => {
        const result = new ParameterField();

        result.name = OBJECT_QUERY_KEY_NAME;
        result.verboseName = OBJECT_QUERY_KEY_LABEL;
        result.required = required;
        result.protected = true;
        result.description = description;

        if (!required) {
          result.placeholder = 'Populated automatically if empty';
        }

        result.updateFieldDescription();

        return result;
      };
      const objectFieldParameters = () => {
        return fields.map(field => {
          const result = new ParameterField();

          result.name = field.item.name;
          result.field = field.item.field;
          result.params = field.item.params;
          result.required = true;
          result.updateFieldDescription();

          return result;
        });
      };

      modelDescription.createQuery = objectQuery();
      modelDescription.createQuery.objectQuery.operation = ObjectQueryOperation.Create;
      modelDescription.createParameters = [
        objectKeyParameter(false, 'Create Object with the following key'),
        ...objectFieldParameters()
      ];

      modelDescription.updateQuery = objectQuery();
      modelDescription.updateQuery.objectQuery.operation = ObjectQueryOperation.Update;
      modelDescription.updateParameters = [
        objectKeyParameter(true, 'Update Object with the following key'),
        ...objectFieldParameters()
      ];

      modelDescription.deleteQuery = objectQuery();
      modelDescription.deleteQuery.objectQuery.operation = ObjectQueryOperation.Delete;
      modelDescription.deleteParameters = [objectKeyParameter(true, 'Delete Object with the following key')];

      modelDescription.primaryKeyField = OBJECT_QUERY_KEY_NAME;
    }

    return modelDescription;
  }
}
