/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../collapse-section/collapse-section.component.ngfactory";
import * as i2 from "../collapse-section/collapse-section.component";
import * as i3 from "../../../customize/services/customize/customize.service";
import * as i4 from "../../../customize-elements/services/element-container/element-container.service";
import * as i5 from "../../../routing/services/routing/routing.service";
import * as i6 from "../root-layout/root-layout.component";
import * as i7 from "../form-element/form-element.component";
import * as i8 from "@angular/common";
import * as i9 from "./accordion.component";
var styles_AccordionComponent = [];
var RenderType_AccordionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AccordionComponent, data: {} });
export { RenderType_AccordionComponent as RenderType_AccordionComponent };
function View_AccordionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-collapse-section", [["class", "collapse-container__item"]], null, [[null, "toggleOpened"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggleOpened" === en)) {
        var pd_0 = (_co.toggleOpenedItem(_v.context.$implicit.item.uid) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CollapseSectionComponent_0, i1.RenderType_CollapseSectionComponent)), i0.ɵdid(1, 770048, null, 0, i2.CollapseSectionComponent, [i3.CustomizeService, i4.ElementContainerService, i5.RoutingService, [2, i6.RootLayoutComponent], [2, i7.FormElementComponent], i0.ChangeDetectorRef], { titleInput: [0, "titleInput"], titleStyle: [1, "titleStyle"], icon: [2, "icon"], children: [3, "children"], tooltip: [4, "tooltip"], collapseIndicatorPosition: [5, "collapseIndicatorPosition"], element: [6, "element"], opened: [7, "opened"], visible: [8, "visible"], first: [9, "first"], last: [10, "last"], theme: [11, "theme"], context: [12, "context"] }, { toggleOpened: "toggleOpened" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.item.titleInput; var currVal_1 = _v.context.$implicit.item.titleStyle; var currVal_2 = _v.context.$implicit.item.icon; var currVal_3 = _v.context.$implicit.item.children; var currVal_4 = _v.context.$implicit.item.tooltip; var currVal_5 = _co.element.collapseIndicatorPosition; var currVal_6 = _co.element; var currVal_7 = _co.openedItems.includes(_v.context.$implicit.item.uid); var currVal_8 = _v.context.$implicit.visible; var currVal_9 = _v.context.first; var currVal_10 = _v.context.last; var currVal_11 = true; var currVal_12 = _co.context; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
export function View_AccordionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "collapse-container collapse-container_theme"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AccordionComponent_1)), i0.ɵdid(2, 278528, null, 0, i8.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; var currVal_1 = _co.trackItem; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AccordionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-accordion", [], null, null, null, View_AccordionComponent_0, RenderType_AccordionComponent)), i0.ɵdid(1, 770048, null, 0, i9.AccordionComponent, [i3.CustomizeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccordionComponentNgFactory = i0.ɵccf("app-accordion", i9.AccordionComponent, View_AccordionComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { AccordionComponentNgFactory as AccordionComponentNgFactory };
