import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import * as JSONEditor from 'jsoneditor';

import { TypedChanges } from '../../utils/angular/angular';

@Component({
  selector: 'app-json-viewer',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonViewerComponent implements OnInit, OnChanges {
  @Input() content: any;

  editor: any;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.editor = new JSONEditor(this.el.nativeElement, {
      mode: 'view',
      mainMenuBar: false,
      navigationBar: false,
      statusBar: false
    });

    this.editor.set(this.getContent());
  }

  ngOnChanges(changes: TypedChanges<JsonViewerComponent>): void {
    if (changes.content && !changes.content.isFirstChange()) {
      if (this.editor) {
        this.editor.update(this.getContent());
      }
    }
  }

  getContent(): any {
    if (this.content instanceof Error) {
      return this.content['message'];
    } else {
      return this.content;
    }
  }
}
