import * as esprima from 'nightly-esprima';

import { isSet } from '@shared';

export function getJavaScriptSyntaxError(js: string): string {
  if (!isSet(js)) {
    return;
  }

  const code = `async ()=>{${js}}`;

  try {
    esprima.parseScript(code);
  } catch (e) {
    return e.message;
  }
}
