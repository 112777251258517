import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, merge, Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, delay, delayWhen, filter, map, switchMap, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import {
  CustomView,
  CustomViewService,
  CustomViewSource,
  CustomViewsStore,
  CustomViewType,
  defaultCustomViewHtml
} from '@modules/custom-views';
import { CustomElementItem, CustomizeService, MarginControl, ViewContext } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Option } from '@modules/field-components';
import { Input, isRequiredInputsSet, ParameterArray, ParameterField } from '@modules/fields';
import { FieldInputControl, InputFieldProvider, parametersToProviderItems } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { View } from '@modules/views';
import { controlValue, generateAlphanumeric, isSet } from '@shared';

import { ActionOutputFormGroup } from '../../forms/action-output.form-group';

export function validateDistFile(): ValidatorFn {
  return control => {
    const parent = control.parent as CustomizeBarCustomEditForm;

    if (!parent) {
      return;
    }

    const source = parent.controls.source.value as CustomViewSource;

    if (source != CustomViewSource.CustomElement) {
      return;
    }

    const dist = parent.controls.dist.value;

    if (!control.value && !dist) {
      return { required: true };
    }
  };
}

export function validateTagName(): ValidatorFn {
  return control => {
    const parent = control.parent as CustomizeBarCustomEditForm;

    if (!parent) {
      return;
    }

    const source = parent.controls.source.value as CustomViewSource;

    if (source != CustomViewSource.CustomElement) {
      return;
    }

    if (!control.value) {
      return { required: true };
    }
  };
}

export function validateFiles(): ValidatorFn {
  return control => {
    const parent = control.parent as CustomizeBarCustomEditForm;

    if (!parent) {
      return;
    }

    const source = parent.controls.source.value as CustomViewSource;

    if (source != CustomViewSource.CustomElement) {
      return;
    }

    if (!control.value || !control.value.js || !control.value.js.length) {
      return { required: true };
    }
  };
}

export function validateInputs(): ValidatorFn {
  return control => {
    const parent = control.parent as CustomizeBarCustomEditForm;

    if (!parent) {
      return;
    }

    const fields = parent.inputFieldProvider.getFields();
    const inputs: Input[] = control.value;

    if (!isRequiredInputsSet(fields, inputs)) {
      return { required: true };
    }
  };
}

@Injectable()
export class CustomizeBarCustomEditForm extends FormGroup implements OnDestroy {
  element: CustomElementItem;
  context: ViewContext;

  controls: {
    source: FormControl;
    custom_view_unique_name: FormControl;
    html: FormControl;
    dist: FormControl;
    dist_file: FormControl;
    tag_name: FormControl;
    files: FormControl;
    view: FormControl;
    parameters: ParameterArray;
    inputs: FormControl;
    outputs: FormControl;
    actions: ActionOutputFormGroup;
    width_fluid: FormControl;
    height_fluid: FormControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
  };

  ignoreSubmitControls: AbstractControl[] = [this.controls.html, this.controls.view];
  submitChanges = merge(
    ...values(this.controls)
      .filter(item => !this.ignoreSubmitControls.includes(item))
      .map(item => item.valueChanges)
  );
  submitLoading$ = new BehaviorSubject<boolean>(false);

  inputFieldProvider = new InputFieldProvider();

  sourceOptions: Option<CustomViewSource>[] = [
    {
      value: CustomViewSource.View,
      name: 'Design',
      icon: 'canvas'
    },
    {
      value: CustomViewSource.HTML,
      name: 'HTML',
      icon: 'richtext'
    },
    {
      value: CustomViewSource.CustomElement,
      name: 'JS',
      icon: 'console'
    }
  ];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private customizeService: CustomizeService,
    private formUtils: FormUtils,
    private elementConfigurationService: ElementConfigurationService,
    private notificationService: NotificationService
  ) {
    super({
      source: new FormControl(CustomViewSource.View),
      custom_view_unique_name: new FormControl(null),
      html: new FormControl(defaultCustomViewHtml),
      dist: new FormControl(''),
      dist_file: new FormControl('', validateDistFile()),
      tag_name: new FormControl('', validateTagName()),
      files: new FormControl({ js: [], css: [] }, validateFiles()),
      view: new FormControl(null),
      parameters: new ParameterArray([]),
      inputs: new FormControl([], validateInputs()),
      outputs: new FormControl([]),
      actions: new ActionOutputFormGroup(elementConfigurationService),
      width_fluid: new FormControl(false),
      height_fluid: new FormControl(false),
      visible_input: new FieldInputControl({ path: ['value'] }),
      margin: new MarginControl()
    });
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  init(element: CustomElementItem, context: ViewContext, firstInit = false): Observable<void> {
    this.element = element;
    this.context = context;

    const customView$ = element.customView
      ? this.customViewsStore.getDetailFirst(element.customView)
      : of(element.customViewTemporary);

    return customView$.pipe(
      map(customView => {
        const source = element.source || (customView ? customView.source : CustomViewSource.View);

        this.controls.custom_view_unique_name.patchValue(element.customView || null);
        this.controls.source.patchValue(source);

        if (customView) {
          this.controls.html.patchValue(customView.html || defaultCustomViewHtml);
          this.controls.dist.patchValue(customView.dist);
          this.controls.tag_name.patchValue(customView.tagName);
          this.controls.files.patchValue({
            js: customView.filesJs,
            css: customView.filesCss
          });
          this.controls.view.patchValue(customView.view);
          this.controls.actions.deserialize(
            customView && customView.view ? customView.view.actions : [],
            element.actions
          );
        }

        this.controls.parameters.patchValue(element.parameters);
        this.controls.inputs.patchValue(element.inputs);
        this.controls.outputs.patchValue(element.outputs);
        this.controls.width_fluid.patchValue(element.widthFluid);
        this.controls.height_fluid.patchValue(element.heightFluid);
        this.controls.visible_input.patchValue(element.visibleInput ? element.visibleInput.serializeWithoutPath() : {});
        this.controls.margin.patchValue(element.margin);

        this.updateInputFieldProvider();
        this.trackChanges();

        if (!firstInit) {
          this.markAsDirty();
        }
      })
    );
  }

  updateInputFieldProvider() {
    this.inputFieldProvider.setProvider(
      controlValue<ParameterField[]>(this.controls.parameters).pipe(
        map(parameters => {
          return parameters ? parametersToProviderItems(parameters) : [];
        })
      )
    );
  }

  trackChanges() {
    this.controls.source.valueChanges
      .pipe(delay(0))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.controls.dist_file.updateValueAndValidity();
        this.controls.tag_name.updateValueAndValidity();
        this.controls.files.updateValueAndValidity();
      });

    this.controls.dist.valueChanges
      .pipe(delay(0))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.controls.dist_file.updateValueAndValidity();
      });

    this.inputFieldProvider
      .getFields$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.controls.inputs.updateValueAndValidity();
      });

    merge(
      this.controls.source.valueChanges.pipe(
        filter(value => [CustomViewSource.HTML, CustomViewSource.View].includes(value))
      ),
      this.controls.html.valueChanges,
      this.controls.view.valueChanges
    )
      .pipe(
        debounceTime(200),
        switchMap(() => {
          this.submitLoading$.next(true);

          return this.submitCustomView(this.controls.custom_view_unique_name.value, true);
        }),
        tap(customView => {
          const uniqueName = customView ? customView.uniqueName : null;

          if (this.controls.custom_view_unique_name.value != uniqueName) {
            this.controls.custom_view_unique_name.patchValue(uniqueName);
          }

          this.submitLoading$.next(false);
        }),
        catchError(error => {
          this.submitLoading$.next(false);

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', error.errors[0]);
          } else {
            this.notificationService.error('Error', error);
          }

          return of(undefined);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  getView(): View {
    return this.controls.view.value;
  }

  submitCustomView(uniqueName: string, save = false): Observable<CustomView> {
    let customView$: Observable<CustomView>;

    if (save) {
      customView$ = isSet(uniqueName) ? this.customViewsStore.getDetailFirst(uniqueName) : of(undefined);
    } else {
      customView$ = of(undefined);
    }

    return customView$.pipe(
      switchMap(customView => {
        let instance = customView;
        const fields = ['unique_name', 'view_type', 'html', 'view', 'params'];
        const pageUid = this.context && this.context.viewSettings ? this.context.viewSettings.uid : undefined;
        const elementUid = this.element.uid;

        if (!instance) {
          instance = new CustomView();
        }

        if (
          !isSet(instance.uniqueName) ||
          (isSet(instance.pageUid) && instance.pageUid != pageUid) ||
          (isSet(instance.elementUid) && instance.elementUid != pageUid)
        ) {
          instance.uniqueName =
            isSet(pageUid) && isSet(elementUid)
              ? [CustomViewType.Component, pageUid, elementUid].join('.')
              : [CustomViewType.Component, generateAlphanumeric(8, { letterFirst: true })].join('.');
        }

        instance.viewType = CustomViewType.Component;
        instance.source = this.controls.source.value;
        instance.pageUid = pageUid;
        instance.elementUid = elementUid;

        if (instance.source == CustomViewSource.View) {
          instance.html = undefined;
          instance.dist = undefined;
          instance.tagName = undefined;
          instance.filesJs = [];
          instance.filesCss = [];
          instance.view = this.controls.view.value;
        } else if (instance.source == CustomViewSource.HTML) {
          instance.html = this.controls.html.value;
          instance.dist = undefined;
          instance.tagName = undefined;
          instance.filesJs = [];
          instance.filesCss = [];
          instance.view = undefined;
        } else if (instance.source == CustomViewSource.CustomElement) {
          instance.html = undefined;
          instance.dist = this.controls.dist_file.value || this.controls.dist.value;
          instance.tagName = this.controls.tag_name.value;
          instance.filesJs = this.controls.files.value['js'];
          instance.filesCss = this.controls.files.value['css'];
          instance.view = undefined;

          if (this.controls.dist_file.value) {
            fields.push('dist');
          }
        }

        if (save) {
          const draft = [CustomViewSource.View, CustomViewSource.HTML].includes(instance.source);

          return customView
            ? this.customViewService.update(
                this.currentProjectStore.instance.uniqueName,
                this.currentEnvironmentStore.instance.uniqueName,
                instance,
                { draft: draft, fields: fields }
              )
            : this.customViewService.create(
                this.currentProjectStore.instance.uniqueName,
                this.currentEnvironmentStore.instance.uniqueName,
                instance,
                { draft: draft, fields: fields }
              );
        } else {
          return of(instance);
        }
      }),
      delayWhen(() => {
        if (save) {
          return this.customViewsStore.getFirst(true);
        } else {
          return of(undefined);
        }
      })
    );
  }

  isConfigured(instance: CustomElementItem): Observable<boolean> {
    return this.elementConfigurationService.isCustomConfigured(instance);
  }

  submit(
    options: {
      submitCustomView?: boolean;
      useTemporaryCustomView?: boolean;
    } = {}
  ): Observable<CustomElementItem> {
    const instance: CustomElementItem = this.element ? cloneDeep(this.element) : new CustomElementItem();
    const existingUniqueName = this.controls.custom_view_unique_name.value;
    const save = options.submitCustomView || (!options.useTemporaryCustomView && !existingUniqueName);

    return this.submitCustomView(existingUniqueName, save).pipe(
      map(customView => {
        const uniqueName = customView ? customView.uniqueName : null;

        if (this.controls.custom_view_unique_name.value != uniqueName) {
          this.controls.custom_view_unique_name.patchValue(uniqueName);
        }

        instance.source = this.controls.source.value;

        if (options.useTemporaryCustomView) {
          instance.customView = undefined;
          instance.customViewTemporary = customView;
        } else {
          instance.customView = customView ? customView.uniqueName : undefined;
          instance.customViewTemporary = undefined;
        }

        instance.parameters = this.controls.parameters.value;
        instance.inputs = this.controls.inputs.value;
        instance.outputs = this.controls.outputs.value;
        instance.actions = this.controls.actions.serialize();
        instance.widthFluid = this.controls.width_fluid.value;
        instance.heightFluid = this.controls.height_fluid.value;
        instance.visibleInput = this.controls.visible_input.value
          ? new Input().deserialize(this.controls.visible_input.value)
          : undefined;
        instance.margin = this.controls.margin.value;

        return instance;
      }),
      tap(element => {
        this.element = element;
      }),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}
