var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';
import { NpmRegistryService } from '../services/npm/npm-registry.service';
var NpmPackageVersionSource = /** @class */ (function (_super) {
    __extends(NpmPackageVersionSource, _super);
    function NpmPackageVersionSource(currentProjectStore, currentEnvironmentStore, npmService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.npmService = npmService;
        _this.loadedPage = false;
        return _this;
    }
    NpmPackageVersionSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        if (!isSet(this.package)) {
            return of([]);
        }
        searchQuery = (searchQuery || '').trim();
        return this.npmService
            .getDetail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.package)
            .pipe(map(function (result) {
            return (result.versions || [])
                .map(function (item) {
                return {
                    value: item.name,
                    name: item.name
                };
            })
                .filter(function (item) {
                if (!searchQuery) {
                    return true;
                }
                return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1;
            })
                .reverse();
        }), tap(function () {
            _this.loadedPage = true;
        }));
    };
    NpmPackageVersionSource.prototype.fetchByValue = function (value) {
        if (!value) {
            return of(undefined);
        }
        if (!isSet(this.package)) {
            return of(undefined);
        }
        return this.npmService
            .getDetail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.package)
            .pipe(map(function (result) {
            var version = (result.versions || []).find(function (item) { return item.name == value; });
            if (!version) {
                return;
            }
            return {
                value: version.name,
                name: version.name
            };
        }));
    };
    NpmPackageVersionSource.prototype.isFetchAvailable = function () {
        return !this.loadedPage;
    };
    NpmPackageVersionSource.prototype.resetPagination = function () {
        this.loadedPage = false;
    };
    NpmPackageVersionSource.prototype.setStaticOptions = function (options) { };
    return NpmPackageVersionSource;
}(SelectSource));
export { NpmPackageVersionSource };
