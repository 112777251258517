var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { copyTextToClipboard } from '@common/code';
import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { AppConfigService, RuntimeEnvironment } from '@core';
import { ActionType } from '@modules/actions';
import { AdminMode } from '@modules/admin-mode';
import { ApiService } from '@modules/api';
import { createFormFieldFactory, FieldOutput, FieldType } from '@modules/fields';
import { ProjectApiService } from '@modules/project-api';
import { auth0BackendName, azureADTenantBackendName, azureADV2TenantBackendName, cognitoBackendName, CurrentEnvironmentStore, CurrentProjectStore, customOAuth2BackendName, ProjectPropertyStore, Resource, SecretToken, socialBackends } from '@modules/projects';
import { HttpMethod, submitForm } from '@modules/queries';
import { Workflow } from '@modules/workflow';
import { capitalize, controlValue, generateUUID, isSet } from '@shared';
import { WorkflowEditController } from '../../../customize-bar/services/workflow-edit-controller/workflow-edit.controller';
import { OAuth2ParamsForm } from './oauth2-params.form';
var OAuth2ParamsComponent = /** @class */ (function () {
    function OAuth2ParamsComponent(mode, appConfigService, apiService, projectApiService, currentProjectStore, currentEnvironmentStore, workflowEditController, projectPropertyStore, notificationService, dialogService, form, cd) {
        this.mode = mode;
        this.appConfigService = appConfigService;
        this.apiService = apiService;
        this.projectApiService = projectApiService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.workflowEditController = workflowEditController;
        this.projectPropertyStore = projectPropertyStore;
        this.notificationService = notificationService;
        this.dialogService = dialogService;
        this.form = form;
        this.cd = cd;
        this.createOauthTokenRedirectUrl = true;
        this.fill = false;
        this.resourceAccessToken = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.customOAuth2Backend = customOAuth2BackendName;
    }
    OAuth2ParamsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.control);
        if (this.userDetailsWorkflowControl) {
            controlValue(this.userDetailsWorkflowControl)
                .pipe(untilDestroyed(this))
                .subscribe(function (userDetailsWorkflow) {
                _this.userDetailsWorkflow = userDetailsWorkflow;
                _this.cd.markForCheck();
            });
        }
    };
    OAuth2ParamsComponent.prototype.ngOnDestroy = function () { };
    OAuth2ParamsComponent.prototype.getResourceAccessToken = function (resource) {
        var _this = this;
        var tokenRequestUID = generateUUID();
        var backend = socialBackends.find(function (item) { return item.name == _this.form.form.value['backend']; });
        var redirect = this.redirectUrl(tokenRequestUID);
        var params = {
            KEY: this.form.form.value['key'],
            SECRET: this.form.form.value['secret'],
            SCOPE: this.form.form.value['scope'].split(','),
            SCOPE_SEPARATOR: this.form.form.value['scope_separator']
        };
        if (backend) {
            if (backend.name == 'google-oauth2') {
                params = assign(params, {
                    AUTH_EXTRA_ARGUMENTS: {
                        access_type: 'offline',
                        include_granted_scopes: 'true',
                        prompt: 'consent'
                    }
                });
            }
            else if (backend.name == cognitoBackendName) {
                params = assign(params, {
                    POOL_DOMAIN: this.form.cognitoOAuth2Form.value['pool_domain']
                });
            }
            else if (backend.name == azureADTenantBackendName || backend.name == azureADV2TenantBackendName) {
                params = assign(params, {
                    TENANT_ID: this.form.azureADTenantOAuth2Form.value['tenant_id']
                });
            }
            else if (backend.name == auth0BackendName) {
                params = assign(params, {
                    DOMAIN: this.form.auth0OAuth2Form.value['domain']
                });
            }
            else if (backend.name == customOAuth2BackendName) {
                params = assign(params, {
                    AUTHORIZATION_URL: this.form.customOAuth2Form.value['authorization_url'],
                    ACCESS_TOKEN_URL: this.form.customOAuth2Form.value['access_token_url']
                });
            }
        }
        this.projectApiService
            .refreshToken()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var data = [
                {
                    name: 'name',
                    value: 'oauth_access_token'
                },
                {
                    name: 'backend',
                    value: backend ? backend.path : ''
                },
                {
                    name: 'params',
                    value: JSON.stringify(params)
                },
                // {
                //   name: 'access_token',
                //   value: this.apiService.getAccessToken()
                // },
                {
                    name: 'access_token',
                    value: _this.projectApiService.getAccessToken()
                },
                {
                    name: 'redirect_uri',
                    value: redirect
                }
            ];
            if (_this.mode == AdminMode.Builder) {
                data.push({
                    name: 'draft',
                    value: '1'
                });
            }
            var w = open('', '', 'height=300,width=300');
            if (!w) {
                _this.notificationService.error('Failed to open popup', 'Your browser has blocked opening a new window. Please check your browser settings to allow opening pop-ups');
                return;
            }
            submitForm(HttpMethod.POST, _this.authUrl(resource), data, w.document);
            fromEvent(window, 'message')
                .pipe(filter(function (message) {
                return (message.data &&
                    message.data['type'] == 'oauth_response' &&
                    message.data['params'] &&
                    message.data['uid'] == tokenRequestUID);
            }), take(1), untilDestroyed(_this))
                .subscribe(function (message) {
                var secretToken = new SecretToken().deserialize(message.data['params']);
                _this.form.form.patchValue({
                    access_token: secretToken.value
                });
                _this.resourceAccessToken.emit(secretToken);
            });
        });
    };
    OAuth2ParamsComponent.prototype.authUrl = function (resource) {
        var projectName = this.currentProjectStore.instance.uniqueName;
        var environmentName = this.currentEnvironmentStore.instance.uniqueName;
        return this.appConfigService.serverBaseUrl + "/api/projects/" + projectName + "/" + environmentName + "/resources/" + resource.uniqueName + "/create_oauth_token/";
    };
    Object.defineProperty(OAuth2ParamsComponent.prototype, "completeUrl", {
        get: function () {
            return this.apiService.createOAuthTokenCompleteUrl;
        },
        enumerable: true,
        configurable: true
    });
    OAuth2ParamsComponent.prototype.redirectUrl = function (uid) {
        return window.location.origin + "/oauth_response?uid=" + uid;
    };
    OAuth2ParamsComponent.prototype.copy = function (text, contentLabel) {
        var _this = this;
        copyTextToClipboard(text)
            .pipe(untilDestroyed(this))
            .subscribe(function (success) {
            if (!success) {
                return;
            }
            var description = isSet(contentLabel) ? capitalize(contentLabel) + " was copied to clipboard" : undefined;
            _this.notificationService.info('Copied', description);
        });
    };
    OAuth2ParamsComponent.prototype.customizeUserDetailsWorkflow = function () {
        var _this = this;
        this.projectPropertyStore
            .getUser()
            .pipe(switchMap(function (projectProperties) {
            var triggerOutputItems = [
                {
                    name: 'client_id',
                    verboseName: 'Client ID',
                    field: FieldType.Text,
                    icon: 'email'
                },
                {
                    name: 'client_secret',
                    verboseName: 'Client Secret',
                    field: FieldType.Text,
                    icon: 'key'
                },
                {
                    name: 'id_token',
                    verboseName: 'ID token',
                    field: FieldType.Text,
                    icon: 'key'
                },
                {
                    name: 'access_token',
                    verboseName: 'Access token',
                    field: FieldType.Text,
                    icon: 'key'
                }
            ];
            var triggerOutputs = triggerOutputItems.map(function (item) {
                var output = new FieldOutput();
                output.name = item.name;
                output.verboseName = item.verboseName;
                output.field = item.field;
                output.icon = item.icon;
                output.updateFieldDescription();
                return output;
            });
            var resultOutputs = [
                {
                    name: 'email',
                    verboseName: 'Email',
                    field: FieldType.Text,
                    icon: 'email'
                },
                {
                    name: 'first_name',
                    verboseName: 'First name',
                    field: FieldType.Text,
                    icon: 'user'
                },
                {
                    name: 'last_name',
                    verboseName: 'Last name',
                    field: FieldType.Text,
                    icon: 'user'
                },
                {
                    name: 'photo',
                    verboseName: 'Photo URL',
                    field: FieldType.Text,
                    icon: 'image'
                }
            ].concat((projectProperties || []).map(function (item) {
                return __assign({ name: item.uid, verboseName: item.name }, (item.field && {
                    field: item.field.field,
                    params: item.field.params
                }), { icon: item.fieldDescription.icon });
            })).map(function (item) {
                var output = new FieldOutput();
                output.name = item.name;
                output.verboseName = item.verboseName;
                output.field = item.field;
                output.icon = item.icon;
                output.updateFieldDescription();
                return output;
            });
            return _this.customizeWorkflow({
                triggerLabel: 'Fetch user details',
                triggerIcon: 'user',
                workflow: _this.userDetailsWorkflow,
                triggerOutputs: triggerOutputs,
                resultOutputs: resultOutputs
            });
        }), filter(function (result) { return !result.cancelled; }), untilDestroyed(this))
            .subscribe(function (result) {
            result.workflow.testRun = result.workflowRun;
            _this.userDetailsWorkflowControl.patchValue(result.workflow);
            _this.cd.markForCheck();
        });
    };
    OAuth2ParamsComponent.prototype.deleteUserDetailsWorkflow = function () {
        var _this = this;
        this.dialogService
            .warning({
            title: 'Delete workflow',
            description: "Are you sure want to delete <strong>User details workflow</strong>?",
            style: 'orange'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result) {
                _this.userDetailsWorkflowControl.patchValue(undefined);
                _this.cd.markForCheck();
            }
        });
    };
    OAuth2ParamsComponent.prototype.customizeWorkflow = function (options) {
        var workflow;
        if (options.workflow) {
            workflow = cloneDeep(options.workflow);
        }
        else {
            workflow = new Workflow();
            workflow.generateUid();
        }
        return this.workflowEditController.open({
            runtime: RuntimeEnvironment.NodeJS,
            create: !this.userDetailsWorkflow,
            workflow: workflow,
            workflowRun: workflow.testRun,
            workflowEditable: true,
            parameters: [],
            triggerOutputs: options.triggerOutputs,
            triggerLabel: options.triggerLabel,
            triggerIcon: options.triggerIcon,
            customizeTrigger: false,
            actionTypesEnabled: [
                ActionType.Query,
                ActionType.RunJavaScript
                // ActionType.Link,
                // ActionType.ExternalLink
            ],
            historyEnabled: true,
            resultEnabled: options.resultOutputs && options.resultOutputs.length > 0,
            resultOutputs: options.resultOutputs,
            analyticsSource: ['oauth2_params', 'auth_workflow'].join('_')
        });
    };
    return OAuth2ParamsComponent;
}());
export { OAuth2ParamsComponent };
