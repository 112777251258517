import { Pipe, PipeTransform } from '@angular/core';

import { isSet } from '../../utils/common/common';
import { numeralFormat } from '../../utils/numbers/numbers';

@Pipe({
  name: 'appFormatNumber'
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: any, format: string): any {
    if (!isSet(format)) {
      return value;
    }

    return numeralFormat(value, format);
  }
}
