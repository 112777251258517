import values from 'lodash/values';

import { NpmPackageVersion } from './npm-package-version';

export class NpmPackage {
  public name: string;
  public description: string;
  public version: string;
  public versions: NpmPackageVersion[];

  deserialize(data: Object): NpmPackage {
    this.name = data['name'];
    this.description = data['description'];
    this.version = data['version'];

    if (data['versions']) {
      this.versions = values(data['versions']).map(item => new NpmPackageVersion().deserialize(item));
    }

    return this;
  }
}
