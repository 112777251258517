<form [formGroup]="form" (ngSubmit)="save()">
  <app-customize-bar-header
    [title]="title || (titleEditable ? 'custom' : 'Custom')"
    [titleEnabled]="titleEnabled"
    [titleEditable]="titleEditable"
    [titleCleanValue]="titleCleanValue"
    [backLabel]="'All components'"
    (titleChanged)="onTitleChanged($event)"
    [deleteEnabled]="deleteEnabled"
    [parentLabel]="parentElement?.displayName || parentPopup?.displayName"
    (parentClick)="parentElement?.customize({ event: $event }) || parentPopup?.customize({ event: $event })"
    (deleteClick)="delete()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <!--          <div class="sidebar__element">-->
          <!--            <app-sidebar-field [label]="'type'">-->
          <!--              <div class="sidebar-icon-buttons">-->
          <!--                <div-->
          <!--                  *ngFor="let item of form.sourceOptions"-->
          <!--                  class="sidebar-icon-button"-->
          <!--                  [class.sidebar-icon-button_active]="form.controls.source.value == item.value"-->
          <!--                  (click)="form.controls.source.patchValue(item.value)"-->
          <!--                >-->
          <!--                  <div class="sidebar-icon-button__icon" [ngClass]="['icon-' + item.icon]"></div>-->
          <!--                  <div class="sidebar-icon-button__label">{{ item.name }}</div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </app-sidebar-field>-->
          <!--          </div>-->

          <ng-container *ngIf="form.controls.source.value == customViewSources.View">
            <div class="sidebar__element">
              <app-custom-view-item-edit
                [control]="form.controls.view"
                [initialTemplatesFilter]="{ type: customViewTemplateTypes.Custom }"
                [loading]="form.submitLoading$ | async"
                [analyticsSource]="element.analyticsName"
                (changeView)="onCustomViewChange($event)"
                (selectTemplate)="onCustomViewTemplateSelected($event)"
              ></app-custom-view-item-edit>
            </div>
          </ng-container>

          <ng-container *ngIf="form.controls.source.value == customViewSources.HTML">
            <div class="sidebar__element">
              <app-sidebar-field>
                <app-auto-field
                  style="display: block; width: 100%; overflow: auto;"
                  [form]="form"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'html',
                      field: 'CodeField',
                      params: {
                        language: 'html',
                        autogrow: true,
                        field: true,
                        field_contrast: true,
                        wrap: true,
                        classes: ['input_fill']
                      }
                    })
                  "
                ></app-auto-field>
              </app-sidebar-field>
            </div>
          </ng-container>

          <ng-container *ngIf="form.controls.source.value == customViewSources.CustomElement">
            <div class="sidebar__element">
              <a
                [appDocumentation]="'components/custom-component'"
                [appDocumentationLeft]="true"
                [appDocumentationSource]="element.analyticsGenericName + '_custom_element'"
                class="sidebar-float-button"
                target="_blank"
              >
                <div class="sidebar-float-button__icon icon-help"></div>
                <div class="sidebar-float-button__label">See how it works</div>
                <div class="sidebar-float-button__close icon-arrow_forward_2"></div>
              </a>
            </div>

            <div class="sidebar__element">
              <app-sidebar-field [label]="'Compiled Custom Element (.zip)'">
                <!--          <input-->
                <!--            class="input"-->
                <!--            [class.input_error]="(form.form | appFormFieldErrors: 'dist_file')?.length"-->
                <!--            type="hidden"-->
                <!--            [formControlName]="'dist_file'"-->
                <!--            id="dist_file"-->
                <!--          />-->

                <div *ngIf="distFilename">
                  <strong>{{ distFilename }}</strong>
                  <br /><br />
                </div>

                <input class="input input_file" type="file" (change)="onFileChange($event.target)" id="dist_upload" />

                <a
                  *ngIf="distPreview && distPreview != distFilename"
                  [href]="distPreview"
                  class="button button_orange-alternative button_small"
                  target="_blank"
                >
                  <span class="icon icon-cloud_download button__icon"></span>&nbsp;
                  <span class="button__label">Download</span>
                </a>

                <label for="dist_upload" class="button button_orange-alternative button_small">
                  <span class="icon icon-cloud_upload button__icon"></span>&nbsp;
                  <span class="button__label">
                    <ng-container *ngIf="distPreview">Upload new file</ng-container>
                    <ng-container *ngIf="!distPreview">Upload file</ng-container>
                  </span>
                </label>

                <app-field-errors [form]="form" [fieldName]="'dist_file'"></app-field-errors>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element">
              <app-sidebar-field [label]="'Custom Element tag name'">
                <app-auto-field
                  [form]="form"
                  [label]="false"
                  [field]="createField({ name: 'tag_name', field: 'CharField', params: { classes: 'input_fill' } })"
                ></app-auto-field>
              </app-sidebar-field>
            </div>

            <app-change-custom-view-files
              [distControl]="form.controls.dist"
              [distFilesControl]="form.controls.dist_file"
              [filesControl]="form.controls.files"
            ></app-change-custom-view-files>
          </ng-container>
        </app-sidebar-section>

        <app-sidebar-section [title]="'Parameters'" [documentation]="'inputs'">
          <div class="sidebar__list">
            <app-parameters-edit-with-inputs
              [control]="form.controls.parameters"
              [inputsControl]="form.controls.inputs"
              [parameterProvider]="form.inputFieldProvider"
              [context]="context"
              [contextElement]="contextElement"
              [configurable]="{
                name: true,
                field: true,
                required: true,
                add: true,
                sortable: true
              }"
              [analyticsSource]="element.analyticsGenericName"
            >
            </app-parameters-edit-with-inputs>
          </div>
        </app-sidebar-section>

        <app-sidebar-section *ngIf="form.controls.source.value == customViewSources.CustomElement" [title]="'Outputs'">
          <div class="sidebar__list">
            <app-fields-edit
              [control]="form.controls.outputs"
              [configurable]="{
                name: true,
                field: true,
                add: true
              }"
              [serializeItem]="serializeOutput"
              [deserializeItem]="deserializeOutput"
              [addLabel]="'Add Output'"
              [addBaseName]="'output'"
              [object]="element.analyticsGenericName"
            >
            </app-fields-edit>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Display'">
        <app-sidebar-section>
          <ng-container
            *ngIf="[customViewSources.HTML, customViewSources.CustomElement].includes(form.controls.source.value)"
          >
            <div class="sidebar__element">
              <app-sidebar-field [label]="'Width'" [layoutRight]="true">
                <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
                  <div
                    class="sidebar-icon-button"
                    [class.sidebar-icon-button_active]="!form.controls.width_fluid.value"
                    (click)="form.controls.width_fluid.setValue(false)"
                  >
                    <div class="sidebar-icon-button__label">Fixed</div>
                  </div>

                  <div
                    class="sidebar-icon-button"
                    [class.sidebar-icon-button_active]="form.controls.width_fluid.value"
                    (click)="form.controls.width_fluid.setValue(true)"
                  >
                    <div class="sidebar-icon-button__label">Fluid</div>
                  </div>
                </div>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element">
              <app-sidebar-field [label]="'Height'" [layoutRight]="true">
                <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
                  <div
                    class="sidebar-icon-button"
                    [class.sidebar-icon-button_active]="!form.controls.height_fluid.value"
                    (click)="form.controls.height_fluid.setValue(false)"
                  >
                    <div class="sidebar-icon-button__label">Fixed</div>
                  </div>

                  <div
                    class="sidebar-icon-button"
                    [class.sidebar-icon-button_active]="form.controls.height_fluid.value"
                    (click)="form.controls.height_fluid.setValue(true)"
                  >
                    <div class="sidebar-icon-button__label">Fluid</div>
                  </div>
                </div>
              </app-sidebar-field>
            </div>
          </ng-container>

          <div class="sidebar__element">
            <app-sidebar-field
              [label]="'Conditional Visibility'"
              [documentation]="'conditional-visibility'"
              [documentationLabel]="'See how it works'"
              [documentationSource]="element.analyticsGenericName + '_visible'"
            >
              <app-input-edit
                [itemForm]="form.controls.visible_input"
                [context]="context"
                [staticValueDisabled]="true"
                [placeholder]="'Value'"
                [fill]="true"
                [analyticsSource]="element.analyticsGenericName + '_visible'"
              ></app-input-edit>

              <ng-container description>
                Example: elements.field.value >= 10
              </ng-container>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'Spacing'">
              <app-margin-control [control]="form.controls.margin"></app-margin-control>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab *ngIf="form.controls.source.value == customViewSources.View" [label]="'Actions'">
        <app-sidebar-section>
          <div class="sidebar__list">
            <ng-container *ngIf="form.getView() as view">
              <div class="sidebar-list__separator">
                <app-sidebar-list-separator [title]="'Component actions'"></app-sidebar-list-separator>
              </div>
              <div class="sidebar-list">
                <div *ngFor="let output of view.actions" class="sidebar-list__item">
                  <app-action-single-edit
                    *ngIf="form.controls.actions.controls[output.name] as control"
                    [title]="output.verboseName"
                    [control]="control"
                    [context]="context"
                    [contextElement]="contextElement"
                    [defaultSubtitle]="'Not specified'"
                    [remove]="control.value"
                    [icon]="output.icon || 'power'"
                    [object]="element.analyticsName"
                    [firstInit]="firstInit"
                  >
                  </app-action-single-edit>
                </div>

                <div *ngIf="!view.actions.length" class="sidebar__message">
                  There are no actions in component
                </div>
              </div>
            </ng-container>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <app-sidebar-submit
    *ngIf="form.controls.source.value == customViewSources.CustomElement"
    [saveDisabled]="form.invalid || form.disabled"
    [errors]="form | appFormErrors"
    (cancelClick)="cancel()"
    (deleteClick)="delete()"
  >
  </app-sidebar-submit>
</form>
