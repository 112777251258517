var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ascComparator, isSet } from '@shared';
import { NpmRegistryService } from '../services/npm/npm-registry.service';
import { slugifyJavaScriptPackage } from '../utils/imports';
export function defaultExportComparator(lhs, rhs) {
    if (lhs == 'default' && rhs != 'default') {
        return -1;
    }
    else if (lhs != 'default' && rhs == 'default') {
        return 1;
    }
    else {
        return 0;
    }
}
var NpmPackageExportsSource = /** @class */ (function (_super) {
    __extends(NpmPackageExportsSource, _super);
    function NpmPackageExportsSource(currentProjectStore, currentEnvironmentStore, npmService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.npmService = npmService;
        _this.loadedPage = false;
        return _this;
    }
    NpmPackageExportsSource.prototype.fetch = function (searchQuery) {
        var _this = this;
        if (!isSet(this.package) || !isSet(this.version)) {
            return of([]);
        }
        searchQuery = (searchQuery || '').trim();
        return this.npmService
            .getExports(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, this.package, this.version)
            .pipe(map(function (result) {
            var packageVariable = slugifyJavaScriptPackage(_this.package);
            return [
                {
                    value: '*',
                    name: "import * as " + packageVariable
                }
            ].concat((result || [])
                .map(function (item) {
                return {
                    value: item,
                    name: item != 'default' ? "import { " + item + " }" : "import " + packageVariable
                };
            })
                .sort(function (lhs, rhs) {
                return ([defaultExportComparator(lhs.value, rhs.value), ascComparator(lhs.value, rhs.value)].find(function (item) { return item != 0; }) || 0);
            })).filter(function (item) {
                if (!searchQuery) {
                    return true;
                }
                return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1;
            });
        }), tap(function () {
            _this.loadedPage = true;
        }));
    };
    NpmPackageExportsSource.prototype.fetchByValue = function (value) {
        if (!value) {
            return of(undefined);
        }
        if (!isSet(this.package) || !isSet(this.version)) {
            return of(undefined);
        }
        return this.fetch('').pipe(map(function (items) { return items.find(function (i) { return i.value == value; }); }));
    };
    NpmPackageExportsSource.prototype.isFetchAvailable = function () {
        return !this.loadedPage;
    };
    NpmPackageExportsSource.prototype.resetPagination = function () {
        this.loadedPage = false;
    };
    NpmPackageExportsSource.prototype.setStaticOptions = function (options) { };
    return NpmPackageExportsSource;
}(SelectSource));
export { NpmPackageExportsSource };
