var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OverlayContainer } from '@angular/cdk/overlay';
import { ErrorHandler } from '@angular/core';
import * as moment from 'moment';
import * as numeral from 'numeral';
import 'numeral/locales';
import { RouteScrollService } from '@common/route-scroll';
import { captureException, ScriptsService, SessionStorage } from '@core';
import { ApiService } from '@modules/api';
import { addClass, addMatSelectCoerceArray, AppError, getNavigatorLanguages, isSet, parseLocationFragment } from '@shared';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var IGNORE_GOOGLE_TAG_MANAGER_KEY = 'ignore_google_tag_manager';
var AppErrorHandler = /** @class */ (function () {
    function AppErrorHandler() {
    }
    AppErrorHandler.prototype.handleError = function (error) {
        console.error(error);
        if (environment.ravenDSN) {
            this.captureError(error);
        }
    };
    AppErrorHandler.prototype.captureError = function (error) {
        var actualException = error.originalError || error;
        if (actualException instanceof AppError) {
            return;
        }
        captureException(actualException);
    };
    return AppErrorHandler;
}());
export { AppErrorHandler };
var GlobalOverlayContainer = /** @class */ (function (_super) {
    __extends(GlobalOverlayContainer, _super);
    function GlobalOverlayContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GlobalOverlayContainer.prototype._createContainer = function () {
        this._containerElement = document.querySelector('.app-overlay-container');
    };
    GlobalOverlayContainer.ngInjectableDef = i0.defineInjectable({ factory: function GlobalOverlayContainer_Factory() { return new GlobalOverlayContainer(i0.inject(i1.DOCUMENT)); }, token: GlobalOverlayContainer, providedIn: "root" });
    return GlobalOverlayContainer;
}(OverlayContainer));
export { GlobalOverlayContainer };
var AppModule = /** @class */ (function () {
    function AppModule(routeScrollService, sessionStorage, apiService, scriptsService) {
        this.routeScrollService = routeScrollService;
        this.sessionStorage = sessionStorage;
        this.apiService = apiService;
        this.scriptsService = scriptsService;
        document.documentElement.setAttribute('env', environment.environment);
        var languages = getNavigatorLanguages();
        this.initPlatformClasses();
        this.routeScrollService.initScrollRestoration();
        addMatSelectCoerceArray();
        // this.initNumeral(languages);
        // this.initMoment(languages);
        this.initMomentToString();
        if (isSet(environment.googleTagManager) && this.isTagManagerEnabled()) {
            this.scriptsService.initTagManager(environment.googleTagManager);
        }
    }
    AppModule.prototype.isTagManagerEnabled = function () {
        if (!environment.production) {
            return false;
        }
        if (this.sessionStorage.get(IGNORE_GOOGLE_TAG_MANAGER_KEY)) {
            return false;
        }
        if (this.apiService.getIncognito()) {
            return false;
        }
        var params = parseLocationFragment(location.hash);
        var incognito = params.find(function (item) { return item.key == 'incognito'; });
        if (incognito) {
            return false;
        }
        return true;
    };
    AppModule.prototype.initPlatformClasses = function () {
        function isMacintosh() {
            return navigator.platform.includes('Mac');
        }
        function isWindows() {
            return navigator.platform.includes('Win');
        }
        if (isMacintosh()) {
            addClass(document.body, 'body_mac');
        }
        else {
            addClass(document.body, 'body_not-mac');
        }
        if (isWindows()) {
            addClass(document.body, 'body_windows');
        }
        else {
            addClass(document.body, 'body_not-windows');
        }
    };
    AppModule.prototype.initNumeral = function (languages) {
        languages.forEach(function (language) {
            if (numeral.locales[language]) {
                numeral.locale(language);
                return false;
            }
        });
    };
    AppModule.prototype.initMomentToString = function () {
        moment.prototype.toString = function () {
            return this.toISOString();
        };
        moment.prototype.toJSON = function () {
            return this.toISOString();
        };
    };
    AppModule.prototype.initMoment = function (languages) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, languages.forEach(function (language) { return __awaiter(_this, void 0, void 0, function () {
                            var Locale, locale, e_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        Locale = import(/* webpackChunkName: "moment-locale" */ 'moment/locale/' + language);
                                        return [4 /*yield*/, Locale];
                                    case 1:
                                        locale = _a.sent();
                                        if (!locale) {
                                            return [2 /*return*/];
                                        }
                                        moment.locale(language);
                                        return [2 /*return*/, false];
                                    case 2:
                                        e_1 = _a.sent();
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AppModule;
}());
export { AppModule };
