<div class="popover2__viewport popover2__viewport_scrollable" #scrollable_element>
  <div class="popover2__content popover2__content_padding_none" [style.min-width.px]="300">
    <div *ngIf="backEnabled || (title | appIsSet)" class="token-popup__item">
      <a
        *ngIf="backEnabled"
        href="javascript:void(0)"
        class="token-popup-list-item token-popup-list-item_orange"
        [style.margin-top.px]="6"
        (click)="back.emit()"
      >
        <div class="token-popup-list-item__inner token-popup-list-item__line">
          <span class="token-popup-list-item__left">
            <span class="icon-arrow_backward_2"></span>
          </span>

          <span class="token-popup-list-item__main">
            {{ backTitle }}
          </span>
        </div>
      </a>

      <div *ngIf="title | appIsSet" class="token-popup-section">
        <div class="token-popup-section__title">
          <div class="token-popup-section__title-main">
            {{ title }}
          </div>
        </div>
      </div>
    </div>

    <div class="token-popup-search">
      <input
        type="text"
        class="token-popup-search__input"
        [formControl]="searchControl"
        [placeholder]="searchPlaceholder"
        [appAutofocus]="true"
      />
      <span
        *ngIf="searchControl.value | appIsSet"
        class="token-popup-search__close icon-close"
        (click)="clearSearch()"
        [appTip]="'Clear'"
        [appTipOptions]="{ side: 'top' }"
      ></span>
    </div>

    <div *ngIf="!loading" class="token-popup__item">
      <div class="token-popup-section">
        <div class="token-popup-section__content">
          <div *ngIf="!options.length" class="token-popup-list-item token-popup-section__content-item">
            <div class="token-popup-list-item__inner">
              <div class="token-popup-list-item__tip">
                <ng-container *ngIf="searchControl.value?.length >= searchMinimumLength">Nothing here</ng-container>
                <ng-container *ngIf="searchControl.value?.length < searchMinimumLength">
                  Enter at least {{ searchMinimumLength }} characters
                </ng-container>
              </div>
            </div>
          </div>

          <a
            *ngFor="let item of options"
            href="javascript:void(0)"
            class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
            [class.token-popup-list-item_active]="item.value == (activeToken$ | async)?.value"
            (mouseenter)="onItemHover(item)"
            (mouseleave)="onItemOut()"
            (click)="onClick(item)"
            #option_element
          >
            <div class="token-popup-list-item__inner">
              <div class="token-popup-list-item__line">
                <span *ngIf="itemIcon" class="token-popup-list-item__left">
                  <span class="token-popup-list-item__icon" [ngClass]="'icon-' + itemIcon"></span>
                </span>

                <span *ngIf="multiple" class="token-popup-list-item__left">
                  <span
                    class="token-popup-list-item__icon"
                    [class.icon-check]="multipleControl.value.includes(item.value)"
                    [class.icon-uncheck]="!multipleControl.value.includes(item.value)"
                  ></span>
                </span>

                <span class="token-popup-list-item__main token-popup-list-item__main_wrap" [title]="item.name">
                  {{ item.name }}
                </span>

                <span
                  *ngIf="!multiple"
                  class="token-popup-list-item__right"
                  [class.token-popup-list-item__right_compact]="false"
                >
                  <span class="token-popup-list-item__icon icon-arrow_forward_2"></span>
                </span>
              </div>
            </div>
          </a>

          <div *ngIf="multiple" class="token-popup__submit">
            <button
              type="button"
              class="button button_orange button_small button_fill button_no-margin"
              [disabled]="!multipleControl.value.length"
              (click)="selectValue.emit(multipleControl.value)"
            >
              <span class="button__icon button__icon_left icon-components"></span>
              <span class="button__label">
                Import selected
                <ng-container *ngIf="multipleControl.value.length">({{ multipleControl.value.length }})</ng-container>
              </span>
              <span class="button__icon button__icon_right icon-arrow_forward_2"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="loading" class="token-popup__item">
      <div class="token-popup-section">
        <div class="token-popup-section__content">
          <ng-container *ngFor="let item of [1, 2, 3, 4]">
            <div
              class="token-popup-list-item"
              [class.token-popup-list-item_orange]="false"
              [class.token-popup-list-item_active]="false"
            >
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__line">
                  <span
                    class="token-popup-list-item__main token-popup-list-item__main_wrap"
                    [class.token-popup-list-item__main_orange]="false"
                    [class.token-popup-list-item__main_function]="false"
                  >
                    <span [class.loading-animation]="true">
                      <span class="stub-text">token item</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading" class="token-popup__footer">
  <div class="token-popup-hotkeys">
    <div class="token-popup-hotkeys__item">
      <div class="token-popup-hotkeys__item-icon">↑ ↓</div>
      <div class="token-popup-hotkeys__item-label">Navigate</div>
    </div>

    <div class="token-popup-hotkeys__item">
      <div class="token-popup-hotkeys__item-icon">Tab</div>
      <div class="token-popup-hotkeys__item-label">Select</div>
    </div>

    <div class="token-popup-hotkeys__item">
      <div class="token-popup-hotkeys__item-icon">Shift + Tab</div>
      <div class="token-popup-hotkeys__item-label">Back</div>
    </div>
  </div>
</div>
