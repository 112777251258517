/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/drag-drop";
import * as i2 from "@angular/common";
import * as i3 from "@angular/cdk/scrolling";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "../../../../../field-components/components/field-errors/field-errors.component.ngfactory";
import * as i6 from "../../../../../field-components/components/field-errors/field-errors.component";
import * as i7 from "../../customize-workflow-step-header/customize-workflow-step-header.component.ngfactory";
import * as i8 from "../../customize-workflow-step-header/customize-workflow-step-header.component";
import * as i9 from "../../../../services/workflow-edit-context/workflow-edit.context";
import * as i10 from "../../../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i11 from "../../../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i12 from "./fork-customize-workflow-step.form";
import * as i13 from "./fork-customize-workflow-step.component";
import * as i14 from "../../../../../../common/dialogs/services/dialog/dialog.service";
var styles_ForkCustomizeWorkflowStepComponent = [];
var RenderType_ForkCustomizeWorkflowStepComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ForkCustomizeWorkflowStepComponent, data: {} });
export { RenderType_ForkCustomizeWorkflowStepComponent as RenderType_ForkCustomizeWorkflowStepComponent };
function View_ForkCustomizeWorkflowStepComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 16, "div", [["cdkDrag", ""], ["class", "sidebar-list__item cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i0.ɵprd(6144, null, i1.ɵb, null, [i1.CdkDrag]), i0.ɵdid(2, 4866048, [[1, 4]], 3, i1.CdkDrag, [i0.ElementRef, [3, i1.CDK_DROP_LIST], i2.DOCUMENT, i0.NgZone, i0.ViewContainerRef, i3.ViewportRuler, i1.DragDropRegistry, i1.CDK_DRAG_CONFIG, [2, i4.Directionality], i1.DragDrop, i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 2, { _handles: 1 }), i0.ɵqud(335544320, 3, { _previewTemplate: 0 }), i0.ɵqud(335544320, 4, { _placeholderTemplate: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 10, "div", [["class", "sidebar-list-item sidebar-list-item_draggable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "sidebar-list-item__drag"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 6, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["Branch ", ""])), (_l()(), i0.ɵeld(11, 0, null, null, 3, "div", [["class", "sidebar-list-item__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, [" ", " "])), i0.ɵpod(13, { "=1": 0, other: 1 }), i0.ɵppd(14, 2), (_l()(), i0.ɵeld(15, 0, null, null, 1, "div", [["class", "sidebar-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "a", [["class", "sidebar-list-item__action icon-bin"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.removeItem(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(0, null, null, 0))], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).disabled; var currVal_1 = i0.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.index + 1); _ck(_v, 10, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 12, 0, _ck(_v, 14, 0, i0.ɵnov(_v.parent, 0), (((_v.context.$implicit.instance == null) ? null : _v.context.$implicit.instance.steps.length) || 0), _ck(_v, 13, 0, "# step", "# steps"))); _ck(_v, 12, 0, currVal_3); }); }
function View_ForkCustomizeWorkflowStepComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-field-errors", [], null, null, null, i5.View_FieldErrorsComponent_0, i5.RenderType_FieldErrorsComponent)), i0.ɵdid(1, 114688, null, 0, i6.FieldErrorsComponent, [], { form: [0, "form"], fieldName: [1, "fieldName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = "items"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ForkCustomizeWorkflowStepComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.I18nPluralPipe, [i2.NgLocalization]), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-customize-workflow-step-header", [], null, [[null, "close"], [null, "delete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeCustomize.emit() !== false);
        ad = (pd_0 && ad);
    } if (("delete" === en)) {
        var pd_1 = (_co.stepDelete.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_CustomizeWorkflowStepHeaderComponent_0, i7.RenderType_CustomizeWorkflowStepHeaderComponent)), i0.ɵdid(2, 114688, null, 0, i8.CustomizeWorkflowStepHeaderComponent, [i9.WorkflowEditContext], { step: [0, "step"], nameControl: [1, "nameControl"], nameValue: [2, "nameValue"], deleteEnabled: [3, "deleteEnabled"] }, { close: "close", delete: "delete" }), (_l()(), i0.ɵeld(3, 0, null, null, 17, "div", [["class", "sidebar__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 16, "app-sidebar-section", [], null, null, null, i10.View_SidebarSectionComponent_0, i10.RenderType_SidebarSectionComponent)), i0.ɵdid(5, 114688, null, 0, i11.SidebarSectionComponent, [], { disabled: [0, "disabled"] }, null), (_l()(), i0.ɵeld(6, 0, null, 3, 14, "div", [["class", "sidebar__list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 13, "div", [["cdkDropList", ""], ["class", "sidebar-list cdk-drop-list"]], [[8, "id", 0], [2, "cdk-drop-list-disabled", null], [2, "cdk-drop-list-dragging", null], [2, "cdk-drop-list-receiving", null]], [[null, "cdkDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkDropListDropped" === en)) {
        var pd_0 = (_co.dragDropItem($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(6144, null, i1.CDK_DROP_LIST, null, [i1.CdkDropList]), i0.ɵdid(9, 1196032, null, 1, i1.CdkDropList, [i0.ElementRef, i1.DragDropRegistry, i0.ChangeDetectorRef, [2, i4.Directionality], [3, i1.CdkDropListGroup], [2, i2.DOCUMENT], i1.DragDrop], { lockAxis: [0, "lockAxis"] }, { dropped: "cdkDropListDropped" }), i0.ɵqud(603979776, 1, { _draggables: 1 }), i0.ɵprd(256, null, i1.CdkDropListGroup, undefined, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ForkCustomizeWorkflowStepComponent_1)), i0.ɵdid(13, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 6, "div", [["class", "sidebar-list__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 3, "a", [["class", "button button_orange-transparent button_small sidebar-list__buttons-item"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "span", [["class", "icon icon-plus button__icon button__icon_left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add Branch"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ForkCustomizeWorkflowStepComponent_2)), i0.ɵdid(20, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.step; var currVal_1 = (_co.workflowEditable ? _co.form.controls.name : undefined); var currVal_2 = _co.form.controls.name.value; var currVal_3 = _co.workflowEditable; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = !_co.workflowEditable; _ck(_v, 5, 0, currVal_4); var currVal_9 = "y"; _ck(_v, 9, 0, currVal_9); var currVal_10 = _co.form.controls.items.controls; _ck(_v, 13, 0, currVal_10); var currVal_11 = !_co.form.controls.items.controls.length; _ck(_v, 20, 0, currVal_11); }, function (_ck, _v) { var currVal_5 = i0.ɵnov(_v, 9).id; var currVal_6 = i0.ɵnov(_v, 9).disabled; var currVal_7 = i0.ɵnov(_v, 9)._dropListRef.isDragging(); var currVal_8 = i0.ɵnov(_v, 9)._dropListRef.isReceiving(); _ck(_v, 7, 0, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ForkCustomizeWorkflowStepComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-fork-customize-workflow-step", [], null, null, null, View_ForkCustomizeWorkflowStepComponent_0, RenderType_ForkCustomizeWorkflowStepComponent)), i0.ɵprd(512, null, i12.ForkCustomizeWorkflowStepForm, i12.ForkCustomizeWorkflowStepForm, [i0.Injector]), i0.ɵdid(2, 245760, null, 0, i13.ForkCustomizeWorkflowStepComponent, [i12.ForkCustomizeWorkflowStepForm, i14.DialogService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ForkCustomizeWorkflowStepComponentNgFactory = i0.ɵccf("app-fork-customize-workflow-step", i13.ForkCustomizeWorkflowStepComponent, View_ForkCustomizeWorkflowStepComponent_Host_0, { runtime: "runtime", step: "step", workflowEditable: "workflowEditable", backgroundWorkflow: "backgroundWorkflow", context: "context", contextElement: "contextElement" }, { stepChange: "stepChange", stepDuplicate: "stepDuplicate", stepDelete: "stepDelete", stepExecute: "stepExecute", closeCustomize: "closeCustomize" }, []);
export { ForkCustomizeWorkflowStepComponentNgFactory as ForkCustomizeWorkflowStepComponentNgFactory };
