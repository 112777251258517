var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { DisplayFieldArray } from '@modules/customize-bar/components/display-fields-edit/display-field.array';
import { InputValueType } from '@modules/fields';
import { FieldInputArray, InputFieldProvider } from '@modules/parameters';
import { SidebarCollapseContext } from '@modules/sidebar';
import { coerceArray, isSet } from '@shared';
import { FieldInputsAddOverlayComponent } from '../field-inputs-add-overlay/field-inputs-add-overlay.component';
var FieldInputsEditComponent = /** @class */ (function () {
    function FieldInputsEditComponent(analyticsService, cd) {
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.addInputEnabled = false;
        this.nestedFieldsEnabled = true;
        this.relationsEnabled = true;
        this.userInput = false;
        this.collapse = true;
        this.collapseContext = new SidebarCollapseContext();
        this.listWrapper = true;
        this.displayValueTypes = [InputValueType.Formula];
        this.addInput = new EventEmitter();
        this.displayAllParameters = false;
    }
    FieldInputsEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.parameterProvider
            .getItems$()
            .pipe(untilDestroyed(this))
            .subscribe(function (providerItems) {
            _this.displayAllParameters = providerItems.every(function (item) { return !item.children || !item.children.length; });
            _this.cd.markForCheck();
        });
        this.optionsFilter = function (option, path) {
            var optionPath = path.concat([option]).map(function (item) { return item.name; });
            return (_this.control.controls.find(function (item) {
                return (isEqual(item.controls.path.value, optionPath) &&
                    !isSet(item.controls.lookup.value) &&
                    !item.controls.exclude.value);
            }) === undefined);
        };
    };
    FieldInputsEditComponent.prototype.ngOnDestroy = function () { };
    FieldInputsEditComponent.prototype.trackByFn = function (i, item) {
        var path = coerceArray(item.controls.path.value);
        if (path.length) {
            return 'field_' + path.join('__');
        }
        else {
            return i;
        }
    };
    FieldInputsEditComponent.prototype.onFilterAdded = function (e) {
        this.lastAddedControl = this.control.appendControl(undefined, __assign({ path: e.path.map(function (item) { return item.name; }) }, (isSet(e.lookup) && { lookup: e.lookup.lookup }), (isSet(e.exclude) && { exclude: e.exclude }), (e.staticValue !== undefined && {
            value_type: InputValueType.StaticValue,
            static_value: e.staticValue
        })));
        this.cd.markForCheck();
    };
    FieldInputsEditComponent.prototype.openAddInput = function () {
        if (this.inputsAddOverlayComponent) {
            this.inputsAddOverlayComponent.open();
        }
    };
    return FieldInputsEditComponent;
}());
export { FieldInputsEditComponent };
