import trim from 'lodash/trim';
import * as numeral from 'numeral';

import { CURRENCY_SYMBOLS } from '../../data/currency-symbols';
import { isSet } from '../common/common';

export function numberToHex(num: number): string {
  // tslint:disable-next-line:no-bitwise
  const str = (num & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - str.length) + str;
}

export function parseNumber(value: any, defaultValue = null): number {
  if (typeof value === 'number' || value === undefined || value === null) {
    return value;
  } else if (typeof value !== 'string') {
    return defaultValue;
  }

  value = trim(value);

  if (value === '') {
    return defaultValue;
  }

  value = parseFloat(value.replace(',', '.'));

  if (isNaN(value)) {
    return defaultValue;
  }

  return value;
}

export function degToRad(degrees: number): number {
  return degrees * (Math.PI / 180);
}

export function radToDeg(radians: number): number {
  return (radians * 180) / Math.PI;
}

export function coerceFloat(value: any, defaultValue?: any): number {
  const result = parseFloat(value);
  return !isNaN(result) ? result : defaultValue;
}

export function getNumberOrdinal(num: number): string {
  if (typeof num !== 'number') {
    return String(num);
  } else if (num < 0) {
    return String(num);
  } else if (num === 0) {
    return 'zero';
  } else if (num === 1) {
    return '1st';
  } else if (num === 2) {
    return '2nd';
  } else if (num === 3) {
    return '3rd';
  } else {
    return `${num}th`;
  }
}

export function numeralFormat(value: any, format: string): string {
  if (!isSet(format)) {
    return value;
  }

  const currenciesRegex = new RegExp(`[${CURRENCY_SYMBOLS.join('')}]`, 'g');
  const replaces = [];

  format = String(format).replace(currenciesRegex, char => {
    replaces.push(char);
    return '$';
  });

  const result = numeral(value).format(format);
  const dollarRegex = new RegExp(`[$]`, 'g');

  return result.replace(dollarRegex, () => replaces.shift());
}
