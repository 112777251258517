import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ascComparator, isSet } from '@shared';

import { NpmRegistryService } from '../services/npm/npm-registry.service';
import { slugifyJavaScriptPackage } from '../utils/imports';

export function defaultExportComparator(lhs: string, rhs: string): number {
  if (lhs == 'default' && rhs != 'default') {
    return -1;
  } else if (lhs != 'default' && rhs == 'default') {
    return 1;
  } else {
    return 0;
  }
}

@Injectable()
export class NpmPackageExportsSource extends SelectSource {
  package: string;
  version: string;
  loadedPage = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private npmService: NpmRegistryService
  ) {
    super();
  }

  fetch(searchQuery: string): Observable<Option[]> {
    if (!isSet(this.package) || !isSet(this.version)) {
      return of([]);
    }

    searchQuery = (searchQuery || '').trim();

    return this.npmService
      .getExports(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        this.package,
        this.version
      )
      .pipe(
        map(result => {
          const packageVariable = slugifyJavaScriptPackage(this.package);

          return [
            {
              value: '*',
              name: `import * as ${packageVariable}`
            },
            ...(result || [])
              .map(item => {
                return {
                  value: item,
                  name: item != 'default' ? `import { ${item} }` : `import ${packageVariable}`
                };
              })
              .sort((lhs, rhs) => {
                return (
                  [defaultExportComparator(lhs.value, rhs.value), ascComparator(lhs.value, rhs.value)].find(
                    item => item != 0
                  ) || 0
                );
              })
          ].filter(item => {
            if (!searchQuery) {
              return true;
            }

            return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1;
          });
        }),
        tap(() => {
          this.loadedPage = true;
        })
      );
  }

  fetchByValue(value: string): Observable<Option> {
    if (!value) {
      return of(undefined);
    }

    if (!isSet(this.package) || !isSet(this.version)) {
      return of(undefined);
    }

    return this.fetch('').pipe(map(items => items.find(i => i.value == value)));
  }

  isFetchAvailable(): boolean {
    return !this.loadedPage;
  }

  resetPagination() {
    this.loadedPage = false;
  }

  setStaticOptions(options: Option[]) {}
}
