import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import defaults from 'lodash/defaults';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AggregateFunc } from '@modules/charts';
import { FieldLookup } from '@modules/field-lookups';
import {
  BaseField,
  FieldDescriptionLookup,
  FieldType,
  getFieldDescriptionByType,
  ParameterField
} from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription, ModelRelation } from '@modules/models';
import { isSet, TypedChanges } from '@shared';

import { ModelOption } from '../../data/model-option';
import { ModelOptionsSource } from '../../services/model-options-source';

export interface ModelOptionSelectedEvent {
  name: string;
  verboseName: string;
  field?: BaseField;
  relation?: ModelRelation;
  lookup?: FieldLookup;
  exclude?: boolean;

  path: {
    name: string;
    verboseName: string;
    field?: BaseField;
    relation?: ModelRelation;
  }[];

  aggregation?: {
    func: AggregateFunc;
    field?: string;
  };

  modelDescription?: ModelDescription;

  staticValue?: any;
}

@Component({
  selector: 'app-select-model-field',
  templateUrl: './select-model-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectModelFieldComponent implements OnInit, OnDestroy, OnChanges {
  @Input() modelDescription: ModelDescription;
  @Input() fields: BaseField[];
  @Input() onlyFields: BaseField[];
  @Input() searchPlaceholder: string;
  @Input() nestedFieldsEnabled = true;
  @Input() relationsEnabled = true;
  @Input() onlyNestedFields = false;
  @Input() fieldsSelectEnabled = true;
  @Input() relationsSelectEnabled = true;
  @Input() aggregationsEnabled = false;
  @Input() lookupsEnabled = false;
  @Input() excludeLookupsEnabled = false;
  @Input() lookupsSelect = false;
  @Input() path: ModelOption[] = [];
  @Input() optionsFilter: (option: ModelOption, path: ModelOption[]) => boolean;
  @Input() emptyMessage: string;
  @Output() nameSelected = new EventEmitter<string>();
  @Output() selected = new EventEmitter<ModelOptionSelectedEvent>();
  @Output() back = new EventEmitter<void>();

  loading = true;
  searchControl = new FormControl('');
  maxRelationsDepth = 4;

  options: ModelOption[] = [];
  optionsSubscription: Subscription;
  filteredFieldOptions: ModelOption[] = [];
  filteredRelationOptions: ModelOption[] = [];
  selectedParentOption: ModelOption;
  selectedParentOptionPath: ModelOption[] = [];
  selectedField: ModelOptionSelectedEvent;
  selectedFieldLookups: FieldDescriptionLookup[] = [];
  selectedFieldExcludeSupported = false;
  aggregations: {
    func: AggregateFunc;
    label: (name: string) => string;
    fieldDropdown?: boolean;
    fieldDropdownOpened$: BehaviorSubject<boolean>;
  }[] = [
    {
      func: AggregateFunc.Count,
      label: name => `Count number of ${name}`
    },
    {
      func: AggregateFunc.Sum,
      label: name => `Sum of ${name} field`,
      fieldDropdown: true
    },
    {
      func: AggregateFunc.Min,
      label: name => `Minimum of ${name} field`,
      fieldDropdown: true
    },
    {
      func: AggregateFunc.Max,
      label: name => `Maximum of ${name} field`,
      fieldDropdown: true
    },
    {
      func: AggregateFunc.Avg,
      label: name => `Average of ${name} field`,
      fieldDropdown: true
    }
  ].map(item => {
    return {
      ...item,
      fieldDropdownOpened$: new BehaviorSubject<boolean>(false)
    };
  });
  selectedAggregationIndex$ = new BehaviorSubject<number>(undefined);
  fieldDropdownPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center',
      offsetX: 0
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center',
      offsetX: 0
    }
  ];

  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private modelOptionsSource: ModelOptionsSource,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateFilteredOptions());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<SelectModelFieldComponent>): void {
    if (changes.modelDescription || changes.fields) {
      this.initOptions();
      this.updateFilteredOptions();
    }
  }

  initOptions() {
    if (this.optionsSubscription) {
      this.optionsSubscription.unsubscribe();
      this.optionsSubscription = undefined;
    }

    if (this.modelDescription) {
      this.optionsSubscription = this.modelOptionsSource
        .getOptions$(this.modelDescription, {
          path: this.path,
          nestedFieldsEnabled: this.nestedFieldsEnabled,
          onlyNestedFields: this.onlyNestedFields,
          relationsEnabled: this.relationsEnabled,
          maxRelationsDepth: this.maxRelationsDepth
        })
        .pipe(untilDestroyed(this))
        .subscribe(options => {
          this.options = options
            .filter(item => {
              if (item.relatedModelDescription) {
                return true;
              } else {
                return this.isSelectAllowed(item);
              }
            })
            .filter(item => {
              if (this.optionsFilter) {
                return this.optionsFilter(item, this.path);
              } else {
                return true;
              }
            });
          this.loading = false;
          this.cd.markForCheck();
        });
    } else if (this.fields) {
      const depth = this.path.length + 1;

      return this.modelDescriptionStore
        .get()
        .pipe(untilDestroyed(this))
        .subscribe(modelDescriptions => {
          this.options = this.fields
            .filter(() => this.fieldsSelectEnabled)
            .filter(field => {
              if (!this.onlyNestedFields) {
                return true;
              }

              if (depth == 1) {
                return field.field == FieldType.RelatedModel;
              } else {
                return true;
              }
            })
            .map(field => {
              const fieldDescription = getFieldDescriptionByType(field.field);
              let relatedModelDescription: ModelDescription;

              if (field.field == FieldType.RelatedModel && this.relationsEnabled && depth < this.maxRelationsDepth) {
                const relatedModelId = field.params ? field.params['related_model']['model'] : undefined;
                relatedModelDescription = relatedModelId
                  ? modelDescriptions.find(item => item.isSame(relatedModelId))
                  : undefined;
              }

              return {
                name: field.name,
                verboseName: field.verboseName || field.name,
                icon: fieldDescription.icon,
                field: field,
                relatedModelDescription: relatedModelDescription
              };
            })
            .filter(item => {
              if (this.optionsFilter) {
                return this.optionsFilter(item, this.path);
              } else {
                return true;
              }
            });
          this.loading = false;
          this.cd.markForCheck();
        });
    } else {
      this.options = [];
      this.loading = false;
      this.cd.markForCheck();
    }
  }

  getFilteredOptions(options: ModelOption[]): ModelOption[] {
    if (this.onlyFields) {
      options = options.filter(option => this.onlyFields.find(item => item.name == option.name));
    }

    const search = this.searchControl.value.toLowerCase().trim();

    if (!isSet(search)) {
      return options;
    }

    return options.filter(option => {
      return [option.verboseName, option.name].some(item => {
        return isSet(item) && item.toLowerCase().includes(search);
      });
    });
  }

  updateFilteredOptions() {
    const options = this.getFilteredOptions(this.options);

    this.filteredFieldOptions = options.filter(item => item.field);
    this.filteredRelationOptions = options.filter(item => item.relation);
    this.cd.markForCheck();
  }

  clearSearch() {
    this.searchControl.patchValue('');
    this.updateFilteredOptions();
  }

  setSelectedParentOption(item: ModelOption) {
    this.selectedParentOption = item;
    this.selectedParentOptionPath = item ? [...this.path, item] : undefined;
    this.cd.markForCheck();
  }

  isSelectAllowed(option: ModelOption): boolean {
    if (option.field) {
      return this.fieldsSelectEnabled;
    } else if (option.relatedModelDescription) {
      return this.relationsSelectEnabled;
    } else {
      return true;
    }
  }

  selectFieldOption(
    selectItem: ModelOption,
    options: {
      appendPath?: boolean;
      aggregation?: {
        func: AggregateFunc;
        field?: string;
      };
    } = {}
  ) {
    options = defaults(options, { appendPath: true });

    const mapPath = (item: ModelOption) => {
      return {
        name: item.name,
        verboseName: item.verboseName,
        icon: item.icon,
        field: item.field,
        relation: item.relation
      };
    };
    const path = options.appendPath
      ? [...this.path.map(item => mapPath(item)), mapPath(selectItem)]
      : this.path.map(item => mapPath(item));

    this.selectedField = {
      name: selectItem.name,
      verboseName: selectItem.verboseName,
      field: selectItem.field,
      relation: selectItem.relation,
      path: path,
      aggregation: options.aggregation,
      modelDescription: selectItem.modelDescription
    };

    const lookups = this.lookupsSelect && this.lookupsEnabled ? this.getFieldLookups(this.selectedField) : undefined;

    if (lookups && lookups.lookups.length) {
      this.selectedFieldLookups = lookups.lookups;
      this.selectedFieldExcludeSupported = lookups.excludeSupported;
      this.cd.markForCheck();
    } else {
      this.selectedFieldLookups = [];
      this.cd.markForCheck();

      this.nameSelected.emit(this.selectedField.name);
      this.selected.emit(this.selectedField);
    }
  }

  selectLookupOption(lookup: FieldDescriptionLookup, exclude = false) {
    this.selectedField = {
      ...this.selectedField,
      lookup: lookup.type,
      exclude: exclude
    };

    this.nameSelected.emit(this.selectedField.name);
    this.selected.emit({
      ...this.selectedField,
      ...(!lookup.field && {
        staticValue: true
      })
    });
  }

  backToFieldSelection() {
    this.selectedField = undefined;
    this.cd.markForCheck();
  }

  getFieldLookups(
    fieldValue: ModelOptionSelectedEvent
  ): { lookups: FieldDescriptionLookup[]; excludeSupported?: boolean } {
    if (!fieldValue) {
      return { lookups: [] };
    }

    const fieldDescription = getFieldDescriptionByType(fieldValue.field.field);
    const queryParameter = this.fields
      ? this.fields.find(item => {
          return isEqual(
            [item.name],
            fieldValue.path.map(i => i.name)
          );
        })
      : undefined;

    if ((this.lookupsEnabled && !queryParameter) || !(queryParameter instanceof ParameterField)) {
      return { lookups: fieldDescription.lookups, excludeSupported: this.excludeLookupsEnabled };
    } else {
      return { lookups: [] };
    }
  }
}
