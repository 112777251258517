import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

import { AppDrag, AppDragDrop, AppDropList, moveItemInArray, transferArrayItem } from '@common/drag-drop2';
import { NotificationService } from '@common/notifications';
import { ActionControllerService, ActionService, isModelUpdateEventMatch, patchModel } from '@modules/action-queries';
import { ActionDescription, TintStyle } from '@modules/actions';
import { CustomizeBarItem } from '@modules/change-components';
import {
  CustomizeService,
  ElementItem,
  ElementType,
  FormElementItem,
  FormStyle,
  getModelAttributesByColumns,
  ITEM_OUTPUT,
  RawListViewSettingsColumn,
  rawListViewSettingsColumnsToViewContextOutputs,
  registerElementComponent,
  SUBMIT_RESULT_OUTPUT,
  traverseElementItems,
  VALUE_OUTPUT,
  ViewContextElement
} from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { AutoElementComponent, BaseElementComponent, ElementContainerService } from '@modules/customize-elements';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { DataSourceType, ModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import {
  applyParamInput$,
  applyParamInputs$,
  createFormFieldFactory,
  DisplayFieldType,
  ERROR_VALUE,
  FieldType,
  getFieldDescriptionByType,
  InputValueType,
  LOADING_VALUE,
  NOT_SET_VALUE,
  ParameterField
} from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { paramsToGetQueryOptions } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { ascComparator, TypedChanges } from '@shared';

import { ElementGroupsContainerDirective } from '../../directives/element-groups-container/element-groups-container.directive';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
import { RootLayoutComponent } from '../root-layout/root-layout.component';

interface ElementState {
  element?: FormElementItem;
  configured?: boolean;
  parameters?: ParameterField[];
  value?: Object;
  valueError?: boolean;
  dataSource?: ModelDescriptionDataSource;
  params?: Object;
  staticData?: Object;
  modelDescription?: ModelDescription;
  inputsLoading?: boolean;
  inputsNotSet?: boolean;
}

export function serializeDataSourceColumns(columns: RawListViewSettingsColumn[]): Object[] {
  return columns
    .filter(item => !item.flex)
    .map(item => {
      return {
        name: item.name
      };
    })
    .sort((lhs, rhs) => ascComparator(lhs.name, rhs.name));
}

function getElementStateFetch(state: ElementState): Object {
  return {
    dataSource: state.dataSource
      ? {
          ...state.dataSource.serialize(),
          columns: serializeDataSourceColumns(state.dataSource.columns)
        }
      : undefined,
    staticData: state.staticData,
    params: state.params,
    inputsLoading: state.inputsLoading,
    inputsNotSet: state.inputsNotSet
  };
}

function getElementStateColumns(state: ElementState): Object {
  return {
    columns: state.dataSource ? state.dataSource.columns : undefined
  };
}

function getElementStateName(state: ElementState): Object {
  return {
    name: state.element ? state.element.name : undefined
  };
}

function getElementStateParameters(state: ElementState): Object {
  return {
    parameters: state.parameters ? state.parameters.map(item => item.serialize()) : []
  };
}

function getElementStateValue(state: ElementState): Object {
  return {
    value: state.value
  };
}

function getElementStateSubmitDisabled(state: ElementState): Object {
  return {
    configured: state.configured,
    valueError: state.valueError
  };
}

@Component({
  selector: 'app-form-element',
  templateUrl: './form-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ViewContextElement]
})
export class FormElementComponent extends BaseElementComponent<FormElementItem>
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() element: FormElementItem;

  @ViewChild(ElementGroupsContainerDirective) elementGroupsContainer: ElementGroupsContainerDirective;
  @ViewChildren(AutoElementComponent) elementComponents = new QueryList<AutoElementComponent>();

  firstVisible$ = new BehaviorSubject<boolean>(false);
  elementState: ElementState = {};

  createField = createFormFieldFactory();
  customizeEnabled$: Observable<boolean>;
  loading$ = new BehaviorSubject<boolean>(false);
  loadingSubscription: Subscription;
  model: Model;
  value$ = new BehaviorSubject<Object>({});
  submitDisabled$ = new BehaviorSubject<boolean>(false);
  updateSubmitDisabledSubscription: Subscription;
  submitLoading$ = new BehaviorSubject<boolean>(false);
  stubRangeDefault = range(3);
  stubRange: any[] = this.stubRangeDefault;
  stubs = [
    {
      label: 'label',
      value: 'long value'
    },
    {
      label: 'long label',
      value: 'value'
    },
    {
      label: 'very long label',
      value: 'value'
    },
    {
      label: 'label',
      value: 'very long value'
    }
  ];
  formStyles = FormStyle;
  tintStyles = TintStyle;

  canEnter = (() => {
    return (drag: AppDrag, drop: AppDropList): boolean => {
      if (this.rootLayoutComponent && !this.rootLayoutComponent.active) {
        return false;
      }

      if (drag.data instanceof ElementItem && drag.data.type == ElementType.FormSubmit) {
        let isInsideCurrentForm = false;

        traverseElementItems(this.element, item => {
          if (item !== this.element && item instanceof FormElementItem) {
            return false;
          } else if (item === drag.data) {
            isInsideCurrentForm = true;
          }
        });

        return isInsideCurrentForm;
      } else {
        return true;
      }
    };
  })();

  trackElement = (() => {
    return (i, item: ElementItem) => {
      const pageUid =
        this.context && this.context.viewSettings && !this.context.viewSettings.newlyCreated
          ? this.context.viewSettings.uid
          : undefined;
      return [pageUid, item.uid].join('_');
    };
  })();

  constructor(
    public customizeService: CustomizeService,
    private elementContainerService: ElementContainerService,
    private elementConfigurationService: ElementConfigurationService,
    private routing: RoutingService,
    private modelService: ModelService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionService: ActionService,
    private actionControllerService: ActionControllerService,
    private modelDescriptionDataSourceService: ModelDescriptionDataSourceService,
    private dataSourceGeneratorService: DataSourceGeneratorService,
    private notificationService: NotificationService,
    private injector: Injector,
    private cd: ChangeDetectorRef,
    public viewContextElement: ViewContextElement,
    @Optional() private rootLayoutComponent: RootLayoutComponent,
    @Optional() private popup: CustomPagePopupComponent
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.initContext();
    this.trackModelUpdates();

    this.elementOnChange(this.element);
    this.trackChanges();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<FormElementComponent>): void {
    if (changes.element) {
      this.initContext();
      this.elementOnChange(this.element);
    }

    if (changes.element && !changes.element.firstChange) {
      setTimeout(() => this.updateElementStatesOnStable(), 0);
    }
  }

  ngAfterViewInit(): void {
    this.updateElementStates();
  }

  trackChanges() {
    this.firstVisible$
      .pipe(
        first(value => value),
        switchMap(() => this.element$),
        switchMap(element => {
          if (!element.getDataSource) {
            return of(element);
          }

          return this.dataSourceGeneratorService
            .applyDataSourceDefaults<ModelDescriptionDataSource>(ModelDescriptionDataSource, element.getDataSource)
            .pipe(
              map(dataSource => {
                element.getDataSource = dataSource;
                return element;
              })
            );
        }),
        switchMap(element => this.getElementState(element)),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.onStateUpdated(state);
        this.elementState = state;
      });
  }

  getElementState(element: FormElementItem): Observable<ElementState> {
    const value$ = element.submitAction
      ? this.actionService.getActionDescription(element.submitAction).pipe(
          switchMap(actionDescription => {
            if (!actionDescription) {
              return of({});
            }

            return applyParamInputs$({}, element.submitAction.inputs, {
              context: this.context,
              parameters: actionDescription.actionParams,
              raiseErrors: true,
              errorValue: ERROR_VALUE,
              handleLoading: true,
              ignoreEmpty: true
            });
          }),
          distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs))
        )
      : of({});
    const staticData$ =
      element.getDataSource && element.getDataSource.type == DataSourceType.Input && element.getDataSource.input
        ? applyParamInput$<Object>(element.getDataSource.input, {
            context: this.context,
            defaultValue: {},
            handleLoading: true,
            ignoreEmpty: true
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of({});
    const dataSourceParams$ = element.getDataSource
      ? applyParamInputs$({}, element.getDataSource.queryInputs, {
          context: this.context,
          parameters: element.getDataSource.queryParameters,
          handleLoading: true,
          ignoreEmpty: true
        }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
      : of({});

    return combineLatest(
      this.elementConfigurationService.isActionConfigured(element.submitAction, { ignoreInputs: true }),
      this.actionService.getActionDescription(element.submitAction),
      value$,
      staticData$,
      dataSourceParams$,
      this.getQueryModelDescription(element.getDataSource)
    ).pipe(
      map(([configured, actionDescription, value, staticData, dataSourceParams, modelDescription]) => {
        const parameters = actionDescription
          ? this.getFormParameters(actionDescription, element.getDataSource)
              .filter(item => item.enabled)
              .map(item => item.parameter)
          : [];
        const inputsLoading = [dataSourceParams, staticData].some(obj => {
          return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
        });
        const inputsNotSet = [dataSourceParams, staticData].some(obj => {
          return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
        });

        return {
          element: element,
          configured: configured,
          parameters: parameters,
          value: value !== ERROR_VALUE ? value : {},
          valueError: value === ERROR_VALUE,
          dataSource: element.getDataSource,
          staticData: staticData,
          params: dataSourceParams,
          modelDescription: modelDescription,
          inputsLoading: inputsLoading,
          inputsNotSet: inputsNotSet
        };
      })
    );
  }

  getFormParameters(
    actionDescription: ActionDescription,
    getDataSource?: ModelDescriptionDataSource
  ): { parameter: ParameterField; enabled: boolean; getQueryInput: boolean }[] {
    if (!actionDescription) {
      return [];
    }

    const actionQueryType =
      actionDescription.queryAction && actionDescription.queryAction.query
        ? actionDescription.queryAction.query.queryType
        : undefined;

    const updateModelAction =
      getDataSource &&
      getDataSource.queryResource == actionDescription.resource &&
      actionQueryType == QueryType.Simple &&
      getDataSource.query &&
      getDataSource.query.queryType == QueryType.Simple &&
      getDataSource.query.simpleQuery &&
      actionDescription.modelAction == 'update';

    return actionDescription.actionParams.map(parameter => {
      const getQueryInput =
        updateModelAction && getDataSource.queryInputs.find(item => item.isName(parameter.name) && item.isSet());

      return {
        parameter: parameter,
        enabled: !getQueryInput,
        getQueryInput: !!getQueryInput
      };
    });
  }

  onStateUpdated(state: ElementState) {
    if (!isEqual(getElementStateColumns(state), getElementStateColumns(this.elementState))) {
      this.updateStubs(state);
      this.updateContextOutputs(state);
    }

    if (!isEqual(getElementStateName(state), getElementStateName(this.elementState))) {
      this.updateContextInfo(state);
    }

    if (!isEqual(getElementStateFetch(state), getElementStateFetch(this.elementState))) {
      this.fetch(state);
    }

    if (!isEqual(getElementStateParameters(state), getElementStateParameters(this.elementState))) {
      this.updateContextOutputs(state);
    }

    if (!isEqual(getElementStateValue(state), getElementStateValue(this.elementState))) {
      this.setCurrentValue(state.value);
    }

    if (!isEqual(getElementStateSubmitDisabled(state), getElementStateSubmitDisabled(this.elementState))) {
      this.updateSubmitDisabled(state);
    }
  }

  fetch(state: ElementState) {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
      this.loadingSubscription = undefined;
    }

    if (!state.dataSource) {
      this.model = undefined;
      this.updateItemContextValue(state);
      this.cd.markForCheck();
      return;
    }

    if (state.inputsNotSet) {
      this.model = undefined;
      this.updateItemContextValue(state);
      this.loading$.next(false);
      this.cd.markForCheck();
      return;
    }

    this.loading$.next(true);
    this.cd.markForCheck();

    this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: true });

    if (state.inputsLoading) {
      return;
    }

    const queryOptions = paramsToGetQueryOptions(state.params);

    queryOptions.columns = state.dataSource ? state.dataSource.columns : undefined;

    this.loadingSubscription = this.modelDescriptionDataSourceService
      .getDetailAdv({
        project: this.currentProjectStore.instance,
        environment: this.currentEnvironmentStore.instance,
        dataSource: state.dataSource,
        queryOptions: queryOptions,
        staticData: state.staticData,
        context: this.context
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        model => {
          this.model = model;
          this.updateItemContextValue(state);
          this.loading$.next(false);
          this.cd.markForCheck();

          this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: false });
        },
        () => {
          this.model = undefined;
          this.updateItemContextValue(state);
          this.loading$.next(false);
          this.cd.markForCheck();

          this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: false });
        }
      );
  }

  getQueryModelDescription(dataSource: ModelDescriptionDataSource) {
    if (
      !dataSource ||
      !dataSource.query ||
      dataSource.query.queryType != QueryType.Simple ||
      !dataSource.query.simpleQuery
    ) {
      return of(undefined);
    }

    const modelId = [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.');
    return this.modelDescriptionStore.getDetailFirst(modelId);
  }

  reloadData(): void {
    this.fetch(this.elementState);
  }

  updateStubs(state: ElementState) {
    this.stubRange =
      state.dataSource && state.dataSource.columns.length
        ? range(state.dataSource.columns.filter(item => item.visible).length)
        : this.stubRangeDefault;
    this.cd.markForCheck();
  }

  initContext() {
    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      icon: 'windows',
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });
    this.viewContextElement.setActions([
      {
        uniqueName: 'update_data',
        name: 'Update Data',
        icon: 'repeat',
        parameters: [],
        handler: () => this.reloadData()
      },
      {
        uniqueName: 'clear_value',
        name: 'Clear Form',
        icon: 'delete',
        parameters: [],
        handler: () => {
          const obs = this.element.submitAction.inputs
            .filter(item => item.valueType == InputValueType.Context)
            .map(input => {
              const path = input.contextValue;

              if (path.length == 3 && path[0] == 'elements' && path[2] == 'value') {
                const actionPath = [...path.slice(0, -1), 'clear_value'];
                const action = this.context.getElementAction(actionPath);

                if (action) {
                  return this.actionService.executeElement(actionPath, this.context, { pristine: true });
                }
              }
            })
            .filter(item => item);

          if (!obs.length) {
            return of(true);
          }

          return combineLatest(obs).pipe(map(() => true));
        }
      }
    ]);
  }

  updateContextInfo(state: ElementState) {
    this.viewContextElement.initInfo(
      {
        name: state.element.name,
        getFieldValue: (field, outputs) => {
          return outputs[VALUE_OUTPUT] ? outputs[VALUE_OUTPUT][field] : undefined;
        }
      },
      true
    );
  }

  updateContextOutputs(state: ElementState) {
    const children = state.dataSource
      ? rawListViewSettingsColumnsToViewContextOutputs(
          state.dataSource.columns.filter(item => item.type != DisplayFieldType.Computed),
          state.modelDescription
        )
      : [];

    this.viewContextElement.setOutputs([
      {
        uniqueName: VALUE_OUTPUT,
        name: 'Current Value',
        icon: 'edit',
        children: state.parameters.map(item => {
          const fieldDescription = getFieldDescriptionByType(item.field);
          const icon = fieldDescription ? fieldDescription.icon : undefined;

          return {
            uniqueName: item.name,
            name: item.verboseName,
            icon: icon,
            // iconOrange: primaryKey,
            fieldType: item.field,
            fieldParams: item.params
          };
        }),
        external: true
      },
      {
        uniqueName: ITEM_OUTPUT,
        name: 'Current Record',
        icon: 'duplicate_2',
        children: children,
        external: true
      },
      {
        uniqueName: SUBMIT_RESULT_OUTPUT,
        name: 'Submit Result',
        icon: 'components',
        fieldType: FieldType.JSON,
        external: true
      }
    ]);
  }

  updateItemContextValue(state: ElementState) {
    const output =
      this.model && state.dataSource ? getModelAttributesByColumns(this.model, state.dataSource.columns) : undefined;

    this.viewContextElement.setOutputValue(ITEM_OUTPUT, output);
    // Backward compatibility
    this.viewContextElement.setOutputValue('model', output);
  }

  setCurrentValue(value: Object) {
    this.value$.next(value);
    this.viewContextElement.setOutputValue(VALUE_OUTPUT, value);
  }

  trackModelUpdates() {
    this.actionService.modelUpdated$.pipe(untilDestroyed(this)).subscribe(e => {
      if (isModelUpdateEventMatch(e, this.elementState.modelDescription, this.model)) {
        this.model = patchModel(this.model, e.model);
        this.cd.markForCheck();
        this.updateItemContextValue(this.elementState);
      }
    });
  }

  isSubmitDisabled$(): Observable<boolean> {
    return this.submitDisabled$.asObservable();
  }

  updateSubmitDisabled(state: ElementState) {
    if (this.updateSubmitDisabledSubscription) {
      this.updateSubmitDisabledSubscription.unsubscribe();
      this.updateSubmitDisabledSubscription = undefined;
    }

    this.updateSubmitDisabledSubscription = combineLatest(this.loading$, this.submitLoading$)
      .pipe(untilDestroyed(this))
      .subscribe(([loading, submitLoading]) => {
        const value = !state.configured || loading || submitLoading || state.valueError;
        this.submitDisabled$.next(value);
      });
  }

  submit() {
    if (this.submitDisabled$.value || !this.elementState.element || !this.elementState.element.submitAction) {
      return;
    }

    this.submitLoading$.next(true);
    this.cd.markForCheck();

    return this.actionControllerService
      .execute(this.elementState.element.submitAction, {
        context: this.context,
        contextElement: this.viewContextElement,
        saveResultTo: SUBMIT_RESULT_OUTPUT,
        injector: this.injector
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.submitLoading$.next(false);
          this.cd.markForCheck();
        },
        () => {
          this.submitLoading$.next(false);
          this.cd.markForCheck();
        }
      );
  }

  dragDrop(event: AppDragDrop<ElementItem[] | CustomizeBarItem[]>) {
    const item = event.previousContainer.data[event.previousIndex];
    const barItem = item instanceof ElementItem ? undefined : (item as CustomizeBarItem);

    if (barItem && barItem.popup) {
      if (this.customizeService.handler && this.customizeService.handler.createPopup) {
        this.customizeService.handler.createPopup(true, {
          ...(barItem.defaultParams && {
            width: barItem.defaultParams['width'],
            style: barItem.defaultParams['style'],
            position: barItem.defaultParams['position']
          }),
          analyticsSource: 'components_library'
        });
        this.elementContainerService.sendAddPopupAnalytics();
      }

      return;
    }

    const siblingLeftEntered = event.data ? !!event.data['siblingLeftEntered'] : false;
    const siblingRightEntered = event.data ? !!event.data['siblingRightEntered'] : false;
    const siblingSelf = event.data ? !!event.data['siblingSelf'] : false;
    const siblingAnchor: ElementItem = event.data ? event.data['siblingAnchor'] : undefined;
    const siblingAnchorContainer: AppDropList = event.data ? event.data['siblingAnchorContainer'] : undefined;

    if (siblingLeftEntered || siblingRightEntered) {
      const anchorContainer: ElementItem[] = siblingSelf ? event.container.data : siblingAnchorContainer.data;
      const anchorIndex = siblingSelf ? event.currentIndex : anchorContainer.indexOf(siblingAnchor);

      this.elementContainerService.dragDropIntoSiblingColumn({
        sourceContainer: event.previousContainer.data as (ElementItem | CustomizeBarItem)[],
        sourceIndex: event.previousIndex,
        sourceCloneItem: event.previousContainer.cloneItems,
        anchorContainer: anchorContainer,
        anchorIndex: anchorIndex,
        anchorSelf: siblingSelf,
        left: siblingLeftEntered,
        context: this.context,
        parent: this.element
      });

      // TODO: Implement onAdd
      // this.cd.detectChanges();
      // const component = this.elementComponents.find(i => i.element === elementItem);
      // this.onAdd(elementItem, item, component);
    } else {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data as ElementItem[], event.previousIndex, event.currentIndex);
      } else if (event.previousContainer.cloneItems) {
        const elementItem = this.elementContainerService.copyElementItem(
          event.previousContainer.data as CustomizeBarItem[],
          event.container.data as ElementItem[],
          event.previousIndex,
          event.currentIndex,
          this.context
        );
        this.customizeService.registerCreatedElement(elementItem, barItem);
      } else {
        transferArrayItem(
          event.previousContainer.data as ElementItem[],
          event.container.data as ElementItem[],
          event.previousIndex,
          event.currentIndex
        );
      }
    }

    this.customizeService.markChanged();
    this.updateElementStatesOnStable();
  }

  duplicateItem(index: number) {
    const elementItem = this.elementContainerService.duplicateElementItem(this.element.children, this.context, index);
    this.cd.detectChanges();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable();

    const component = this.elementComponents.find(i => i.element === elementItem);

    if (component) {
      component.customize();
    }
  }

  replaceItem(index: number, elements: ElementItem[]) {
    this.elementContainerService.replaceElementItem(this.element.children, index, elements);
    this.cd.markForCheck();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable();
  }

  deleteItem(element: ElementItem) {
    const index = this.element.children.findIndex(item => item === element);

    if (index == -1) {
      return;
    }

    this.elementContainerService.deleteElementItem(this.element.children, index);
    this.cd.markForCheck();
    this.customizeService.markChanged();
    this.updateElementStatesOnStable();
  }

  moveItemTo(element: ElementItem, link: any[]) {
    this.deleteItem(element);

    this.customizeService.stopTrackChanges();
    this.customizeService
      .saveActualChanges()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.routing.navigateApp(link));
  }

  updateElementStatesOnStable() {
    if (this.elementGroupsContainer) {
      this.elementGroupsContainer.updateElementStatesOnStable();
    }
  }

  updateElementStates() {
    if (this.elementGroupsContainer) {
      this.elementGroupsContainer.updateElementStates();
    }
  }
}

registerElementComponent({
  type: ElementType.Form,
  component: FormElementComponent,
  alwaysActive: false,
  label: 'Form',
  actions: []
});
