import { Inject, Injectable, InjectionToken, Injector, OnDestroy, Optional, Type } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import defaults from 'lodash/defaults';
import isArray from 'lodash/isArray';
import values from 'lodash/values';
import { combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { NotificationType } from '@common/notifications';
import { ActionDescriptionService, ActionService, ActionStore, OutputsInfo } from '@modules/action-queries';
import {
  ActionDescription,
  ActionItem,
  ActionType,
  Approve,
  ClosePopupAction,
  CopyToClipboardAction,
  DownloadAction,
  DownloadActionType,
  ExportAction,
  ExportDataType,
  ImportAction,
  LinkAction,
  NotificationAction,
  OpenActionMenuAction,
  OpenPopupAction,
  QueryAction,
  RunJavaScriptAction,
  Segue,
  SegueType,
  SetPropertyAction,
  TintStyle,
  ViewSettingsAction,
  WorkflowAction
} from '@modules/actions';
import {
  ActionElementStyles,
  CustomizeService,
  CustomViewSettings,
  Margin,
  PopupSettings,
  RawListViewSettingsColumn,
  ViewContext,
  ViewContextElement,
  ViewSettings,
  ViewSettingsService,
  ViewSettingsStore,
  ViewSettingsType
} from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { ActionTextStyleGlobalParams } from '@modules/customize-shared';
import { DataSourceType } from '@modules/data-sources';
import { CustomSelectItem, Option } from '@modules/field-components';
import {
  FieldOutput,
  Input,
  Input as FieldInput,
  InputValueType,
  isInputSet,
  isRequiredInputsSet,
  ParameterArray,
  ParameterField
} from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription, PER_PAGE_PARAM } from '@modules/models';
import {
  FieldInputControl,
  InputFieldProvider,
  inputFieldProviderItemsFromModelGet,
  inputFieldProviderItemsFromModelGetDetail,
  modelDescriptionHasAutoParameters,
  parametersToProviderItems,
  parametersToProviderItemsFlat
} from '@modules/parameters';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  getResourceTypeItemRequestName,
  Resource,
  ResourceType
} from '@modules/projects';
import {
  ActionQuery,
  editableQueryTypes,
  HttpQuery,
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  Query,
  QueryService,
  QueryType
} from '@modules/queries';
import { isResourceCustom, ResourceControllerService, RestAPIResourceParams } from '@modules/resources';
import { Storage } from '@modules/storages';
import { ascComparator, controlValid, controlValue, isSet, splitmax } from '@shared';

import { DataSourceControl } from '../model-description-data-source-edit/data-source.control';
import { ListModelDescriptionDataSourceControl } from '../model-description-data-source-edit/list-model-description-data-source';
import { ActionElementStylesControl } from '../styles-action-element-edit/action-element-styles.control';
import { ConfirmationControl } from './confirmation.control';

export interface ActionOption {
  queryType: QueryType;
  action?: string;
}

export interface ModelDescriptionInContext {
  modelDescription: ModelDescription;
  fieldToken: (field: string) => string[];
}

export interface CustomizeActionEditOptions {
  actionItemClass?: Type<ActionItem>;
  titleEditable?: boolean;
  titleCleanValue?: (value: string) => string;
  modelDescriptionInContext?: ModelDescriptionInContext;
  parametersEditable?: boolean;
  nameEditable?: boolean;
  iconEditable?: boolean;
  styleEditable?: boolean;
  colorsEditable?: boolean;
  approveEnabled?: boolean;
  confirmationEnabled?: boolean;
  completionEditable?: boolean;
  disabledEditable?: boolean;
  visibleEditable?: boolean;
  tooltipEditable?: boolean;
  elementStylesEditable?: boolean;
  elementStylesMarginEditable?: boolean;
  dataSourceControl?: DataSourceControl;
}

export interface CustomizeActionOptions extends CustomizeActionEditOptions {
  actionItem?: ActionItem;
  actionLabel?: string;
  title?: string;
  visibleInput?: FieldInput;
  tooltip?: string;
  elementStyles?: ActionElementStyles;
  context?: ViewContext;
  typesOnly?: ActionType[];
  javascriptDependencies?: boolean;
  originEnabled?: boolean;
}

export interface CustomizeActionResult {
  action: ActionItem;
  visibleInput?: FieldInput;
  title?: string;
  tooltip?: string;
  elementStyles?: ActionElementStyles;
}

export const validateResource: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['type'].value;
  const downloadActionType = control.parent.controls['download_action_type'].value;
  const downloadQueryAction = type == ActionType.Download && downloadActionType == DownloadActionType.Query;

  if (type != ActionType.Query && !downloadQueryAction) {
    return;
  }

  if (!isSet(control.value)) {
    return { required: true };
  }
};

export const validateQuery: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['type'].value;
  const downloadActionType = control.parent.controls['download_action_type'].value;
  const downloadQueryAction = type == ActionType.Download && downloadActionType == DownloadActionType.Query;

  if (type != ActionType.Query && !downloadQueryAction) {
    return;
  }

  const query = control.value as ActionQuery;

  if (!query || !query.isConfigured()) {
    return { required: true };
  }
};

export const validateLink: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['type'].value;

  if (type != ActionType.Link) {
    return;
  }

  const value = control.value as Segue;

  if (!value || ![SegueType.PreviousPage, SegueType.Page, SegueType.ModelChange].includes(value.type)) {
    return { required: true };
  }
};

export const validateInputs: ValidatorFn = control => {
  const parent = control.parent as CustomizeBarActionEditForm;
  if (!parent) {
    return;
  }

  const type = (control.parent as CustomizeBarActionEditForm).value['type'];
  const downloadActionType = control.parent.controls['download_action_type'].value;
  const downloadQueryAction = type == ActionType.Download && downloadActionType == DownloadActionType.Query;

  if (type != ActionType.Query && !downloadQueryAction) {
    return;
  }

  if (
    ![ActionType.Query, ActionType.Link, ActionType.ExternalLink, ActionType.Export].includes(type) &&
    !downloadQueryAction
  ) {
    return;
  }

  const fields = parent.inputFieldProvider.getFields();
  const inputs: Input[] = control.value;

  if (!isRequiredInputsSet(fields, inputs)) {
    return { required: true };
  }
};

export const validateDownloadActionType: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['type'].value;

  if (type != ActionType.Download) {
    return;
  }

  if (!isSet(control.value)) {
    return { required: true };
  }
};

export const validateDownloadActionInput: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['type'].value;
  const downloadActionType = control.parent.controls['download_action_type'].value;

  if (type != ActionType.Download || downloadActionType != DownloadActionType.Input) {
    return;
  }

  if (!isInputSet(control.value)) {
    return { required: true };
  }
};

export const validateElementAction: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['type'].value;

  if (type != ActionType.ElementAction) {
    return;
  }

  if (!isSet(control.value)) {
    return { required: true };
  }
};

export const validateNotificationActionTitle: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['type'].value;

  if (type != ActionType.ShowNotification) {
    return;
  }

  if (!isInputSet(control.value)) {
    return { required: true };
  }
};

export const validateSetPropertyActionRequired: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['type'].value;

  if (type != ActionType.SetProperty) {
    return;
  }

  if (!isSet(control.value)) {
    return { required: true };
  }
};

export const validateRunJavascriptActionRequired: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['type'].value;

  if (type != ActionType.RunJavaScript) {
    return;
  }

  if (!isSet(control.value)) {
    return { required: true };
  }
};

export const validateSetPropertyActionInput: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['type'].value;

  if (type != ActionType.SetProperty) {
    return;
  }

  if (!isInputSet(control.value)) {
    return { required: true };
  }
};

export const validateCopyToClipboardActionTitle: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const type = control.parent.controls['type'].value;

  if (type != ActionType.CopyToClipboard) {
    return;
  }

  if (!isInputSet(control.value)) {
    return { required: true };
  }
};

export const validateActions: AsyncValidatorFn = control => {
  const parent = control.parent as CustomizeBarActionEditForm;

  if (!parent) {
    return of(null);
  }

  if (!control.value || !control.value.length) {
    return of(null);
  }

  return combineLatest(control.value.map(item => parent.elementConfigurationService.isActionConfigured(item))).pipe(
    map(result => {
      if (result.some(configured => !configured)) {
        return { required: true };
      }
    })
  );
};

export const CustomizeBarActionEditFormDefaultType = new InjectionToken<any>('CustomizeBarActionEditFormDefaultType');

export const CustomizeBarActionEditFormValidatorOrOpts = new InjectionToken<any>(
  'CustomizeBarActionEditFormValidatorOrOpts'
);

export const CustomizeBarActionEditFormAsyncValidator = new InjectionToken<any>(
  'CustomizeBarActionEditFormAsyncValidator'
);

export const CustomizeBarActionEditFormGetQueries = new InjectionToken<any>('CustomizeBarActionEditFormGetQueries');

export const OPENED_MODAL_VALUE = '__OPENED_MODAL__';

const defaultVerboseName = {
  path: ['value'],
  value_type: InputValueType.StaticValue,
  static_value: 'Button'
};

@Injectable()
export class CustomizeBarActionEditForm extends FormGroup implements OnDestroy {
  queryClass = ActionQuery;
  options: CustomizeActionOptions = {};
  silentChanges = new Subject();
  valueChangesWithSilent = merge(this.valueChanges, this.silentChanges);
  inputFieldProvider = new InputFieldProvider();

  controls: {
    title: FormControl;
    icon: FormControl;
    type: FormControl;
    resource: FormControl;
    model: FormControl;
    action: FormControl;
    action_params: ParameterArray;
    outputs: ParameterArray;
    array_output: FormControl;
    verbose_name: FieldInputControl;
    query: FormControl;
    link: FormControl;
    inputs: FormControl;
    approve_enabled: FormControl;
    approve: FormControl;
    style: FormControl;
    tint: FormControl;
    disabled_input: FieldInputControl;
    visible_input: FieldInputControl;
    tooltip: FormControl;
    new_tab: FormControl;
    new_tab_custom: FieldInputControl;
    on_success_notification: FormControl;
    on_success_actions: FormControl;
    on_error_notification: FormControl;
    on_error_actions: FormControl;
    download_action_type: FormControl;
    download_action_file_column: FormControl;
    download_action_input: FieldInputControl;
    element_action: FormControl;
    notification_action_title: FieldInputControl;
    notification_action_description: FieldInputControl;
    notification_action_icon: FormControl;
    notification_action_type: FormControl;
    notification_action_color_enabled: FormControl;
    notification_action_color: FormControl;
    notification_action_close_timeout_enabled: FormControl;
    notification_action_close_timeout: FormControl;
    set_property_action_property: FormControl;
    set_property_action_value: FieldInputControl;
    run_javascript: FormControl;
    copy_to_clipboard_action_value: FieldInputControl;
    export_data_type: FormControl;
    export_data_source: ListModelDescriptionDataSourceControl;
    export_sorting_field: FormControl;
    export_sorting_asc: FormControl;
    // export_ids: FieldInputControl;
    export_per_page: FieldInputControl;
    open_popup: FormControl;
    open_popup_close_other: FormControl;
    open_popup_toggle_popup: FormControl;
    close_popup: FormControl;
    open_action_menu_actions: FormControl;
    workflow: FormControl;
    confirmation_enabled: FormControl;
    confirmation: ConfirmationControl;

    element_styles: ActionElementStylesControl;
  };

  pageParametersControl = new ParameterArray([]);
  popupParametersControl = new ParameterArray([]);

  allTypeOptions = [
    { value: ActionType.Query, name: 'Run Operation', icon: 'cloud_upload' },
    { value: ActionType.Workflow, name: 'Run Workflow', icon: 'workflow' },
    { value: ActionType.Link, name: 'Navigate to Page', icon: 'redo' },
    { value: ActionType.ExternalLink, name: 'Open URL', icon: 'model_link' },
    { value: ActionType.OpenPopup, name: 'Open Overlay', icon: 'copy' },
    { value: ActionType.ClosePopup, name: 'Close Overlay', icon: 'windows' },
    { value: ActionType.OpenActionMenu, name: 'Open Actions dropdown', icon: 'fileds', originRequired: true },
    { value: ActionType.ElementAction, name: 'Run Component action', icon: 'components' },
    { value: ActionType.ShowNotification, name: 'Show Notification', icon: 'notification' },
    { value: ActionType.SetProperty, name: 'Set Variable', icon: 'variable' },
    { value: ActionType.RunJavaScript, name: 'Run JavaScript', icon: 'console' },
    { value: ActionType.CopyToClipboard, name: 'Copy to Clipboard', icon: 'documents' },
    { value: ActionType.Export, name: 'Export Data', icon: 'download' },
    { value: ActionType.Import, name: 'Import Data', icon: 'upload' },
    { value: ActionType.Download, name: 'Download File', icon: 'save' },
    { value: ActionType.ScanCode, name: 'Scan QR/Bar code', icon: 'qr_code' }
  ].map(item => {
    return {
      ...item,
      attrs: { 'data-action-type': item.value }
    };
  });

  notificationTypeOptions = [
    { value: NotificationType.Success, name: 'Success' },
    { value: NotificationType.Info, name: 'Info' },
    { value: NotificationType.Warning, name: 'Warning' },
    { value: NotificationType.Error, name: 'Error' }
  ];

  notificationCloseTimeoutEnabledOptions = [
    { value: true, name: 'After time (seconds)' },
    { value: false, name: 'By click' }
  ];

  styleOptions = [
    {
      value: TintStyle.Primary,
      image: 'button-primary'
    },
    {
      value: TintStyle.Default,
      image: 'button-default'
    },
    {
      value: TintStyle.Transparent,
      image: 'button-transparent'
    }
  ];

  newTabOptions = [
    { value: true, name: 'Yes' },
    { value: false, name: 'No' },
    { value: 'custom', name: 'Custom' }
  ];

  constructor(
    private formUtils: FormUtils,
    private resourceControllerService: ResourceControllerService,
    private actionService: ActionService,
    private queryService: QueryService,
    public elementConfigurationService: ElementConfigurationService,
    private actionDescriptionService: ActionDescriptionService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private actionStore: ActionStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsStore: ViewSettingsStore,
    private exportDataSourceControl: ListModelDescriptionDataSourceControl,
    private customizeService: CustomizeService,
    private injector: Injector,
    @Optional()
    @Inject(CustomizeBarActionEditFormDefaultType)
    defaultType?: ActionType,
    @Optional()
    @Inject(CustomizeBarActionEditFormValidatorOrOpts)
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    @Optional()
    @Inject(CustomizeBarActionEditFormAsyncValidator)
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    @Optional()
    @Inject(CustomizeBarActionEditFormGetQueries)
    private getQueries?: boolean
  ) {
    super(
      (() => {
        const controls = {
          title: new FormControl(undefined),
          icon: new FormControl(null),
          type: new FormControl(defaultType, Validators.required),
          resource: new FormControl('', validateResource),
          model: new FormControl(''),
          action: new FormControl(''),
          action_params: new ParameterArray([]),
          outputs: new FormControl([]),
          array_output: new FormControl(false),
          verbose_name: new FieldInputControl(defaultVerboseName),
          query: new FormControl(undefined, validateQuery),
          link: new FormControl('', validateLink),
          inputs: new FormControl([], validateInputs),
          approve_enabled: new FormControl(false),
          approve: new FormControl(null),
          style: new FormControl(TintStyle.Primary),
          tint: new FormControl(null),
          disabled_input: new FieldInputControl({ path: ['value'] }),
          visible_input: new FieldInputControl({ path: ['value'] }),
          tooltip: new FormControl(''),
          new_tab: new FormControl(false),
          new_tab_custom: new FieldInputControl({ path: ['value'] }),
          on_success_notification: new FormControl(false),
          on_success_actions: new FormControl([], undefined, validateActions),
          on_error_actions: new FormControl([], undefined, validateActions),
          on_error_notification: new FormControl(false),
          download_action_type: new FormControl(null, validateDownloadActionType),
          download_action_file_column: new FormControl(null),
          download_action_input: new FieldInputControl({ path: ['value'] }, validateDownloadActionInput),
          element_action: new FormControl(null, validateElementAction),
          notification_action_title: new FieldInputControl({ path: ['value'] }, validateNotificationActionTitle),
          notification_action_description: new FieldInputControl({ path: ['value'] }),
          notification_action_icon: new FormControl(''),
          notification_action_type: new FormControl(NotificationType.Info),
          notification_action_color_enabled: new FormControl(false),
          notification_action_color: new FormControl('#7640f5'),
          notification_action_close_timeout_enabled: new FormControl(true),
          notification_action_close_timeout: new FormControl(undefined),
          set_property_action_property: new FormControl(undefined, validateSetPropertyActionRequired),
          set_property_action_value: new FieldInputControl({ path: ['value'] }),
          run_javascript: new FormControl(undefined, validateRunJavascriptActionRequired),
          copy_to_clipboard_action_value: new FieldInputControl(
            { path: ['value'] },
            validateCopyToClipboardActionTitle
          ),
          export_data_type: new FormControl(ExportDataType.DataSource),
          export_data_source: exportDataSourceControl,
          export_sorting_field: new FormControl(undefined),
          export_sorting_asc: new FormControl(true),
          // export_ids: new FieldInputControl({ path: ['value'] }),
          export_per_page: new FieldInputControl({ path: ['value'] }),
          open_popup: new FormControl(undefined),
          open_popup_close_other: new FormControl(true),
          open_popup_toggle_popup: new FormControl(false),
          close_popup: new FormControl(OPENED_MODAL_VALUE),
          open_action_menu_actions: new FormControl([]),
          workflow: new FormControl(undefined),
          confirmation_enabled: new FormControl(false),
          confirmation: new ConfirmationControl()
        };

        controls['element_styles'] = new ActionElementStylesControl(injector, {
          textStyleGlobalParams: controlValue(controls.style).pipe(
            map(style => {
              return { tint: style } as ActionTextStyleGlobalParams;
            })
          )
        });

        return controls;
      })(),
      validatorOrOpts,
      asyncValidator
    );

    controlValue<ActionType>(this.controls.type)
      .pipe(distinctUntilChanged())
      .subscribe(type => {
        this.updateValidators(type);
      });

    this.controls.export_data_source.controls.columns.clearValidators();

    this.controls.type.valueChanges.pipe(delay(0)).subscribe(() => {
      this.controls.resource.updateValueAndValidity();
      this.controls.query.updateValueAndValidity();
      this.controls.link.updateValueAndValidity();
      this.controls.inputs.updateValueAndValidity();
      this.controls.element_action.updateValueAndValidity();
      this.controls.notification_action_title.updateValueAndValidity();
      this.controls.set_property_action_property.updateValueAndValidity();
      this.controls.set_property_action_value.updateValueAndValidity();
      this.controls.run_javascript.updateValueAndValidity();
      this.controls.copy_to_clipboard_action_value.updateValueAndValidity();
    });
    this.inputFieldProvider.getFields$().subscribe(() => {
      this.controls.inputs.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.inputFieldProvider.clearProvider();
  }

  updateValidators(type: ActionType) {
    this.controls.export_data_source.setRequired(type == ActionType.Export);

    if (type == ActionType.Import) {
      this.controls.model.setValidators(Validators.required);
    } else {
      this.controls.model.clearValidators();
    }
  }

  initObservers() {
    this.controls.resource.valueChanges.subscribe(value => this.onResourceChange(value));

    combineLatest(
      controlValue<ActionType>(this.controls.type),
      controlValue<string>(this.controls.resource),
      this.controls.action.valueChanges
    )
      .pipe(debounceTime(0))
      .subscribe(([type, resourceName, action]) => {
        if ([ActionType.Query, ActionType.Download].includes(type)) {
          this.onActionChange(resourceName, action);
        } else {
          this.onActionChange(undefined, undefined);
        }
      });

    this.controls.action_params.valueChanges.subscribe(value => this.onParametersChange());

    this.controls.query.valueChanges.subscribe(value => this.onQueryChange());

    this.controls.type.valueChanges.subscribe(value => this.onTypeChange());

    this.controls.link.valueChanges.subscribe(value => this.onLinkChange());

    this.controls.element_action.valueChanges.subscribe(value => this.onElementActionChange());

    this.controls.workflow.valueChanges.subscribe(value => this.onWorkflowChange());

    this.pageParametersControl.valueChanges.subscribe(value => this.onPageParametersChange());

    this.popupParametersControl.valueChanges.subscribe(value => this.onOpenPopupParametersChange());

    this.pageParametersControl.valueChanges
      .pipe(debounceTime(200))
      .subscribe(value => this.onPageParametersChangeDebounce(value));

    this.popupParametersControl.valueChanges
      .pipe(debounceTime(200))
      .subscribe(value => this.onPopupParametersChangeDebounce(value));

    this.controls.type.valueChanges.subscribe(type => {
      const onSuccessActions: ActionItem[] = this.controls.on_success_actions.value;
      const onErrorActions: ActionItem[] = this.controls.on_error_actions.value;

      if (
        [ActionType.Query, ActionType.Download, ActionType.Workflow].includes(type) &&
        !onSuccessActions.length &&
        !this.controls.on_success_notification.value
      ) {
        this.controls.on_success_notification.patchValue(true);
      } else if (!onSuccessActions.length && this.controls.on_success_notification.value) {
        this.controls.on_success_notification.patchValue(false);
      }

      if (
        [ActionType.Query, ActionType.Download, ActionType.Workflow].includes(type) &&
        !onErrorActions.length &&
        !this.controls.on_error_notification.value
      ) {
        this.controls.on_error_notification.patchValue(true);
      } else if (!onErrorActions.length && this.controls.on_error_notification.value) {
        this.controls.on_error_notification.patchValue(false);
      }
    });
  }

  init(options: CustomizeActionOptions, firstInit = false) {
    options = defaults(options, { actionItemClass: ActionItem });

    this.options = options;

    this.actionPatchValue(options.actionItem, { emitEvent: false }, options);

    if (options.titleEditable) {
      this.patchValue(
        {
          title: options.title
        },
        { emitEvent: false }
      );
    }

    if (options.visibleEditable) {
      this.patchValue(
        {
          visible_input: options.visibleInput ? options.visibleInput.serializeWithoutPath() : {}
        },
        { emitEvent: false }
      );
    }

    if (options.tooltipEditable) {
      this.patchValue(
        {
          tooltip: options.tooltip
        },
        { emitEvent: false }
      );
    }

    if (options.elementStylesEditable && options.elementStyles) {
      this.controls.element_styles.deserialize(options.elementStyles);
    }

    this.initObservers();
    this.updateInputFieldProvider().subscribe();

    if (!firstInit) {
      this.markAsDirty();
    }

    if (options.titleEditable) {
      this.controls.verbose_name.valueChanges.subscribe(value => {
        const input = value ? new FieldInput().deserialize(value) : undefined;

        if (!input || input.valueType != InputValueType.StaticValue) {
          return;
        }

        let title = input.staticValue;

        if (options.titleCleanValue) {
          title = options.titleCleanValue(title);
        }

        this.controls.title.patchValue(title);
      });
    }

    this.linkPage$().subscribe(page => {
      if (page) {
        this.pageParametersControl.patchValue(page.parameters, { emitEvent: false });
      }
    });

    this.openPopup$().subscribe(popup => {
      if (popup) {
        this.popupParametersControl.patchValue(popup.parameters, { emitEvent: false });
      }
    });
  }

  actionPatchValue(
    actionItem?: ActionItem,
    patchOptions?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
    options: {
      parametersEditable?: boolean;
      nameEditable?: boolean;
      iconEditable?: boolean;
      colorsEditable?: boolean;
      approveEnabled?: boolean;
      confirmationEnabled?: boolean;
      completionEditable?: boolean;
      disabledEditable?: boolean;
    } = {}
  ) {
    let value = {};

    if (actionItem) {
      this.actionService.getActionDescription(actionItem).subscribe(actionDescription => {
        const sharedActionDescription = actionItem.sharedActionDescription
          ? splitmax(actionItem.sharedActionDescription, '.', 2)[1]
          : undefined;
        let action: ActionOption;
        let query: ActionQuery;

        if (
          actionDescription &&
          actionDescription.type == ActionType.Query &&
          actionDescription.queryAction &&
          actionDescription.queryAction.query
        ) {
          action = {
            queryType: sharedActionDescription ? QueryType.Simple : actionDescription.queryAction.query.queryType,
            action: sharedActionDescription
          };
          query = actionDescription.queryAction.query;
        } else if (
          actionDescription &&
          actionDescription.type == ActionType.Download &&
          actionDescription.downloadAction &&
          actionDescription.downloadAction.query
        ) {
          action = {
            queryType: sharedActionDescription ? QueryType.Simple : actionDescription.downloadAction.query.queryType,
            action: sharedActionDescription
          };
          query = actionDescription.downloadAction.query;
        }

        if (actionDescription) {
          const exportSort =
            actionDescription.exportAction &&
            actionDescription.exportAction.sort &&
            actionDescription.exportAction.sort.length
              ? actionDescription.exportAction.sort[0]
              : undefined;

          value = {
            type: actionDescription.type,
            resource: actionDescription.resource,
            action: action,
            outputs: actionDescription.outputs,
            array_output: actionDescription.arrayOutput,
            query: query,
            link: actionDescription.linkAction
              ? {
                  type: actionDescription.linkAction.type,
                  page: actionDescription.linkAction.page,
                  model: actionDescription.linkAction.model
                }
              : undefined,
            inputs: actionItem.inputs,
            element_action: actionDescription.elementAction,
            download_action_type: actionDescription.downloadAction
              ? actionDescription.downloadAction.type
              : DownloadActionType.Query,
            download_action_file_column: actionDescription.downloadAction
              ? actionDescription.downloadAction.fileColumn
              : undefined,
            download_action_input:
              actionDescription.downloadAction && actionDescription.downloadAction.input
                ? actionDescription.downloadAction.input.serializeWithoutPath()
                : {},
            notification_action_title:
              actionDescription.notificationAction && actionDescription.notificationAction.title
                ? actionDescription.notificationAction.title.serializeWithoutPath()
                : {},
            notification_action_description:
              actionDescription.notificationAction && actionDescription.notificationAction.description
                ? actionDescription.notificationAction.description.serializeWithoutPath()
                : {},
            notification_action_icon:
              actionDescription.notificationAction && actionDescription.notificationAction.icon
                ? actionDescription.notificationAction.icon
                : '',
            notification_action_type: actionDescription.notificationAction
              ? actionDescription.notificationAction.type
              : NotificationType.Info,
            notification_action_color_enabled:
              actionDescription.notificationAction && isSet(actionDescription.notificationAction.color),
            notification_action_color:
              actionDescription.notificationAction && isSet(actionDescription.notificationAction.color)
                ? actionDescription.notificationAction.color
                : '#7640f5',
            notification_action_close_timeout_enabled: actionDescription.notificationAction
              ? actionDescription.notificationAction.closeTimeoutEnabled
              : true,
            notification_action_close_timeout: actionDescription.notificationAction
              ? actionDescription.notificationAction.closeTimeout
              : undefined,
            export_data_type: actionDescription.exportAction
              ? actionDescription.exportAction.dataType
              : ExportDataType.DataSource,
            ...(exportSort &&
              isSet(exportSort.field) && {
                export_sorting_field: exportSort.field,
                export_sorting_asc: !exportSort.desc
              }),
            set_property_action_property: actionDescription.setPropertyAction
              ? actionDescription.setPropertyAction.property
              : undefined,
            set_property_action_value:
              actionDescription.setPropertyAction && actionDescription.setPropertyAction.value
                ? actionDescription.setPropertyAction.value.serializeWithoutPath()
                : {},
            run_javascript: actionDescription.runJavaScriptAction
              ? actionDescription.runJavaScriptAction.js
              : undefined,
            copy_to_clipboard_action_value:
              actionDescription.copyToClipboardAction && actionDescription.copyToClipboardAction.value
                ? actionDescription.copyToClipboardAction.value.serializeWithoutPath()
                : {},
            open_popup: actionDescription.openPopupAction ? actionDescription.openPopupAction.popup : undefined,
            open_popup_close_other: actionDescription.openPopupAction
              ? actionDescription.openPopupAction.closeOther
              : true,
            open_popup_toggle_popup: actionDescription.openPopupAction
              ? actionDescription.openPopupAction.togglePopup
              : false,
            close_popup:
              actionDescription.closePopupAction && actionDescription.closePopupAction.popup
                ? actionDescription.closePopupAction.popup
                : OPENED_MODAL_VALUE,
            open_action_menu_actions: actionDescription.openActionMenuAction
              ? actionDescription.openActionMenuAction.actions
              : [],
            workflow: actionDescription.workflowAction ? actionDescription.workflowAction.workflow : undefined
          };

          if ([ActionType.Link, ActionType.ExternalLink].includes(actionDescription.type)) {
            const newTabInput = actionItem.inputs.find(item => item.isName('new_tab'));

            value['inputs'] = value['inputs'].filter(item => !item.isName('new_tab'));

            if (newTabInput) {
              if (newTabInput.valueType === InputValueType.StaticValue && newTabInput.staticValue === true) {
                value['new_tab'] = true;
                value['new_tab_custom'] = {};
              } else if (newTabInput.valueType === InputValueType.StaticValue && newTabInput.staticValue === false) {
                value['new_tab'] = false;
                value['new_tab_custom'] = {};
              } else {
                value['new_tab'] = 'custom';
                value['new_tab_custom'] = newTabInput.serializeWithoutPath();
              }
            }
          } else if (actionDescription.type == ActionType.Export) {
            // const idsInput = actionItem.inputs.find(item => item.name === 'ids');
            const perPageInput = actionItem.inputs.find(item => item.isName(PER_PAGE_PARAM));

            value['inputs'] = value['inputs'].filter(item => [PER_PAGE_PARAM].some(param => item.isName(param)));

            // if (idsInput) {
            //   value['export_ids'] = idsInput.serialize();
            // }

            if (perPageInput) {
              value['export_per_page'] = perPageInput.serializeWithoutPath();
            }
          } else if (actionDescription.type == ActionType.Import) {
            const modelId =
              actionDescription.importAction &&
              isSet(actionDescription.importAction.resource) &&
              isSet(actionDescription.importAction.model)
                ? [actionDescription.importAction.resource, actionDescription.importAction.model].join('.')
                : undefined;
            value['model'] = modelId ? { model: modelId } : undefined;
          }
        } else {
          value = {
            type: undefined,
            resource: undefined,
            model: undefined,
            action: action,
            action_params: [],
            outputs: [],
            query: undefined,
            link: '',
            href: '',
            inputs: actionItem.inputs,
            new_tab_custom: {},
            // export_ids: {},
            export_per_page: {},
            download_action_type: DownloadActionType.Query,
            download_action_file_column: undefined,
            download_action_input: {},
            element_action: undefined
          };
        }

        if (options.nameEditable) {
          value['verbose_name'] = actionItem.verboseNameInput
            ? actionItem.verboseNameInput.serializeWithoutPath()
            : defaultVerboseName;
        }

        if (options.approveEnabled) {
          value['approve_enabled'] = !!actionItem.approve;
          value['approve'] = actionItem.approve;
        }

        if (options.confirmationEnabled) {
          value['confirmation_enabled'] = !!actionItem.confirmation;
        }

        if (options.completionEditable) {
          value['on_success_notification'] = actionItem.onSuccessNotification;
          value['on_success_actions'] = actionItem.onSuccessActions;
          value['on_error_notification'] = actionItem.onErrorNotification;
          value['on_error_actions'] = actionItem.onErrorActions;
        }

        if (actionItem instanceof ViewSettingsAction) {
          if (options.iconEditable) {
            value['icon'] = actionItem.icon;
          }

          if (options.colorsEditable) {
            value['style'] = actionItem.style;
            value['tint'] = actionItem.tint;
          }

          if (options.disabledEditable) {
            this.patchValue(
              {
                disabled_input: actionItem.disabledInput ? actionItem.disabledInput.serializeWithoutPath() : {}
              },
              { emitEvent: false }
            );
          }
        }

        this.updateValidators(actionDescription ? actionDescription.type : undefined);

        const exportActionDataSource =
          actionDescription && actionDescription.exportAction ? actionDescription.exportAction.dataSource : undefined;
        this.controls.export_data_source.deserialize(exportActionDataSource);

        if (options.confirmationEnabled) {
          this.controls.confirmation.deserialize(actionItem.confirmation);
        }
      });
    }

    this.patchValue(value, patchOptions);

    if (actionItem) {
      if (actionItem.sharedActionDescription) {
        this.setActionParams(this.controls.resource.value, this.controls.action.value, true, patchOptions);
      } else if (actionItem.actionDescription) {
        this.controls.action_params.patchValue(actionItem.actionDescription.actionParams, patchOptions);
      }
    }

    if (!patchOptions.emitEvent) {
      this.silentChanges.next(this.value);
    }
  }

  setAction(action: ActionOption) {
    const query = new ActionQuery();

    query.queryType = action ? action.queryType : undefined;

    if (query.queryType == QueryType.Simple) {
      if (!query.simpleQuery) {
        query.simpleQuery = new query.simpleQueryClass();
      }

      query.simpleQuery.name = action.action;
    }

    this.controls.query.patchValue(query);
    this.controls.inputs.patchValue([]);
  }

  setActionParams(
    resourceName: string,
    action: ActionOption,
    init = false,
    patchOptions?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ) {
    const id = action ? [resourceName, action.action].join('.') : undefined;

    this.actionStore.getDetailFirst(id).subscribe((actionDescription: ActionDescription) => {
      const value = {
        action_params: actionDescription ? actionDescription.actionParams : [],
        inputs: []
      };

      if (actionDescription) {
        if (actionDescription.verboseName) {
          value['verbose_name'] = new Input()
            .deserializeFromStatic('value', actionDescription.verboseName)
            .serializeWithoutPath();
        }

        if (actionDescription.icon) {
          value['icon'] = actionDescription.icon;
        }

        if (
          this.options.modelDescriptionInContext &&
          actionDescription.modelAction &&
          actionDescription.resource == this.options.modelDescriptionInContext.modelDescription.resource &&
          actionDescription.model == this.options.modelDescriptionInContext.modelDescription.model
        ) {
          value.inputs = actionDescription.actionParams
            .filter(item =>
              this.options.modelDescriptionInContext.modelDescription.fields.some(i => i.name == item.name)
            )
            .filter(item => this.options.modelDescriptionInContext.fieldToken(item.name))
            .map(item => {
              const input = new Input();
              input.path = [item.name];
              input.valueType = InputValueType.Context;
              input.contextValue = this.options.modelDescriptionInContext.fieldToken(item.name);
              return input;
            });
        }
      }

      if (init) {
        this.controls.action_params.patchValue(value['action_params'], patchOptions);
      } else {
        this.patchValue(value, patchOptions);
      }
    });
  }

  getResource$(): Observable<Resource> {
    return controlValue<string>(this.controls.resource).pipe(
      map(value => this.currentEnvironmentStore.resources.find(item => item.uniqueName == value))
    );
  }

  getActionDescription$(): Observable<ActionDescription> {
    return combineLatest(
      controlValue<string>(this.controls.resource),
      controlValue<ActionOption>(this.controls.action)
    ).pipe(
      switchMap(([resource, action]) => {
        if (!action || !action.action) {
          return of(undefined);
        }

        return this.actionStore.get().pipe(
          map(actions => {
            if (!actions) {
              return;
            }

            return actions.find(item => item.resource == resource && item.name == action.action);
          })
        );
      })
    );
  }

  getModelDescription$(): Observable<ModelDescription> {
    return this.getActionDescription$().pipe(
      switchMap(actionDescription => {
        if (!actionDescription || !actionDescription.resource || !actionDescription.model) {
          return of(undefined);
        }

        const modelId = [actionDescription.resource, actionDescription.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId);
      })
    );
  }

  updateInputFieldProvider() {
    return combineLatest(
      controlValue<ActionType>(this.controls.type),
      controlValue<ParameterField[]>(this.controls.action_params),
      controlValue<Segue>(this.controls.link),
      controlValue<ParameterField[]>(this.pageParametersControl),
      controlValue<ParameterField[]>(this.popupParametersControl),
      controlValue<DownloadActionType>(this.controls.download_action_type),
      controlValue<(string | number)[]>(this.controls.element_action),
      controlValue<ActionOption>(this.controls.action),
      this.getResource$(),
      this.getActionDescription$(),
      this.getModelDescription$()
    ).pipe(
      first(),
      map(result => {
        const type: ActionType = result[0];
        const actionParams: ParameterField[] = result[1];
        const link: Segue = result[2];
        const pageParameters: ParameterField[] = result[3];
        const popupParameters: ParameterField[] = result[4];
        const downloadType: DownloadActionType = result[5];
        const elementAction: (string | number)[] = result[6];
        const action: ActionOption = result[7];
        const resource: Resource = result[8];
        const actionDescription: ActionDescription = result[9];
        const modelDescription: ModelDescription = result[10];

        if (
          (type == ActionType.Query || (type == ActionType.Download && downloadType == DownloadActionType.Query)) &&
          actionDescription &&
          ['get', 'get_detail'].includes(actionDescription.modelAction)
        ) {
          if (
            actionDescription.modelAction == 'get_detail' &&
            modelDescription &&
            modelDescription.getDetailQuery &&
            !(modelDescriptionHasAutoParameters(resource, modelDescription) && !modelDescription.virtual)
          ) {
            if (modelDescription) {
              const controller = resource ? this.resourceControllerService.get(resource.type) : undefined;
              const modelParameters = controller
                ? controller.getDetailParametersOrDefaults(resource, modelDescription)
                : [];

              return inputFieldProviderItemsFromModelGetDetail(resource, modelParameters, undefined);
            } else {
              return parametersToProviderItems(actionParams);
            }
          } else {
            if (modelDescription) {
              const getQuery = new ListModelDescriptionQuery();

              getQuery.queryType = action ? action.queryType : undefined;

              if (getQuery.queryType == QueryType.Simple) {
                if (!getQuery.simpleQuery) {
                  getQuery.simpleQuery = new getQuery.simpleQueryClass();
                }

                getQuery.simpleQuery.model = actionDescription.model;
              }

              return inputFieldProviderItemsFromModelGet(
                resource,
                modelDescription,
                getQuery,
                [],
                DataSourceType.Query
              );
            } else {
              return parametersToProviderItems(actionParams);
            }
          }
        } else if (type == ActionType.Link) {
          return this.actionDescriptionService.getLinkActionParameters(link, pageParameters);
        } else if (type == ActionType.OpenPopup) {
          return this.actionDescriptionService.getOpenPopupActionParameters(popupParameters);
        } else if (type == ActionType.ExternalLink) {
          return this.actionDescriptionService
            .getExternalLinkActionParameters()
            .filter(item => item.name !== 'new_tab')
            .map(item => {
              return {
                label: item.verboseName || item.name,
                field: {
                  ...item
                },
                defaultValueType: InputValueType.StaticValue
              };
            });
          // } else if (type == ActionType.Export) {
          //   const resource = modelDescription
          //     ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == modelDescription.resource)
          //     : undefined;
          //
          //   return this.actionDescriptionService.getExportActionParameters(resource, modelDescription, actionParams);
        } else if (type == ActionType.ElementAction) {
          if (!this.options.context || !isSet(elementAction)) {
            return [];
          }

          return this.options.context.getElementAction$(elementAction).pipe(
            map(item => {
              if (!item) {
                return [];
              }

              return parametersToProviderItemsFlat(item.parameters);
            })
          );
        } else {
          return parametersToProviderItemsFlat((actionParams || []).filter(item => item.name));
        }
      }),
      tap(items => {
        this.inputFieldProvider.setProvider(items);
      })
    );
  }

  controlsValid$(controls: string[]): Observable<boolean> {
    return combineLatest(controls.map(item => controlValid(this.controls[item]))).pipe(
      map(result => result.every(item => item)),
      debounceTime(60)
    );
  }

  operationTypeValid$(): Observable<boolean> {
    return combineLatest(
      controlValue<ActionType>(this.controls.type),
      controlValue<ExportDataType>(this.controls.export_data_type)
    ).pipe(
      switchMap(([type, exportDataType]) => {
        const controls = ['type'];

        if ([ActionType.Query, ActionType.Download].includes(type)) {
          controls.push('resource');
          controls.push('query');
        } else if (type == ActionType.Link) {
          controls.push('link');
        } else if (type == ActionType.ExternalLink) {
        } else if (type == ActionType.ElementAction) {
          controls.push('element_action');
        } else if (type == ActionType.ShowNotification) {
          controls.push('notification_action_title');
        } else if (type == ActionType.SetProperty) {
          controls.push('set_property_action_property');
          controls.push('set_property_action_value');
        } else if (type == ActionType.RunJavaScript) {
          controls.push('run_javascript');
        } else if (type == ActionType.CopyToClipboard) {
          controls.push('copy_to_clipboard_action_value');
        } else if (type == ActionType.Export) {
          if (exportDataType == ExportDataType.DataSource) {
            controls.push('export_data_source');
          }
        } else if (type == ActionType.Import) {
          controls.push('model');
        } else if (type == ActionType.OpenPopup) {
          controls.push('open_popup');
          // } else if (type == ActionType.ClosePopup) {
        } else if (type == ActionType.OpenActionMenu) {
          controls.push('open_action_menu_actions');
        } else if (type == ActionType.Workflow) {
          controls.push('workflow');
        }

        return this.controlsValid$(controls);
      })
    );
  }

  operationValid$(): Observable<boolean> {
    return combineLatest(this.operationTypeValid$(), this.controlsValid$(['inputs'])).pipe(
      map(items => items.every(item => item))
    );
  }

  actionsValid$(): Observable<boolean> {
    return this.controlsValid$(['on_success_actions', 'on_error_actions']);
  }

  resource$(control: AbstractControl, onlyChanges = false): Observable<Resource> {
    const result = controlValue<string>(control).pipe(
      switchMap(value => {
        return this.currentEnvironmentStore.resources$.pipe(
          map(resources => resources.find(item => item.uniqueName == value))
        );
      })
    );

    if (!onlyChanges) {
      return result;
    }

    return result.pipe(
      distinctUntilChanged((lhs, rhs) => {
        const lhsUniqueName = lhs ? lhs.uniqueName : undefined;
        const rhsUniqueName = rhs ? rhs.uniqueName : undefined;
        return lhsUniqueName == rhsUniqueName;
      })
    );
  }

  modelDescription$(modelControl: AbstractControl): Observable<ModelDescription> {
    return controlValue<{ model: string }>(modelControl).pipe(
      switchMap(modelValue => {
        if (!modelValue || !modelValue.model) {
          return of(undefined);
        }

        return this.modelDescriptionStore.getDetailFirst(modelValue.model);
      })
    );
  }

  actionDescription$(resourceControl: AbstractControl, actionControl: AbstractControl): Observable<ActionDescription> {
    return combineLatest(controlValue<string>(resourceControl), controlValue<ActionOption>(actionControl)).pipe(
      switchMap(([resourceValue, actionValue]) => {
        if (!resourceValue || !actionValue || !actionValue.action) {
          return of(undefined);
        }

        const modelId = [resourceValue, actionValue.action].join('.');
        return this.actionStore.getDetail(modelId);
      })
    );
  }

  resourceBaseHttpQuery$(control: AbstractControl): Observable<HttpQuery> {
    return this.resource$(control).pipe(
      map(resource => {
        if (!resource) {
          return undefined;
        }

        const resourceParams = resource.parseParams<RestAPIResourceParams>(RestAPIResourceParams);
        return resourceParams.baseHttpQuery;
      })
    );
  }

  getCustomActionOption(resource: Resource): Option<ActionOption> {
    if (!isResourceCustom(resource) && resource.type != ResourceType.JetBridge) {
      return;
    }

    const controller = this.resourceControllerService.get(resource.type);
    const queryTypes = controller
      ? controller.supportedQueryTypes(resource.typeItem, ModelDescriptionQuery)
      : undefined;
    const name = `Make ${getResourceTypeItemRequestName(resource.typeItem)}`;

    if (queryTypes.includes(QueryType.Http)) {
      return {
        value: { queryType: QueryType.Http },
        name: name,
        icon: 'plus'
      };
    } else if (queryTypes.includes(QueryType.SQL)) {
      return {
        value: { queryType: QueryType.SQL },
        name: name,
        icon: 'plus'
      };
    } else if (queryTypes.includes(QueryType.Object)) {
      return {
        value: { queryType: QueryType.Object },
        name: name,
        icon: 'plus'
      };
    }
  }

  resourceActionQueryItems$(control: AbstractControl): Observable<CustomSelectItem<ActionOption>[]> {
    return combineLatest(this.resource$(control), this.actionStore.get(), this.modelDescriptionStore.get()).pipe(
      map(([resource, actionDescriptions, modelDescriptions]) => {
        if (!resource) {
          return [];
        }

        const options = [];

        if (actionDescriptions) {
          const groupedOptions: {
            modelDescription?: ModelDescription;
            storage?: Storage;
            labelSort?: string;
            items: CustomSelectItem<ActionOption>[];
          }[] = values(
            actionDescriptions
              .filter(item => item.resource == resource.uniqueName)
              .filter(item => item.type == ActionType.Query)
              .filter(item => this.getQueries || !['get', 'get_detail'].includes(item.modelAction))
              .reduce((acc, actionDescription) => {
                const modelDescription = actionDescription.model
                  ? modelDescriptions.find(
                      item => item.resource == resource.uniqueName && item.model == actionDescription.model
                    )
                  : undefined;
                const storage = actionDescription.storage
                  ? resource.storages.find(item => item.isSame(actionDescription.storage))
                  : undefined;

                if (modelDescription && (!resource.demo || modelDescription.featured)) {
                  const key = `model_${modelDescription.modelId}`;
                  if (!acc[key]) {
                    acc[key] = {
                      modelDescription: modelDescription,
                      labelSort: String(modelDescription.verboseNamePlural || modelDescription.model).toLowerCase(),
                      items: []
                    };
                  }

                  let label = actionDescription.verboseName;
                  let icon: string;

                  if (actionDescription.modelAction == 'get') {
                    label = 'Get Record List';
                    icon = 'documents';
                  } else if (actionDescription.modelAction == 'get_detail') {
                    label = 'Get One Record';
                    icon = 'document';
                  } else if (actionDescription.modelAction == 'create') {
                    label = 'Create Record';
                    icon = 'plus_circle';
                  } else if (actionDescription.modelAction == 'update') {
                    label = 'Update Record';
                    icon = 'edit';
                  } else if (actionDescription.modelAction == 'delete') {
                    label = 'Delete Record';
                    icon = 'bin';
                  }

                  acc[key].items.push({
                    option: {
                      value: { queryType: QueryType.Simple, action: actionDescription.name },
                      name: label,
                      icon: icon
                    },
                    subtitle: 'Actions',
                    valueLabel: actionDescription.verboseName || actionDescription.name,
                    valueIcon: null
                  });
                } else if (storage && !resource.demo) {
                  const key = `storage_${storage.uniqueName}`;
                  if (!acc[key]) {
                    acc[key] = {
                      storage: storage,
                      labelSort: String(storage.name || storage.uniqueName).toLowerCase(),
                      items: []
                    };
                  }

                  let label = actionDescription.verboseName;
                  let icon: string;

                  if (actionDescription.storageAction == 'get_object_url') {
                    label = 'Get File URL';
                    icon = 'external_link';
                  } else if (actionDescription.storageAction == 'upload') {
                    label = 'Upload File';
                    icon = 'cloud_upload';
                  } else if (actionDescription.storageAction == 'get') {
                    label = 'Get Objects';
                    icon = 'documents';
                  } else if (actionDescription.storageAction == 'create_directory') {
                    label = 'Create Directory';
                    icon = 'folder';
                  } else if (actionDescription.storageAction == 'remove') {
                    label = 'Remove Object';
                    icon = 'bin';
                  }

                  acc[key].items.push({
                    option: {
                      value: { queryType: QueryType.Simple, action: actionDescription.name },
                      name: label,
                      icon: icon
                    },
                    subtitle: 'Actions',
                    valueLabel: actionDescription.verboseName || actionDescription.name,
                    valueIcon: null
                  });
                } else if (!modelDescription && (!resource.demo || actionDescription.featured)) {
                  if (!acc['']) {
                    acc[''] = {
                      items: []
                    };
                  }

                  acc[''].items.push({
                    option: {
                      value: { queryType: QueryType.Simple, action: actionDescription.name },
                      name: actionDescription.verboseName,
                      icon: 'play'
                    },
                    subtitle: 'Actions',
                    valueIcon: null
                  });
                }

                return acc;
              }, {})
          );

          const sectionSort = item => {
            if (item.modelDescription) {
              return 0;
            } else if (item.storage) {
              return 2;
            } else {
              return 1;
            }
          };

          options.push(
            ...groupedOptions
              .sort((lhs, rhs) => {
                const lhsSectionSort = sectionSort(lhs);
                const rhsSectionSort = sectionSort(rhs);

                const sectionCompare = ascComparator(lhsSectionSort, rhsSectionSort);
                if (sectionCompare !== 0) {
                  return sectionCompare;
                }

                return ascComparator(lhs.labelSort || '', rhs.labelSort || '');
              })
              .reduce((acc, item) => {
                if (item.modelDescription) {
                  acc.push({
                    button: {
                      name: item.modelDescription.model,
                      label: item.modelDescription.verboseNamePlural || item.modelDescription.model,
                      icon: 'components'
                    },
                    children: item.items,
                    subtitle: 'Collections'
                  });
                } else if (item.storage) {
                  acc.push({
                    button: {
                      name: item.storage.uniqueName,
                      label: item.storage.name || item.storage.uniqueName,
                      icon: 'open_folder'
                    },
                    children: item.items,
                    subtitle: 'Storages'
                  });
                } else {
                  acc.push(
                    ...item.items.sort((lhs, rhs) => {
                      return ascComparator(
                        String(lhs.option.name).toLowerCase(),
                        String(rhs.option.name).toLowerCase()
                      );
                    })
                  );
                }

                return acc;
              }, [])
          );
        }

        const customActionOption = this.getCustomActionOption(resource);

        if (customActionOption) {
          options.push({
            option: customActionOption,
            stickyBottom: true,
            orange: true,
            large: true
          });
        }

        return options;
      })
    );
  }

  resourceActionDownloadItems$(control: AbstractControl): Observable<CustomSelectItem<ActionOption>[]> {
    return combineLatest(this.resource$(control), this.actionStore.get()).pipe(
      map(([resource, actionDescriptions]) => {
        if (!resource) {
          return [];
        }

        const options = [];

        if (actionDescriptions) {
          options.push(
            ...actionDescriptions
              .filter(item => item.resource == resource.uniqueName)
              .filter(item => !resource.demo || item.featured)
              .filter(item => item.type == ActionType.Download)
              .sort((lhs, rhs) => {
                return ascComparator(String(lhs.verboseName).toLowerCase(), String(rhs.verboseName).toLowerCase());
              })
              .map(item => {
                return {
                  option: {
                    value: { queryType: QueryType.Simple, action: item.name },
                    name: item.verboseName,
                    icon: 'document'
                  }
                };
              })
          );
        }
        const customActionOption = this.getCustomActionOption(resource);

        if (customActionOption) {
          options.push({
            option: customActionOption,
            stickyBottom: true,
            orange: true,
            large: true
          });
        }

        return options;
      })
    );
  }

  getActionEditable$(): Observable<boolean> {
    return controlValue<ActionOption>(this.controls.action).pipe(
      map(value => value && editableQueryTypes.includes(value.queryType))
    );
  }

  getQueryEditable$(): Observable<boolean> {
    return controlValue<Query>(this.controls.query).pipe(
      map(value => value && editableQueryTypes.includes(value.queryType))
    );
  }

  linkOptions$(): Observable<Option<Segue>[]> {
    return this.viewSettingsStore.get().pipe(
      map(viewSettings => {
        return [
          // {
          //   value: { type: SegueType.ModelCreate },
          //   name: 'Collection - Create'
          // },
          // {
          //   value: { type: SegueType.ModelChange },
          //   name: 'Collection - Change'
          // },
          // {
          //   value: { type: SegueType.ModelMassEdit },
          //   name: 'Collection - Mass Edit'
          // },
          // {
          //   value: { type: SegueType.ModelExport },
          //   name: 'Collection - Export'
          // },
          // {
          //   value: { type: SegueType.ModelActivityLog },
          //   name: 'Collection - Activity Log'
          // },
          // {
          //   value: { type: SegueType.ModelDelete },
          //   name: 'Collection - Delete'
          // },
          {
            value: { type: SegueType.PreviousPage },
            name: 'Previous Page'
          },
          ...viewSettings
            .map(item => {
              if (item.uniqueName && item.view == ViewSettingsType.Custom) {
                return {
                  value: { type: SegueType.Page, page: item.uniqueName },
                  name: `${item.name} (${item.uniqueName})`
                };
              } else if (item.resource && item.model && item.view == ViewSettingsType.Change) {
                const modelDescription = this.modelDescriptionStore.instance.find(
                  i => i.resource == item.resource && i.model == item.model
                );
                const modelDescriptionName = modelDescription ? modelDescription.verboseNamePlural : undefined;
                const name = modelDescriptionName || item.name || item.model;

                return {
                  value: { type: SegueType.ModelChange, model: [item.resource, item.model].join('.') },
                  name: `${name} (Change page)`
                };
              }
            })
            .filter(item => item != undefined)
            .sort((lhs, rhs) => {
              if (lhs.name.toLowerCase() < rhs.name.toLowerCase()) {
                return -1;
              } else if (lhs.name.toLowerCase() > rhs.name.toLowerCase()) {
                return 1;
              } else {
                return 0;
              }
            })
          // ...modelDescriptions
          //   .map(item => {
          //     const resource = this.currentEnvironmentStore.resources.find(i => i.uniqueName == item.resource);
          //
          //     if (resource && resource.demo) {
          //       return;
          //     }
          //     const modelName = item.verboseNamePlural || item.model;
          //     const resourceName = resource ? resource.name || resource.uniqueName : undefined;
          //
          //     const label = resourceName ? `${modelName} (${resourceName})` : modelName;
          //
          //     return {
          //       label: label,
          //       type: MenuItemType.ModelLink,
          //       params: {
          //         title: modelName,
          //         model: item.modelId
          //       }
          //     };
          //   })
          //   .filter(item => item != undefined)
        ];
      })
    );
  }

  linkPage$(): Observable<ViewSettings> {
    return controlValue<Segue>(this.controls.link).pipe(
      switchMap(value => {
        if (isSet(value) && value.type == SegueType.Page && isSet(value.page)) {
          return this.viewSettingsStore.getDetailFirst(value.page);
        } else {
          return of(undefined);
        }
      })
    );
  }

  openPopup$(): Observable<PopupSettings> {
    return controlValue<string>(this.controls.open_popup).pipe(
      map(value => {
        if (
          isSet(value) &&
          this.options.context &&
          this.options.context.viewSettings &&
          this.options.context.viewSettings instanceof CustomViewSettings
        ) {
          return this.options.context.viewSettings.popups.find(item => item.uid == value);
        }
      })
    );
  }

  getOutputs$(): Observable<OutputsInfo> {
    return controlValue(this).pipe(
      switchMap(() => {
        const result = this.submit();
        const actionDescription = result.action.actionDescription;
        return actionDescription
          ? this.actionService.getActionDescriptionOutputs(actionDescription)
          : of({ outputs: [] });
      })
    );
  }

  actionValueEquals(lhs: ActionOption, rhs: ActionOption) {
    const lhsQueryType = lhs ? lhs.queryType : undefined;
    const lhsActionDescription = lhs && lhs.action ? lhs.action : undefined;
    const rhsQueryType = rhs ? rhs.queryType : undefined;
    const rhsActionDescription = rhs && rhs.action ? rhs.action : undefined;

    return lhsQueryType == rhsQueryType && lhsActionDescription == rhsActionDescription;
  }

  linkValueEquals(lhs: Segue, rhs: Segue) {
    if (lhs && rhs && lhs.type == SegueType.Page && rhs.type == SegueType.Page) {
      return lhs.page == rhs.page;
    } else if (lhs && rhs) {
      return lhs.type == rhs.type;
    } else {
      return lhs === rhs;
    }
  }

  setQuery(query: Query) {
    this.controls.query.patchValue(query);
    this.markAsDirty();
  }

  setOutputs(
    columns: RawListViewSettingsColumn[],
    array: boolean,
    options: { markAsDirty?: boolean; modelDescription?: ModelDescription } = {}
  ) {
    const outputs = columns.map(item => {
      const output = new FieldOutput();

      output.name = item.name;
      output.verboseName = item.verboseName;
      output.field = item.field;
      output.params = item.params;
      output.updateFieldDescription();

      return output;
    });

    this.controls.outputs.setValue(outputs);
    this.controls.array_output.setValue(array);

    if (options.markAsDirty) {
      this.controls.outputs.markAsDirty();
      this.controls.array_output.markAsDirty();
    }
  }

  setAutoDetectOutputs(query: Query, options: { markAsDirty?: boolean } = {}) {
    const response = this.queryService.getResponseProcessed(query);
    const isArrayResponse = response && isArray(response);
    const responseColumns = this.queryService.getAutoDetectColumns(query, false);

    if (!responseColumns) {
      return;
    }

    this.setOutputs(responseColumns, isArrayResponse, { markAsDirty: options.markAsDirty });
  }

  resetExportColumns(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      markAsDirty?: boolean;
    } = {}
  ) {
    const exportDataType = this.controls.export_data_type.value as ExportDataType;
    if (exportDataType == ExportDataType.DataSource) {
      this.controls.export_data_source.resetInputColumns({
        context: options.context,
        contextElement: options.contextElement,
        markAsDirty: true
      });
    } else if (exportDataType == ExportDataType.CurrentComponent) {
      const newColumns = this.options.dataSourceControl.controls.columns.serialize(false);
      this.controls.export_data_source.controls.columns.deserialize(newColumns);
    }
  }

  toggleExportDefaultSorting() {
    const control = this.controls.export_sorting_asc;
    control.patchValue(!control.value);
  }

  onResourceChange(resourceName: string) {
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);
    const firstAction = this.actionStore.instance.find(action => {
      if (action.resource != resourceName) {
        return false;
      }

      const modelDescription = action.model
        ? this.modelDescriptionStore.instance.find(
            item => item.resource == resource.uniqueName && item.model == action.model
          )
        : undefined;

      if (resource.demo && modelDescription) {
        return modelDescription.featured;
      } else if (resource.demo && !modelDescription) {
        return action.featured;
      }

      return true;
    });
    let actionOption: ActionOption;

    if (firstAction) {
      // actionOption = {
      //   queryType: QueryType.Simple,
      //   action: firstAction.name
      // };
    } else if (resource) {
      const customOption = this.getCustomActionOption(resource);

      if (customOption) {
        actionOption = customOption.value;
      }
    }

    this.controls.action.patchValue(actionOption);
    this.controls.inputs.patchValue([]);
  }

  onActionChange(resourceName: string, action: ActionOption) {
    this.setAction(action);
    this.setActionParams(resourceName, action);

    this.onActionParamsChange();
  }

  onParametersChange() {
    this.onActionParamsChange();
  }

  onQueryChange() {
    this.onActionParamsChange();
  }

  onTypeChange() {
    this.onActionParamsChange();
  }

  onLinkChange() {
    this.onActionParamsChange();
  }

  onElementActionChange() {
    this.onActionParamsChange();
  }

  onWorkflowChange() {
    this.onActionParamsChange();
  }

  onActionParamsChange() {
    setTimeout(() => {
      this.updateInputFieldProvider().subscribe();
    }, 0);
  }

  onPageParametersChange() {
    setTimeout(() => {
      this.updateInputFieldProvider().subscribe();
    }, 0);
  }

  onOpenPopupParametersChange() {
    setTimeout(() => {
      this.updateInputFieldProvider().subscribe();
    }, 0);
  }

  onPageParametersChangeDebounce(value: ParameterField[]) {
    const link = this.controls.link.value;

    if (isSet(link) && link.type == SegueType.Page && isSet(link.page)) {
      this.submitPageParameters(link.page, value);
    }
  }

  onPopupParametersChangeDebounce(value: ParameterField[]) {
    const uid = this.controls.open_popup.value;

    if (isSet(uid)) {
      this.submitPopupParameters(uid, value);
    }
  }

  isConfigured(instance: ActionItem): Observable<boolean> {
    return this.elementConfigurationService.isActionConfigured(instance, { restrictDemo: true });
  }

  submitPageParameters(pageUniqueName: string, parameters: ParameterField[]) {
    this.viewSettingsStore
      .getDetailFirst(pageUniqueName)
      .pipe(
        switchMap(oldInstance => {
          const newInstance = cloneDeep(oldInstance) as ViewSettings;
          newInstance.parameters = parameters;
          return this.viewSettingsService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            newInstance
          );
        }),
        tap(result => this.viewSettingsStore.updateItem(result))
      )
      .subscribe();
  }

  submitPopupParameters(uid: string, parameters: ParameterField[]) {
    if (
      !isSet(uid) ||
      !this.options.context ||
      !this.options.context.viewSettings ||
      !(this.options.context.viewSettings instanceof CustomViewSettings)
    ) {
      return;
    }

    const page = this.options.context.viewSettings;

    this.viewSettingsStore.getDetailFirst<CustomViewSettings>(page.uniqueName).subscribe(viewSettings => {
      const popup = viewSettings.popups.find(item => item.uid == uid);

      if (!popup) {
        return;
      }

      const newPopup = cloneDeep(popup) as PopupSettings;

      newPopup.parameters = parameters;

      if (this.customizeService.handler.openPopup) {
        this.customizeService.handler.updatePopup(popup.uid, newPopup);
      }
    });
  }

  submit(): CustomizeActionResult {
    const value = this.value;
    const instance: ActionItem = this.options.actionItem
      ? cloneDeep(this.options.actionItem)
      : new this.options.actionItemClass();

    if (this.options.nameEditable) {
      instance.verboseNameInput = value['verbose_name']
        ? new FieldInput().deserialize(value['verbose_name'])
        : undefined;
    }

    if (instance instanceof ViewSettingsAction) {
      if (this.options.iconEditable) {
        instance.icon = value['icon'];
      }

      if (this.options.styleEditable) {
        instance.style = value['style'];
      }

      if (this.options.colorsEditable) {
        instance.tint = value['tint'];
      }

      if (this.options.disabledEditable) {
        instance.disabledInput = value['disabled_input']
          ? new FieldInput().deserialize(value['disabled_input'])
          : undefined;
      }
    }

    if (this.options.completionEditable) {
      instance.onSuccessNotification = value['on_success_notification'];

      if (value['on_success_actions']) {
        instance.onSuccessActions = value['on_success_actions'];
      }

      instance.onErrorNotification = value['on_error_notification'];

      if (value['on_error_actions']) {
        instance.onErrorActions = value['on_error_actions'];
      }
    }

    instance.inputs = [...value['inputs']];

    if (
      (value['type'] == ActionType.Query || value['type'] == ActionType.Download) &&
      value['action'] &&
      value['action'].action
    ) {
      const action = value['action'] as ActionOption;

      instance.actionDescription = undefined;
      instance.sharedActionDescription = [value['resource'], action.action].join('.');
    } else {
      const actionDescription = new ActionDescription();

      actionDescription.resource = value['resource'];
      // actionDescription.name = value['verbose_name'] ? slugify(value['verbose_name'], { separator: '_' }).replace(/_+/g, '_') : undefined;
      // actionDescription.verboseName = value['verbose_name'];
      actionDescription.actionParams = value['action_params'];
      actionDescription.outputs = value['outputs'];
      actionDescription.arrayOutput = value['array_output'];
      actionDescription.type = value['type'];
      actionDescription.icon = value['icon'];
      actionDescription.elementAction = value['element_action'];

      if (actionDescription.type == ActionType.Query) {
        if (!actionDescription.queryAction) {
          actionDescription.queryAction = new QueryAction();
        }

        actionDescription.queryAction.query = value['query'];
      } else if (actionDescription.type == ActionType.Download) {
        if (!actionDescription.downloadAction) {
          actionDescription.downloadAction = new DownloadAction();
        }

        actionDescription.downloadAction.type = value['download_action_type'];
        actionDescription.downloadAction.query = value['query'];
        actionDescription.downloadAction.fileColumn = value['download_action_file_column'];
        actionDescription.downloadAction.input = value['download_action_input']
          ? new FieldInput().deserialize(value['download_action_input'])
          : undefined;
      } else if (actionDescription.type == ActionType.Link) {
        if (!actionDescription.linkAction) {
          actionDescription.linkAction = new LinkAction();
        }

        if (value['link']) {
          actionDescription.linkAction.type = value['link'].type;
          actionDescription.linkAction.page = value['link'].page;
          actionDescription.linkAction.model = value['link'].model;
        }

        // actionDescription.linkAction.model = value['model'] ? value['model']['model'] : undefined;

        if (value['new_tab'] === true) {
          const input = new Input();
          input.path = ['new_tab'];
          input.valueType = InputValueType.StaticValue;
          input.staticValue = true;
          instance.inputs.push(input);
        } else if (value['new_tab'] === false) {
          const input = new Input();
          input.path = ['new_tab'];
          input.valueType = InputValueType.StaticValue;
          input.staticValue = false;
          instance.inputs.push(input);
        } else {
          const input = new Input().deserialize(value['new_tab_custom']);
          input.path = ['new_tab'];
          instance.inputs.push(input);
        }
      } else if (actionDescription.type == ActionType.ShowNotification) {
        if (!actionDescription.notificationAction) {
          actionDescription.notificationAction = new NotificationAction();
        }

        if (value['notification_action_title']) {
          actionDescription.notificationAction.title = value['notification_action_title']
            ? new FieldInput().deserialize(value['notification_action_title'])
            : undefined;
        }

        if (value['notification_action_description']) {
          actionDescription.notificationAction.description = value['notification_action_description']
            ? new FieldInput().deserialize(value['notification_action_description'])
            : undefined;
        }

        if (value['notification_action_icon']) {
          actionDescription.notificationAction.icon = value['notification_action_icon'];
        }

        if (value['notification_action_type']) {
          actionDescription.notificationAction.type = value['notification_action_type'];
        }

        if (value['notification_action_color_enabled'] && isSet(value['notification_action_color'])) {
          actionDescription.notificationAction.color = value['notification_action_color'];
        }

        actionDescription.notificationAction.closeTimeoutEnabled = !!value['notification_action_close_timeout_enabled'];

        if (isSet(value['notification_action_close_timeout'])) {
          const closeTimeout = parseInt(value['notification_action_close_timeout'], 10);
          actionDescription.notificationAction.closeTimeout = !isNaN(closeTimeout) ? closeTimeout : undefined;
        } else {
          actionDescription.notificationAction.closeTimeout = undefined;
        }
      } else if (actionDescription.type == ActionType.SetProperty) {
        if (!actionDescription.setPropertyAction) {
          actionDescription.setPropertyAction = new SetPropertyAction();
        }

        actionDescription.setPropertyAction.property = value['set_property_action_property'];

        if (value['set_property_action_value']) {
          actionDescription.setPropertyAction.value = value['set_property_action_value']
            ? new FieldInput().deserialize(value['set_property_action_value'])
            : undefined;
        }
      } else if (actionDescription.type == ActionType.RunJavaScript) {
        if (!actionDescription.runJavaScriptAction) {
          actionDescription.runJavaScriptAction = new RunJavaScriptAction();
        }

        actionDescription.runJavaScriptAction.js = value['run_javascript'];
      } else if (actionDescription.type == ActionType.CopyToClipboard) {
        if (!actionDescription.copyToClipboardAction) {
          actionDescription.copyToClipboardAction = new CopyToClipboardAction();
        }

        if (value['copy_to_clipboard_action_value']) {
          actionDescription.copyToClipboardAction.value = value['copy_to_clipboard_action_value']
            ? new FieldInput().deserialize(value['copy_to_clipboard_action_value'])
            : undefined;
        }
      } else if (actionDescription.type == ActionType.Export) {
        if (!actionDescription.exportAction) {
          actionDescription.exportAction = new ExportAction();
        }

        actionDescription.exportAction.dataType = this.controls.export_data_type.value;
        actionDescription.exportAction.dataSource = this.controls.export_data_source.serialize();
        actionDescription.exportAction.sort = isSet(this.controls.export_sorting_field.value)
          ? [{ field: this.controls.export_sorting_field.value, desc: !this.controls.export_sorting_asc.value }]
          : undefined;

        // const idsInput = new Input().deserialize(this.controls.export_ids.value);
        // idsInput.name = 'ids';

        const perPageInput = new Input().deserialize(this.controls.export_per_page.value);
        perPageInput.path = [PER_PAGE_PARAM];

        instance.inputs = [
          ...instance.inputs.filter(item => ![PER_PAGE_PARAM].some(param => item.isName(param))),
          perPageInput
        ];
      } else if (actionDescription.type == ActionType.Import) {
        if (!actionDescription.importAction) {
          actionDescription.importAction = new ImportAction();
        }

        const [resource, model] = value['model'] ? value['model']['model'].split('.') : [undefined, undefined];

        actionDescription.resource = resource;
        actionDescription.importAction.resource = resource;
        actionDescription.importAction.model = model;
      } else if (actionDescription.type == ActionType.ExternalLink) {
        if (value['new_tab'] === true) {
          const input = new Input();
          input.path = ['new_tab'];
          input.valueType = InputValueType.StaticValue;
          input.staticValue = true;
          instance.inputs.push(input);
        } else if (value['new_tab'] === false) {
          const input = new Input();
          input.path = ['new_tab'];
          input.valueType = InputValueType.StaticValue;
          input.staticValue = false;
          instance.inputs.push(input);
        } else {
          const input = new Input().deserialize(value['new_tab_custom']);
          input.path = ['new_tab'];
          instance.inputs.push(input);
        }
      } else if (actionDescription.type == ActionType.OpenPopup) {
        if (!actionDescription.openPopupAction) {
          actionDescription.openPopupAction = new OpenPopupAction();
        }

        if (value['open_popup']) {
          actionDescription.openPopupAction.popup = value['open_popup'];
        }

        actionDescription.openPopupAction.closeOther = value['open_popup_close_other'];
        actionDescription.openPopupAction.togglePopup = value['open_popup_toggle_popup'];
      } else if (actionDescription.type == ActionType.ClosePopup) {
        if (!actionDescription.closePopupAction) {
          actionDescription.closePopupAction = new ClosePopupAction();
        }

        if (value['close_popup']) {
          actionDescription.closePopupAction.popup =
            value['close_popup'] == OPENED_MODAL_VALUE ? undefined : value['close_popup'];
        }
      } else if (actionDescription.type == ActionType.OpenActionMenu) {
        if (!actionDescription.openActionMenuAction) {
          actionDescription.openActionMenuAction = new OpenActionMenuAction();
        }

        actionDescription.openActionMenuAction.actions = value['open_action_menu_actions'];
      } else if (actionDescription.type == ActionType.Workflow) {
        if (!actionDescription.workflowAction) {
          actionDescription.workflowAction = new WorkflowAction();
        }

        if (value['workflow']) {
          actionDescription.workflowAction.workflow = value['workflow'];
        }
      }

      if (actionDescription.type == ActionType.Link || actionDescription.type == ActionType.ExternalLink) {
        actionDescription.actionParams = this.inputFieldProvider.getFields().map(item => {
          const result = new ParameterField();

          result.name = item.name;
          result.verboseName = item.verboseName;
          result.description = item.description;
          result.field = item.field;
          result.required = item['required'];
          result.defaultType = item['defaultType'];
          result.defaultValue = item['defaultValue'];
          result.params = item.params;
          result.updateFieldDescription();

          return result;
        });
      }

      instance.sharedActionDescription = undefined;
      instance.actionDescription = actionDescription;
    }

    if (this.options.approveEnabled) {
      if (value['approve_enabled'] && value['approve'] instanceof Approve) {
        instance.approve = value['approve'];
      } else {
        instance.approve = undefined;
      }
    }

    if (this.options.confirmationEnabled) {
      instance.confirmation = this.controls.confirmation_enabled.value
        ? this.controls.confirmation.serialize()
        : undefined;
    }

    return {
      action: instance,
      title: value['title'],
      visibleInput: value['visible_input'] ? new FieldInput().deserialize(value['visible_input']) : undefined,
      tooltip: isSet(value['tooltip']) ? value['tooltip'].trim() : undefined,
      ...(this.options.elementStylesEditable && {
        elementStyles: this.controls.element_styles.serialize()
      })
    };
  }
}
