var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PopupService } from '@common/popups';
import { SessionStorage } from '@core';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ChangeViewSettings, CustomizeService, CustomViewSettings, ElementItem, ElementType, PopupSettings, ViewContext } from '@modules/customize';
import { AutoElementComponent, BUILDER_ELEMENT_BUFFER, ElementContainerService } from '@modules/customize-elements';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ChooseTemplateSection, ChooseTemplateService } from '@modules/template-components';
import { PageTemplatesController } from '@modules/template-queries';
import { isControlElement, KeyboardEventKeyCode, openUrl, scrollTo, scrollWindowTo } from '@shared';
import { ElementGroupsContainerDirective } from '../../directives/element-groups-container/element-groups-container.directive';
var RootLayoutComponent = /** @class */ (function () {
    function RootLayoutComponent(customizeService, elementContainerService, injector, currentProjectStore, currentEnvironmentStore, routing, analyticsService, chooseTemplateService, pageTemplatesController, sessionStorage, popupService, 
    // private zone: NgZone,
    cd) {
        var _this = this;
        this.customizeService = customizeService;
        this.elementContainerService = elementContainerService;
        this.injector = injector;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.routing = routing;
        this.analyticsService = analyticsService;
        this.chooseTemplateService = chooseTemplateService;
        this.pageTemplatesController = pageTemplatesController;
        this.sessionStorage = sessionStorage;
        this.popupService = popupService;
        this.cd = cd;
        this.templates = true;
        this.root = false;
        this.fill = true;
        this.active = false;
        this.elementComponents = new QueryList();
        this.analyticsEvents = AnalyticsEvent;
        this.canEnter = (function () {
            return function (drag, drop) {
                if (!_this.active) {
                    return false;
                }
                if (drag.data instanceof ElementItem && drag.data.type == ElementType.FormSubmit) {
                    return false;
                }
                else {
                    return true;
                }
            };
        })();
        this.trackElement = (function () {
            return function (i, item) {
                var pageUid = _this.context && _this.context.viewSettings && !_this.context.viewSettings.newlyCreated
                    ? _this.context.viewSettings.uid
                    : undefined;
                return [pageUid, item.uid].join('_');
            };
        })();
    }
    RootLayoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customizeService
            .createElement$()
            .pipe(filter(function () { return _this.isActive(); }), untilDestroyed(this))
            .subscribe(function (barItem) {
            var elementItem = _this.elementContainerService.createElementItem(barItem, _this.context);
            _this.createElement(elementItem);
        });
        fromEvent(document, 'keydown')
            .pipe(filter(function () { return !isControlElement(document.activeElement) && !_this.popupService.items.length && _this.isActive(); }), untilDestroyed(this))
            .subscribe(function (e) {
            if ((e.metaKey || e.ctrlKey) && e.keyCode == KeyboardEventKeyCode.V) {
                _this.pasteElement();
            }
        });
    };
    RootLayoutComponent.prototype.ngOnDestroy = function () { };
    RootLayoutComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['elements'] && !changes['elements'].firstChange) {
            setTimeout(function () { return _this.updateElementStatesOnStable(); }, 0);
        }
    };
    RootLayoutComponent.prototype.ngAfterViewInit = function () {
        this.updateElementStates();
    };
    RootLayoutComponent.prototype.isActive = function () {
        var _this = this;
        var openedPopups = this.customizeService.handler && this.customizeService.handler.getOpenedPopups
            ? this.customizeService.handler && this.customizeService.handler.getOpenedPopups()
            : [];
        return (this.active &&
            ((!this.popup && !openedPopups.length) || (this.popup && openedPopups.some(function (item) { return item.uid == _this.popup.uid; }))));
    };
    RootLayoutComponent.prototype.dragDrop = function (event) {
        var item = event.previousContainer.data[event.previousIndex];
        var barItem = item instanceof ElementItem ? undefined : item;
        if (barItem && barItem.popup) {
            if (this.customizeService.handler && this.customizeService.handler.createPopup) {
                this.customizeService.handler.createPopup(true, __assign({}, (barItem.defaultParams && {
                    width: barItem.defaultParams['width'],
                    style: barItem.defaultParams['style'],
                    position: barItem.defaultParams['position']
                }), { analyticsSource: 'components_library' }));
                this.elementContainerService.sendAddPopupAnalytics();
            }
            return;
        }
        var siblingLeftEntered = event.data ? !!event.data['siblingLeftEntered'] : false;
        var siblingRightEntered = event.data ? !!event.data['siblingRightEntered'] : false;
        var siblingSelf = event.data ? !!event.data['siblingSelf'] : false;
        var siblingAnchor = event.data ? event.data['siblingAnchor'] : undefined;
        var siblingAnchorContainer = event.data ? event.data['siblingAnchorContainer'] : undefined;
        if (siblingLeftEntered || siblingRightEntered) {
            var anchorContainer = siblingSelf ? event.container.data : siblingAnchorContainer.data;
            var anchorIndex = siblingSelf ? event.currentIndex : anchorContainer.indexOf(siblingAnchor);
            this.elementContainerService.dragDropIntoSiblingColumn({
                sourceContainer: event.previousContainer.data,
                sourceIndex: event.previousIndex,
                sourceCloneItem: event.previousContainer.cloneItems,
                anchorContainer: anchorContainer,
                anchorIndex: anchorIndex,
                anchorSelf: siblingSelf,
                left: siblingLeftEntered,
                context: this.context,
                parent: undefined
            });
        }
        else {
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            }
            else if (event.previousContainer.cloneItems) {
                var elementItem = this.elementContainerService.copyElementItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex, this.context);
                this.customizeService.registerCreatedElement(elementItem, barItem);
            }
            else {
                transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            }
        }
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
    };
    RootLayoutComponent.prototype.duplicateItem = function (index) {
        var elementItem = this.elementContainerService.duplicateElementItem(this.elements, this.context, index);
        this.cd.detectChanges();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
        var component = this.elementComponents.find(function (i) { return i.element === elementItem; });
        if (component) {
            component.customize();
        }
    };
    RootLayoutComponent.prototype.replaceItem = function (index, elements) {
        this.elementContainerService.replaceElementItem(this.elements, index, elements);
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
    };
    RootLayoutComponent.prototype.createElement = function (element) {
        this.elementContainerService.prependElementItem(this.page, this.popup, element, this.context);
        this.onElementCreated(element);
    };
    RootLayoutComponent.prototype.pasteElement = function () {
        var createdElement = this.elementContainerService.addSavedElement(this.page, this.popup, BUILDER_ELEMENT_BUFFER, this.context);
        if (createdElement.element) {
            this.onElementCreated(createdElement.element);
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.Paste, {
                ComponentTypeID: createdElement.element.analyticsName
            });
        }
        else if (createdElement.popup) {
            this.onPopupCreated(createdElement.popup);
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.Paste);
        }
    };
    RootLayoutComponent.prototype.onElementCreated = function (element) {
        this.customizeService.registerCreatedElement(element);
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
        this.scrollToTop();
    };
    RootLayoutComponent.prototype.onPopupCreated = function (popup) {
        var _this = this;
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
        if (this.customizeService.handler.openPopup && this.customizeService.handler.openPopup) {
            setTimeout(function () {
                _this.customizeService.handler.openPopup(popup.uid, { customize: true });
            });
        }
        this.scrollToTop();
    };
    RootLayoutComponent.prototype.scrollToTop = function () {
        var popupElement = document.querySelector('.custom-page-popup-container_scrollable.custom-page-popup-container_visible');
        if (popupElement) {
            scrollTo(popupElement, 0);
        }
        else {
            scrollWindowTo(0);
        }
    };
    RootLayoutComponent.prototype.deleteItem = function (element, immediate) {
        if (immediate === void 0) { immediate = false; }
        var index = this.elements.findIndex(function (item) { return item === element; });
        if (index == -1) {
            return;
        }
        this.elementContainerService.deleteElementItem(this.elements, index);
        this.cd.markForCheck();
        this.customizeService.markChanged(immediate);
        this.updateElementStatesOnStable();
    };
    RootLayoutComponent.prototype.moveItemTo = function (element, link) {
        var _this = this;
        this.deleteItem(element);
        this.customizeService.stopTrackChanges();
        this.customizeService
            .saveActualChanges()
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.routing.navigateApp(link); });
    };
    Object.defineProperty(RootLayoutComponent.prototype, "dropListBottomMargin", {
        get: function () {
            return window.innerHeight * -1;
        },
        enumerable: true,
        configurable: true
    });
    RootLayoutComponent.prototype.updateElementStatesOnStable = function () {
        if (this.elementGroupsContainer) {
            this.elementGroupsContainer.updateElementStatesOnStable();
        }
    };
    RootLayoutComponent.prototype.updateElementStates = function () {
        if (this.elementGroupsContainer) {
            this.elementGroupsContainer.updateElementStates();
        }
    };
    RootLayoutComponent.prototype.chooseTemplate = function () {
        this.chooseTemplateService.chooseTemplate(this.injector, {
            currentPage: this.page,
            chooseSection: false,
            initialSection: ChooseTemplateSection.Resources,
            cancel: true,
            analyticsSource: 'new_page',
            analyticsInnerSource: 'templates_popup'
        });
    };
    RootLayoutComponent.prototype.openPageTemplates = function () {
        var _this = this;
        this.pageTemplatesController
            .open({
            cancelEnabled: true,
            analyticsSource: 'new_page'
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.startPage) {
                _this.routing.navigateApp(result.startPage.link, { replaceUrl: true });
            }
        });
    };
    RootLayoutComponent.prototype.getAppUrl = function () {
        return window.location.href.replace('/app/', '/builder/');
    };
    RootLayoutComponent.prototype.openAppUrl = function () {
        var url = this.getAppUrl();
        openUrl(url, true);
    };
    return RootLayoutComponent;
}());
export { RootLayoutComponent };
