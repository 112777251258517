import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import camelCase from 'lodash/camelCase';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { slugify } from 'transliteration';

import {
  JavascriptDependency,
  JavascriptDependencyImport,
  NpmPackageExportsSource,
  NpmPackageSource,
  NpmPackageVersionSource
} from '@modules/npm';
import { controlValue } from '@shared';

enum Section {
  Package = 'package',
  Version = 'version',
  Imports = 'imports'
}

@Component({
  selector: 'app-select-javascript-dependency',
  templateUrl: './select-javascript-dependency.component.html',
  providers: [NpmPackageSource, NpmPackageVersionSource, NpmPackageExportsSource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectJavaScriptDependencyComponent implements OnInit, OnDestroy {
  @Input() origin: CdkOverlayOrigin;
  @Input() trigger: HTMLElement;
  @Output() selected = new EventEmitter<JavascriptDependency>();

  npmPackageControl = new FormControl('', Validators.required);
  npmPackageVersionControl = new FormControl('', Validators.required);
  npmPackageExportsControl = new FormControl([], Validators.required);
  section: Section = Section.Package;
  sections = Section;

  constructor(
    public npmPackageSource: NpmPackageSource,
    public npmPackageVersionSource: NpmPackageVersionSource,
    public npmPackageExportsSource: NpmPackageExportsSource,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    controlValue(this.npmPackageControl)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.npmPackageVersionSource.package = value;
        this.npmPackageVersionSource.reset();
      });

    combineLatest(controlValue(this.npmPackageControl), controlValue(this.npmPackageVersionControl))
      .pipe(untilDestroyed(this))
      .subscribe(([name, version]) => {
        this.npmPackageExportsSource.package = name;
        this.npmPackageExportsSource.version = version;
        this.npmPackageExportsSource.reset();
      });
  }

  ngOnDestroy(): void {}

  setSection(value: Section) {
    this.section = value;
    this.cd.markForCheck();
  }

  submit() {
    const packageName = camelCase(slugify(this.npmPackageControl.value, { trim: true, separator: '_' }));
    const imports: JavascriptDependencyImport[] = this.npmPackageExportsControl.value.map(item => {
      return item == 'default' ? { name: item, alias: packageName } : { name: item };
    });

    this.selected.emit({
      package: this.npmPackageControl.value,
      version: this.npmPackageVersionControl.value,
      imports: imports
    });
  }
}
