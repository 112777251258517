import { AfterViewInit, ChangeDetectorRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { DynamicComponent } from '@common/dynamic-component';
import { BasePopupComponent, PopupService } from '@common/popups';
import { ActionService, WorkflowExecuteEventType } from '@modules/action-queries';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { WorkflowRun, WorkflowStep, WorkflowStepRun } from '@modules/workflow';
import { isControlElement, isSet, KeyboardEventKeyCode } from '@shared';
import { getCustomizeWorkflowStepComponent } from '../../../data/customize-workflow-step-components';
import { getWorkflowStepComponent } from '../../../data/workflow-step-components';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { WorkflowEditContext } from '../../../services/workflow-edit-context/workflow-edit.context';
var markWorkflowElementClickProperty = '_markWorkflowElementClickProperty';
export function markWorkflowElementClick(clickEvent) {
    clickEvent[markWorkflowElementClickProperty] = true;
}
export function isWorkflowElementClick(clickEvent) {
    return !!clickEvent[markWorkflowElementClickProperty];
}
var AutoWorkflowStepComponent = /** @class */ (function () {
    function AutoWorkflowStepComponent(customizeBarContext, workflowEditContext, actionService, popupService, popupComponent, injector, cd, analyticsService) {
        this.customizeBarContext = customizeBarContext;
        this.workflowEditContext = workflowEditContext;
        this.actionService = actionService;
        this.popupService = popupService;
        this.popupComponent = popupComponent;
        this.injector = injector;
        this.cd = cd;
        this.analyticsService = analyticsService;
        this.workflowEditable = false;
        this.stepAddBefore = new EventEmitter();
        this.stepDuplicate = new EventEmitter();
        this.stepDelete = new EventEmitter();
        this.customizeComponentData$ = new BehaviorSubject(undefined);
        this.customizing$ = new BehaviorSubject(false);
        this.loadingExecute = false;
    }
    Object.defineProperty(AutoWorkflowStepComponent.prototype, "stepId", {
        get: function () {
            return this.step.uid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutoWorkflowStepComponent.prototype, "stepName", {
        get: function () {
            return this.step.name;
        },
        enumerable: true,
        configurable: true
    });
    AutoWorkflowStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initComponent();
        combineLatest(this.customizeComponentData$, this.customizeBarContext.settingsComponents$)
            .pipe(debounceTime(10), map(function (_a) {
            var customizeComponentData = _a[0], components = _a[1];
            return customizeComponentData && components[0] === customizeComponentData;
        }), startWith(false))
            .pipe(untilDestroyed(this))
            .subscribe(function (value) { return _this.customizing$.next(value); });
        fromEvent(document, 'keydown')
            .pipe(filter(function () {
            return (_this.customizing$.value &&
                _this.popupService.last() === _this.popupComponent.data &&
                !isControlElement(document.activeElement));
        }), untilDestroyed(this))
            .subscribe(function (e) {
            if (e.keyCode == KeyboardEventKeyCode.Escape) {
                _this.closeCustomize();
            }
            else if (e.keyCode == KeyboardEventKeyCode.Backspace) {
                if (_this.workflowEditable) {
                    _this.delete();
                }
            }
        });
    };
    AutoWorkflowStepComponent.prototype.ngOnDestroy = function () { };
    AutoWorkflowStepComponent.prototype.ngOnChanges = function (changes) {
        if ([changes.step, changes.index].some(function (item) { return item && !item.firstChange; })) {
            if (changes.step &&
                (changes.step.previousValue ? changes.step.previousValue.type : undefined) !==
                    (changes.step.currentValue ? changes.step.currentValue.type : undefined)) {
                this.initComponent();
            }
            else {
                this.updateComponent({ closeCustomize: true });
            }
        }
    };
    AutoWorkflowStepComponent.prototype.ngAfterViewInit = function () {
        var createdElement = this.workflowEditContext.initCreatedElement(this.step);
        if (createdElement) {
            this.customize({ contextElement: createdElement.contextElement });
        }
    };
    AutoWorkflowStepComponent.prototype.getComponentInputs = function () {
        return {
            step: this.step,
            prevStep: this.prevStep,
            workflowEditable: this.workflowEditable,
            index: this.index,
            context: this.context,
            customizing$: this.customizing$,
            actionTypesEnabled: this.actionTypesEnabled,
            analyticsSource: this.analyticsSource
        };
    };
    AutoWorkflowStepComponent.prototype.initComponent = function () {
        var _this = this;
        var component = getWorkflowStepComponent(this.step.type);
        if (!component) {
            this.componentData = undefined;
            this.cd.markForCheck();
            console.error("No such step type registered: " + this.step.type);
            return;
        }
        this.componentData = {
            component: component,
            inputs: this.getComponentInputs(),
            outputs: {
                stepAddBefore: [
                    function (step) {
                        _this.stepAddBefore.emit(step);
                    }
                ],
                stepCustomize: [
                    function (e) {
                        _this.customize({ contextElement: e ? e.contextElement : undefined });
                    }
                ],
                stepExecute: [
                    function () {
                        _this.execute();
                    }
                ],
                stepDuplicate: [
                    function () {
                        _this.duplicate();
                    }
                ],
                stepDelete: [
                    function () {
                        _this.delete();
                    }
                ]
            }
        };
        this.cd.markForCheck();
    };
    AutoWorkflowStepComponent.prototype.updateComponent = function (options) {
        if (options === void 0) { options = {}; }
        if (!this.dynamicComponent ||
            !this.dynamicComponent.currentComponent ||
            !this.dynamicComponent.currentComponent.instance) {
            return;
        }
        var ref = this.dynamicComponent.currentComponent;
        var inputs = this.getComponentInputs();
        var changes = toPairs(inputs).reduce(function (acc, _a) {
            var prop = _a[0], currentValue = _a[1];
            var prevValue = ref.instance[prop];
            if ((isSet(options.forcePropUpdate) && prop == options.forcePropUpdate) || prevValue !== currentValue) {
                acc[prop] = new SimpleChange(prevValue, currentValue, false);
                ref.instance[prop] = currentValue;
            }
            return acc;
        }, {});
        this.componentData.inputs = inputs;
        if (values(changes).length) {
            if (ref.instance['ngOnChanges']) {
                ref.instance['ngOnChanges'](changes);
            }
            ref.changeDetectorRef.markForCheck();
            if (options.closeCustomize && this.customizeComponentData$.value) {
                this.closeCustomize();
            }
        }
    };
    AutoWorkflowStepComponent.prototype.duplicate = function () {
        this.closeCustomize();
        this.stepDuplicate.emit();
    };
    AutoWorkflowStepComponent.prototype.delete = function () {
        this.closeCustomize();
        this.stepDelete.emit();
    };
    AutoWorkflowStepComponent.prototype.customize = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (this.customizing$.value) {
            return;
        }
        var component = getCustomizeWorkflowStepComponent(this.step.type);
        if (!component) {
            return;
        }
        var dynamicComponent = {
            component: component,
            inputs: {
                runtime: this.workflowEditContext.runtime,
                step: this.step,
                workflowEditable: this.workflowEditable,
                context: this.context,
                contextElement: options.contextElement
            },
            outputs: {
                stepChange: [
                    function (result) {
                        _this.onCustomized(result);
                    }
                ],
                stepDuplicate: [
                    function () {
                        _this.duplicate();
                    }
                ],
                stepDelete: [
                    function () {
                        _this.delete();
                    }
                ],
                stepExecute: [
                    function () {
                        _this.execute();
                    }
                ],
                closeCustomize: [
                    function () {
                        _this.customizeBarContext.closeSettingsComponent(dynamicComponent);
                    }
                ]
            }
        };
        this.customizeBarContext.setSettingsComponent(dynamicComponent);
        this.customizeComponentData$.next(dynamicComponent);
    };
    AutoWorkflowStepComponent.prototype.updateStepRun = function (stepRun) {
        var _this = this;
        var existingRun = this.workflowEditContext.run$.value;
        var run = new WorkflowRun();
        if (existingRun) {
            run.stepRuns = existingRun.stepRuns.filter(function (item) { return item.uid != _this.step.uid; });
        }
        if (stepRun) {
            run.stepRuns.push(stepRun);
        }
        run.resultStepRun = existingRun ? existingRun.resultStepRun : undefined;
        this.workflowEditContext.run$.next(run);
    };
    AutoWorkflowStepComponent.prototype.execute = function () {
        var _this = this;
        this.loadingExecute = true;
        this.cd.markForCheck();
        var event$ = new Subject();
        event$.pipe(untilDestroyed(this)).subscribe(function (event) {
            _this.workflowEditContext.testExecuteEvents$.next(event);
            if (event.type == WorkflowExecuteEventType.StepStarted) {
                _this.updateStepRun();
            }
            else if (event.type == WorkflowExecuteEventType.StepFinished) {
                var run = new WorkflowStepRun();
                run.uid = _this.step.uid;
                run.params = event.params;
                run.result = event.result;
                run.error = event.error;
                _this.updateStepRun(run);
            }
        });
        this.actionService
            .executeWorkflowSteps([this.step], event$, {
            context: this.context,
            showSuccess: true,
            showError: true,
            delayBefore: 200,
            disableRouting: true,
            disablePopups: true,
            injector: this.injector
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.loadingExecute = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.WorkflowBuilder.StepTest, {
                Success: true,
                Step: _this.step.analyticsName,
                Source: _this.analyticsSource
            });
        }, function (error) {
            _this.loadingExecute = false;
            _this.cd.markForCheck();
            var errorMessage;
            if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
                errorMessage = error.nonFieldErrors[0];
            }
            else {
                errorMessage = 'Unknown error';
            }
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.WorkflowBuilder.StepTest, {
                Success: false,
                Error: errorMessage,
                Step: _this.step.analyticsName,
                Source: _this.analyticsSource
            });
        });
    };
    AutoWorkflowStepComponent.prototype.onCustomized = function (step) {
        this.step.deserialize(step.serialize());
        this.updateComponent({ forcePropUpdate: 'step' });
        this.workflowEditContext.markChanged();
    };
    AutoWorkflowStepComponent.prototype.closeCustomize = function () {
        if (this.customizing$.value && this.customizeComponentData$.value) {
            this.customizeBarContext.closeSettingsComponent(this.customizeComponentData$.value);
            this.customizeComponentData$.next(undefined);
        }
    };
    AutoWorkflowStepComponent.prototype.onClick = function (e) {
        markWorkflowElementClick(e);
    };
    return AutoWorkflowStepComponent;
}());
export { AutoWorkflowStepComponent };
