import { Overlay } from '@angular/cdk/overlay';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import * as Color from 'color';
import { extent, max } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { scaleBand, ScaleBand, ScaleLinear, scaleLinear } from 'd3-scale';
import { pointer, select, Selection } from 'd3-selection';
import { Series, stack } from 'd3-shape';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { skip } from 'rxjs/operators';

import {
  applyDatasetsDefaultColors,
  CHART_COLORS,
  Dataset,
  DatasetGroupLookup,
  DataTotalItem,
  getDatasetsGroupLookup,
  getDatasetsGroups,
  getDatasetsUniqueGroups,
  getDateFormatByLookup,
  normalizeDatasetsGroupValues,
  prepareDataset,
  syncSortedDatasetsGroups
} from '@modules/charts';
import { getThemeVarBorderRadiusValue, ThemeContext, ThemeVar } from '@modules/theme-components';
import { elementResize$, elementSize$, generateAlphanumeric, isSet, numeralFormat, TypedChanges } from '@shared';

// TODO: Refactor import
import { getColorHex, getColorHexStr, parseColor } from '../../../colors/utils/colors';

import { DataTooltipController } from '../../services/data-tooltip-controller/data-tooltip.controller';

import { DataClickEvent } from '../../data/events';
import { fitXAxisLabelWithVisibility, getYAxisWidth } from '../../utils/d3';

interface DatasetGroup {
  datasetIndex: number;
  groupIndex: number;
}

function isDatasetGroupEqual(lhs: DatasetGroup, rhs: DatasetGroup): boolean {
  return lhs.datasetIndex == rhs.datasetIndex && lhs.groupIndex == rhs.groupIndex;
}

@Component({
  selector: 'app-bar-chart2',
  templateUrl: './bar-chart2.component.html',
  providers: [DataTooltipController],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarChart2Component implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() datasets: Dataset[] = [];
  @Input() stacked = false;
  @Input() percentage = false;
  @Input() yFormat: string;
  @Input() min: number;
  @Input() max: number;
  @Input() defaultColors = CHART_COLORS;
  @Input() animate = true;
  @Input() xAxisVisible = true;
  @Input() yAxisVisible = true;
  @Input() legend = true;
  @Input() interactive = true;
  @Input() datasetBackground = true;
  @Input() dataClickEnabled = false;
  // @Input() width = 3;
  @Input() trackItem = false;
  @Input() theme = false;
  @Output() itemEnter = new EventEmitter<{ x: number; y: number }>();
  @Output() itemLeave = new EventEmitter<void>();
  @Output() dataClick = new EventEmitter<DataClickEvent>();

  @ViewChild('canvas') canvasElement: ElementRef;
  @ViewChild('svg') svgElement: ElementRef;
  @ViewChild('tooltip_container') tooltipContainerElement: ElementRef;
  @ViewChild(CdkPortalOutlet) portalOutlet: CdkPortalOutlet;

  data: Dataset<number, string | moment.Moment>[] = [];
  dataGroups: (string | moment.Moment)[] = [];
  dataGroupLookup?: DatasetGroupLookup;
  dataTotal: DataTotalItem[] = [];
  margin = { top: 8, right: 8, bottom: 22, left: 40 };
  width: number;
  height: number;
  svg: Selection<SVGGElement, {}, any, {}>;
  xGroupScale: ScaleBand<any>;
  xSubgroupScale: ScaleBand<any>;
  xAxis: Selection<SVGGElement, {}, any, {}>;
  yScale: ScaleLinear<number, number>;
  yAxis: Selection<SVGGElement, {}, any, {}>;
  stackSeries: Series<number, string>[];
  stackTotalY: number[];
  hoverDatasetGroup$ = new BehaviorSubject<DatasetGroup>(undefined);
  hoverLegendDatasetIndex$ = new BehaviorSubject<number>(undefined);
  selectedDatasetIndexes: { [k: number]: boolean };
  uid = generateAlphanumeric(8);

  getId(name: string): string {
    return `${name}-${this.uid}`;
  }

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private dataTooltip: DataTooltipController,
    private themeContext: ThemeContext,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<BarChart2Component>): void {
    if (changes.datasets || changes.percentage) {
      this.data = this.datasets.map(dataset => prepareDataset(dataset));
      this.dataGroupLookup = getDatasetsGroupLookup(this.data);
      this.dataGroups = getDatasetsUniqueGroups(this.data);

      applyDatasetsDefaultColors(this.data, this.defaultColors);
      syncSortedDatasetsGroups(this.data, this.dataGroups);

      if (this.percentage) {
        normalizeDatasetsGroupValues(this.data, this.dataGroups);
      }

      this.dataTotal = getDatasetsGroups(this.data);
    }

    if (this.svg) {
      this.rerender();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();

      elementSize$(this.canvasElement.nativeElement)
        .pipe(skip(1), untilDestroyed(this))
        .subscribe(() => this.onResize());
    }, 0);
  }

  init() {
    this.initBounds();
    this.initSvg();
    this.initYAxis();
    this.renderYAxis();
    this.fitYAxis();
    this.initXAxis();
    this.renderXAxis();
    this.renderBar();
    this.renderGradients();
    this.initDatasetHover();
  }

  initBounds() {
    const width = this.canvasElement.nativeElement.offsetWidth;
    const height = this.canvasElement.nativeElement.offsetHeight;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;
  }

  initSvg() {
    this.svg = select(this.svgElement.nativeElement)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);
  }

  initXAxis() {
    this.xAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_x');
  }

  renderXAxis() {
    const xGroups = this.dataGroups.map(d => {
      if (d instanceof moment) {
        const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
        return (d as moment.Moment).format(format);
      } else {
        return String(d);
      }
    });
    const xGroupsDate = this.dataGroups.every(item => item instanceof moment);

    this.xGroupScale = scaleBand().domain(xGroups).range([0, this.width]).padding(0.2);

    this.xSubgroupScale = scaleBand()
      .domain(this.data.map((item, i) => i.toString()))
      .range([0, this.xGroupScale.bandwidth()])
      .padding(0.05);

    if (!this.xAxisVisible) {
      this.xAxis.selectChildren().remove();
      return;
    }

    const ticks = Math.floor(this.width / 80);
    const axisGenerator = axisBottom(this.xGroupScale).ticks(ticks).tickSize(0).tickPadding(10);

    const axis = this.xAxis.attr('transform', `translate(0,${this.height})`).call(axisGenerator);

    fitXAxisLabelWithVisibility(axis, this.el.nativeElement);

    this.setAxisClasses(axis);
  }

  initYAxis() {
    this.yAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_y');
  }

  renderYAxis() {
    if (this.stacked) {
      const stackGenerator = stack<number>()
        .keys(this.data.map((item, i) => i.toString()))
        .value((item, key) => {
          const datasetIndex = parseInt(key, 10);
          return this.data[datasetIndex].dataset[item].value;
        });
      this.stackSeries = stackGenerator(this.dataGroups.map((item, i) => i));
      this.stackTotalY = this.stackSeries.reduce((acc, dataset) => {
        dataset.forEach(item => {
          acc.push(item[0]);
          acc.push(item[1]);
        });
        return acc;
      }, []);
    } else {
      this.stackSeries = undefined;
      this.stackTotalY = undefined;
    }

    const domain = this.stackSeries ? extent(this.stackTotalY) : extent(this.dataTotal, d => d.item.value);
    const domainExpand = 0.05 * Math.abs(domain[1] - domain[0]);

    if (domain[0] !== 0) {
      domain[0] -= domainExpand;
    }

    domain[1] += domainExpand;

    if (isSet(this.min)) {
      domain[0] = this.min;
    }

    if (isSet(this.max)) {
      domain[1] = this.max;
    }

    this.yScale = scaleLinear().domain(domain).range([this.height, 0]);

    if (!this.yAxisVisible) {
      this.yAxis.selectChildren().remove();
      return;
    }

    const ticks = Math.floor(this.height / 50);
    const axisGenerator = axisLeft(this.yScale)
      .ticks(ticks)
      .tickSize(-this.width)
      .tickPadding(10)
      .tickFormat(value => {
        const yFormat = this.percentage ? '0%' : this.yFormat;

        if (!isSet(yFormat)) {
          return String(value);
        }

        return numeralFormat(value, yFormat);
      });

    const axis = this.yAxis.call(axisGenerator);

    this.setAxisClasses(axis);
  }

  setAxisClasses(axis: Selection<SVGElement, any, any, any>) {
    axis.selectAll('.domain').attr('class', 'chart-axis-domain domain');
    axis.selectAll('.tick').attr('class', 'chart-axis-tick-group tick');
    axis.selectAll('.chart-axis-tick-group line').attr('class', 'chart-axis-tick');
    axis.selectAll('.chart-axis-tick-group text').attr('class', 'chart-axis-label');
  }

  fitYAxis() {
    const width = getYAxisWidth(this.yAxis);

    this.margin.left = width + 10;
    this.width = this.canvasElement.nativeElement.offsetWidth - this.margin.left - this.margin.right;
    this.svg.attr('transform', `translate(${this.margin.left},${this.margin.top})`);
  }

  renderBar() {
    const minBarHeight = 5;
    const borderRadius = getThemeVarBorderRadiusValue(this.themeContext.options.borderRadius, ThemeVar.BorderRadiusS);

    if (this.stacked) {
      const datasets = this.stackSeries.map((dataset, datasetIndex) => {
        return {
          groups: dataset.map((item, groupIndex) => {
            return {
              dataset: this.data[datasetIndex],
              datasetIndex: datasetIndex,
              yFrom: item[0],
              yTo: item[1],
              group: this.data[datasetIndex].dataset[groupIndex].group,
              groupIndex: groupIndex,
              value: this.data[datasetIndex].dataset[groupIndex].value
            };
          })
        };
      });

      const innerPadding = 2;

      this.svg
        .selectAll('.chart-bar-dataset')
        .data(datasets)
        .join('g')
        .attr('class', 'chart-bar-dataset')
        .selectAll('.chart-bar')
        .data(d => d.groups)
        .join('rect')
        .attr('class', d => {
          const classes = [
            'chart-bar',
            `chart-bar_index-${d.datasetIndex}`,
            `chart-bar_group-index-${d.datasetIndex}-${d.groupIndex}`
          ];

          if (this.dataClickEnabled) {
            classes.push('chart-bar_clickable');
          }

          return classes.join(' ');
        })
        .attr('rx', borderRadius)
        .attr('ry', borderRadius)
        .attr('x', d => {
          let group: string;

          if (d.group instanceof moment) {
            const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
            group = (d.group as moment.Moment).format(format);
          } else {
            group = String(d.group);
          }

          return this.xGroupScale(group);
        })
        .attr('y', d => this.yScale(d.yTo) + innerPadding * 0.5)
        .attr('width', this.xGroupScale.bandwidth())
        .attr('height', d => Math.max(this.yScale(d.yFrom) - this.yScale(d.yTo) - innerPadding, 0))
        .attr('fill', d => {
          const colorHex = getColorHex(d.dataset.color);
          const clr = parseColor(colorHex, '#000');
          return clr.string();
        })
        .attr('fill', d => {
          const barGradient = this.getId(`bar-gradient-${d.datasetIndex}`);
          return `url(#${barGradient})`;
        })
        .on('mouseenter', (e, d) =>
          this.onMouseEnter({
            datasetIndex: d.datasetIndex,
            group: d.group,
            groupIndex: d.groupIndex,
            value: d.value,
            event: e
          })
        )
        .on('mousemove', e => this.onMouseMove(e))
        .on('mouseleave', () => this.onMouseLeave())
        .on('click', (e, d) => {
          const group = this.data[d.datasetIndex].dataset[d.groupIndex].group;
          const group2 = this.data[d.datasetIndex].dataset[d.groupIndex].group2;
          const group3 = this.data[d.datasetIndex].dataset[d.groupIndex].group3;
          const value = this.data[d.datasetIndex].dataset[d.groupIndex].value;

          this.onClick({
            datasetIndex: d.datasetIndex,
            groupIndex: d.groupIndex,
            group: group,
            group2: group2,
            group3: group3,
            value: value,
            element: e.target
          });
        });
    } else {
      const hasNegativeValues = this.dataTotal.some(item => item.item.value < 0);
      const groups = this.svg
        .selectAll('.chart-bar-group')
        .data(this.dataGroups.map((item, i) => ({ group: item, index: i })))
        .join('g')
        .attr('class', 'chart-bar-group')
        .attr('transform', d => {
          let group: string;

          if (d.group instanceof moment) {
            const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
            group = (d.group as moment.Moment).format(format);
          } else {
            group = String(d.group);
          }

          return `translate(${this.xGroupScale(group)},0)`;
        });

      groups
        .selectAll('.chart-bar')
        .data(d => {
          return this.data.map((item, i) => {
            return {
              dataset: item,
              datasetIndex: i,
              value: item.dataset[d.index].value,
              group: d.group,
              groupIndex: d.index
            };
          });
        })
        .join('rect')
        .attr('class', d => {
          const classes = [
            'chart-bar',
            `chart-bar_index-${d.datasetIndex}`,
            `chart-bar_group-index-${d.datasetIndex}-${d.groupIndex}`
          ];

          if (this.dataClickEnabled) {
            classes.push('chart-bar_clickable');
          }

          return classes.join(' ');
        })
        .attr('rx', borderRadius)
        .attr('ry', borderRadius)
        .attr('x', d => this.xSubgroupScale(d.datasetIndex.toString()))
        .attr('y', d => {
          if (hasNegativeValues) {
            const y = this.yScale(Math.max(d.value, 0));
            const height = Math.abs(this.yScale(0) - this.yScale(d.value));

            if (d.value >= 0 && height < minBarHeight) {
              return this.yScale(0) - minBarHeight;
            } else {
              return y;
            }
          } else {
            const y = this.yScale(d.value);
            const height = this.height - this.yScale(d.value);

            if (d.value >= 0 && height < minBarHeight) {
              return this.height - minBarHeight;
            } else {
              return y;
            }
          }
        })
        .attr('width', this.xSubgroupScale.bandwidth())
        .attr('height', d => {
          if (hasNegativeValues) {
            const height = Math.abs(this.yScale(0) - this.yScale(d.value));
            return Math.max(height, minBarHeight);
          } else {
            const height = this.height - this.yScale(d.value);
            return Math.max(height, minBarHeight);
          }
        })
        .attr('fill', d => {
          const colorHex = getColorHex(d.dataset.color);
          const clr = parseColor(colorHex, '#000');
          return clr.string();
        })
        .attr('fill', d => {
          const barGradient = this.getId(`bar-gradient-${d.datasetIndex}`);
          return `url(#${barGradient})`;
        })
        .on('mouseenter', (e, d) =>
          this.onMouseEnter({
            datasetIndex: d.datasetIndex,
            group: d.group,
            groupIndex: d.groupIndex,
            value: d.value,
            event: e
          })
        )
        .on('mousemove', e => this.onMouseMove(e))
        .on('mouseleave', () => this.onMouseLeave())
        .on('click', (e, d) => {
          const group = this.data[d.datasetIndex].dataset[d.groupIndex].group;
          const group2 = this.data[d.datasetIndex].dataset[d.groupIndex].group2;
          const group3 = this.data[d.datasetIndex].dataset[d.groupIndex].group3;
          const value = this.data[d.datasetIndex].dataset[d.groupIndex].value;

          this.onClick({
            datasetIndex: d.datasetIndex,
            groupIndex: d.groupIndex,
            group: group,
            group2: group2,
            group3: group3,
            value: value,
            element: e.target
          });
        });
    }
  }

  renderGradients() {
    const gradients = this.svg
      .selectAll('.chart-bar-gradient')
      .data(this.data.map((item, i) => ({ dataset: item, index: i })))
      .join('linearGradient')
      .attr('id', d => this.getId(`bar-gradient-${d.index}`))
      .attr('class', 'chart-bar-gradient');

    if (this.stackSeries) {
      gradients
        .attr('gradientUnits', 'objectBoundingBox')
        .attr('x1', '0%')
        .attr('y1', '100%')
        .attr('x2', '0%')
        .attr('y2', '0%');
    } else {
      gradients
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', this.height)
        .attr('x2', 0)
        .attr('y2', d => {
          const maxValue = max(this.data[d.index].dataset, item => item.value);
          return this.yScale(maxValue);
        });
    }

    gradients
      .selectAll('stop')
      .data(d => {
        const colorHex = getColorHex(this.data[d.index].color);
        const clr = parseColor(colorHex, '#000');
        return [
          { offset: '0%', color: clr.lighten(0.1) },
          { offset: '100%', color: clr.darken(0.1) }
        ];
      })
      .join('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color);
  }

  initDatasetHover() {
    this.hoverLegendDatasetIndex$.pipe(untilDestroyed(this)).subscribe(hoverIndex => {
      this.data.forEach((item, i) => {
        const nodes = this.svg.selectAll<SVGRectElement, any>(`.chart-bar_index-${i}`).nodes();
        if (!isSet(hoverIndex) || i === hoverIndex) {
          nodes.forEach(node => node.classList.remove('chart-bar_disabled'));
        } else {
          nodes.forEach(node => node.classList.add('chart-bar_disabled'));
        }
      });
    });

    this.hoverDatasetGroup$.pipe(untilDestroyed(this)).subscribe(hoverGroup => {
      this.getAllDatasetGroups().forEach(item => {
        const selector = `.chart-bar_group-index-${item.datasetIndex}-${item.groupIndex}`;
        const nodes = this.svg.selectAll<SVGRectElement, any>(selector).nodes();
        if (!isSet(hoverGroup) || isDatasetGroupEqual(item, hoverGroup)) {
          nodes.forEach(node => node.classList.remove('chart-bar_disabled'));
        } else {
          nodes.forEach(node => node.classList.add('chart-bar_disabled'));
        }
      });
    });
  }

  onMouseEnter(options: {
    datasetIndex: number;
    group: string | moment.Moment;
    groupIndex: number;
    value: number;
    event: MouseEvent;
  }) {
    if (!this.interactive) {
      return;
    }

    if ((event.target as SVGElement).classList.contains('chart-bar_hidden')) {
      return;
    }

    this.dataTooltip.close();

    this.hoverDatasetGroup$.next({ datasetIndex: options.datasetIndex, groupIndex: options.groupIndex });

    let group: string;

    if (options.group instanceof moment) {
      const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
      group = (options.group as moment.Moment).format(format);
    } else {
      group = options.group as string;
    }

    // let group: string;
    //
    // if (options.group instanceof moment) {
    //   // group = (options.group as moment.Moment).toISOString();
    //   const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
    //   group = (options.group as moment.Moment).format(format);
    // } else {
    //   group = String(options.group);
    // }
    //
    // let x: number;
    // let y: number;
    //
    // if (this.stackSeries) {
    //   const groupX = this.xGroupScale(options.group);
    //   const yTo = this.stackSeries[options.datasetIndex][options.groupIndex][1];
    //   x = groupX + this.xGroupScale.bandwidth() * 0.5;
    //   y = this.yScale(yTo);
    // } else {
    //   const groupX = this.xGroupScale(options.group);
    //   const subgroupX = this.xSubgroupScale(options.datasetIndex.toString());
    //   x = groupX + subgroupX + this.xSubgroupScale.bandwidth() * 0.5;
    //   y = this.yScale(options.value);
    // }

    const [pointerX, pointerY] = pointer(options.event, this.el.nativeElement);

    this.dataTooltip.show({
      group: group,
      datasets: this.data.map((dataset, i) => {
        const defaultLabel = this.data.length > 1 ? `Dataset ${i + 1}` : undefined;
        return {
          value: dataset.dataset[options.groupIndex].value,
          valueFormat: dataset.format,
          label: isSet(dataset.name) ? dataset.name : defaultLabel,
          color: dataset.color
        };
      }),
      datasetActiveIndex: options.datasetIndex,
      valueFormat: this.percentage ? '0%' : this.yFormat,
      theme: this.theme,
      x: pointerX,
      y: pointerY,
      portalOutlet: this.portalOutlet
    });
  }

  onMouseMove(e: MouseEvent) {
    if (!this.interactive) {
      return;
    }

    const [pointerX, pointerY] = pointer(e, this.el.nativeElement);

    this.dataTooltip.move(pointerX, pointerY, true);
  }

  onMouseLeave() {
    if (!this.interactive) {
      return;
    }

    this.hoverDatasetGroup$.next(undefined);

    this.dataTooltip.close();
  }

  onClick(options: DataClickEvent) {
    if (!this.dataClickEnabled) {
      return;
    }

    this.dataClick.emit(options);
  }

  get selectedDatasetCount(): number {
    return this.selectedDatasetIndexes ? keys(this.selectedDatasetIndexes).length : 0;
  }

  toggleSelectedDatasetIndex(index: number) {
    if (!this.interactive) {
      return;
    }

    if (!this.selectedDatasetIndexes) {
      this.selectedDatasetIndexes = fromPairs(this.data.map((item, i) => [i, true]).filter(([i]) => i !== index));
    } else if (this.selectedDatasetIndexes[index]) {
      delete this.selectedDatasetIndexes[index];
    } else {
      this.selectedDatasetIndexes[index] = true;

      if (this.selectedDatasetCount === this.data.length) {
        this.selectedDatasetIndexes = undefined;
      }
    }

    this.cd.markForCheck();

    this.data.forEach((item, i) => {
      const nodes = this.svg.selectAll<SVGRectElement, any>(`.chart-bar_index-${i}`).nodes();
      if (!this.selectedDatasetIndexes || this.selectedDatasetIndexes[i]) {
        nodes.forEach(node => node.classList.remove('chart-bar_hidden'));
      } else {
        nodes.forEach(node => node.classList.add('chart-bar_hidden'));
      }
    });
  }

  getAllDatasetGroups(): DatasetGroup[] {
    return this.data.reduce((acc, dataset, d) => {
      dataset.dataset.forEach((group, g) => {
        acc.push({ datasetIndex: d, groupIndex: g });
      });
      return acc;
    }, []);
  }

  onLegendDatasetMouseEnter(index: number) {
    if (!this.interactive) {
      return;
    }

    this.hoverLegendDatasetIndex$.next(index);
  }

  onLegendDatasetMouseLeave() {
    if (!this.interactive) {
      return;
    }

    this.hoverLegendDatasetIndex$.next(undefined);
  }

  rerender() {
    this.initBounds();
    this.renderYAxis();
    this.fitYAxis();
    this.renderXAxis();
    this.renderBar();
    this.renderGradients();
  }

  onResize() {
    this.rerender();
  }

  colorDisplay(value: string): string {
    return getColorHexStr(value);
  }
}
