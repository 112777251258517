/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../custom-page-popup/custom-page-popup.component.ngfactory";
import * as i2 from "../../../customize/data/view-context-element";
import * as i3 from "../../../customize/data/view-context";
import * as i4 from "../custom-page-popup/custom-page-popup.component";
import * as i5 from "../../../customize/services/customize/customize.service";
import * as i6 from "../../../customize-bar/services/customize-bar/customize-bar.service";
import * as i7 from "../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i8 from "../../../../common/popups/services/popup/popup.service";
import * as i9 from "../../../../core/services/session-storage/session.storage";
import * as i10 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i11 from "@angular/common";
import * as i12 from "@angular/cdk/overlay";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "../custom-page-popups-menu/custom-page-popups-menu.component.ngfactory";
import * as i15 from "../custom-page-popups-menu/custom-page-popups-menu.component";
import * as i16 from "./custom-page-popups.component";
import * as i17 from "../../../admin-mode/providers/route-admin-mode";
import * as i18 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i19 from "../../../routing/services/routing/routing.service";
import * as i20 from "../../../../common/scroll/services/scroll/scroll.service";
import * as i21 from "../../../../common/dialogs/services/dialog/dialog.service";
var styles_CustomPagePopupsComponent = [];
var RenderType_CustomPagePopupsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomPagePopupsComponent, data: {} });
export { RenderType_CustomPagePopupsComponent as RenderType_CustomPagePopupsComponent };
function View_CustomPagePopupsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-custom-page-popup", [["class", "custom-page-popup-container__popup"]], [[2, "custom-page-popup-container__popup_visible", null], [2, "custom-page-popup-container__popup_scrollable", null], [1, "data-popup-id", 0], [1, "data-popup-name", 0]], [[null, "editPopup"], [null, "deletePopup"], [null, "closePopup"], [null, "duplicatePopup"], [null, "movePopupToPage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("editPopup" === en)) {
        var pd_0 = (_co.updatePopups(true) !== false);
        ad = (pd_0 && ad);
    } if (("deletePopup" === en)) {
        var pd_1 = (_co.deletePopup(_v.parent.context.$implicit.popup.uid, $event) !== false);
        ad = (pd_1 && ad);
    } if (("closePopup" === en)) {
        var pd_2 = (_co.closePopup(_v.parent.context.$implicit.popup.uid) !== false);
        ad = (pd_2 && ad);
    } if (("duplicatePopup" === en)) {
        var pd_3 = (_co.duplicatePopupFromToolbar(_v.parent.context.$implicit.popup.uid, { open: true, customize: true }) !== false);
        ad = (pd_3 && ad);
    } if (("movePopupToPage" === en)) {
        var pd_4 = (_co.moveToPageFromToolbar(_v.parent.context.$implicit.popup.uid, $event, { open: true }) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_CustomPagePopupComponent_0, i1.RenderType_CustomPagePopupComponent)), i0.ɵprd(131584, null, i2.ViewContextElement, i2.ViewContextElement, [i3.ViewContext]), i0.ɵdid(2, 4964352, [[3, 4], ["popup_element", 4]], 0, i4.CustomPagePopupComponent, [i0.ElementRef, i5.CustomizeService, i6.CustomizeBarService, [2, i7.CustomizeBarContext], i2.ViewContextElement, i8.PopupService, i9.SessionStorage, i10.UniversalAnalyticsService, i0.ChangeDetectorRef], { popup: [0, "popup"], viewSettings: [1, "viewSettings"], context: [2, "context"], customizing: [3, "customizing"], popupsContextElement: [4, "popupsContextElement"], visible: [5, "visible"], active: [6, "active"] }, { editPopup: "editPopup", deletePopup: "deletePopup", closePopup: "closePopup", duplicatePopup: "duplicatePopup", movePopupToPage: "movePopupToPage" }), i0.ɵpid(131072, i11.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_4 = _v.parent.context.$implicit.popup; var currVal_5 = _co.viewSettings; var currVal_6 = _co.context; var currVal_7 = i0.ɵunv(_v, 2, 3, i0.ɵnov(_v, 3).transform(_co.customizeService.layoutEnabled$)); var currVal_8 = _co.popupsContextElement; var currVal_9 = _v.parent.context.$implicit.opened; var currVal_10 = (_v.parent.context.$implicit.popup.uid === ((_co.activePopup == null) ? null : _co.activePopup.popup.uid)); _ck(_v, 2, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.opened; var currVal_1 = _v.parent.context.$implicit.popup.isSide(); var currVal_2 = i0.ɵnov(_v, 2).elementId; var currVal_3 = i0.ɵnov(_v, 2).elementName; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_CustomPagePopupsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CustomPagePopupsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "overlay-content overlay-content_animated"]], [[2, "customizable", null]], null, null, null, null)), i0.ɵpid(131072, i11.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomPagePopupsComponent_5)), i0.ɵdid(3, 540672, null, 0, i11.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_1 = i0.ɵnov(_v.parent.parent, 2); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform(_co.customizeService.layoutEnabled$)); _ck(_v, 0, 0, currVal_0); }); }
function View_CustomPagePopupsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.closePopup(_v.parent.context.$implicit.popup.uid) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.closePopup(_v.parent.context.$implicit.popup.uid) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_CustomPagePopupsComponent_4)), i0.ɵdid(2, 671744, null, 0, i12.CdkConnectedOverlay, [i12.Overlay, i0.TemplateRef, i0.ViewContainerRef, i12.ɵc, [2, i13.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(3, 2), i0.ɵpid(131072, i11.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.origin; var currVal_1 = _co.dropdownPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 3, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = true; var currVal_5 = !i0.ɵunv(_v, 2, 5, i0.ɵnov(_v, 4).transform(_co.customizeService.layoutEnabled$)); var currVal_6 = true; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_CustomPagePopupsComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CustomPagePopupsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomPagePopupsComponent_7)), i0.ɵdid(2, 540672, null, 0, i11.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 2); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomPagePopupsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["popup_container_element", 1]], null, 6, "div", [["class", "custom-page-popup-container"]], [[2, "custom-page-popup-container_visible", null], [2, "custom-page-popup-container_side", null], [2, "custom-page-popup-container_interactive", null], [2, "custom-page-popup-container_scrollable", null]], [[null, "scroll"], [null, "wheel"], [null, "click"], [null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } if (("wheel" === en)) {
        var pd_1 = ($event.stopPropagation() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onPopupContainerClicked(_v.context.$implicit.popup) !== false);
        ad = (pd_2 && ad);
    } if (("mousedown" === en)) {
        var pd_3 = (_co.onPopupContainerMouseDown($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, [[2, 0], ["popup_overlay_element", 1]], null, 0, "div", [["class", "custom-page-popup-container__overlay"]], [[2, "custom-page-popup-container__overlay_enabled", null]], null, null, null, null)), (_l()(), i0.ɵand(0, [["popup_tpl", 2]], null, 0, null, View_CustomPagePopupsComponent_2)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomPagePopupsComponent_3)), i0.ɵdid(4, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomPagePopupsComponent_6)), i0.ɵdid(6, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_5 = _v.context.$implicit.origin; _ck(_v, 4, 0, currVal_5); var currVal_6 = !_v.context.$implicit.origin; _ck(_v, 6, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.opened; var currVal_1 = _v.context.$implicit.popup.isSide(); var currVal_2 = _v.context.$implicit.popup.overlay; var currVal_3 = !_v.context.$implicit.popup.isSide(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.popup.overlay; _ck(_v, 1, 0, currVal_4); }); }
function View_CustomPagePopupsComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-page-popups-menu", [], null, [[null, "createPopup"], [null, "duplicatePopup"], [null, "movePopupToPage"], [null, "deletePopup"], [null, "openPopup"], [null, "customizePopup"], [null, "closePopup"], [null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("createPopup" === en)) {
        var pd_0 = (_co.createPopupFromToolbar(true, $event) !== false);
        ad = (pd_0 && ad);
    } if (("duplicatePopup" === en)) {
        var pd_1 = (_co.duplicatePopupFromToolbar($event, { open: true }) !== false);
        ad = (pd_1 && ad);
    } if (("movePopupToPage" === en)) {
        var pd_2 = (_co.moveToPageFromToolbar($event.uid, $event.page, { open: true }) !== false);
        ad = (pd_2 && ad);
    } if (("deletePopup" === en)) {
        var pd_3 = (_co.deletePopup($event, true) !== false);
        ad = (pd_3 && ad);
    } if (("openPopup" === en)) {
        var pd_4 = (_co.openPopup($event, { openComponents: true }) !== false);
        ad = (pd_4 && ad);
    } if (("customizePopup" === en)) {
        var pd_5 = (_co.openPopup($event, { customize: true }) !== false);
        ad = (pd_5 && ad);
    } if (("closePopup" === en)) {
        var pd_6 = (_co.closePopup($event) !== false);
        ad = (pd_6 && ad);
    } if (("closed" === en)) {
        var pd_7 = (_co.closedToolbar.emit() !== false);
        ad = (pd_7 && ad);
    } return ad; }, i14.View_CustomPagePopupsMenuComponent_0, i14.RenderType_CustomPagePopupsMenuComponent)), i0.ɵdid(1, 770048, null, 0, i15.CustomPagePopupsMenuComponent, [i5.CustomizeService, i0.ChangeDetectorRef], { viewSettings: [0, "viewSettings"], popups: [1, "popups"], openedPopupUids: [2, "openedPopupUids"], opened: [3, "opened"] }, { openPopup: "openPopup", closePopup: "closePopup", createPopup: "createPopup", customizePopup: "customizePopup", movePopupToPage: "movePopupToPage", duplicatePopup: "duplicatePopup", deletePopup: "deletePopup", closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewSettings; var currVal_1 = _co.viewSettings.popups; var currVal_2 = _co.openedPopupUids; var currVal_3 = _co.openedToolbar; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CustomPagePopupsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { popupContainerElements: 1 }), i0.ɵqud(671088640, 2, { popupOverlayElements: 1 }), i0.ɵqud(671088640, 3, { popupComponents: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomPagePopupsComponent_1)), i0.ɵdid(4, 278528, null, 0, i11.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomPagePopupsComponent_8)), i0.ɵdid(6, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popups; var currVal_1 = _co.trackPopupItem; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = (_co.mode == _co.adminModes.Builder); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_CustomPagePopupsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-page-popups", [], null, null, null, View_CustomPagePopupsComponent_0, RenderType_CustomPagePopupsComponent)), i0.ɵdid(1, 770048, null, 0, i16.CustomPagePopupsComponent, [i17.ROUTE_ADMIN_MODE, i5.CustomizeService, i7.CustomizeBarContext, i18.ActionControllerService, i9.SessionStorage, i19.RoutingService, i20.ScrollService, i21.DialogService, i0.Injector, i10.UniversalAnalyticsService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomPagePopupsComponentNgFactory = i0.ɵccf("app-custom-page-popups", i16.CustomPagePopupsComponent, View_CustomPagePopupsComponent_Host_0, { viewSettings: "viewSettings", context: "context", popupsContextElement: "popupsContextElement", openedToolbar: "openedToolbar" }, { closedToolbar: "closedToolbar" }, []);
export { CustomPagePopupsComponentNgFactory as CustomPagePopupsComponentNgFactory };
