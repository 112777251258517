var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, InjectionToken, Injector, NgZone, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, of, ReplaySubject } from 'rxjs';
import { delayWhen, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { AppDropListGroup } from '@common/drag-drop2';
import { getCurrentLanguage } from '@common/localize';
import { DocumentService } from '@core';
import { ActionControllerService, ActionService } from '@modules/action-queries';
import { AdminMode } from '@modules/admin-mode';
import { PageFontsService, ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { ChangeCustomizeBarComponent, CustomizeBarTab } from '@modules/change-components';
import { AdminComponentService, CustomizeService, CustomViewSettings, ElementItem, filterElementItems, isElementClickEvent, ViewContext, ViewContextElement, ViewContextElementFactory, ViewContextElementType, ViewContextGlobalsService, ViewSettingsService, ViewSettingsStore, ViewSettingsType } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService, ProjectPropertyEditController } from '@modules/customize-bar';
import { CustomPagePopupsComponent } from '@modules/customize-components';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { BUILDER_ADD_ELEMENT, ElementContainerService } from '@modules/customize-elements';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { ViewSettingsQueries } from '@modules/customize-utils';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import { FieldType, getFieldDescriptionByType } from '@modules/fields';
import { MenuSection, MenuService, MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectPropertyStorage, ProjectPropertyStore, ProjectPropertyType } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { CurrentUserStore } from '@modules/users';
import { ascComparator, capitalize, getLocationAppPath, getLocationQueryParams, isElementHasChild, isSet } from '@shared';
import { CustomPagePropertiesComponent } from '../../../customize-components/components/custom-page-properties/custom-page-properties.component';
import { CustomPageQueryController, queryControllerContextElementToken, queryControllerParentElementToken, queryControllerQueryToken } from './custom-page-query.controller';
export var parametersToken = new InjectionToken('parametersToken');
export var queriesToken = new InjectionToken('queriesToken');
export var actionsToken = new InjectionToken('actionsToken');
export var popupsToken = new InjectionToken('popupsToken');
export var teamToken = new InjectionToken('teamToken');
export var teamPropertiesToken = new InjectionToken('teamPropertiesToken');
export var userToken = new InjectionToken('userToken');
export var userPropertiesToken = new InjectionToken('userPropertiesToken');
export var globalPropertiesToken = new InjectionToken('globalPropertiesToken');
export var pagePropertiesToken = new InjectionToken('pagePropertiesToken');
export var appPropertiesToken = new InjectionToken('appPropertiesToken');
export var devicePropertiesToken = new InjectionToken('appDeviceToken');
var markClickEventSidebarProperty = '_markClickEventSidebarProperty';
export function markSidebarClickEvent(clickEvent) {
    clickEvent[markClickEventSidebarProperty] = true;
}
export function isSidebarClickEvent(clickEvent) {
    return !!clickEvent[markClickEventSidebarProperty];
}
var PageSettings;
(function (PageSettings) {
    PageSettings["Parameters"] = "parameters";
    PageSettings["Queries"] = "queries";
    PageSettings["Popups"] = "popups";
    PageSettings["Properties"] = "properties";
})(PageSettings || (PageSettings = {}));
var CustomPageComponent = /** @class */ (function () {
    function CustomPageComponent(mode, adminComponentService, customizeService, currentProjectStore, projectSettingsStore, currentEnvironmentStore, viewSettingsService, viewSettingsQueries, viewSettingsStore, menuSettingsStore, customizeBarService, customizeBarContext, elementContainerService, documentService, routing, context, globalsService, viewContextElementParameters, viewContextElementQueries, viewContextElementActions, viewContextElementPopups, viewContextElementTeam, viewContextElementTeamProperty, viewContextElementUser, viewContextElementUserProperty, viewContextElementGlobalProperty, viewContextElementPageProperty, viewContextElementAppProperty, viewContextElementDeviceProperty, activatedRoute, router, metaService, cd, menuService, currentUserStore, projectPropertyStore, projectPropertyEditController, modelDescriptionStore, actionControllerService, apiService, projectApiService, elementConfigurationService, viewContextElementFactory, pageFontsService, themeService, dialogService, injector, zone, analyticsService) {
        this.mode = mode;
        this.adminComponentService = adminComponentService;
        this.customizeService = customizeService;
        this.currentProjectStore = currentProjectStore;
        this.projectSettingsStore = projectSettingsStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.viewSettingsService = viewSettingsService;
        this.viewSettingsQueries = viewSettingsQueries;
        this.viewSettingsStore = viewSettingsStore;
        this.menuSettingsStore = menuSettingsStore;
        this.customizeBarService = customizeBarService;
        this.customizeBarContext = customizeBarContext;
        this.elementContainerService = elementContainerService;
        this.documentService = documentService;
        this.routing = routing;
        this.context = context;
        this.globalsService = globalsService;
        this.viewContextElementParameters = viewContextElementParameters;
        this.viewContextElementQueries = viewContextElementQueries;
        this.viewContextElementActions = viewContextElementActions;
        this.viewContextElementPopups = viewContextElementPopups;
        this.viewContextElementTeam = viewContextElementTeam;
        this.viewContextElementTeamProperty = viewContextElementTeamProperty;
        this.viewContextElementUser = viewContextElementUser;
        this.viewContextElementUserProperty = viewContextElementUserProperty;
        this.viewContextElementGlobalProperty = viewContextElementGlobalProperty;
        this.viewContextElementPageProperty = viewContextElementPageProperty;
        this.viewContextElementAppProperty = viewContextElementAppProperty;
        this.viewContextElementDeviceProperty = viewContextElementDeviceProperty;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.metaService = metaService;
        this.cd = cd;
        this.menuService = menuService;
        this.currentUserStore = currentUserStore;
        this.projectPropertyStore = projectPropertyStore;
        this.projectPropertyEditController = projectPropertyEditController;
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionControllerService = actionControllerService;
        this.apiService = apiService;
        this.projectApiService = projectApiService;
        this.elementConfigurationService = elementConfigurationService;
        this.viewContextElementFactory = viewContextElementFactory;
        this.pageFontsService = pageFontsService;
        this.themeService = themeService;
        this.dialogService = dialogService;
        this.injector = injector;
        this.zone = zone;
        this.analyticsService = analyticsService;
        this.dropListGroups = new QueryList();
        this.renameLoading = false;
        this.duplicateLoading = false;
        this.deleteLoading = false;
        this.viewContextInitialized = new ReplaySubject(1);
        this.queriesSubscriptions = [];
        this.pageSubscriptions = [];
        this.queryControllers = [];
        this.pageActions = [];
        this.pageSettingsItems = [
            {
                type: PageSettings.Parameters,
                label: 'Parameters',
                icon: 'input',
                iconSize: 20
            },
            {
                type: PageSettings.Queries,
                label: 'Actions',
                icon: 'play_2',
                iconSize: 17
            },
            {
                type: PageSettings.Popups,
                label: 'Overlays',
                icon: 'windows'
            },
            {
                type: PageSettings.Properties,
                label: 'Variables',
                icon: 'variable',
                iconSize: 19
            }
        ];
        this.adminModes = AdminMode;
        this.pageSettings = PageSettings;
    }
    CustomPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menuService.section = MenuSection.Default;
        this.customizeService.setHandler(this);
        this.allSettings$ = this.projectSettingsStore.getAllSettings$();
        this.updateCustomizeHandleInfo();
        this.initViewContextElements();
        this.allSettings$.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateBackgroundColor(); });
        this.projectPropertyStore
            .getGlobal()
            .pipe(untilDestroyed(this))
            .subscribe(function (properties) {
            properties = properties || [];
            var outputs = properties
                .map(function (item) {
                return __assign({ uniqueName: item.uid, name: item.name, icon: item.fieldDescription.icon }, (item.field
                    ? {
                        fieldType: item.field.field,
                        fieldParams: item.field.params
                    }
                    : {}));
            })
                .sort(function (lhs, rhs) { return ascComparator(lhs.name, rhs.name); });
            _this.viewContextElementGlobalProperty.setOutputs(outputs);
        });
        this.currentEnvironmentStore.globalStorage
            .getValues$()
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.viewContextElementGlobalProperty.setOutputValues(__assign({}, value));
        });
        combineLatest(this.currentUserStore.instance$, this.currentProjectStore.instance$, this.currentEnvironmentStore.instance$, this.projectPropertyStore.get(), this.currentEnvironmentStore.globalStorage.getValues$())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var currentUser = _a[0], currentProject = _a[1], currentEnvironment = _a[2], projectProperties = _a[3], globalProjectProperties = _a[4];
            var userProperties = projectProperties
                ? projectProperties.filter(function (item) { return item.type == ProjectPropertyType.User; })
                : [];
            var groupProperties = projectProperties
                ? projectProperties.filter(function (item) { return item.type == ProjectPropertyType.Group; })
                : [];
            _this.updateContextUser();
            _this.updateContextUserProperties(userProperties);
            _this.updateContextTeam();
            _this.updateContextTeamProperties(groupProperties);
            if (currentEnvironment.user) {
                var value = fromPairs(userProperties.map(function (property) { return [property.uid, currentEnvironment.user.getPropertyValue(property)]; }));
                _this.viewContextElementUserProperty.setOutputValues(value);
            }
            if (currentEnvironment.group) {
                var value = fromPairs(groupProperties.map(function (property) { return [property.uid, currentEnvironment.group.getPropertyValue(property)]; }));
                _this.viewContextElementTeamProperty.setOutputValues(value);
            }
            _this.viewContextElementTeam.setOutputValues(__assign({}, (_this.currentEnvironmentStore.instance.group
                ? {
                    uid: _this.currentEnvironmentStore.instance.group.uid,
                    name: _this.currentEnvironmentStore.instance.group.name
                }
                : {})));
            _this.viewContextElementUser.setOutputValues({
                email: currentUser ? currentUser.email : undefined,
                first_name: currentUser ? currentUser.firstName : undefined,
                last_name: currentUser ? currentUser.lastName : undefined,
                token: _this.apiService.getAccessToken() ? _this.apiService.getAccessToken() : undefined,
                project_token: _this.projectApiService.getAccessToken() ? _this.projectApiService.getAccessToken() : undefined,
                uid: _this.currentEnvironmentStore.instance.user ? _this.currentEnvironmentStore.instance.user.uid : undefined,
                language: getCurrentLanguage()
            });
            _this.viewContextElementAppProperty.patchOutputValues({
                name: currentProject.uniqueName,
                env_name: currentEnvironment.uniqueName
            });
            _this.viewContextInitialized.next();
        });
        merge(of({}), this.router.events.pipe(filter(function (item) { return item instanceof ActivationEnd; })))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.viewContextElementAppProperty.patchOutputValues({
                app_path: getLocationAppPath(),
                query_params: getLocationQueryParams()
            });
        });
        this.documentService.viewportSize$.pipe(untilDestroyed(this)).subscribe(function (value) {
            var type = _this.documentService.getViewportType(value.width);
            _this.viewContextElementDeviceProperty.patchOutputValues({
                is_desktop: _this.documentService.isDesktopViewportType(type),
                is_mobile: _this.documentService.isMobileViewportType(type),
                is_phone: _this.documentService.isPhoneViewportType(type),
                is_tablet: _this.documentService.isTabletViewportType(type),
                screen_width: value.width,
                screen_height: value.height
            });
        });
        merge(of(this.dropListGroups.toArray()), this.dropListGroups.changes.pipe(map(function () { return _this.dropListGroups.toArray(); })))
            .pipe(untilDestroyed(this))
            .subscribe(function (groups) {
            _this.context.dropListGroups = groups;
        });
    };
    CustomPageComponent.prototype.initViewContextElements = function () {
        var _this = this;
        this.viewContextElementParameters.unregister();
        this.viewContextElementParameters.initGlobal({ uniqueName: 'page', name: 'Page' });
        this.viewContextElementQueries.unregister();
        this.viewContextElementQueries.initGlobal({ uniqueName: 'queries', name: 'Page queries' });
        this.viewContextElementActions.unregister();
        this.viewContextElementActions.initGlobal({ uniqueName: 'actions', name: 'Page actions' });
        this.viewContextElementPopups.unregister();
        this.viewContextElementPopups.initGlobal({ uniqueName: 'modals', name: 'Modals' });
        this.viewContextElementTeam.unregister();
        this.viewContextElementTeam.initGlobal({ uniqueName: 'group', name: 'Team' });
        this.viewContextElementTeamProperty.unregister();
        this.viewContextElementTeamProperty.initGlobal({
            uniqueName: 'team_properties',
            name: 'Team Properties',
            action: {
                label: 'Add Property',
                icon: 'plus',
                handler: function () {
                    return _this.projectPropertyEditController
                        .create({
                        type: ProjectPropertyType.Group,
                        context: _this.context,
                        analyticsSource: 'component_input'
                    })
                        .pipe(map(function (item) {
                        return {
                            insertToken: ['team_properties', item.property.uid]
                        };
                    }));
                }
            },
            documentation: 'conditional-visibility/properties'
        });
        this.viewContextElementUser.unregister();
        this.viewContextElementUser.initGlobal({ uniqueName: 'user', name: 'User' });
        this.viewContextElementUserProperty.unregister();
        this.viewContextElementUserProperty.initGlobal({
            uniqueName: 'user_properties',
            name: 'User Properties',
            action: {
                label: 'Add Property',
                icon: 'plus',
                handler: function () {
                    return _this.projectPropertyEditController
                        .create({
                        type: ProjectPropertyType.User,
                        context: _this.context,
                        analyticsSource: 'component_input'
                    })
                        .pipe(map(function (item) {
                        return {
                            insertToken: ['user_properties', item.property.uid]
                        };
                    }));
                }
            },
            documentation: 'conditional-visibility/properties'
        });
        this.viewContextElementGlobalProperty.unregister();
        this.viewContextElementGlobalProperty.initGlobal({
            uniqueName: 'global_variables',
            name: 'Global variables',
            action: {
                label: 'Add Variable',
                icon: 'plus',
                handler: function () {
                    return _this.projectPropertyEditController
                        .create({
                        type: ProjectPropertyType.Global,
                        defaultName: 'variable',
                        defaultValueEnabled: true,
                        context: _this.context,
                        analyticsSource: 'component_input'
                    })
                        .pipe(map(function (item) {
                        return {
                            insertToken: ['global_variables', item.property.uid]
                        };
                    }));
                }
            },
            documentation: 'conditional-visibility/properties'
        });
        this.viewContextElementPageProperty.unregister();
        this.viewContextElementPageProperty.initGlobal({
            uniqueName: 'page_variables',
            name: 'Page variables',
            action: {
                label: 'Add Variable',
                icon: 'plus',
                handler: function () {
                    return _this.projectPropertyEditController
                        .create({
                        type: ProjectPropertyType.Page,
                        defaultName: 'variable',
                        defaultValueEnabled: true,
                        pageUid: _this.currentViewSettings.uid,
                        context: _this.context,
                        analyticsSource: 'component_input'
                    })
                        .pipe(map(function (item) {
                        return {
                            insertToken: ['page_variables', item.property.uid]
                        };
                    }));
                }
            },
            documentation: 'conditional-visibility/properties'
        });
        this.viewContextElementAppProperty.unregister();
        this.viewContextElementAppProperty.initGlobal({ uniqueName: 'app', name: 'Application' });
        this.viewContextElementAppProperty.setOutputs([
            {
                uniqueName: 'name',
                name: 'App name',
                icon: 'home'
            },
            {
                uniqueName: 'env_name',
                name: 'Environment name',
                icon: 'tag'
            },
            {
                uniqueName: 'app_path',
                name: 'App path',
                icon: 'console'
            },
            {
                uniqueName: 'query_params',
                name: 'URL query parameters',
                icon: 'components',
                fieldType: FieldType.JSON
            }
        ]);
        this.viewContextElementDeviceProperty.unregister();
        this.viewContextElementDeviceProperty.initGlobal({ uniqueName: 'device', name: 'Device' });
        this.viewContextElementDeviceProperty.setOutputs([
            {
                uniqueName: 'is_desktop',
                name: 'Is Desktop',
                icon: 'pages'
            },
            {
                uniqueName: 'is_mobile',
                name: 'Is Mobile',
                subtitle: '(Phone or Tablet)',
                icon: 'pages'
            },
            {
                uniqueName: 'is_phone',
                name: 'Is Phone',
                icon: 'pages'
            },
            {
                uniqueName: 'is_tablet',
                name: 'Is Tablet',
                icon: 'pages'
            },
            {
                uniqueName: 'screen_width',
                name: 'Screen width',
                icon: 'align_horizontal_fill'
            },
            {
                uniqueName: 'screen_height',
                name: 'Screen height',
                icon: 'align_vertical_fill'
            }
        ]);
    };
    CustomPageComponent.prototype.updateContextUser = function () {
        this.viewContextElementUser.setOutputs([
            {
                uniqueName: 'email',
                name: 'Email',
                icon: 'email'
            },
            {
                uniqueName: 'first_name',
                name: 'First name',
                icon: 'user'
            },
            {
                uniqueName: 'last_name',
                name: 'Last name',
                icon: 'user'
            },
            {
                uniqueName: 'token',
                name: 'User token',
                icon: 'key'
            },
            {
                uniqueName: 'project_token',
                name: 'User app token',
                icon: 'key'
            },
            {
                uniqueName: 'uid',
                name: 'User ID',
                icon: 'number'
            },
            {
                uniqueName: 'language',
                name: 'Language',
                icon: 'earth_planet'
            }
        ]);
    };
    CustomPageComponent.prototype.updateContextUserProperties = function (properties) {
        var outputs = properties.map(function (item) {
            return __assign({ uniqueName: item.uid, name: item.name, icon: item.fieldDescription.icon }, (item.field
                ? {
                    fieldType: item.field.field,
                    fieldParams: item.field.params
                }
                : {}));
        });
        this.viewContextElementUserProperty.setOutputs(outputs);
    };
    CustomPageComponent.prototype.updateContextTeam = function () {
        this.viewContextElementTeam.setOutputs([
            {
                uniqueName: 'uid',
                name: 'Team ID',
                icon: 'number'
            },
            {
                uniqueName: 'name',
                name: 'Team name',
                icon: 'users_teams'
            }
        ]);
    };
    CustomPageComponent.prototype.updateContextTeamProperties = function (properties) {
        var outputs = properties.map(function (item) {
            return __assign({ uniqueName: item.uid, name: item.name, icon: item.fieldDescription.icon }, (item.field
                ? {
                    fieldType: item.field.field,
                    fieldParams: item.field.params
                }
                : {}));
        });
        this.viewContextElementTeamProperty.setOutputs(outputs);
    };
    CustomPageComponent.prototype.ngOnDestroy = function () {
        this.customizeService.layoutCustomization = undefined;
        this.customizeService.unsetHandler(this);
        this.adminComponentService.contentWithSidebar$.next(false);
        this.themeService.backgroundColor = undefined;
    };
    CustomPageComponent.prototype.onBeforeDestroy = function () {
        this.context.pauseElements();
        this.context.clear();
    };
    CustomPageComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['uniqueName'] &&
            (!this.currentViewSettings || this.currentViewSettings.uniqueName != this.uniqueName)) {
            this.context.pauseElements();
            this.loadSubscription = this.getViewSettings().subscribe(function () {
                _this.cd.detectChanges();
                _this.context.resumeElements();
            });
        }
        if (changes['params']) {
            this.updateContextPage();
        }
    };
    CustomPageComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var downTarget;
        this.adminComponentService.contentMousedown.pipe(untilDestroyed(this)).subscribe(function (e) {
            downTarget = e.target;
        });
        this.adminComponentService.contentClick.pipe(untilDestroyed(this)).subscribe(function (e) {
            if (!_this.sidebarElement) {
                return;
            }
            if (!isElementClickEvent(e) &&
                !isSidebarClickEvent(e) &&
                !isElementHasChild(_this.sidebarElement.nativeElement, downTarget, true)) {
                _this.customizeBarContext.resetSettingsComponent();
            }
        });
        this.adminComponentService.contentWithSidebar$.next(true);
    };
    CustomPageComponent.prototype.createCustomPageQueryController = function (query) {
        var contextElement = this.viewContextElementFactory.create(this.injector);
        return Injector.create({
            providers: [
                { provide: queryControllerQueryToken, useValue: query },
                { provide: queryControllerContextElementToken, useValue: contextElement },
                { provide: queryControllerParentElementToken, useValue: this.viewContextElementQueries },
                {
                    provide: CustomPageQueryController,
                    deps: [
                        queryControllerQueryToken,
                        queryControllerContextElementToken,
                        queryControllerParentElementToken,
                        ViewContext,
                        CurrentProjectStore,
                        CurrentEnvironmentStore,
                        ModelDescriptionStore,
                        ActionService,
                        ModelDescriptionDataSourceService,
                        DataSourceGeneratorService
                    ]
                }
            ],
            parent: this.injector
        }).get(CustomPageQueryController);
    };
    CustomPageComponent.prototype.createPageAction = function (action) {
        return this.viewContextElementFactory.create(this.injector);
    };
    CustomPageComponent.prototype.setInitialViewSettings = function (viewSettings, resetTrack) {
        if (resetTrack === void 0) { resetTrack = true; }
        this.initialViewSettings = viewSettings;
        if (viewSettings) {
            this.setCurrentViewSettings(cloneDeep(viewSettings));
        }
        else {
            this.setCurrentViewSettings(undefined);
        }
        if (resetTrack) {
            this.customizeService.startTrackChanges();
        }
    };
    CustomPageComponent.prototype.setCurrentViewSettings = function (viewSettings) {
        var _this = this;
        var prevPageUid = this.currentViewSettings ? this.currentViewSettings.uid : undefined;
        var newPageUid = viewSettings ? viewSettings.uid : undefined;
        var pageChanged = newPageUid != prevPageUid;
        if (pageChanged) {
            this.pageSubscriptions.forEach(function (item) { return item.unsubscribe(); });
            this.pageSubscriptions = [];
        }
        this.currentViewSettings = viewSettings;
        this.context.viewSettings = viewSettings;
        if (pageChanged && viewSettings) {
            this.context.pageStorage = Injector.create({
                providers: [
                    {
                        provide: ProjectPropertyStorage,
                        useFactory: function (projectPropertyStore) {
                            return new ProjectPropertyStorage(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, projectPropertyStore, ProjectPropertyType.Page, {
                                pageUid: viewSettings.uid
                            });
                        },
                        deps: [ProjectPropertyStore]
                    }
                ],
                parent: this.injector
            }).get(ProjectPropertyStorage);
            this.pageSubscriptions.push(this.projectPropertyStore
                .getPage(this.currentViewSettings.uid)
                .pipe(untilDestroyed(this))
                .subscribe(function (properties) {
                var outputs = properties
                    .map(function (item) {
                    return __assign({ uniqueName: item.uid, name: item.name, icon: item.fieldDescription.icon }, (item.field
                        ? {
                            fieldType: item.field.field,
                            fieldParams: item.field.params
                        }
                        : {}));
                })
                    .sort(function (lhs, rhs) { return ascComparator(lhs.name, rhs.name); });
                _this.viewContextElementPageProperty.setOutputs(outputs);
            }));
            this.pageSubscriptions.push(this.context.pageStorage
                .getValues$()
                .pipe(untilDestroyed(this))
                .subscribe(function (value) {
                _this.viewContextElementPageProperty.setOutputValues(__assign({}, value));
            }));
            this.pageSubscriptions.push(this.zone.onStable.pipe(take(1), untilDestroyed(this)).subscribe(function () {
                viewSettings.openActions.forEach(function (action) { return _this.executeAction(action); });
            }));
        }
        else if (!viewSettings) {
            this.context.pageStorage = undefined;
        }
        this.updateQueries(viewSettings);
        this.updateActions(viewSettings);
        this.updateBackgroundColor();
        this.cd.markForCheck();
    };
    CustomPageComponent.prototype.updateQueries = function (viewSettings) {
        var _this = this;
        var queries = viewSettings ? viewSettings.queries : [];
        var queriesUids = queries.map(function (item) { return item.uid; });
        var removeQueries = this.queryControllers.filter(function (item) { return !queriesUids.includes(item.query.uid); });
        this.queryControllers = queries.map(function (query) {
            var controller = _this.queryControllers.find(function (item) { return item.query.uid == query.uid; });
            if (!controller) {
                controller = _this.createCustomPageQueryController(query);
                controller.ngOnInit();
            }
            else {
                controller.setQuery(query);
            }
            return controller;
        });
        removeQueries.forEach(function (item) { return item.ngOnDestroy(); });
    };
    CustomPageComponent.prototype.updateActions = function (viewSettings) {
        var _this = this;
        var actions = viewSettings ? viewSettings.openActions : [];
        var actionUids = actions.map(function (item) { return item.uid; });
        var removeActions = this.pageActions.filter(function (item) { return !actionUids.includes(item.uniqueName); });
        this.pageActions = actions.map(function (action, i) {
            var pageAction = _this.pageActions.find(function (item) { return item.uniqueName == action.uid; });
            if (!pageAction) {
                pageAction = _this.createPageAction(action);
                pageAction.initGlobal({
                    uniqueName: action.uid,
                    name: "Action " + (i + 1),
                    icon: 'power',
                    insert: true
                }, _this.viewContextElementActions);
            }
            else {
                pageAction.initInfo({ name: "Action " + (i + 1) });
            }
            pageAction.setActions([
                {
                    uniqueName: 'execute',
                    name: 'Run action',
                    icon: 'repeat',
                    parameters: [],
                    handler: function () { return _this.executeAction(action); }
                }
            ]);
            return pageAction;
        });
        removeActions.forEach(function (item) { return item.unregister(); });
    };
    CustomPageComponent.prototype.executeAction = function (action) {
        this.actionControllerService
            .execute(action, {
            context: this.context,
            confirmRouting: !!this.customizeService.enabled,
            injector: this.injector
        })
            .pipe(untilDestroyed(this))
            .subscribe();
    };
    CustomPageComponent.prototype.addSavedElementItem = function () {
        var _this = this;
        if (!this.currentViewSettings) {
            return;
        }
        var createdElement = this.elementContainerService.addSavedElement(this.currentViewSettings, undefined, BUILDER_ADD_ELEMENT, this.context);
        if (createdElement.element) {
            this.customizeService.registerCreatedElement(createdElement.element);
            this.customizeService.markChanged();
        }
        else if (createdElement.popup) {
            this.customizeService.markChanged();
            setTimeout(function () {
                _this.openPopup(createdElement.popup.uid, { openComponents: true });
            });
        }
    };
    CustomPageComponent.prototype.getViewSettings = function (resetTrack) {
        var _this = this;
        if (resetTrack === void 0) { resetTrack = true; }
        if (this.loadSubscription) {
            this.loadSubscription.unsubscribe();
            this.loadSubscription = undefined;
        }
        this.initialViewSettings = undefined;
        this.setCurrentViewSettings(this.currentViewSettings);
        this.context.clear(ViewContextElementType.Element);
        this.cd.detectChanges();
        this.customizeBarContext.resetSettingsComponent();
        if (this.customizeBarComponent) {
            this.customizeBarComponent.setSelectedTab(CustomizeBarTab.Components);
        }
        return (isSet(this.uniqueName)
            ? this.viewSettingsStore.getDetailFirst(this.uniqueName, ViewSettingsType.Custom)
            : this.viewSettingsStore.getDistinctName('New Page').pipe(map(function (name) {
                var page = new CustomViewSettings();
                page.name = name;
                return page;
            }))).pipe(delayWhen(function () { return _this.viewContextInitialized; }), tap(function (viewSettings) {
            _this.setInitialViewSettings(viewSettings, resetTrack);
            _this.context.objectType = 'custom_page';
            _this.context.objectId = _this.uniqueName;
            _this.cd.markForCheck();
            _this.metaService.set({ title: viewSettings ? viewSettings.name : 'Page not found' });
            _this.updateContextPage();
            _this.updateCustomizeHandleInfo();
            _this.addSavedElementItem();
            _this.customizeService.layoutCustomization = {
                subtitle: '',
                title: viewSettings ? 'Edit Page \n' + capitalize(viewSettings.name) : 'Edit Page',
                image: 'list',
                clickEvent: undefined,
                description: []
            };
        }), untilDestroyed(this));
    };
    CustomPageComponent.prototype.getCollaborationParams = function () {
        return {
            object_type: 'custom_page',
            object_id: this.uniqueName
        };
    };
    CustomPageComponent.prototype.getUserActivitiesParams = function () {
        return {
            view_settings: this.currentViewSettings ? this.currentViewSettings.uid : undefined
        };
    };
    CustomPageComponent.prototype.updateContextPage = function () {
        this.viewContextElementParameters.setOutputValues(this.params);
        this.viewContextElementParameters.setOutputs(this.currentViewSettings
            ? this.currentViewSettings.parameters.map(function (item) {
                var fieldDescription = getFieldDescriptionByType(item.field);
                return {
                    uniqueName: item.name,
                    name: item.verboseName || item.name,
                    icon: fieldDescription.icon
                };
            })
            : []);
    };
    CustomPageComponent.prototype.getQueryModelDescription = function (resource, query) {
        if (!query || query.queryType != QueryType.Simple || !query.simpleQuery) {
            return of(undefined);
        }
        var modelId = [resource, query.simpleQuery.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId);
    };
    CustomPageComponent.prototype.customizeViewSettings = function (result) {
        this.currentViewSettings.backgroundColor = result.backgroundColor;
        this.currentViewSettings.backgroundColorDark = result.backgroundColorDark;
        this.currentViewSettings.backgroundColor2 = result.backgroundColor2;
        this.currentViewSettings.backgroundColor2Dark = result.backgroundColor2Dark;
        this.currentViewSettings.backgroundColor3 = result.backgroundColor3;
        this.currentViewSettings.backgroundColor3Dark = result.backgroundColor3Dark;
        this.currentViewSettings.backgroundColor4 = result.backgroundColor4;
        this.currentViewSettings.backgroundColor4Dark = result.backgroundColor4Dark;
        this.currentViewSettings.backgroundColor5 = result.backgroundColor5;
        this.currentViewSettings.backgroundColor5Dark = result.backgroundColor5Dark;
        this.currentViewSettings.textColor = result.textColor;
        this.currentViewSettings.textColorDark = result.textColorDark;
        this.currentViewSettings.textColor2 = result.textColor2;
        this.currentViewSettings.textColor2Dark = result.textColor2Dark;
        this.currentViewSettings.textColor3 = result.textColor3;
        this.currentViewSettings.textColor3Dark = result.textColor3Dark;
        this.currentViewSettings.borderColor = result.borderColor;
        this.currentViewSettings.borderColorDark = result.borderColorDark;
        this.currentViewSettings.borderColor2 = result.borderColor2;
        this.currentViewSettings.borderColor2Dark = result.borderColor2Dark;
        this.currentViewSettings.borderColor3 = result.borderColor3;
        this.currentViewSettings.borderColor3Dark = result.borderColor3Dark;
        this.currentViewSettings.maxWidth = result.maxWidth;
        this.currentViewSettings.padding = result.padding;
        this.customizeService.markChanged();
        this.cd.markForCheck();
        this.updateBackgroundColor();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.SuccessfullySetUp, {
            PageID: this.currentViewSettings.uniqueName
        });
    };
    CustomPageComponent.prototype.updateViewSettingsParameters = function (result) {
        this.currentViewSettings.parameters = result.parameters;
        this.customizeService.markChanged();
        this.cd.markForCheck();
        this.updateContextPage();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.SuccessfullySetUp, {
            PageID: this.currentViewSettings.uniqueName
        });
    };
    CustomPageComponent.prototype.updateViewSettingsQueries = function (queries) {
        this.currentViewSettings.queries = queries;
        this.updateQueries(this.currentViewSettings);
        this.customizeService.markChanged();
        this.cd.markForCheck();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.SuccessfullySetUp, {
            PageID: this.currentViewSettings.uniqueName
        });
    };
    CustomPageComponent.prototype.updateViewSettingsActions = function (actions) {
        this.currentViewSettings.openActions = actions;
        this.updateActions(this.currentViewSettings);
        this.customizeService.markChanged();
        this.cd.markForCheck();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.SuccessfullySetUp, {
            PageID: this.currentViewSettings.uniqueName
        });
    };
    CustomPageComponent.prototype.updateCustomizeHandleInfo = function () {
        this.customizeService.setHandlerInfo(this, {
            breadcrumbs: [],
            page: this.currentViewSettings
        });
    };
    CustomPageComponent.prototype.updateBackgroundColor = function () {
        var allSettings = this.projectSettingsStore.getAllSettings();
        var allSettingsBackground = allSettings ? allSettings.backgroundColor : undefined;
        var allSettingsBackgroundDark = allSettings ? allSettings.backgroundColorDark : undefined;
        var pageBackground = this.currentViewSettings ? this.currentViewSettings.backgroundColor : undefined;
        var pageBackgroundDark = this.currentViewSettings ? this.currentViewSettings.backgroundColorDark : undefined;
        this.themeService.backgroundColor = pageBackground || allSettingsBackground;
        this.themeService.backgroundColorDark = pageBackgroundDark || allSettingsBackgroundDark;
    };
    CustomPageComponent.prototype.getChangesState = function () {
        return {
            viewSettings: cloneDeep(this.currentViewSettings)
        };
    };
    CustomPageComponent.prototype.setChangesState = function (state) {
        this.setCurrentViewSettings(cloneDeep(state.viewSettings));
    };
    CustomPageComponent.prototype.isChangesStateEqual = function (lhs, rhs) {
        var compare = ['params'];
        return isEqual(lhs.viewSettings.serialize(compare), rhs.viewSettings.serialize(compare));
    };
    CustomPageComponent.prototype.saveChangesState = function (state) {
        var _this = this;
        var create = !this.initialViewSettings.uid;
        var elementItems = filterElementItems(this.currentViewSettings.elements, function (item) { return item instanceof ElementItem; });
        return this.elementConfigurationService.getElementItemsMeta(elementItems).pipe(tap(function (meta) {
            state.viewSettings.configuredElements = meta.configured;
            state.viewSettings.configuredModelElements = meta.configuredModel;
            state.viewSettings.configuredActionElements = meta.configuredAction;
        }), switchMap(function () {
            if (!create) {
                return _this.viewSettingsService
                    .update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, state.viewSettings)
                    .pipe(tap(function (result) {
                    _this.viewSettingsStore.updateItem(result);
                    _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
                        Type: 'page',
                        PageUniqueName: result.uniqueName
                    });
                }));
            }
            else {
                return _this.viewSettingsStore.getDistinctName(state.viewSettings.name || 'New Page').pipe(switchMap(function (name) {
                    return _this.viewSettingsStore.getDistinctUniqueNameForName(name).pipe(map(function (uniqueName) { return [uniqueName, name]; }));
                }), switchMap(function (_a) {
                    var uniqueName = _a[0], name = _a[1];
                    state.viewSettings.uniqueName = uniqueName;
                    state.viewSettings.name = name;
                    return _this.viewSettingsService.create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, state.viewSettings);
                }), tap(function (result) {
                    _this.viewSettingsStore.addItem(result);
                    _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
                        Type: 'page',
                        PageUniqueName: result.uniqueName
                    });
                    _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.PageCreated, {
                        PageUniqueName: result.uniqueName
                    });
                }));
            }
        }), switchMap(function (result) {
            return _this.viewSettingsStore.getDetailFirst(result.uniqueName, ViewSettingsType.Custom);
        }), delayWhen(function () {
            return _this.pageFontsService.updateFontsUsed();
        }), 
        // delayWhen(result => {
        //   if (create) {
        //     return this.viewSettingsQueries.addPageToMenu(result);
        //   } else {
        //     return of(undefined);
        //   }
        // }),
        map(function (result) {
            if (result) {
                var created = !_this.initialViewSettings.uid;
                if (created) {
                    _this.setInitialViewSettings(result, false);
                    _this.currentViewSettings.newlyCreated = true;
                }
                _this.currentViewSettings.uid = result.uid;
                _this.currentViewSettings.parameters = result.parameters;
                _this.currentViewSettings.queries = result.queries;
                _this.currentViewSettings.configuredElements = result.configuredElements;
                _this.currentViewSettings.configuredModelElements = result.configuredModelElements;
                _this.currentViewSettings.configuredActionElements = result.configuredActionElements;
                _this.initialViewSettings = cloneDeep(_this.currentViewSettings);
                if (created) {
                    _this.updateCustomizeHandleInfo();
                    _this.routing.navigateApp(result.link, {
                        queryParams: _this.activatedRoute.snapshot.queryParams,
                        replaceUrl: true
                    });
                }
            }
            // this.analyticsService.sendEvent(AnalyticsEvent.GA.ModelDetailLayout, AnalyticsEventAction.Updated);
            return {
                viewSettings: result
            };
        }));
    };
    CustomPageComponent.prototype.reload = function () {
        this.currentViewSettings = undefined;
        this.cd.detectChanges();
        var obs = new ReplaySubject();
        this.loadSubscription = this.getViewSettings(false)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return obs.next(); }, function (error) { return obs.error(error); }, function () { return obs.complete(); });
        return obs.asObservable();
    };
    CustomPageComponent.prototype.renamePage = function (name) {
        var _this = this;
        if (!this.currentViewSettings.uid) {
            this.currentViewSettings.name = name;
            this.updateCustomizeHandleInfo();
            return;
        }
        this.renameLoading = true;
        this.cd.markForCheck();
        this.pageSubscriptions.push(this.viewSettingsStore
            .getDistinctUniqueNameForName(name, this.currentViewSettings)
            .pipe(switchMap(function (uniqueName) {
            var viewSettings = cloneDeep(_this.currentViewSettings);
            viewSettings.name = name;
            viewSettings.uniqueName = uniqueName;
            return _this.viewSettingsService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, viewSettings, ['uid', 'unique_name', 'name', 'deleted']);
        }), delayWhen(function () { return _this.viewSettingsStore.getFirst(true); }), delayWhen(function () { return _this.menuSettingsStore.getFirst(true); }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.currentViewSettings.name = result.name;
            _this.currentViewSettings.uniqueName = result.uniqueName;
            _this.updateCustomizeHandleInfo();
            _this.renameLoading = false;
            _this.cd.markForCheck();
            if (result) {
                _this.routing.navigateApp(result.link, {
                    queryParams: _this.activatedRoute.snapshot.queryParams,
                    replaceUrl: true
                });
            }
        }, function () {
            _this.renameLoading = false;
            _this.cd.markForCheck();
        }));
    };
    CustomPageComponent.prototype.duplicatePage = function () {
        var _this = this;
        var viewSettings = cloneDeep(this.currentViewSettings);
        var copyName = "Copy of " + (viewSettings.name || 'New Page');
        this.duplicateLoading = true;
        this.cd.markForCheck();
        this.pageSubscriptions.push(this.viewSettingsStore
            .getDistinctName(copyName)
            .pipe(switchMap(function (name) {
            return _this.viewSettingsStore.getDistinctUniqueNameForName(name).pipe(map(function (uniqueName) { return [uniqueName, name]; }));
        }), switchMap(function (_a) {
            var uniqueName = _a[0], name = _a[1];
            viewSettings.uid = undefined;
            viewSettings.uniqueName = uniqueName;
            viewSettings.name = name;
            return _this.viewSettingsService.create(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, viewSettings);
        }), delayWhen(function () { return _this.viewSettingsStore.getFirst(true); }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.duplicateLoading = false;
            _this.cd.markForCheck();
            _this.routing.navigateApp(result.link);
        }, function () {
            _this.duplicateLoading = false;
            _this.cd.markForCheck();
        }));
    };
    CustomPageComponent.prototype.deletePage = function () {
        var _this = this;
        this.pageSubscriptions.push(this.dialogService
            .warning({
            title: 'Deleting',
            description: "Are you sure want to delete page " + this.currentViewSettings.name + "?",
            style: 'orange'
        })
            .pipe(filter(function (result) { return result == true; }), switchMap(function () {
            _this.deleteLoading = true;
            _this.cd.markForCheck();
            return _this.viewSettingsService.delete(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, _this.currentViewSettings);
        }), delayWhen(function () { return _this.viewSettingsStore.getFirst(true); }), delayWhen(function () { return _this.menuSettingsStore.getFirst(true); }), untilDestroyed(this))
            .subscribe(function () {
            _this.deleteLoading = false;
            _this.cd.markForCheck();
            var viewSettings = _this.currentViewSettings;
            _this.setCurrentViewSettings(undefined);
            _this.routing.navigateApp(_this.currentProjectStore.instance.homeLink);
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.Deleted, {
                PageID: viewSettings.uniqueName
            });
        }, function () {
            _this.deleteLoading = false;
            _this.cd.markForCheck();
        }));
    };
    CustomPageComponent.prototype.getPage = function () {
        return this.currentViewSettings;
    };
    CustomPageComponent.prototype.onSidebarClick = function (e) {
        markSidebarClickEvent(e);
    };
    CustomPageComponent.prototype.openPopup = function (uid, options) {
        if (options === void 0) { options = {}; }
        this.popupsComponent.openPopup(uid, options);
    };
    CustomPageComponent.prototype.closePopup = function (uid) {
        this.popupsComponent.closePopup(uid);
    };
    CustomPageComponent.prototype.createPopup = function (open, options) {
        if (options === void 0) { options = {}; }
        return this.popupsComponent.createPopup(open, options);
    };
    CustomPageComponent.prototype.updatePopup = function (uid, newPopup) {
        this.popupsComponent.updatePopup(uid, newPopup);
    };
    CustomPageComponent.prototype.getOpenedPopups = function () {
        return this.popupsComponent.getOpenedPopups();
    };
    CustomPageComponent.prototype.openPageSettings = function (value) {
        this.currentPageSettings = value;
        this.cd.markForCheck();
    };
    CustomPageComponent.prototype.closeCurrentSettings = function () {
        this.currentPageSettings = undefined;
        this.cd.markForCheck();
    };
    CustomPageComponent.prototype.closePageSettings = function (value) {
        if (this.currentPageSettings == value) {
            this.closeCurrentSettings();
        }
    };
    return CustomPageComponent;
}());
export { CustomPageComponent };
