import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, defer, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';

import { AppConfigService, CookieStorage, LocalStorage, SessionStorage } from '@core';

// TODO: Refactor import
import { isSet } from '../../../../shared/utils/common/common';

import { ServerRequestError } from '../../utils/server-request-error/server-request-error';
import { isTokenExpired } from '../../utils/token-options/token-options';

export interface TokenOptions {
  token?: string;
  accessToken?: string;
  accessTokenExpires?: moment.Moment;
  refreshToken?: string;
  refreshTokenExpires?: moment.Moment;
  serverTime?: moment.Moment;
  sso?: string;
  social?: string;
  incognito?: boolean;
}

export const AUTOSAVE_KEY = 'jet_autosave';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  onError = new Subject<ServerRequestError>();
  refreshObs: Observable<boolean>;

  private _overrideToken$ = new BehaviorSubject<TokenOptions>(undefined);

  constructor(
    private cookieStorage: CookieStorage,
    private localStorage: LocalStorage,
    private sessionStorage: SessionStorage,
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {}

  public get apiBaseUrl() {
    return `${this.appConfigService.serverBaseUrl}/api/`;
  }

  public methodURL(method) {
    return `${this.apiBaseUrl}${method}`;
  }

  public projectMethodURL(projectName, method) {
    return this.methodURL(`projects/${projectName}/${method}`);
  }

  public environmentMethodURL(projectName, environmentName, method) {
    return this.methodURL(`projects/${projectName}/${environmentName}/${method}`);
  }

  public get apiNodeBaseUrl() {
    return `${this.appConfigService.serverNodeBaseUrl}/`;
  }

  public get apiNodeDirectBaseUrl() {
    if (!isSet(this.appConfigService.serverNodeDirectBaseUrl)) {
      return;
    }

    return `${this.appConfigService.serverNodeDirectBaseUrl}/`;
  }

  public nodeMethodURL(method, direct = false) {
    if (direct && isSet(this.apiNodeDirectBaseUrl)) {
      return `${this.apiNodeDirectBaseUrl}${method}`;
    } else {
      return `${this.apiNodeBaseUrl}${method}`;
    }
  }

  public nodeProjectMethodURL(projectName, method, direct = false) {
    return this.nodeMethodURL(`projects/${projectName}/${method}`, direct);
  }

  public nodeEnvironmentMethodURL(projectName, environmentName, method, direct = false) {
    return this.nodeMethodURL(`projects/${projectName}/${environmentName}/${method}`, direct);
  }

  public get apiSyncDataBaseUrl() {
    return `${this.appConfigService.dataSyncBaseUrl}/`;
  }

  public syncDataMethodURL(method) {
    return `${this.apiSyncDataBaseUrl}${method}`;
  }

  public syncDataProjectMethodURL(projectName, method) {
    return this.syncDataMethodURL(`projects/${projectName}/${method}`);
  }

  public syncDataEnvironmentMethodURL(projectName, environmentName, method) {
    return this.syncDataMethodURL(`projects/${projectName}/${environmentName}/${method}`);
  }

  public get apiWorkflowsBaseUrl() {
    return `${this.appConfigService.workflowsBaseUrl}/`;
  }

  public workflowsMethodURL(method) {
    return `${this.apiWorkflowsBaseUrl}${method}`;
  }

  public workflowsProjectMethodURL(projectName, method) {
    return this.workflowsMethodURL(`projects/${projectName}/${method}`);
  }

  public workflowsEnvironmentMethodURL(projectName, environmentName, method) {
    return this.workflowsMethodURL(`projects/${projectName}/${environmentName}/${method}`);
  }

  public get dataSourcesBaseUrl() {
    return `${this.appConfigService.dataSourcesBaseUrl}/`;
  }

  public get dataSourcesDirectBaseUrl() {
    if (!isSet(this.appConfigService.dataSourcesDirectBaseUrl)) {
      return;
    }

    return `${this.appConfigService.dataSourcesDirectBaseUrl}/`;
  }

  public dataSourcesMethodURL(method, direct = false) {
    if (direct && isSet(this.dataSourcesDirectBaseUrl)) {
      return `${this.dataSourcesDirectBaseUrl}${method}`;
    } else {
      return `${this.dataSourcesBaseUrl}${method}`;
    }
  }

  public dataSourcesProjectMethodURL(projectName, method, direct = false) {
    return this.dataSourcesMethodURL(`projects/${projectName}/${method}`, direct);
  }

  public dataSourcesEnvironmentMethodURL(projectName, environmentName, method, direct = false) {
    return this.dataSourcesMethodURL(`projects/${projectName}/${environmentName}/${method}`, direct);
  }

  public get createOAuthTokenUrl() {
    return this.methodURL('create_oauth_token/');
  }

  public get createOAuthTokenCompleteUrl() {
    return this.methodURL('create_oauth_token_complete/');
  }

  public getToken(forceOriginal = false): string {
    if (this.overrideToken && !forceOriginal) {
      return this.overrideToken.token;
    } else if (this.isSessionScope()) {
      return this.sessionStorage.get('token');
    } else {
      return this.cookieStorage.get('token');
    }
  }

  public getProjectToken(forceOriginal = false): string {
    if (this.overrideToken && !forceOriginal) {
      return;
    } else if (this.isSessionScope()) {
      return this.sessionStorage.get('project_token');
    } else {
      return this.cookieStorage.get('project_token');
    }
  }

  public getAccessToken(forceOriginal = false): string {
    if (this.overrideToken && !forceOriginal) {
      return this.overrideToken.accessToken;
    } else if (this.isSessionScope()) {
      return this.sessionStorage.get('jet_access_token');
    } else {
      return this.localStorage.get('jet_access_token');
    }
  }

  public getSSOUid(): string {
    if (this.isSessionScope()) {
      return this.sessionStorage.get('jet_sso');
    } else {
      return this.localStorage.get('jet_sso');
    }
  }

  public getSocialBackend(): string {
    if (this.isSessionScope()) {
      return this.sessionStorage.get('jet_social');
    } else {
      return this.localStorage.get('jet_social');
    }
  }

  public getIncognito(): string {
    if (this.isSessionScope()) {
      return this.sessionStorage.get('jet_incognito');
    } else {
      return this.localStorage.get('jet_incognito');
    }
  }

  public setAutosave(value: boolean) {
    this.localStorage.set(AUTOSAVE_KEY, JSON.stringify(value));
  }

  public toggleAutosave() {
    this.setAutosave(!this.getAutosave());
  }

  public getAutosave(): boolean {
    const defaultValue = this.getIncognito() ? 'false' : 'true';
    try {
      return JSON.parse(this.localStorage.get(AUTOSAVE_KEY, defaultValue));
    } catch (e) {
      return true;
    }
  }

  get overrideToken(): TokenOptions {
    return this._overrideToken$.value;
  }

  get overrideToken$(): Observable<TokenOptions> {
    return this._overrideToken$.asObservable();
  }

  setOverrideToken(value: TokenOptions) {
    this._overrideToken$.next(value);
  }

  clearOverrideToken() {
    this.setOverrideToken(undefined);
  }

  public getAuthorization(childProjectName?: string, forceOriginal = false): string {
    const accessToken = this.getAccessToken(forceOriginal);

    if (accessToken) {
      return `JWT ${accessToken}`;
    }

    // TODO: Remove after legacy tokens are restricted
    const token = this.getToken(forceOriginal);
    const projectToken = this.getProjectToken(forceOriginal);

    let tokenValue: string;

    if (token) {
      tokenValue = `Token ${token}`;
    } else if (projectToken) {
      tokenValue = `ProjectToken ${projectToken}`;
    }

    if (childProjectName && tokenValue) {
      tokenValue = `${tokenValue};project_child=${childProjectName}`;
    }

    if (tokenValue) {
      return tokenValue;
    }
  }

  public setHeadersToken(headers: HttpHeaders, childProjectName?: string, forceOriginal = false) {
    const authorization = this.getAuthorization(childProjectName, forceOriginal);

    if (authorization) {
      headers = headers.set('Authorization', authorization);
    }

    return headers;
  }

  refreshToken(forceOriginal = false): Observable<boolean> {
    return defer(() => {
      if (this.refreshObs) {
        return this.refreshObs;
      }

      const refreshObs = this.executeRefreshToken(forceOriginal).pipe(share());
      this.refreshObs = refreshObs;
      refreshObs.subscribe(
        () => {
          this.refreshObs = undefined;
        },
        () => {
          this.refreshObs = undefined;
        }
      );
      return refreshObs;
    });
  }

  executeRefreshToken(forceOriginal = false): Observable<boolean> {
    let accessToken: string;
    let accessTokenExpires: moment.Moment;

    if (this.overrideToken && !forceOriginal) {
      accessToken = this.overrideToken.accessToken;
      accessTokenExpires = this.overrideToken.accessTokenExpires;
    } else if (this.isSessionScope()) {
      const expiresStr = this.sessionStorage.get('jet_access_token_expires');
      accessToken = this.sessionStorage.get('jet_access_token');
      accessTokenExpires = expiresStr ? moment(expiresStr) : undefined;
    } else {
      const expiresStr = this.localStorage.get('jet_access_token_expires');
      accessToken = this.localStorage.get('jet_access_token');
      accessTokenExpires = expiresStr ? moment(expiresStr) : undefined;
    }

    if (accessToken && !isTokenExpired(accessTokenExpires)) {
      return of(true);
    }

    let refreshToken: string;
    let refreshTokenExpires: moment.Moment;

    if (this.overrideToken && !forceOriginal) {
      refreshToken = this.overrideToken.refreshToken;
      refreshTokenExpires = this.overrideToken.refreshTokenExpires;
    } else if (this.isSessionScope()) {
      const expiresStr = this.sessionStorage.get('jet_refresh_token_expires');
      refreshToken = this.sessionStorage.get('jet_refresh_token');
      refreshTokenExpires = expiresStr ? moment(expiresStr) : undefined;
    } else {
      const expiresStr = this.localStorage.get('jet_refresh_token_expires');
      refreshToken = this.localStorage.get('jet_refresh_token');
      refreshTokenExpires = expiresStr ? moment(expiresStr) : undefined;
    }

    if (!refreshToken || !refreshTokenExpires || refreshTokenExpires.diff(moment(), 'minutes') < 1) {
      return of(false);
    }

    const refreshUrl = this.methodURL('token/refresh/');
    const params = {
      v: this.appConfigService.version
    };
    const data = { refresh: refreshToken };

    return this.http.post(refreshUrl, data, { params: params }).pipe(
      tap(result => {
        const options: TokenOptions = {
          accessToken: result['access_token'],
          accessTokenExpires: moment(result['access_token_expires']),
          serverTime: moment(result['server_time'])
        };

        if (result['refresh_token']) {
          options.refreshToken = result['refresh_token'];
        }

        if (result['refresh_token_expires']) {
          options.refreshTokenExpires = moment(result['refresh_token_expires']);
        }

        this.saveToken(options, forceOriginal);
      }),
      switchMap(() => {
        const refreshCompleteUrl = this.methodURL('token/refresh/complete/');
        return this.http.post(refreshCompleteUrl, data);
      }),
      map(() => true)
    );
  }

  public isUserToken() {
    if (this.overrideToken) {
      return this.overrideToken.token != undefined || this.overrideToken.refreshToken != undefined;
    } else if (this.isSessionScope()) {
      return this.sessionStorage.get('token') != undefined || this.sessionStorage.get('jet_refresh_token') != undefined;
    } else {
      return this.cookieStorage.get('token') != undefined || this.localStorage.get('jet_refresh_token') != undefined;
    }
  }

  public isProjectToken() {
    if (this.overrideToken) {
      return false;
    } else if (this.isSessionScope()) {
      return !this.isUserToken() && this.sessionStorage.get('project_token') != undefined;
    } else {
      return !this.isUserToken() && this.cookieStorage.get('project_token') != undefined;
    }
  }

  public saveToken(options: TokenOptions, forceOriginal = false) {
    const getLocalTime = (date: moment.Moment) => {
      return moment().add(date.diff(options.serverTime), 'milliseconds').toISOString();
    };

    if (this.overrideToken && !forceOriginal) {
      const newToken = { ...this.overrideToken };

      if (options.token) {
        newToken.token = options.token;
      }

      if (options.accessToken) {
        newToken.accessToken = options.accessToken;
        newToken.accessTokenExpires = moment(getLocalTime(options.accessTokenExpires));
      }

      if (options.refreshToken) {
        newToken.refreshToken = options.refreshToken;
        newToken.refreshTokenExpires = moment(getLocalTime(options.refreshTokenExpires));
      }

      if (options.sso) {
        newToken.sso = options.sso;
      }

      this.setOverrideToken(newToken);
    } else if (this.isSessionScope()) {
      if (options.token) {
        this.sessionStorage.set('token', options.token);
      }

      if (options.accessToken) {
        this.sessionStorage.set('jet_access_token', options.accessToken);
        this.sessionStorage.set('jet_access_token_expires', getLocalTime(options.accessTokenExpires));
      }

      if (options.refreshToken) {
        this.sessionStorage.set('jet_refresh_token', options.refreshToken);
        this.sessionStorage.set('jet_refresh_token_expires', getLocalTime(options.refreshTokenExpires));
      }

      if (options.sso) {
        this.sessionStorage.set('jet_sso', options.sso);
      }

      if (options.social) {
        this.sessionStorage.set('jet_social', options.social);
      }

      if (options.incognito) {
        this.sessionStorage.set('jet_incognito', '1');
      }
    } else {
      if (options.token) {
        this.cookieStorage.set('token', options.token);
      }

      if (options.accessToken) {
        this.localStorage.set('jet_access_token', options.accessToken);
        this.localStorage.set('jet_access_token_expires', getLocalTime(options.accessTokenExpires));
      }

      if (options.refreshToken) {
        this.localStorage.set('jet_refresh_token', options.refreshToken);
        this.localStorage.set('jet_refresh_token_expires', getLocalTime(options.refreshTokenExpires));
      }

      if (options.sso) {
        this.localStorage.set('jet_sso', options.sso);
      }

      if (options.social) {
        this.localStorage.set('jet_social', options.social);
      }

      if (options.incognito) {
        this.localStorage.set('jet_incognito', '1');
      }
    }

    this.deleteProjectToken();
  }

  public deleteToken() {
    if (this.overrideToken) {
      this.setOverrideToken({});
    } else if (this.isSessionScope()) {
      this.sessionStorage.remove('token');
      this.sessionStorage.remove('jet_access_token');
      this.sessionStorage.remove('jet_access_token_expires');
      this.sessionStorage.remove('jet_refresh_token');
      this.sessionStorage.remove('jet_refresh_token_expires');
      this.sessionStorage.remove('jet_sso');
      this.sessionStorage.remove('jet_social');
      this.sessionStorage.remove('jet_incognito');
    } else {
      this.cookieStorage.remove('token');
      this.localStorage.remove('jet_access_token');
      this.localStorage.remove('jet_access_token_expires');
      this.localStorage.remove('jet_refresh_token');
      this.localStorage.remove('jet_refresh_token_expires');
      this.localStorage.remove('jet_sso');
      this.localStorage.remove('jet_social');
      this.localStorage.remove('jet_incognito');
    }
  }

  public deleteProjectAccessTokens() {
    const sessionKeys = this.sessionStorage.keys();

    sessionKeys.forEach(item => {
      if (/jet_project_(.+)_access_token/.exec(item) || /jet_project_(.+)_access_token_expires/.exec(item)) {
        this.sessionStorage.remove(item);
      }
    });

    if (this.isSessionScope()) {
      sessionKeys.forEach(item => {
        if (/jet_project_(.+)_refresh_token/.exec(item) || /jet_project_(.+)_refresh_token_expires/.exec(item)) {
          this.sessionStorage.remove(item);
        }
      });
    } else {
      const localStorageKeys = this.localStorage.keys();
      localStorageKeys.forEach(item => {
        if (/jet_project_(.+)_refresh_token/.exec(item) || /jet_project_(.+)_refresh_token_expires/.exec(item)) {
          this.localStorage.remove(item);
        }
      });
    }
  }

  public saveProjectToken(token: string) {
    const expires = new Date();
    expires.setDate(expires.getDate() + 30);

    if (this.isSessionScope()) {
      this.sessionStorage.set('project_token', token);
    } else {
      this.cookieStorage.set('project_token', token);
    }

    this.deleteToken();
  }

  public deleteProjectToken() {
    if (this.isSessionScope()) {
      this.sessionStorage.remove('project_token');
    } else {
      this.cookieStorage.remove('project_token');
    }
  }

  public catchApiError<T, R>(processAuthExpire = true) {
    return catchError<T, R>(error => {
      if (processAuthExpire && error instanceof HttpErrorResponse && error.status == 401) {
        console.error(error);
      }

      let serverError: ServerRequestError;

      if (error instanceof ServerRequestError) {
        serverError = error;
      } else {
        serverError = new ServerRequestError(error);

        if (!(error.status == 401 && serverError.errors[0] == 'Authentication credentials were not provided.')) {
          this.onError.next(serverError);
        }
      }

      return throwError(serverError);
    });
  }

  public enableSessionScope() {
    this.sessionStorage.set('session_scope', '1');
  }

  public disableSessionScope() {
    this.sessionStorage.remove('session_scope');
  }

  public isSessionScope() {
    return !!this.sessionStorage.get('session_scope');
  }
}
