import * as Color from 'color';
import padStart from 'lodash/padStart';

// TODO: Refactor import
import { isSet } from '../../../shared/utils/common/common';
import { numberToHex } from '../../../shared/utils/numbers/numbers';

import { colors } from '../data/colors';

export function getColorHex(color: string): string | number {
  if (!color) {
    return;
  }

  if (color.startsWith('#')) {
    return color;
  } else if (!color.startsWith('#')) {
    const predefinedColor = colors.find(item => item.name == color);
    if (predefinedColor) {
      return predefinedColor.hex;
    }
  }
}

export function colorHexToStr(color: number): string {
  if (typeof color === 'number') {
    return '#' + numberToHex(color);
  }
}

export function getColorHexStr(color: string): string {
  const result = getColorHex(color);

  if (!isSet(result)) {
    return;
  }

  if (typeof result === 'number') {
    return colorHexToStr(result);
  } else {
    return result;
  }
}

export function getColorHexAStr(clr: Color): string {
  if (!clr) {
    return;
  }

  const alpha = Math.round(clr.alpha() * 255);
  let result = clr.hex();

  if (alpha < 255) {
    const alphaHex = Number(alpha).toString(16);
    result += padStart(alphaHex, 2, '0');
  }

  return result.toUpperCase();
}

export function getColorVariable(color: string): string {
  if (!isSet(color)) {
    return;
  }

  const variable = String(color).match(/^--(.+)$/);
  if (variable) {
    return variable[1];
  }
}

export function fadeColorStr(color: string, fade = 0.5): string {
  if (!color) {
    return;
  }

  try {
    const newColor = Color(color).fade(fade);
    return getColorHexAStr(newColor);
  } catch (e) {}
}

export function isLightColor(color: string): boolean {
  if (!color) {
    return false;
  }

  const colorHex = getColorHex(color);

  if (!colorHex) {
    return false;
  }

  try {
    const clr = Color(colorHex);
    const isDark = clr.contrast(Color('white')) >= 2;

    return !isDark;
  } catch (e) {
    return false;
  }
}

export function parseColor(color: string | number, defaultColor?: string | number): Color {
  if (isSet(color)) {
    try {
      return Color(color);
    } catch (e) {}
  }

  if (isSet(defaultColor)) {
    return Color(defaultColor);
  }
}
