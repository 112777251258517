<app-select-javascript-dependency-selector
  *ngIf="section == sections.Package"
  [title]="'Choose package from NPM'"
  [itemIcon]="'components'"
  [source]="npmPackageSource"
  [searchPlaceholder]="'Package name...'"
  [searchMinimumLength]="2"
  (selectValue)="npmPackageControl.patchValue($event); setSection(sections.Version)"
></app-select-javascript-dependency-selector>

<app-select-javascript-dependency-selector
  *ngIf="section == sections.Version"
  [title]="'Choose package version'"
  [itemIcon]="'tag'"
  [source]="npmPackageVersionSource"
  [searchPlaceholder]="'Package version...'"
  [backEnabled]="true"
  [backTitle]="'Back to package list'"
  (selectValue)="npmPackageVersionControl.patchValue($event); setSection(sections.Imports)"
  (back)="setSection(sections.Package)"
></app-select-javascript-dependency-selector>

<app-select-javascript-dependency-selector
  *ngIf="section == sections.Imports"
  [multiple]="true"
  [source]="npmPackageExportsSource"
  [searchPlaceholder]="'Package imports...'"
  [backEnabled]="true"
  [backTitle]="'Back to version list'"
  (selectValue)="npmPackageExportsControl.patchValue($event); submit()"
  (back)="setSection(sections.Version)"
></app-select-javascript-dependency-selector>
