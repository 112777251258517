import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { UniversalAnalyticsService } from '@modules/analytics';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { DisplayFieldArray } from '@modules/customize-bar/components/display-fields-edit/display-field.array';
import { ModelBasedDataSource } from '@modules/data-sources';
import { InputValueType } from '@modules/fields';
import { ModelOption, ModelOptionSelectedEvent } from '@modules/filters-components';
import { FieldInputArray, FieldInputControl, InputFieldProvider } from '@modules/parameters';
import { SidebarCollapseContext } from '@modules/sidebar';
import { coerceArray, isSet } from '@shared';

import { FieldInputsAddOverlayComponent } from '../field-inputs-add-overlay/field-inputs-add-overlay.component';

@Component({
  selector: 'app-field-inputs-edit',
  templateUrl: './field-inputs-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldInputsEditComponent implements OnInit, OnDestroy {
  @Input() control: FieldInputArray;
  @Input() dataSource: ModelBasedDataSource;
  @Input() parameterProvider: InputFieldProvider;
  @Input() fieldsControl: DisplayFieldArray;
  @Input() addInputEnabled = false;
  @Input() nestedFieldsEnabled = true;
  @Input() relationsEnabled = true;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() userInput = false;
  @Input() collapse = true;
  @Input() collapseContext = new SidebarCollapseContext();
  @Input() listWrapper = true;
  @Input() displayValueTypes = [InputValueType.Formula];
  @Input() classes: string | string[];
  @Input() addClasses: string | string[];
  @Input() analyticsSource: string;
  @Output() addInput = new EventEmitter<void>();

  @ViewChild(FieldInputsAddOverlayComponent) inputsAddOverlayComponent: FieldInputsAddOverlayComponent;

  optionsFilter: (option: ModelOption, path: ModelOption[]) => boolean;
  lastAddedControl: FieldInputControl;
  displayAllParameters = false;

  constructor(private analyticsService: UniversalAnalyticsService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.parameterProvider
      .getItems$()
      .pipe(untilDestroyed(this))
      .subscribe(providerItems => {
        this.displayAllParameters = providerItems.every(item => !item.children || !item.children.length);
        this.cd.markForCheck();
      });

    this.optionsFilter = (option, path) => {
      const optionPath = [...path, option].map(item => item.name);
      return (
        this.control.controls.find(item => {
          return (
            isEqual(item.controls.path.value, optionPath) &&
            !isSet(item.controls.lookup.value) &&
            !item.controls.exclude.value
          );
        }) === undefined
      );
    };
  }

  ngOnDestroy(): void {}

  trackByFn(i: number, item: FieldInputControl) {
    const path = coerceArray(item.controls.path.value);

    if (path.length) {
      return 'field_' + path.join('__');
    } else {
      return i;
    }
  }

  onFilterAdded(e: ModelOptionSelectedEvent) {
    this.lastAddedControl = this.control.appendControl(undefined, {
      path: e.path.map(item => item.name),
      ...(isSet(e.lookup) && { lookup: e.lookup.lookup }),
      ...(isSet(e.exclude) && { exclude: e.exclude }),
      ...(e.staticValue !== undefined && {
        value_type: InputValueType.StaticValue,
        static_value: e.staticValue
      })
    });
    this.cd.markForCheck();
  }

  openAddInput() {
    if (this.inputsAddOverlayComponent) {
      this.inputsAddOverlayComponent.open();
    }
  }
}
