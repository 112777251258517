<form [formGroup]="form" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="title | appCapitalize"
    [titleEditable]="true"
    [subtitle]="'Overlay'"
    [backLabel]="'All components'"
    [deleteEnabled]="true"
    [deleteTip]="'Delete Overlay'"
    (titleChanged)="form.controls.name.patchValue($event)"
    (deleteClick)="delete()"
  >
  </app-customize-bar-header>

  <div class="sidebar__content">
    <app-sidebar-tabs>
      <app-sidebar-tab [label]="'General'">
        <app-sidebar-section>
          <div class="sidebar__element">
            <app-sidebar-field [label]="'title'">
              <app-input-edit
                [itemForm]="form.controls.title"
                [context]="context"
                [fill]="true"
                [analyticsSource]="analyticsObject + '_title'"
              ></app-input-edit>
              <div *ngIf="form.controls.title.invalid && form.dirty" class="field__errors">
                <div class="field__error">required</div>
              </div>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'style'">
              <div class="sidebar-image-toggle sidebar-image-toggle_cols_3">
                <div *ngFor="let item of form.styleOptions" class="sidebar-image-toggle__col">
                  <div
                    class="sidebar-image-toggle__item"
                    [class.sidebar-image-toggle__item_active]="
                      item.style === form.controls.style.value && item.position === form.controls.position.value
                    "
                    (click)="
                      form.controls.style.patchValue(item.style); form.controls.position.patchValue(item.position)
                    "
                  >
                    <div
                      class="sidebar-image-toggle__item-inner"
                      [style.background-image]="'/assets/images/popups/' + item.image + '.svg' | appDeployCssUrl"
                    ></div>
                  </div>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field>
              <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="form.controls.overlay.value"
                  (click)="form.controls.overlay.setValue(true)"
                >
                  <div class="sidebar-icon-button__label">Show background</div>
                </div>
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="!form.controls.overlay.value"
                  (click)="form.controls.overlay.setValue(false)"
                >
                  <div class="sidebar-icon-button__label">Without background</div>
                </div>
              </div>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'show close button'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'close_button',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div class="sidebar__element">
            <app-sidebar-field [label]="'close on background click'" [additional]="'(app mode)'" [layoutLeft]="true">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'close_on_blur',
                    field: 'BooleanField',
                    params: { style: booleanFieldStyle.Toggle, orange: true }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>
        </app-sidebar-section>

        <app-sidebar-section [title]="'Overlay Parameters'" [documentation]="'inputs'">
          <div class="sidebar__list">
            <app-parameters-edit
              [control]="form.controls.parameters"
              [configurable]="{
                name: true,
                field: true,
                required: true,
                add: true,
                sortable: true
              }"
              [analyticsSource]="analyticsObject"
            >
            </app-parameters-edit>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>

      <app-sidebar-tab [label]="'Actions'" [error]="(actionsValid$ | async) !== false ? undefined : 'Not configured'">
        <app-sidebar-section>
          <div class="sidebar__list">
            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'When overlay opens'"></app-sidebar-list-separator>
            </div>
            <app-actions-edit
              [control]="form.controls.open_actions"
              [context]="context"
              [emptyAction]="'Open action'"
              [emptyActionValue]="'Not specified'"
              [emptyIcon]="'copy'"
              [actionLabel]="'Overlay open'"
              [object]="analyticsObject"
              [firstInit]="firstInit"
            >
            </app-actions-edit>
            <div class="sidebar-list__separator">
              <app-sidebar-list-separator [title]="'When overlay closes'"></app-sidebar-list-separator>
            </div>
            <app-actions-edit
              [control]="form.controls.close_actions"
              [context]="context"
              [emptyAction]="'Close action'"
              [emptyActionValue]="'Not specified'"
              [emptyIcon]="'close'"
              [actionLabel]="'Overlay close'"
              [object]="analyticsObject"
              [firstInit]="firstInit"
            >
            </app-actions-edit>
          </div>
        </app-sidebar-section>
      </app-sidebar-tab>
    </app-sidebar-tabs>
  </div>

  <!--  <app-sidebar-submit-->
  <!--    [saveDisabled]="form.invalid || form.disabled"-->
  <!--    [errors]="form | appFormErrors"-->
  <!--    (cancelClick)="cancel()"-->
  <!--  >-->
  <!--  </app-sidebar-submit>-->
</form>
