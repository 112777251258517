import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import { Option } from 'ng-gxselect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, map } from 'rxjs/operators';

import { SelectComponent, SelectSegment } from '@common/select';
import { ViewSettingsAction } from '@modules/actions';
import { ElementActionsPosition, modelFieldToDisplayField } from '@modules/customize';
import {
  applyParamInputs$,
  FieldDescription,
  FieldLabelButton,
  FieldType,
  FormField,
  getFieldDescriptionByType,
  Input,
  registerFieldComponent,
  transformFieldForeignKey
} from '@modules/fields';
import { EMPTY_FILTER_VALUES } from '@modules/filters';
import { ModelDescriptionStore, ModelUtilsService, ReducedModelService } from '@modules/model-queries';
import { fromLegacyModel, Model, ModelDescription } from '@modules/models';
import { ModelSelectSource } from '@modules/models-list';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { prepareDataSourceColumnForGet } from '@modules/resources';
import { isSet } from '@shared';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-foreign-key-field',
  templateUrl: './foreign-key-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ModelSelectSource]
})
export class ForeignKeyFieldComponent extends FieldComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild(SelectComponent) selectComponent: SelectComponent;

  fieldDescription: FieldDescription;
  valueStr: string | Observable<string>;
  valueStrLoading = false;
  modelDescription: ModelDescription;
  relatedFormField: FormField;
  relatedValue: any;
  buttons: FieldLabelButton[];
  topActions: ViewSettingsAction[] = [];
  bottomActions: ViewSettingsAction[] = [];
  formSubscription: Subscription;
  updateFieldSubscription: Subscription;

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public source: ModelSelectSource,
    private injector: Injector,
    private reducedModelService: ReducedModelService,
    private modelDescriptionStore: ModelDescriptionStore,
    private modelUtilsService: ModelUtilsService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  createModel(): Model {
    return Injector.create({
      providers: [{ provide: Model, deps: [Injector] }],
      parent: this.injector
    }).get<Model>(Model);
  }

  ngOnInit(): void {
    this.initField();
    this.initSource();
    this.initValue();
    this.initModelDescription();
    this.initForm();
    this.updateButtons();
    this.updateActions();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['field']) {
      this.initField();
      this.initSource();
      this.initModelDescription();
    }

    if (changes['value']) {
      this.initValue();
    }

    if (changes['form']) {
      this.initForm();
    }

    this.updateButtons();
    this.updateActions();
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.open();
    }
  }

  initField() {
    this.fieldDescription = getFieldDescriptionByType(this.field.field);
  }

  initForm() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.form && this.field && this.form.controls[this.field.name]) {
      this.formSubscription = this.form.controls[this.field.name].valueChanges
        .pipe(delay(0), untilDestroyed(this))
        .subscribe(() => {
          this.updateButtons();
          this.initValue();
        });
    }
  }

  initSource() {
    if (this.updateFieldSubscription) {
      this.updateFieldSubscription.unsubscribe();
    }

    if (!this.field) {
      return;
    }

    const modelId = this.field.params['related_model']
      ? fromLegacyModel(this.field.params['related_model']['model'])
      : undefined;

    this.modelDescriptionStore
      .getDetailFirst(modelId)
      .pipe(untilDestroyed(this))
      .subscribe(modelDescription => {
        if (!modelDescription) {
          return;
        }

        const resource = this.currentEnvironmentStore.resources.find(
          item => item.uniqueName == modelDescription.resource
        );

        if (!resource) {
          return;
        }

        const listQuery = new ListModelDescriptionQuery();

        listQuery.queryType = QueryType.Simple;
        listQuery.simpleQuery = new listQuery.simpleQueryClass();
        listQuery.simpleQuery.model = modelDescription.model;

        let detailQuery: ModelDescriptionQuery;

        if (modelDescription.getDetailQuery) {
          detailQuery = new ModelDescriptionQuery();

          detailQuery.queryType = QueryType.Simple;
          detailQuery.simpleQuery = new detailQuery.simpleQueryClass();
          detailQuery.simpleQuery.model = modelDescription.model;
        }

        const columns = modelDescription
          ? modelDescription.fields
              .map(item => modelFieldToDisplayField(item, false))
              .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item))
          : [];

        const inputs = this.field.params['inputs']
          ? this.field.params['inputs'].map(item => new Input().deserialize(item))
          : [];

        const nameInput = this.field.params['custom_display_field_input']
          ? new Input().deserialize(this.field.params['custom_display_field_input'])
          : undefined;

        const subtitleInput = this.field.params['subtitle_input']
          ? new Input().deserialize(this.field.params['subtitle_input'])
          : undefined;

        const iconInput = this.field.params['icon_input']
          ? new Input().deserialize(this.field.params['icon_input'])
          : undefined;

        this.updateFieldSubscription = applyParamInputs$({}, inputs, {
          context: this.context,
          ignoreEmpty: true,
          emptyValues: EMPTY_FILTER_VALUES
        })
          .pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
          .pipe(untilDestroyed(this))
          .subscribe(params => {
            this.source.init({
              resource: modelDescription.resource,
              query: listQuery,
              queryParameters: modelDescription.getParameters,
              detailQuery: detailQuery,
              columns: columns,
              params: params,
              sortingField: this.field.params['sorting_field'],
              sortingAsc: this.field.params['sorting_asc'],
              valueField: this.field.params['custom_primary_key'] || modelDescription.primaryKeyField,
              nameField: this.field.params['custom_display_field'] || modelDescription.displayField,
              nameInput: nameInput,
              subtitleField: this.field.params['subtitle_field'],
              subtitleInput: subtitleInput,
              iconField: this.field.params['icon_field'],
              iconInput: iconInput,
              context: this.context,
              contextElement: this.contextElement
            });
            this.source.reset();
            this.cd.markForCheck();
          });
      });
  }

  initValue() {
    if (!this.readonly) {
      return;
    }

    // if (this.context['related_model'] && this.context['related_model'].modelDescription.displayField) {
    //   const model = this.context['related_model'];
    //   const modelDescription = model.modelDescription;
    //
    //   this.relatedFormField = modelDescription.field(modelDescription.displayField).formField;
    //   this.relatedValue = model.getAttribute(model.modelDescription.displayField);
    //   this.cd.markForCheck();
    // } else {
    this.initValueStr();
    // }
  }

  initValueStr() {
    if (!isSet(this.currentValue)) {
      this.valueStr = undefined;
      this.cd.markForCheck();
      // } else if (this.context['related_model']) {
      //   this.valueStr = this.modelUtilsService.str(this.context['related_model']);
      //   this.cd.markForCheck();
    } else if (this.source.hasName()) {
      this.valueStr = undefined;
      this.valueStrLoading = true;
      this.cd.markForCheck();

      this.source
        .fetchByValue(this.currentValue)
        .pipe(untilDestroyed(this))
        .subscribe(
          (result: Option) => {
            if (result) {
              this.valueStr = result.name;
            }

            this.valueStrLoading = false;
            this.cd.markForCheck();
          },
          () => {
            this.valueStrLoading = false;
            this.cd.markForCheck();
          }
        );
    } else {
      this.valueStr = `ID: ${this.currentValue}`;
      this.valueStrLoading = false;
      this.cd.markForCheck();
    }
  }

  initModelDescription() {
    const model = this.field.params['related_model']
      ? fromLegacyModel(this.field.params['related_model']['model'])
      : undefined;

    this.modelDescriptionStore
      .getDetailFirst(model)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.modelDescription = result;
        this.cd.markForCheck();
      });
  }

  get createLink() {
    if (!this.modelDescription) {
      return;
    }

    return this.modelDescription.createLink;
  }

  get changeLink(): Observable<any[]> {
    if (!this.currentValue || !this.modelDescription || !this.modelDescription.getDetailEnabled) {
      return of(undefined);
    }

    const idValue = transformFieldForeignKey(this.field.params, this.currentValue);

    if (this.field.params['custom_primary_key']) {
      return this.reducedModelService
        .getDetail(this.modelDescription.modelId, this.field.params['custom_primary_key'], idValue)
        .pipe(map(model => (model ? model.getLink() : undefined)));
    } else {
      const data = {};
      const instance = this.createModel().deserialize(this.modelDescription.model, data);
      instance.setUp(this.modelDescription);
      instance.setPrimaryKey(idValue);

      return of(instance.getLink());
    }
  }

  updateButtons() {
    // this.changeLink.pipe(untilDestroyed(this)).subscribe(changeLink => {
    //   const buttons = [...this.labelButtons];
    //
    //   if (this.field) {
    //     if (changeLink && this.field.params['open_button'] !== false) {
    //       buttons.push({ link: changeLink, icon: 'eye', tip: 'Open ' + this.field.label });
    //     }
    //
    //     if (!this.readonly && this.field.params['create_button'] !== false) {
    //       buttons.push({ link: this.createLink, icon: 'plus', tip: 'Create ' + this.field.label });
    //     }
    //   }
    //
    //   this.buttons = buttons;
    //   this.cd.markForCheck();
    // });
  }

  updateActions() {
    const topActions = this.elementActions.find(item => item.position == ElementActionsPosition.Top);
    const bottomActions = this.elementActions.find(item => item.position == ElementActionsPosition.Bottom);

    this.topActions = topActions ? topActions.actions : [];
    this.bottomActions = bottomActions ? bottomActions.actions : [];
    this.cd.markForCheck();
  }

  // TODO: Move to separate params option
  get selectFill() {
    return this.field.params['classes'] && this.field.params['classes'].indexOf('select_fill') != -1;
  }
  // TODO: Move to separate params option
  get selectSmall() {
    return this.field.params['classes'] && this.field.params['classes'].indexOf('select_small') != -1;
  }

  // TODO: Move to separate params option
  get selectSegment(): SelectSegment {
    if (!this.field.params['classes']) {
      return;
    }

    if (this.field.params['classes'].indexOf('select_segment-top') != -1) {
      return SelectSegment.Top;
    } else if (this.field.params['classes'].indexOf('select_segment') != -1) {
      return SelectSegment.Middle;
    } else if (this.field.params['classes'].indexOf('select_segment-bottom') != -1) {
      return SelectSegment.Bottom;
    }
  }

  open() {
    if (this.selectComponent) {
      this.selectComponent.open();
    }
  }

  asActions(value: any): ViewSettingsAction[] {
    return value as ViewSettingsAction[];
  }

  asSelectComponent(value: any): SelectComponent {
    return value as SelectComponent;
  }
}

registerFieldComponent(FieldType.RelatedModel, ForeignKeyFieldComponent);
