/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/observers";
import * as i2 from "../select-javascript-dependency/select-javascript-dependency.component.ngfactory";
import * as i3 from "../../../npm/sources/npm-package.source";
import * as i4 from "../../../projects/stores/current-project.store";
import * as i5 from "../../../projects/stores/current-environment.store";
import * as i6 from "../../../npm/services/npm/npm-registry.service";
import * as i7 from "../../../npm/sources/npm-package-version.source";
import * as i8 from "../../../npm/sources/npm-package-exports.source";
import * as i9 from "../select-javascript-dependency/select-javascript-dependency.component";
import * as i10 from "@angular/cdk/overlay";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "./select-javascript-dependency-popover.component";
var styles_SelectJavascriptDependencyPopoverComponent = [];
var RenderType_SelectJavascriptDependencyPopoverComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SelectJavascriptDependencyPopoverComponent, data: {} });
export { RenderType_SelectJavascriptDependencyPopoverComponent as RenderType_SelectJavascriptDependencyPopoverComponent };
function View_SelectJavascriptDependencyPopoverComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], null, [[null, "cdkObserveContent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cdkObserveContent" === en)) {
        var pd_0 = (_co.onPopoverContentChanged() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 1196032, null, 0, i1.CdkObserveContent, [i1.ContentObserver, i0.ElementRef, i0.NgZone], null, { event: "cdkObserveContent" }), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "popover2 popover2_header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "app-select-javascript-dependency", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        _co.selected.emit($event);
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SelectJavaScriptDependencyComponent_0, i2.RenderType_SelectJavaScriptDependencyComponent)), i0.ɵprd(512, null, i3.NpmPackageSource, i3.NpmPackageSource, [i4.CurrentProjectStore, i5.CurrentEnvironmentStore, i6.NpmRegistryService]), i0.ɵprd(512, null, i7.NpmPackageVersionSource, i7.NpmPackageVersionSource, [i4.CurrentProjectStore, i5.CurrentEnvironmentStore, i6.NpmRegistryService]), i0.ɵprd(512, null, i8.NpmPackageExportsSource, i8.NpmPackageExportsSource, [i4.CurrentProjectStore, i5.CurrentEnvironmentStore, i6.NpmRegistryService]), i0.ɵdid(8, 245760, null, 0, i9.SelectJavaScriptDependencyComponent, [i3.NpmPackageSource, i7.NpmPackageVersionSource, i8.NpmPackageExportsSource, i0.ChangeDetectorRef], { origin: [0, "origin"], trigger: [1, "trigger"] }, { selected: "selected" }), (_l()(), i0.ɵeld(9, 0, null, null, 0, "div", [["class", "popover2__arrow"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.trigger; _ck(_v, 8, 0, currVal_0, currVal_1); }, null); }
export function View_SelectJavascriptDependencyPopoverComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_SelectJavascriptDependencyPopoverComponent_1)), i0.ɵdid(2, 671744, [[1, 4]], 0, i10.CdkConnectedOverlay, [i10.Overlay, i0.TemplateRef, i0.ViewContainerRef, i10.ɵc, [2, i11.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.popoverPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 3, 0, "overlay"); var currVal_4 = _co.dropdownOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_SelectJavascriptDependencyPopoverComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-select-javascript-dependency-popover", [], null, null, null, View_SelectJavascriptDependencyPopoverComponent_0, RenderType_SelectJavascriptDependencyPopoverComponent)), i0.ɵdid(1, 4440064, null, 0, i12.SelectJavascriptDependencyPopoverComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectJavascriptDependencyPopoverComponentNgFactory = i0.ɵccf("app-select-javascript-dependency-popover", i12.SelectJavascriptDependencyPopoverComponent, View_SelectJavascriptDependencyPopoverComponent_Host_0, { origin: "origin", trigger: "trigger" }, { selected: "selected" }, []);
export { SelectJavascriptDependencyPopoverComponentNgFactory as SelectJavascriptDependencyPopoverComponentNgFactory };
