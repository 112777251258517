import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import defaults from 'lodash/defaults';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { PopupService } from '@common/popups';
import { ResizeType } from '@common/resizable';
import { SessionStorage } from '@core';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, CustomViewSettings, ignoreElementCustomize, isElementCustomizeIgnored, markElementClickEvent, PopupSettings, PopupStyle, ViewContext, ViewContextElement } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService } from '@modules/customize-bar';
import { applyParamInput, getFieldDescriptionByType } from '@modules/fields';
import { EMPTY, isControlElement, KeyboardEventKeyCode } from '@shared';
// TODO: Refactor import
import { BUILDER_ELEMENT_BUFFER } from '../../../customize-elements/services/element-container/element-container.service';
var customPagePopupClickEventProperty = '_customPagePopupClickEvent';
export function markCustomPagePopupClickEvent(clickEvent) {
    clickEvent[customPagePopupClickEventProperty] = true;
}
export function isCustomPagePopupClickEvent(clickEvent) {
    return !!clickEvent[customPagePopupClickEventProperty];
}
var CustomPagePopupComponent = /** @class */ (function () {
    function CustomPagePopupComponent(el, customizeService, customizeBarService, customizeBarContext, contextElement, popupService, sessionStorage, analyticsService, cd) {
        this.el = el;
        this.customizeService = customizeService;
        this.customizeBarService = customizeBarService;
        this.customizeBarContext = customizeBarContext;
        this.contextElement = contextElement;
        this.popupService = popupService;
        this.sessionStorage = sessionStorage;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.customizing = false;
        this.visible = false;
        this.active = false;
        this.closeEnabled = true;
        this.editPopup = new EventEmitter();
        this.deletePopup = new EventEmitter();
        this.closePopup = new EventEmitter();
        this.duplicatePopup = new EventEmitter();
        this.movePopupToPage = new EventEmitter();
        this.hover = false;
        this.configured = true;
        this.customizingElement = false;
        this.resizingElement = false;
        this.resizeStepSize = 10;
        this.customizeComponentData = new BehaviorSubject(undefined);
        this.interactionsDisabled = false;
        this.resizeTypes = ResizeType;
        this.minSize = { width: 260 };
        this.popupStyles = PopupStyle;
        this.movePagesOpened = false;
        this.movePagesPositions = [
            {
                panelClass: ['overlay_position_bottom-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'bottom',
                overlayY: 'top',
                offsetY: 4
            },
            {
                panelClass: ['overlay_position_top-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'top',
                overlayY: 'bottom',
                offsetY: -4
            }
        ];
    }
    Object.defineProperty(CustomPagePopupComponent.prototype, "elementId", {
        get: function () {
            return this.popup.uid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomPagePopupComponent.prototype, "elementName", {
        get: function () {
            return this.popup.name;
        },
        enumerable: true,
        configurable: true
    });
    CustomPagePopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.width = this.popup.width;
        this.customizeService.lastHovered$
            .pipe(debounceTime(1), // TODO: Workaround for slow ngOnDestroy
        untilDestroyed(this))
            .subscribe(function (lastHovered) {
            _this.hover = lastHovered === _this;
            _this.cd.markForCheck();
        });
        if (this.customizeBarContext) {
            combineLatest(this.customizeComponentData, this.customizeBarContext.settingsComponents$)
                .pipe(untilDestroyed(this))
                .subscribe(function (_a) {
                var customizeComponentData = _a[0], components = _a[1];
                _this.customizingElement =
                    customizeComponentData && components.find(function (item) { return item === customizeComponentData; }) != undefined;
                _this.cd.markForCheck();
            });
        }
        fromEvent(document, 'keydown')
            .pipe(filter(function () { return _this.customizingElement && !_this.popupService.items.length && !isControlElement(document.activeElement); }), untilDestroyed(this))
            .subscribe(function (e) {
            if (e.keyCode == KeyboardEventKeyCode.Escape) {
                _this.closeCustomize();
            }
            else if (e.keyCode == KeyboardEventKeyCode.Backspace) {
                _this.deletePopup.emit();
            }
            else if ((e.metaKey || e.ctrlKey) && e.keyCode == KeyboardEventKeyCode.X) {
                _this.copyElement(true);
            }
            else if ((e.metaKey || e.ctrlKey) && e.keyCode == KeyboardEventKeyCode.C) {
                _this.copyElement();
            }
        });
    };
    CustomPagePopupComponent.prototype.ngOnDestroy = function () {
        this.customizeService.removeHover(this);
        this.closeCustomize();
        this.contextElement.unregister();
    };
    CustomPagePopupComponent.prototype.ngOnChanges = function (changes) {
        if (changes.popup || changes.context) {
            this.init();
        }
        if (changes.popup) {
            this.initContextElement();
            this.updateContextOutputs();
        }
        if (changes.visible && !this.visible) {
            this.closeCustomize();
        }
    };
    CustomPagePopupComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    Object.defineProperty(CustomPagePopupComponent.prototype, "displayName", {
        get: function () {
            if (!this.popup) {
                return;
            }
            return this.popup.name || 'modal';
        },
        enumerable: true,
        configurable: true
    });
    CustomPagePopupComponent.prototype.init = function () {
        this.initContextObserver();
    };
    CustomPagePopupComponent.prototype.initContextObserver = function () {
        var _this = this;
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
        this.updateInputs();
        this.contextSubscription = this.context.outputValues$
            .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function () { return _this.updateInputs(); });
    };
    CustomPagePopupComponent.prototype.initContextElement = function () {
        this.contextElement.initGlobal({ uniqueName: this.popup.uid, name: this.popup.name, icon: this.popup.getIcon() }, this.popupsContextElement);
    };
    CustomPagePopupComponent.prototype.updateContextOutputs = function () {
        this.contextElement.setOutputs(this.popup.parameters.map(function (item) {
            var fieldDescription = getFieldDescriptionByType(item.field);
            return {
                uniqueName: item.name,
                name: item.verboseName || item.name,
                icon: fieldDescription.icon
            };
        }));
    };
    CustomPagePopupComponent.prototype.setContextOutputValues = function (values) {
        this.contextElement.setOutputValues(fromPairs(this.popup.parameters.map(function (item) { return [item.name, values[item.name]]; })));
    };
    CustomPagePopupComponent.prototype.updateInputs = function () {
        var title;
        if (this.popup.title) {
            try {
                var value = applyParamInput(this.popup.title, { context: this.context });
                if (value !== EMPTY) {
                    title = value;
                }
            }
            catch (e) { }
        }
        this.title = title;
        this.cd.markForCheck();
    };
    CustomPagePopupComponent.prototype.setElementSize = function () {
        this.popup.width = this.width;
    };
    CustomPagePopupComponent.prototype.markElementEvent = function (e) {
        markElementClickEvent(e);
    };
    CustomPagePopupComponent.prototype.customize = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        options = defaults(options, { firstInit: false });
        if (options.event) {
            if (isElementCustomizeIgnored(options.event)) {
                return;
            }
            else {
                ignoreElementCustomize(options.event);
            }
        }
        if (this.customizingElement) {
            if (options.highlight) {
                this.customizeBarContext.highlight();
            }
            return;
        }
        this.customizeBarService
            .customizePopup({ context: this.customizeBarContext, popup: this.popup, viewContext: this.context })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e && e.type === CustomizeBarEditEventType.Deleted) {
                _this.deletePopup.emit();
            }
            else if (e && e.type === CustomizeBarEditEventType.Updated) {
                var instance = e.args['result'];
                _this.setElementSize();
                _this.onCustomized(instance);
            }
        });
        this.customizeComponentData.next(this.customizeBarContext.settingsComponent);
    };
    CustomPagePopupComponent.prototype.closeCustomize = function () {
        if (this.customizeComponentData.value) {
            this.customizeBarContext.closeSettingsComponent(this.customizeComponentData.value);
            this.customizeComponentData.next(undefined);
        }
    };
    CustomPagePopupComponent.prototype.copyElement = function (cut) {
        if (cut === void 0) { cut = false; }
        this.sessionStorage.set(BUILDER_ELEMENT_BUFFER, JSON.stringify({
            popup: this.popup.serialize(),
            persistent: !cut
        }));
        if (cut) {
            this.deletePopup.emit(true);
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.Cut);
        }
        else {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Modal.Copy);
        }
    };
    CustomPagePopupComponent.prototype.onCustomized = function (popup) {
        this.popup.patch(popup);
        this.updateInputs();
        this.initContextElement();
        this.updateContextOutputs();
        this.customizeService.markChanged();
        this.cd.markForCheck();
        this.editPopup.emit();
    };
    CustomPagePopupComponent.prototype.close = function () {
        this.closePopup.emit();
    };
    CustomPagePopupComponent.prototype.onHover = function (hover) {
        if (hover) {
            this.customizeService.addHover(this);
        }
        else {
            this.customizeService.removeHover(this);
        }
    };
    CustomPagePopupComponent.prototype.onResizeStarted = function () {
        this.interactionsDisabled = true;
        this.resizingElement = true;
        this.cd.markForCheck();
    };
    CustomPagePopupComponent.prototype.onResize = function (event) {
        if (event.types.includes(ResizeType.Horizontal) && event.widthChanged) {
            this.width = event.width;
        }
        this.setElementSize();
        this.interactionsDisabled = false;
        this.resizingElement = false;
        this.cd.markForCheck();
        this.customizeService.markChanged();
    };
    CustomPagePopupComponent.prototype.setMovePagesOpened = function (value) {
        this.movePagesOpened = value;
        this.cd.markForCheck();
    };
    CustomPagePopupComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (!this.movePagesOverlay) {
            return;
        }
        this.movePagesOverlay.positionChange.pipe(untilDestroyed(this)).subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.movePagesPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.movePagesPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.movePagesOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.movePagesOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    CustomPagePopupComponent.prototype.onMouseDown = function (event) {
        markCustomPagePopupClickEvent(event);
    };
    return CustomPagePopupComponent;
}());
export { CustomPagePopupComponent };
