import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

import { NpmRegistryService } from '../services/npm/npm-registry.service';

@Injectable()
export class NpmPackageSource extends SelectSource {
  loadedPage = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private npmService: NpmRegistryService
  ) {
    super();
  }

  fetch(searchQuery: string): Observable<Option[]> {
    searchQuery = (searchQuery || '').trim();

    return this.npmService
      .search(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        searchQuery
      )
      .pipe(
        map(result => {
          return result.items.map(item => {
            return {
              value: item.name,
              name: item.name
            };
          });
        }),
        tap(() => {
          this.loadedPage = true;
        })
      );
  }

  fetchByValue(value: string): Observable<Option> {
    if (!value) {
      return of(undefined);
    }

    return this.npmService
      .getDetail(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, value)
      .pipe(
        map(result => {
          return {
            value: result.name,
            name: result.name
          };
        })
      );
  }

  isFetchAvailable(): boolean {
    return !this.loadedPage;
  }

  resetPagination() {
    this.loadedPage = false;
  }

  setStaticOptions(options: Option[]) {}
}
