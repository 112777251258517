var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, QueryList } from '@angular/core';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { moveItemInArray, transferArrayItem } from '@common/drag-drop2';
import { NotificationService } from '@common/notifications';
import { ActionControllerService, ActionService, isModelUpdateEventMatch, patchModel } from '@modules/action-queries';
import { TintStyle } from '@modules/actions';
import { CustomizeService, ElementItem, ElementType, FormElementItem, FormStyle, getModelAttributesByColumns, ITEM_OUTPUT, rawListViewSettingsColumnsToViewContextOutputs, registerElementComponent, SUBMIT_RESULT_OUTPUT, traverseElementItems, VALUE_OUTPUT, ViewContextElement } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { AutoElementComponent, BaseElementComponent, ElementContainerService } from '@modules/customize-elements';
import { DataSourceGeneratorService } from '@modules/customize-generators';
import { DataSourceType, ModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionDataSourceService } from '@modules/data-sources-queries';
import { applyParamInput$, applyParamInputs$, createFormFieldFactory, DisplayFieldType, ERROR_VALUE, FieldType, getFieldDescriptionByType, InputValueType, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { QueryType } from '@modules/queries';
import { paramsToGetQueryOptions } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { ascComparator } from '@shared';
import { ElementGroupsContainerDirective } from '../../directives/element-groups-container/element-groups-container.directive';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
import { RootLayoutComponent } from '../root-layout/root-layout.component';
export function serializeDataSourceColumns(columns) {
    return columns
        .filter(function (item) { return !item.flex; })
        .map(function (item) {
        return {
            name: item.name
        };
    })
        .sort(function (lhs, rhs) { return ascComparator(lhs.name, rhs.name); });
}
function getElementStateFetch(state) {
    return {
        dataSource: state.dataSource
            ? __assign({}, state.dataSource.serialize(), { columns: serializeDataSourceColumns(state.dataSource.columns) }) : undefined,
        staticData: state.staticData,
        params: state.params,
        inputsLoading: state.inputsLoading,
        inputsNotSet: state.inputsNotSet
    };
}
function getElementStateColumns(state) {
    return {
        columns: state.dataSource ? state.dataSource.columns : undefined
    };
}
function getElementStateName(state) {
    return {
        name: state.element ? state.element.name : undefined
    };
}
function getElementStateParameters(state) {
    return {
        parameters: state.parameters ? state.parameters.map(function (item) { return item.serialize(); }) : []
    };
}
function getElementStateValue(state) {
    return {
        value: state.value
    };
}
function getElementStateSubmitDisabled(state) {
    return {
        configured: state.configured,
        valueError: state.valueError
    };
}
var FormElementComponent = /** @class */ (function (_super) {
    __extends(FormElementComponent, _super);
    function FormElementComponent(customizeService, elementContainerService, elementConfigurationService, routing, modelService, currentProjectStore, currentEnvironmentStore, modelDescriptionStore, actionService, actionControllerService, modelDescriptionDataSourceService, dataSourceGeneratorService, notificationService, injector, cd, viewContextElement, rootLayoutComponent, popup) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.elementContainerService = elementContainerService;
        _this.elementConfigurationService = elementConfigurationService;
        _this.routing = routing;
        _this.modelService = modelService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.actionService = actionService;
        _this.actionControllerService = actionControllerService;
        _this.modelDescriptionDataSourceService = modelDescriptionDataSourceService;
        _this.dataSourceGeneratorService = dataSourceGeneratorService;
        _this.notificationService = notificationService;
        _this.injector = injector;
        _this.cd = cd;
        _this.viewContextElement = viewContextElement;
        _this.rootLayoutComponent = rootLayoutComponent;
        _this.popup = popup;
        _this.elementComponents = new QueryList();
        _this.firstVisible$ = new BehaviorSubject(false);
        _this.elementState = {};
        _this.createField = createFormFieldFactory();
        _this.loading$ = new BehaviorSubject(false);
        _this.value$ = new BehaviorSubject({});
        _this.submitDisabled$ = new BehaviorSubject(false);
        _this.submitLoading$ = new BehaviorSubject(false);
        _this.stubRangeDefault = range(3);
        _this.stubRange = _this.stubRangeDefault;
        _this.stubs = [
            {
                label: 'label',
                value: 'long value'
            },
            {
                label: 'long label',
                value: 'value'
            },
            {
                label: 'very long label',
                value: 'value'
            },
            {
                label: 'label',
                value: 'very long value'
            }
        ];
        _this.formStyles = FormStyle;
        _this.tintStyles = TintStyle;
        _this.canEnter = (function () {
            return function (drag, drop) {
                if (_this.rootLayoutComponent && !_this.rootLayoutComponent.active) {
                    return false;
                }
                if (drag.data instanceof ElementItem && drag.data.type == ElementType.FormSubmit) {
                    var isInsideCurrentForm_1 = false;
                    traverseElementItems(_this.element, function (item) {
                        if (item !== _this.element && item instanceof FormElementItem) {
                            return false;
                        }
                        else if (item === drag.data) {
                            isInsideCurrentForm_1 = true;
                        }
                    });
                    return isInsideCurrentForm_1;
                }
                else {
                    return true;
                }
            };
        })();
        _this.trackElement = (function () {
            return function (i, item) {
                var pageUid = _this.context && _this.context.viewSettings && !_this.context.viewSettings.newlyCreated
                    ? _this.context.viewSettings.uid
                    : undefined;
                return [pageUid, item.uid].join('_');
            };
        })();
        return _this;
    }
    FormElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.initContext();
        this.trackModelUpdates();
        this.elementOnChange(this.element);
        this.trackChanges();
    };
    FormElementComponent.prototype.ngOnDestroy = function () { };
    FormElementComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.element) {
            this.initContext();
            this.elementOnChange(this.element);
        }
        if (changes.element && !changes.element.firstChange) {
            setTimeout(function () { return _this.updateElementStatesOnStable(); }, 0);
        }
    };
    FormElementComponent.prototype.ngAfterViewInit = function () {
        this.updateElementStates();
    };
    FormElementComponent.prototype.trackChanges = function () {
        var _this = this;
        this.firstVisible$
            .pipe(first(function (value) { return value; }), switchMap(function () { return _this.element$; }), switchMap(function (element) {
            if (!element.getDataSource) {
                return of(element);
            }
            return _this.dataSourceGeneratorService
                .applyDataSourceDefaults(ModelDescriptionDataSource, element.getDataSource)
                .pipe(map(function (dataSource) {
                element.getDataSource = dataSource;
                return element;
            }));
        }), switchMap(function (element) { return _this.getElementState(element); }), untilDestroyed(this))
            .subscribe(function (state) {
            _this.onStateUpdated(state);
            _this.elementState = state;
        });
    };
    FormElementComponent.prototype.getElementState = function (element) {
        var _this = this;
        var value$ = element.submitAction
            ? this.actionService.getActionDescription(element.submitAction).pipe(switchMap(function (actionDescription) {
                if (!actionDescription) {
                    return of({});
                }
                return applyParamInputs$({}, element.submitAction.inputs, {
                    context: _this.context,
                    parameters: actionDescription.actionParams,
                    raiseErrors: true,
                    errorValue: ERROR_VALUE,
                    handleLoading: true,
                    ignoreEmpty: true
                });
            }), distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        var staticData$ = element.getDataSource && element.getDataSource.type == DataSourceType.Input && element.getDataSource.input
            ? applyParamInput$(element.getDataSource.input, {
                context: this.context,
                defaultValue: {},
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        var dataSourceParams$ = element.getDataSource
            ? applyParamInputs$({}, element.getDataSource.queryInputs, {
                context: this.context,
                parameters: element.getDataSource.queryParameters,
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        return combineLatest(this.elementConfigurationService.isActionConfigured(element.submitAction, { ignoreInputs: true }), this.actionService.getActionDescription(element.submitAction), value$, staticData$, dataSourceParams$, this.getQueryModelDescription(element.getDataSource)).pipe(map(function (_a) {
            var configured = _a[0], actionDescription = _a[1], value = _a[2], staticData = _a[3], dataSourceParams = _a[4], modelDescription = _a[5];
            var parameters = actionDescription
                ? _this.getFormParameters(actionDescription, element.getDataSource)
                    .filter(function (item) { return item.enabled; })
                    .map(function (item) { return item.parameter; })
                : [];
            var inputsLoading = [dataSourceParams, staticData].some(function (obj) {
                return obj == LOADING_VALUE || values(obj).some(function (item) { return item === LOADING_VALUE; });
            });
            var inputsNotSet = [dataSourceParams, staticData].some(function (obj) {
                return obj == NOT_SET_VALUE || values(obj).some(function (item) { return item === NOT_SET_VALUE; });
            });
            return {
                element: element,
                configured: configured,
                parameters: parameters,
                value: value !== ERROR_VALUE ? value : {},
                valueError: value === ERROR_VALUE,
                dataSource: element.getDataSource,
                staticData: staticData,
                params: dataSourceParams,
                modelDescription: modelDescription,
                inputsLoading: inputsLoading,
                inputsNotSet: inputsNotSet
            };
        }));
    };
    FormElementComponent.prototype.getFormParameters = function (actionDescription, getDataSource) {
        if (!actionDescription) {
            return [];
        }
        var actionQueryType = actionDescription.queryAction && actionDescription.queryAction.query
            ? actionDescription.queryAction.query.queryType
            : undefined;
        var updateModelAction = getDataSource &&
            getDataSource.queryResource == actionDescription.resource &&
            actionQueryType == QueryType.Simple &&
            getDataSource.query &&
            getDataSource.query.queryType == QueryType.Simple &&
            getDataSource.query.simpleQuery &&
            actionDescription.modelAction == 'update';
        return actionDescription.actionParams.map(function (parameter) {
            var getQueryInput = updateModelAction && getDataSource.queryInputs.find(function (item) { return item.isName(parameter.name) && item.isSet(); });
            return {
                parameter: parameter,
                enabled: !getQueryInput,
                getQueryInput: !!getQueryInput
            };
        });
    };
    FormElementComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getElementStateColumns(state), getElementStateColumns(this.elementState))) {
            this.updateStubs(state);
            this.updateContextOutputs(state);
        }
        if (!isEqual(getElementStateName(state), getElementStateName(this.elementState))) {
            this.updateContextInfo(state);
        }
        if (!isEqual(getElementStateFetch(state), getElementStateFetch(this.elementState))) {
            this.fetch(state);
        }
        if (!isEqual(getElementStateParameters(state), getElementStateParameters(this.elementState))) {
            this.updateContextOutputs(state);
        }
        if (!isEqual(getElementStateValue(state), getElementStateValue(this.elementState))) {
            this.setCurrentValue(state.value);
        }
        if (!isEqual(getElementStateSubmitDisabled(state), getElementStateSubmitDisabled(this.elementState))) {
            this.updateSubmitDisabled(state);
        }
    };
    FormElementComponent.prototype.fetch = function (state) {
        var _this = this;
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
            this.loadingSubscription = undefined;
        }
        if (!state.dataSource) {
            this.model = undefined;
            this.updateItemContextValue(state);
            this.cd.markForCheck();
            return;
        }
        if (state.inputsNotSet) {
            this.model = undefined;
            this.updateItemContextValue(state);
            this.loading$.next(false);
            this.cd.markForCheck();
            return;
        }
        this.loading$.next(true);
        this.cd.markForCheck();
        this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: true });
        if (state.inputsLoading) {
            return;
        }
        var queryOptions = paramsToGetQueryOptions(state.params);
        queryOptions.columns = state.dataSource ? state.dataSource.columns : undefined;
        this.loadingSubscription = this.modelDescriptionDataSourceService
            .getDetailAdv({
            project: this.currentProjectStore.instance,
            environment: this.currentEnvironmentStore.instance,
            dataSource: state.dataSource,
            queryOptions: queryOptions,
            staticData: state.staticData,
            context: this.context
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (model) {
            _this.model = model;
            _this.updateItemContextValue(state);
            _this.loading$.next(false);
            _this.cd.markForCheck();
            _this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: false });
        }, function () {
            _this.model = undefined;
            _this.updateItemContextValue(state);
            _this.loading$.next(false);
            _this.cd.markForCheck();
            _this.viewContextElement.patchOutputValueMeta(ITEM_OUTPUT, { loading: false });
        });
    };
    FormElementComponent.prototype.getQueryModelDescription = function (dataSource) {
        if (!dataSource ||
            !dataSource.query ||
            dataSource.query.queryType != QueryType.Simple ||
            !dataSource.query.simpleQuery) {
            return of(undefined);
        }
        var modelId = [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId);
    };
    FormElementComponent.prototype.reloadData = function () {
        this.fetch(this.elementState);
    };
    FormElementComponent.prototype.updateStubs = function (state) {
        this.stubRange =
            state.dataSource && state.dataSource.columns.length
                ? range(state.dataSource.columns.filter(function (item) { return item.visible; }).length)
                : this.stubRangeDefault;
        this.cd.markForCheck();
    };
    FormElementComponent.prototype.initContext = function () {
        var _this = this;
        this.viewContextElement.initElement({
            uniqueName: this.element.uid,
            name: this.element.name,
            icon: 'windows',
            element: this.element,
            popup: this.popup ? this.popup.popup : undefined
        });
        this.viewContextElement.setActions([
            {
                uniqueName: 'update_data',
                name: 'Update Data',
                icon: 'repeat',
                parameters: [],
                handler: function () { return _this.reloadData(); }
            },
            {
                uniqueName: 'clear_value',
                name: 'Clear Form',
                icon: 'delete',
                parameters: [],
                handler: function () {
                    var obs = _this.element.submitAction.inputs
                        .filter(function (item) { return item.valueType == InputValueType.Context; })
                        .map(function (input) {
                        var path = input.contextValue;
                        if (path.length == 3 && path[0] == 'elements' && path[2] == 'value') {
                            var actionPath = path.slice(0, -1).concat(['clear_value']);
                            var action = _this.context.getElementAction(actionPath);
                            if (action) {
                                return _this.actionService.executeElement(actionPath, _this.context, { pristine: true });
                            }
                        }
                    })
                        .filter(function (item) { return item; });
                    if (!obs.length) {
                        return of(true);
                    }
                    return combineLatest(obs).pipe(map(function () { return true; }));
                }
            }
        ]);
    };
    FormElementComponent.prototype.updateContextInfo = function (state) {
        this.viewContextElement.initInfo({
            name: state.element.name,
            getFieldValue: function (field, outputs) {
                return outputs[VALUE_OUTPUT] ? outputs[VALUE_OUTPUT][field] : undefined;
            }
        }, true);
    };
    FormElementComponent.prototype.updateContextOutputs = function (state) {
        var children = state.dataSource
            ? rawListViewSettingsColumnsToViewContextOutputs(state.dataSource.columns.filter(function (item) { return item.type != DisplayFieldType.Computed; }), state.modelDescription)
            : [];
        this.viewContextElement.setOutputs([
            {
                uniqueName: VALUE_OUTPUT,
                name: 'Current Value',
                icon: 'edit',
                children: state.parameters.map(function (item) {
                    var fieldDescription = getFieldDescriptionByType(item.field);
                    var icon = fieldDescription ? fieldDescription.icon : undefined;
                    return {
                        uniqueName: item.name,
                        name: item.verboseName,
                        icon: icon,
                        // iconOrange: primaryKey,
                        fieldType: item.field,
                        fieldParams: item.params
                    };
                }),
                external: true
            },
            {
                uniqueName: ITEM_OUTPUT,
                name: 'Current Record',
                icon: 'duplicate_2',
                children: children,
                external: true
            },
            {
                uniqueName: SUBMIT_RESULT_OUTPUT,
                name: 'Submit Result',
                icon: 'components',
                fieldType: FieldType.JSON,
                external: true
            }
        ]);
    };
    FormElementComponent.prototype.updateItemContextValue = function (state) {
        var output = this.model && state.dataSource ? getModelAttributesByColumns(this.model, state.dataSource.columns) : undefined;
        this.viewContextElement.setOutputValue(ITEM_OUTPUT, output);
        // Backward compatibility
        this.viewContextElement.setOutputValue('model', output);
    };
    FormElementComponent.prototype.setCurrentValue = function (value) {
        this.value$.next(value);
        this.viewContextElement.setOutputValue(VALUE_OUTPUT, value);
    };
    FormElementComponent.prototype.trackModelUpdates = function () {
        var _this = this;
        this.actionService.modelUpdated$.pipe(untilDestroyed(this)).subscribe(function (e) {
            if (isModelUpdateEventMatch(e, _this.elementState.modelDescription, _this.model)) {
                _this.model = patchModel(_this.model, e.model);
                _this.cd.markForCheck();
                _this.updateItemContextValue(_this.elementState);
            }
        });
    };
    FormElementComponent.prototype.isSubmitDisabled$ = function () {
        return this.submitDisabled$.asObservable();
    };
    FormElementComponent.prototype.updateSubmitDisabled = function (state) {
        var _this = this;
        if (this.updateSubmitDisabledSubscription) {
            this.updateSubmitDisabledSubscription.unsubscribe();
            this.updateSubmitDisabledSubscription = undefined;
        }
        this.updateSubmitDisabledSubscription = combineLatest(this.loading$, this.submitLoading$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var loading = _a[0], submitLoading = _a[1];
            var value = !state.configured || loading || submitLoading || state.valueError;
            _this.submitDisabled$.next(value);
        });
    };
    FormElementComponent.prototype.submit = function () {
        var _this = this;
        if (this.submitDisabled$.value || !this.elementState.element || !this.elementState.element.submitAction) {
            return;
        }
        this.submitLoading$.next(true);
        this.cd.markForCheck();
        return this.actionControllerService
            .execute(this.elementState.element.submitAction, {
            context: this.context,
            contextElement: this.viewContextElement,
            saveResultTo: SUBMIT_RESULT_OUTPUT,
            injector: this.injector
        })
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.submitLoading$.next(false);
            _this.cd.markForCheck();
        }, function () {
            _this.submitLoading$.next(false);
            _this.cd.markForCheck();
        });
    };
    FormElementComponent.prototype.dragDrop = function (event) {
        var item = event.previousContainer.data[event.previousIndex];
        var barItem = item instanceof ElementItem ? undefined : item;
        if (barItem && barItem.popup) {
            if (this.customizeService.handler && this.customizeService.handler.createPopup) {
                this.customizeService.handler.createPopup(true, __assign({}, (barItem.defaultParams && {
                    width: barItem.defaultParams['width'],
                    style: barItem.defaultParams['style'],
                    position: barItem.defaultParams['position']
                }), { analyticsSource: 'components_library' }));
                this.elementContainerService.sendAddPopupAnalytics();
            }
            return;
        }
        var siblingLeftEntered = event.data ? !!event.data['siblingLeftEntered'] : false;
        var siblingRightEntered = event.data ? !!event.data['siblingRightEntered'] : false;
        var siblingSelf = event.data ? !!event.data['siblingSelf'] : false;
        var siblingAnchor = event.data ? event.data['siblingAnchor'] : undefined;
        var siblingAnchorContainer = event.data ? event.data['siblingAnchorContainer'] : undefined;
        if (siblingLeftEntered || siblingRightEntered) {
            var anchorContainer = siblingSelf ? event.container.data : siblingAnchorContainer.data;
            var anchorIndex = siblingSelf ? event.currentIndex : anchorContainer.indexOf(siblingAnchor);
            this.elementContainerService.dragDropIntoSiblingColumn({
                sourceContainer: event.previousContainer.data,
                sourceIndex: event.previousIndex,
                sourceCloneItem: event.previousContainer.cloneItems,
                anchorContainer: anchorContainer,
                anchorIndex: anchorIndex,
                anchorSelf: siblingSelf,
                left: siblingLeftEntered,
                context: this.context,
                parent: this.element
            });
            // TODO: Implement onAdd
            // this.cd.detectChanges();
            // const component = this.elementComponents.find(i => i.element === elementItem);
            // this.onAdd(elementItem, item, component);
        }
        else {
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            }
            else if (event.previousContainer.cloneItems) {
                var elementItem = this.elementContainerService.copyElementItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex, this.context);
                this.customizeService.registerCreatedElement(elementItem, barItem);
            }
            else {
                transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            }
        }
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
    };
    FormElementComponent.prototype.duplicateItem = function (index) {
        var elementItem = this.elementContainerService.duplicateElementItem(this.element.children, this.context, index);
        this.cd.detectChanges();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
        var component = this.elementComponents.find(function (i) { return i.element === elementItem; });
        if (component) {
            component.customize();
        }
    };
    FormElementComponent.prototype.replaceItem = function (index, elements) {
        this.elementContainerService.replaceElementItem(this.element.children, index, elements);
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
    };
    FormElementComponent.prototype.deleteItem = function (element) {
        var index = this.element.children.findIndex(function (item) { return item === element; });
        if (index == -1) {
            return;
        }
        this.elementContainerService.deleteElementItem(this.element.children, index);
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
    };
    FormElementComponent.prototype.moveItemTo = function (element, link) {
        var _this = this;
        this.deleteItem(element);
        this.customizeService.stopTrackChanges();
        this.customizeService
            .saveActualChanges()
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.routing.navigateApp(link); });
    };
    FormElementComponent.prototype.updateElementStatesOnStable = function () {
        if (this.elementGroupsContainer) {
            this.elementGroupsContainer.updateElementStatesOnStable();
        }
    };
    FormElementComponent.prototype.updateElementStates = function () {
        if (this.elementGroupsContainer) {
            this.elementGroupsContainer.updateElementStates();
        }
    };
    return FormElementComponent;
}(BaseElementComponent));
export { FormElementComponent };
registerElementComponent({
    type: ElementType.Form,
    component: FormElementComponent,
    alwaysActive: false,
    label: 'Form',
    actions: []
});
