import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, Optional } from '@angular/core';

import {
  PageBlockDefaults,
  PageBlockDefaultsDirective
} from '../../directives/page-block-defaults/page-block-defaults.directive';

export type PageBlockSize = 'm';
export type PageBlockMarginSize = 'none' | 'xxs' | 'xs' | 's' | 'm' | 'l';
export type PageBlockPaddingSize = 'none' | 'xxxs' | 'xxs' | 'xs' | 's' | 'm';

@Component({
  selector: 'app-page-block',
  templateUrl: './page-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageBlockComponent implements OnInit {
  @Input() @HostBinding('class.page-block') cls = true;
  @Input() @HostBinding('class.page-block_title') title = false;
  @Input() @HostBinding('class.page-block_fill') fill = false;
  @Input() @HostBinding('class.page-block_first') first = false;
  @Input() @HostBinding('class.page-block_last') last = false;
  @Input() size: PageBlockSize;
  @Input() marginSize: PageBlockMarginSize;
  @Input() paddingSize: PageBlockPaddingSize;

  defaultMarginSize: PageBlockMarginSize = 'm';
  defaultPaddingSize: PageBlockPaddingSize = 'm';

  @HostBinding('class.page-block_size_m') get sizeM() {
    return this.size == 'm';
  }

  @HostBinding('class.page-block_margin-size_none') get marginSizeNone() {
    return this.marginSize == 'none';
  }

  @HostBinding('class.page-block_margin-size_xxs') get marginSizeXXS() {
    return this.marginSize == 'xxs';
  }

  @HostBinding('class.page-block_margin-size_xs') get marginSizeXS() {
    return this.marginSize == 'xs';
  }

  @HostBinding('class.page-block_margin-size_s') get marginSizeS() {
    return this.marginSize == 's';
  }

  @HostBinding('class.page-block_margin-size_m') get marginSizeM() {
    return this.marginSize == 'm';
  }

  @HostBinding('class.page-block_margin-size_l') get marginSizeL() {
    return this.marginSize == 'l';
  }

  @HostBinding('class.page-block_padding-size_none') get paddingSizeNone() {
    return this.paddingSize == 'none';
  }

  @HostBinding('class.page-block_padding-size_xxxs') get paddingSizeXXXS() {
    return this.paddingSize == 'xxxs';
  }

  @HostBinding('class.page-block_padding-size_xxs') get paddingSizeXXS() {
    return this.paddingSize == 'xxs';
  }

  @HostBinding('class.page-block_padding-size_xs') get paddingSizeXS() {
    return this.paddingSize == 'xs';
  }

  @HostBinding('class.page-block_padding-size_s') get paddingSizeS() {
    return this.paddingSize == 's';
  }

  @HostBinding('class.page-block_padding-size_m') get paddingSizeM() {
    return this.paddingSize == 'm';
  }

  constructor(@Optional() private defaultsDirective: PageBlockDefaultsDirective) {}

  ngOnInit() {
    const defaultsDirective: PageBlockDefaults = this.defaultsDirective
      ? this.defaultsDirective.appPageBlockDefaults
      : {};

    this.size = this.size || defaultsDirective.size;
    this.marginSize = this.marginSize || defaultsDirective.marginSize || this.defaultMarginSize;
    this.paddingSize = this.paddingSize || defaultsDirective.paddingSize || this.defaultPaddingSize;
  }
}
