import { Injectable, OnDestroy } from '@angular/core';
import clone from 'lodash/clone';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { isSet } from '@shared';

import { Environment } from '../data/environment';
import { ProjectGroup, ProjectUser } from '../data/project-user';
import { Resource } from '../data/resource';
import { ProjectPropertyStorage } from '../services/project-property-storage/project-property.storage';
import { CurrentProjectStore } from './current-project.store';

@Injectable()
export class CurrentEnvironmentStore implements OnDestroy {
  public globalStorage: ProjectPropertyStorage;

  private _uniqueName = new BehaviorSubject<string>(undefined);
  private _instance = new BehaviorSubject<Environment>(undefined);
  private _resources = new BehaviorSubject<Resource[]>([]);
  private _original$ = new BehaviorSubject<Environment>(undefined);
  private _overrideUser$ = new BehaviorSubject<ProjectUser>(undefined);
  private _overrideGroup$ = new BehaviorSubject<ProjectGroup>(undefined);

  constructor(private currentProjectStore: CurrentProjectStore) {
    combineLatest(this.currentProjectStore.instance$, this._uniqueName, this._overrideUser$, this._overrideGroup$)
      .pipe(untilDestroyed(this))
      .subscribe(([project, environmentName, overrideUser, overrideGroup]) => {
        let environment = project
          ? project.environments.find(item => item.uniqueName == environmentName && !!item.group)
          : undefined;
        const resources = project && environment ? project.getEnvironmentResources(environment.uniqueName) : [];

        this._original$.next(environment);

        if (environment) {
          if (isSet(overrideUser) || isSet(overrideGroup)) {
            environment = clone(environment);
            environment.user = isSet(overrideUser) ? overrideUser : environment.user;
            environment.group = isSet(overrideGroup) ? overrideGroup : environment.group;
          }
        }

        this._instance.next(environment);
        this._resources.next(resources);
      });
  }

  ngOnDestroy(): void {}

  get uniqueName(): string {
    return this._uniqueName.value;
  }

  set uniqueName(value: string) {
    this._uniqueName.next(value);
  }

  get instance(): Environment {
    return this._instance.value;
  }

  get instance$(): Observable<Environment> {
    return this._instance.asObservable();
  }

  get resources(): Resource[] {
    return this._resources.value;
  }

  get resources$(): Observable<Resource[]> {
    return this._resources.asObservable();
  }

  getFirst(): Observable<Environment> {
    return this.instance$.pipe(first());
  }

  getResourcesFirst(): Observable<Resource[]> {
    return this.resources$.pipe(first());
  }

  get original(): Environment {
    return this._original$.value;
  }

  get original$(): Observable<Environment> {
    return this._original$.asObservable();
  }

  get overrideUser(): ProjectUser {
    return this._overrideUser$.value;
  }

  get overrideUser$(): Observable<ProjectUser> {
    return this._overrideUser$.asObservable();
  }

  setOverrideUser(value: ProjectUser) {
    this._overrideUser$.next(value);
  }

  clearOverrideUser() {
    this.setOverrideUser(undefined);
  }

  get overrideGroup(): ProjectGroup {
    return this._overrideGroup$.value;
  }

  get overrideGroup$(): Observable<ProjectGroup> {
    return this._overrideGroup$.asObservable();
  }

  setOverrideGroup(value: ProjectGroup) {
    this._overrideGroup$.next(value);
  }

  clearOverrideGroup() {
    this.setOverrideGroup(undefined);
  }

  updateUser(user: ProjectUser) {
    if (this.instance) {
      this.currentProjectStore.updateEnvironmentUser(this.instance.uniqueName, user);
    }

    if (this._overrideUser$.value && this._overrideUser$.value.uid == user.uid) {
      this._overrideUser$.next(user);
    }
  }

  updateGroup(group: ProjectGroup) {
    if (this.instance) {
      this.currentProjectStore.updateEnvironmentGroup(this.instance.uniqueName, group);
    }

    if (this._overrideGroup$.value && this._overrideGroup$.value.uid == group.uid) {
      this._overrideGroup$.next(group);
    }
  }
}
