import camelCase from 'lodash/camelCase';
import { slugify } from 'transliteration';

import { isSet } from '@shared';

import { JavascriptDependency } from '../data/javascript-dependency';

export function slugifyJavaScriptPackage(name: string): string {
  return camelCase(slugify(name, { trim: true, separator: '_' }));
}

export function getJavascriptDependencyImport(dependency: JavascriptDependency) {
  const url = `https://esm.sh/${dependency.package}${isSet(dependency.version) ? `@${dependency.version}` : ''}`;
  const packageVariable = slugifyJavaScriptPackage(dependency.package);

  if (dependency.imports.length == 1 && dependency.imports[0].name == '*') {
    return `const ${packageVariable} = await import('${url}');`;
  } else {
    const variables = dependency.imports.map(item => (isSet(item.alias) ? `${item.name}: ${item.alias}` : item.name));
    return `const { ${variables.join(', ')} } = await import('${url}');`;
  }
}
