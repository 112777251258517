<div class="menu3-section">
  <div class="menu3-section__header">
    <div class="menu3-section__header-main">
      <div class="menu3-section__header-title">
        {{ section.label }}

        <span
          *ngIf="section.documentation | appIsSet"
          class="menu3-section__header-title-action icon-help"
          [appDocumentation]="section.documentation"
          [appDocumentationLeft]="false"
        ></span>
      </div>
    </div>

    <div *ngIf="section.itemsTotal || !(section.descriptionTitle | appIsSet)" class="menu3-section__header-right">
      <div
        *ngIf="section.button"
        class="menu3-section__header-action menu3-section__header-action_orange icon-plus"
        (click)="section.button.handler()"
        [appTip]="section.button.label"
        [appTipOptions]="{ side: 'top' }"
      ></div>
    </div>
  </div>

  <ng-container *ngIf="!section.itemsTotal">
    <div *ngIf="!bannerHidden && (section.descriptionTitle | appIsSet)" class="menu3-section__banner">
      <div class="menu3-section-banner menu3-section-banner_orange">
        <!--        <div class="menu3-section-banner__close icon-close" (click)="setBannerHidden(true)"></div>-->
        <div
          *ngIf="section.icon"
          class="menu3-section-banner__icon"
          [ngClass]="section.icon ? 'icon-' + section.icon : 'icon-cloud'"
        ></div>
        <div class="menu3-section-banner__title">{{ section.descriptionTitle }}</div>
        <div class="menu3-section-banner__description">{{ section.descriptionText }}</div>

        <div *ngIf="section.button" class="menu3-section-banner__buttons">
          <div class="menu3-section-banner__button" (click)="section.button.handler()">
            <div
              class="menu3-section-banner__button-icon"
              [ngClass]="section.button.icon ? 'icon-' + section.button.icon : 'icon-plus'"
            ></div>
            <div class="menu3-section-banner__button-label">{{ section.button.label }}</div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="bannerHidden || !(section.descriptionTitle | appIsSet)">
      <div *ngIf="section.button" class="menu3-section__items">
        <div class="menu3-action-item menu3-action-item_orange menu3-section__item" (click)="section.button.handler()">
          <div class="menu3-action-item__left">
            <div
              class="menu3-action-item__icon"
              [ngClass]="section.button.icon ? 'icon-' + section.button.icon : 'icon-plus'"
            ></div>
          </div>
          <div class="menu3-action-item__main">
            <div class="menu3-action-item__title">{{ section.button.label }}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="section.items.length" class="menu3-section__items">
    <div *ngFor="let item of section.items" class="menu3-section__item">
      <app-custom-page-popups-menu-section-item
        [item]="item"
        [active]="openedPopupUids.includes(item.uid)"
        [viewSettings]="viewSettings"
        (selected)="openPopup.emit(item.uid)"
        (customize)="customizePopup.emit(item.uid)"
        (moveToPage)="movePopupToPage.emit({ uid: item.uid, page: $event })"
        (duplicate)="duplicatePopup.emit(item.uid)"
        (delete)="deletePopup.emit(item.uid)"
      ></app-custom-page-popups-menu-section-item>
    </div>
  </div>
</div>
