import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import camelCase from 'lodash/camelCase';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { slugify } from 'transliteration';
import { NpmPackageExportsSource, NpmPackageSource, NpmPackageVersionSource } from '@modules/npm';
import { controlValue } from '@shared';
var Section;
(function (Section) {
    Section["Package"] = "package";
    Section["Version"] = "version";
    Section["Imports"] = "imports";
})(Section || (Section = {}));
var SelectJavaScriptDependencyComponent = /** @class */ (function () {
    function SelectJavaScriptDependencyComponent(npmPackageSource, npmPackageVersionSource, npmPackageExportsSource, cd) {
        this.npmPackageSource = npmPackageSource;
        this.npmPackageVersionSource = npmPackageVersionSource;
        this.npmPackageExportsSource = npmPackageExportsSource;
        this.cd = cd;
        this.selected = new EventEmitter();
        this.npmPackageControl = new FormControl('', Validators.required);
        this.npmPackageVersionControl = new FormControl('', Validators.required);
        this.npmPackageExportsControl = new FormControl([], Validators.required);
        this.section = Section.Package;
        this.sections = Section;
    }
    SelectJavaScriptDependencyComponent.prototype.ngOnInit = function () {
        var _this = this;
        controlValue(this.npmPackageControl)
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.npmPackageVersionSource.package = value;
            _this.npmPackageVersionSource.reset();
        });
        combineLatest(controlValue(this.npmPackageControl), controlValue(this.npmPackageVersionControl))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var name = _a[0], version = _a[1];
            _this.npmPackageExportsSource.package = name;
            _this.npmPackageExportsSource.version = version;
            _this.npmPackageExportsSource.reset();
        });
    };
    SelectJavaScriptDependencyComponent.prototype.ngOnDestroy = function () { };
    SelectJavaScriptDependencyComponent.prototype.setSection = function (value) {
        this.section = value;
        this.cd.markForCheck();
    };
    SelectJavaScriptDependencyComponent.prototype.submit = function () {
        var packageName = camelCase(slugify(this.npmPackageControl.value, { trim: true, separator: '_' }));
        var imports = this.npmPackageExportsControl.value.map(function (item) {
            return item == 'default' ? { name: item, alias: packageName } : { name: item };
        });
        this.selected.emit({
            package: this.npmPackageControl.value,
            version: this.npmPackageVersionControl.value,
            imports: imports
        });
    };
    return SelectJavaScriptDependencyComponent;
}());
export { SelectJavaScriptDependencyComponent };
