import { OnDestroy } from '@angular/core';
import clone from 'lodash/clone';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { isSet } from '@shared';
import { CurrentProjectStore } from './current-project.store';
var CurrentEnvironmentStore = /** @class */ (function () {
    function CurrentEnvironmentStore(currentProjectStore) {
        var _this = this;
        this.currentProjectStore = currentProjectStore;
        this._uniqueName = new BehaviorSubject(undefined);
        this._instance = new BehaviorSubject(undefined);
        this._resources = new BehaviorSubject([]);
        this._original$ = new BehaviorSubject(undefined);
        this._overrideUser$ = new BehaviorSubject(undefined);
        this._overrideGroup$ = new BehaviorSubject(undefined);
        combineLatest(this.currentProjectStore.instance$, this._uniqueName, this._overrideUser$, this._overrideGroup$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var project = _a[0], environmentName = _a[1], overrideUser = _a[2], overrideGroup = _a[3];
            var environment = project
                ? project.environments.find(function (item) { return item.uniqueName == environmentName && !!item.group; })
                : undefined;
            var resources = project && environment ? project.getEnvironmentResources(environment.uniqueName) : [];
            _this._original$.next(environment);
            if (environment) {
                if (isSet(overrideUser) || isSet(overrideGroup)) {
                    environment = clone(environment);
                    environment.user = isSet(overrideUser) ? overrideUser : environment.user;
                    environment.group = isSet(overrideGroup) ? overrideGroup : environment.group;
                }
            }
            _this._instance.next(environment);
            _this._resources.next(resources);
        });
    }
    CurrentEnvironmentStore.prototype.ngOnDestroy = function () { };
    Object.defineProperty(CurrentEnvironmentStore.prototype, "uniqueName", {
        get: function () {
            return this._uniqueName.value;
        },
        set: function (value) {
            this._uniqueName.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentEnvironmentStore.prototype, "instance", {
        get: function () {
            return this._instance.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentEnvironmentStore.prototype, "instance$", {
        get: function () {
            return this._instance.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentEnvironmentStore.prototype, "resources", {
        get: function () {
            return this._resources.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentEnvironmentStore.prototype, "resources$", {
        get: function () {
            return this._resources.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CurrentEnvironmentStore.prototype.getFirst = function () {
        return this.instance$.pipe(first());
    };
    CurrentEnvironmentStore.prototype.getResourcesFirst = function () {
        return this.resources$.pipe(first());
    };
    Object.defineProperty(CurrentEnvironmentStore.prototype, "original", {
        get: function () {
            return this._original$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentEnvironmentStore.prototype, "original$", {
        get: function () {
            return this._original$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentEnvironmentStore.prototype, "overrideUser", {
        get: function () {
            return this._overrideUser$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentEnvironmentStore.prototype, "overrideUser$", {
        get: function () {
            return this._overrideUser$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CurrentEnvironmentStore.prototype.setOverrideUser = function (value) {
        this._overrideUser$.next(value);
    };
    CurrentEnvironmentStore.prototype.clearOverrideUser = function () {
        this.setOverrideUser(undefined);
    };
    Object.defineProperty(CurrentEnvironmentStore.prototype, "overrideGroup", {
        get: function () {
            return this._overrideGroup$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CurrentEnvironmentStore.prototype, "overrideGroup$", {
        get: function () {
            return this._overrideGroup$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CurrentEnvironmentStore.prototype.setOverrideGroup = function (value) {
        this._overrideGroup$.next(value);
    };
    CurrentEnvironmentStore.prototype.clearOverrideGroup = function () {
        this.setOverrideGroup(undefined);
    };
    CurrentEnvironmentStore.prototype.updateUser = function (user) {
        if (this.instance) {
            this.currentProjectStore.updateEnvironmentUser(this.instance.uniqueName, user);
        }
        if (this._overrideUser$.value && this._overrideUser$.value.uid == user.uid) {
            this._overrideUser$.next(user);
        }
    };
    CurrentEnvironmentStore.prototype.updateGroup = function (group) {
        if (this.instance) {
            this.currentProjectStore.updateEnvironmentGroup(this.instance.uniqueName, group);
        }
        if (this._overrideGroup$.value && this._overrideGroup$.value.uid == group.uid) {
            this._overrideGroup$.next(group);
        }
    };
    return CurrentEnvironmentStore;
}());
export { CurrentEnvironmentStore };
