export const CURRENCY_SYMBOLS = [
  '$',
  '¢',
  '£',
  '¤',
  '¥',
  '֏',
  '؋',
  '߾',
  '߿',
  '৲',
  '৳',
  '৻',
  '૱',
  '௹',
  '฿',
  '៛',
  '₠',
  '₡',
  '₢',
  '₣',
  '₤',
  '₥',
  '₦',
  '₧',
  '₨',
  '₩',
  '₪',
  '₫',
  '€',
  '₭',
  '₮',
  '₯',
  '₰',
  '₱',
  '₲',
  '₳',
  '₴',
  '₵',
  '₶',
  '₷',
  '₸',
  '₹',
  '₺',
  '₻',
  '₼',
  '₽',
  '₾',
  '₿',
  '꠸',
  '﷼',
  '﹩',
  '＄',
  '￠',
  '￡',
  '￥',
  '￦',
  '𑿝',
  '𑿞',
  '𑿟',
  '𑿠',
  '𞋿',
  '𞲰'
];
