var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { BehaviorSubject, defer, of, Subject, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, share, switchMap, tap } from 'rxjs/operators';
import { AppConfigService, LocalStorage, SessionStorage } from '@core';
import { ApiService, isTokenExpired, ServerRequestError } from '@modules/api';
import { CurrentProjectStore } from '@modules/projects';
import { ResponseService } from '../response/response.service';
var ProjectApiService = /** @class */ (function () {
    function ProjectApiService(apiService, responseService, currentProjectStore, sessionStorage, localStorage, appConfigService, http) {
        this.apiService = apiService;
        this.responseService = responseService;
        this.currentProjectStore = currentProjectStore;
        this.sessionStorage = sessionStorage;
        this.localStorage = localStorage;
        this.appConfigService = appConfigService;
        this.http = http;
        this.onError = new Subject();
        this._overrideToken$ = new BehaviorSubject(undefined);
    }
    ProjectApiService.prototype.apiBaseUrlForProjectResource = function (resource) {
        if (!resource || !resource.params['url']) {
            return;
        }
        return "" + resource.params['url'];
    };
    ProjectApiService.prototype.getAccessToken = function () {
        var projectName = this.currentProjectStore.instance.uniqueName;
        if (this.overrideToken) {
            return this.overrideToken.accessToken;
        }
        else if (this.isSessionScope()) {
            return this.sessionStorage.get("jet_project_" + projectName + "_access_token");
        }
        else {
            return this.localStorage.get("jet_project_" + projectName + "_access_token");
        }
    };
    ProjectApiService.prototype.methodURLForProjectResource = function (method, resource) {
        return "" + this.apiBaseUrlForProjectResource(resource) + method;
    };
    ProjectApiService.prototype.modelUrlForProjectResource = function (model, resource) {
        return this.apiBaseUrlForProjectResource(resource) + "models/" + model + "/";
    };
    ProjectApiService.prototype.detailModelUrlForProjectResource = function (model, id, resource) {
        return this.apiBaseUrlForProjectResource(resource) + "models/" + model + "/" + encodeURIComponent(id) + "/";
    };
    ProjectApiService.prototype.actionUrlForProjectResource = function (model, action, resource) {
        return this.apiBaseUrlForProjectResource(resource) + "models/" + model + "/" + action + "/";
    };
    ProjectApiService.prototype.detailActionUrlForProjectResource = function (model, id, action, resource) {
        return this.apiBaseUrlForProjectResource(resource) + "models/" + model + "/" + encodeURIComponent(id) + "/" + action + "/";
    };
    Object.defineProperty(ProjectApiService.prototype, "overrideToken", {
        get: function () {
            return this._overrideToken$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectApiService.prototype, "overrideToken$", {
        get: function () {
            return this._overrideToken$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ProjectApiService.prototype.setOverrideToken = function (value) {
        this._overrideToken$.next(value);
    };
    ProjectApiService.prototype.clearOverrideToken = function () {
        this.setOverrideToken(undefined);
    };
    ProjectApiService.prototype.getToken = function (projectName, environmentName, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.apiService.refreshToken(options.forceOriginal).pipe(switchMap(function () {
            var url = _this.apiService.methodURL("projects/" + projectName + "/" + environmentName + "/token/");
            var headers = new HttpHeaders();
            var data = {};
            if (options.user) {
                data['user'] = options.user.uid;
            }
            if (options.mode) {
                data['mode'] = options.mode;
            }
            headers = _this.apiService.setHeadersToken(headers, undefined, options.forceOriginal);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) {
            return {
                accessToken: result['project_access_token'],
                accessTokenExpires: moment(result['project_access_token_expires']),
                refreshToken: result['project_refresh_token'],
                refreshTokenExpires: moment(result['project_refresh_token_expires']),
                serverTime: moment(result['server_time'])
            };
        }), this.apiService.catchApiError(false), publishLast(), refCount());
    };
    ProjectApiService.prototype.setHeadersToken = function (headers, childProjectName) {
        // return this.apiService.setHeadersToken(headers, childProjectName);
        var token = this.apiService.getToken();
        var projectToken = this.apiService.getProjectToken();
        var projectName = this.currentProjectStore.instance.uniqueName;
        var tokenValue;
        var authorization = [];
        if (token) {
            tokenValue = "Token " + token;
        }
        else if (projectToken) {
            tokenValue = "ProjectToken " + projectToken;
        }
        if (childProjectName && tokenValue) {
            tokenValue = tokenValue + ";project_child=" + childProjectName;
        }
        var accessToken;
        if (this.overrideToken) {
            accessToken = this.overrideToken.accessToken;
        }
        else if (this.isSessionScope()) {
            accessToken = this.sessionStorage.get("jet_project_" + projectName + "_access_token");
        }
        else {
            accessToken = this.localStorage.get("jet_project_" + projectName + "_access_token");
        }
        if (accessToken && !window['legacy_tokens']) {
            authorization.push("JWT " + accessToken);
        }
        else if (tokenValue) {
            authorization.push(tokenValue);
        }
        if (authorization.length) {
            headers = headers.set('Authorization', authorization);
        }
        return headers;
    };
    ProjectApiService.prototype.refreshToken = function (forceOriginal) {
        var _this = this;
        if (forceOriginal === void 0) { forceOriginal = false; }
        return defer(function () {
            if (_this.refreshObs) {
                return _this.refreshObs;
            }
            var refreshObs = _this.executeRefreshToken(forceOriginal).pipe(share());
            _this.refreshObs = refreshObs;
            refreshObs.subscribe(function () {
                _this.refreshObs = undefined;
            }, function () {
                _this.refreshObs = undefined;
            });
            return refreshObs;
        });
    };
    ProjectApiService.prototype.executeRefreshToken = function (forceOriginal) {
        var _this = this;
        if (forceOriginal === void 0) { forceOriginal = false; }
        var projectName = this.currentProjectStore.instance.uniqueName;
        var accessToken;
        var accessTokenExpires;
        if (this.overrideToken && !forceOriginal) {
            accessToken = this.overrideToken.accessToken;
            accessTokenExpires = this.overrideToken.accessTokenExpires;
        }
        else if (this.isSessionScope()) {
            var expiresStr = this.sessionStorage.get("jet_project_" + projectName + "_access_token_expires");
            accessToken = this.sessionStorage.get("jet_project_" + projectName + "_access_token");
            accessTokenExpires = expiresStr ? moment(expiresStr) : undefined;
        }
        else {
            var expiresStr = this.localStorage.get("jet_project_" + projectName + "_access_token_expires");
            accessToken = this.localStorage.get("jet_project_" + projectName + "_access_token");
            accessTokenExpires = expiresStr ? moment(expiresStr) : undefined;
        }
        if (accessToken && !isTokenExpired(accessTokenExpires)) {
            return of(true);
        }
        var refreshToken;
        var refreshTokenExpires;
        if (this.overrideToken) {
            refreshToken = this.overrideToken.refreshToken;
            refreshTokenExpires = this.overrideToken.refreshTokenExpires;
        }
        else if (this.isSessionScope()) {
            var expiresStr = this.sessionStorage.get("jet_project_" + projectName + "_refresh_token_expires");
            refreshToken = this.sessionStorage.get("jet_project_" + projectName + "_refresh_token");
            refreshTokenExpires = expiresStr ? moment(expiresStr) : undefined;
        }
        else {
            var expiresStr = this.localStorage.get("jet_project_" + projectName + "_refresh_token_expires");
            refreshToken = this.localStorage.get("jet_project_" + projectName + "_refresh_token");
            refreshTokenExpires = expiresStr ? moment(expiresStr) : undefined;
        }
        if (!refreshToken || !refreshTokenExpires || refreshTokenExpires.diff(moment(), 'minutes') < 1) {
            return of(false);
        }
        var refreshUrl = this.apiService.methodURL('token/refresh/');
        var params = {
            v: this.appConfigService.version
        };
        var data = { refresh: refreshToken };
        return this.http.post(refreshUrl, data, { params: params }).pipe(tap(function (result) {
            var options = {
                accessToken: result['access_token'],
                accessTokenExpires: moment(result['access_token_expires']),
                serverTime: moment(result['server_time'])
            };
            if (result['refresh_token']) {
                options.refreshToken = result['refresh_token'];
            }
            if (result['refresh_token_expires']) {
                options.refreshTokenExpires = moment(result['refresh_token_expires']);
            }
            _this.saveToken(options);
        }), switchMap(function () {
            var refreshCompleteUrl = _this.apiService.methodURL('token/refresh/complete/');
            return _this.http.post(refreshCompleteUrl, data);
        }), map(function () { return true; }));
    };
    ProjectApiService.prototype.saveToken = function (options) {
        var getLocalTime = function (date) {
            return moment().add(date.diff(options.serverTime), 'milliseconds').toISOString();
        };
        var projectName = this.currentProjectStore.instance.uniqueName;
        if (this.overrideToken) {
            var newToken = __assign({}, this.overrideToken);
            if (options.accessToken) {
                newToken.accessToken = options.accessToken;
                newToken.accessTokenExpires = moment(getLocalTime(options.accessTokenExpires));
            }
            if (options.refreshToken) {
                newToken.refreshToken = options.refreshToken;
                newToken.refreshTokenExpires = moment(getLocalTime(options.refreshTokenExpires));
            }
            this.setOverrideToken(newToken);
        }
        else if (this.isSessionScope()) {
            if (options.accessToken) {
                this.sessionStorage.set("jet_project_" + projectName + "_access_token", options.accessToken);
                this.sessionStorage.set("jet_project_" + projectName + "_access_token_expires", getLocalTime(options.accessTokenExpires));
            }
            if (options.refreshToken) {
                this.sessionStorage.set("jet_project_" + projectName + "_refresh_token", options.refreshToken);
                this.sessionStorage.set("jet_project_" + projectName + "_refresh_token_expires", getLocalTime(options.refreshTokenExpires));
            }
        }
        else {
            if (options.accessToken) {
                this.localStorage.set("jet_project_" + projectName + "_access_token", options.accessToken);
                this.localStorage.set("jet_project_" + projectName + "_access_token_expires", getLocalTime(options.accessTokenExpires));
            }
            if (options.refreshToken) {
                this.localStorage.set("jet_project_" + projectName + "_refresh_token", options.refreshToken);
                this.localStorage.set("jet_project_" + projectName + "_refresh_token_expires", getLocalTime(options.refreshTokenExpires));
            }
        }
    };
    ProjectApiService.prototype.deleteToken = function () {
        var projectName = this.currentProjectStore.instance.uniqueName;
        if (this.overrideToken) {
            this.setOverrideToken({});
        }
        else if (this.isSessionScope()) {
            this.sessionStorage.remove("jet_project_" + projectName + "_access_token");
            this.sessionStorage.remove("jet_project_" + projectName + "_access_token_expires");
            this.sessionStorage.remove("jet_project_" + projectName + "_refresh_token");
            this.sessionStorage.remove("jet_project_" + projectName + "_refresh_token_expires");
        }
        else {
            this.localStorage.remove("jet_project_" + projectName + "_access_token");
            this.localStorage.remove("jet_project_" + projectName + "_access_token_expires");
            this.localStorage.remove("jet_project_" + projectName + "_refresh_token");
            this.localStorage.remove("jet_project_" + projectName + "_refresh_token_expires");
        }
    };
    ProjectApiService.prototype.setHeadersBridgeSettings = function (headers, resource) {
        if (resource.params['bridge_settings']) {
            return headers.set('X-Bridge-Settings', resource.params['bridge_settings']);
        }
        return headers;
    };
    ProjectApiService.prototype.catchApiError = function (processAuthExpire) {
        var _this = this;
        if (processAuthExpire === void 0) { processAuthExpire = true; }
        return catchError(function (error) {
            if (processAuthExpire && error instanceof HttpErrorResponse && error.status == 401) {
                console.error(error);
            }
            if (error instanceof HttpErrorResponse) {
                var warning = error.headers.get('X-Application-Warning');
                if (warning) {
                    _this.responseService.handleWarning(warning);
                }
            }
            var serverError = new ServerRequestError(error);
            serverError.exception = error;
            _this.onError.next(serverError);
            return throwError(serverError);
        });
    };
    ProjectApiService.prototype.processApiResponse = function () {
        var _this = this;
        return map(function (response) {
            var warning = response.headers.get('X-Application-Warning');
            if (warning) {
                _this.responseService.handleWarning(warning);
            }
            return response.body;
        });
    };
    ProjectApiService.prototype.isSessionScope = function () {
        return this.apiService.isSessionScope();
    };
    return ProjectApiService;
}());
export { ProjectApiService };
