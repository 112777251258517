import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';

import { NpmRegistryService } from '../services/npm/npm-registry.service';

@Injectable()
export class NpmPackageVersionSource extends SelectSource {
  package: string;
  loadedPage = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private npmService: NpmRegistryService
  ) {
    super();
  }

  fetch(searchQuery: string): Observable<Option[]> {
    if (!isSet(this.package)) {
      return of([]);
    }

    searchQuery = (searchQuery || '').trim();

    return this.npmService
      .getDetail(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        this.package
      )
      .pipe(
        map(result => {
          return (result.versions || [])
            .map(item => {
              return {
                value: item.name,
                name: item.name
              };
            })
            .filter(item => {
              if (!searchQuery) {
                return true;
              }

              return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1;
            })
            .reverse();
        }),
        tap(() => {
          this.loadedPage = true;
        })
      );
  }

  fetchByValue(value: string): Observable<Option> {
    if (!value) {
      return of(undefined);
    }

    if (!isSet(this.package)) {
      return of(undefined);
    }

    return this.npmService
      .getDetail(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        this.package
      )
      .pipe(
        map(result => {
          const version = (result.versions || []).find(item => item.name == value);
          if (!version) {
            return;
          }

          return {
            value: version.name,
            name: version.name
          };
        })
      );
  }

  isFetchAvailable(): boolean {
    return !this.loadedPage;
  }

  resetPagination() {
    this.loadedPage = false;
  }

  setStaticOptions(options: Option[]) {}
}
